export const tryParseFloat = (value, defaultValue) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
};
export const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
};
// we need this method because window.open(url, '_blank') doesn't work on mobile
export function openInNewTab(url) {
    const newWindow = window.open(url, '_blank');
    if (newWindow === null) {
        // The popup was blocked or not allowed
        window.open(url, '_self');
    }
}
export function isLocalEnvironment() {
    return ['local'].includes(process.env.APP_ENV);
}
export const makeDictFromScottishImprovements = (text) => {
    // downcase the text
    text = text.toLowerCase();
    // split the text by ";" to get key value pairs
    const keyValuePairs = text.split(';')
        // filtering out any potentially remaining empty strings
        .filter(str => str.trim().length);
    // construct a dictionary
    const dictionary = {};
    for (const pair of keyValuePairs) {
        const [key, value] = pair.split(':').map(str => str.trim());
        dictionary[key] = value;
    }
    return dictionary;
};
export const fileToBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};
export function base64ToFile(base64, filename, contentType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], filename, { type: contentType });
}
export function base64DataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
export function isFlagSet(value, flag) {
    return (value & flag) === flag;
}
export const removeUndefinedFromList = (list) => {
    return list.filter(x => !!x);
};
const excludeList = ['win'];
export const pluralise = (word, length) => {
    return length === 1 || excludeList.includes(word) ? word : `${word}s`;
};
