import { uniq } from 'lodash';
import { Line, Text } from 'react-konva';
import { getFloorAreaM2 } from '../../../code/models/heat_loss';
import { stepSize } from '../constants';
import React from 'react';
import { magnetiseSize, indigo600, gray900, gray400 } from './code/constants';
import { getNextWall, getPrevWall, calculateRotation, calculateLineLength } from './code/utils';
export const LineWithText = ({ onDragEnd, onDragStart, otherRoomId, onClick, innerText, draggable, wallIndex, walls, scale, setWalls, x, y, setCurrentWallId, setPage, isCurrentRoom, isCurrentWall, rooms, room }) => {
    var _a;
    const otherRooms = rooms.filter(x => x.uuid !== (room === null || room === void 0 ? void 0 : room.uuid));
    const otherRoomsX = uniq(otherRooms.flatMap(x => x.walls.flatMap(y => y.x + x.x)));
    const otherRoomsY = uniq(otherRooms.flatMap(x => x.walls.flatMap(y => y.y + x.y)));
    const currentVertex = walls[wallIndex];
    const nextVertex = getNextWall(currentVertex, walls);
    const nextNextVertex = getNextWall(nextVertex, walls);
    const prevVertex = getPrevWall(currentVertex, walls);
    const isHorizontal = currentVertex.x === nextVertex.x;
    const prevIsHorizontal = prevVertex.x === currentVertex.x;
    const nextIsHorizontal = nextVertex.x === nextNextVertex.x;
    const isPartialWall = (isHorizontal && (prevIsHorizontal || nextIsHorizontal)) ||
        (!isHorizontal && (!prevIsHorizontal || !nextIsHorizontal));
    // Calculate the midpoint of the line
    const midX = (currentVertex.x + nextVertex.x) / 2;
    const midY = (currentVertex.y + nextVertex.y) / 2;
    // const length = Math.sqrt(x1 * x2 + y1 * y2)
    const text = `${(calculateLineLength(currentVertex.x, currentVertex.y, nextVertex.x, nextVertex.y, stepSize))} m`;
    const rotation = calculateRotation(currentVertex.x, currentVertex.y, nextVertex.x, nextVertex.y);
    // const offset = calculateOffset(rotation, 20)
    // const offsetX = rotation === 0 ? text.length * 4 : 0
    const fontSize = Math.min(10 * scale.x, 10);
    const offsetX = text.length * (fontSize / 5);
    const fontSizeSmall = Math.min(9 * scale.x, 9);
    const offsetXInnerSmall = innerText.length * (fontSizeSmall / 5);
    // 180, 0 should only move on X plane
    // 90, -90 should only move on Y plane
    const textRotation = new Map([
        [0, { rotation: 0, offset: -10 }],
        [90, { rotation: -90, offset: 20 }],
        [180, { rotation: 0, offset: 20 }],
        [-90, { rotation: -90, offset: -10 }]
    ]).get(rotation);
    const textRotationInner = new Map([
        [0, { rotation: 0, offset: 13 }], // bottom
        [90, { rotation: -90, offset: -5 }], // left
        [180, { rotation: 0, offset: -5 }], // top
        [-90, { rotation: -90, offset: 13 }] // right
    ]).get(rotation);
    const points = (currentVertex.x > nextVertex.x)
        ? [nextVertex.x, currentVertex.y, currentVertex.x, nextVertex.y]
        : (currentVertex.y > nextVertex.y)
            ? [currentVertex.x, nextVertex.y, nextVertex.x, currentVertex.y]
            : [currentVertex.x, currentVertex.y, nextVertex.x, nextVertex.y];
    const onDragMove = (e) => {
        var _a, _b;
        e.currentTarget.position({ x, y });
        if (!draggable || isPartialWall)
            return;
        const isHorizontal = currentVertex.y === nextVertex.y;
        const cursor = e.currentTarget.getStage().getRelativePointerPosition();
        const newX = Math.round(cursor.x / stepSize) * stepSize;
        const newY = Math.round(cursor.y / stepSize) * stepSize;
        const closestX = (_a = otherRoomsX.find(y => Math.abs(y - newX) <= magnetiseSize)) !== null && _a !== void 0 ? _a : newX;
        const closestY = (_b = otherRoomsY.find(x => Math.abs(x - newY) <= magnetiseSize)) !== null && _b !== void 0 ? _b : newY;
        const newWall = { ...walls[wallIndex] };
        const nextIndex = walls.length - 1 === wallIndex ? 0 : wallIndex + 1;
        const nextWall = { ...walls[nextIndex] };
        if (isHorizontal) {
            newWall.y = closestY - y;
            nextWall.y = closestY - y;
        }
        else {
            newWall.x = closestX - x;
            nextWall.x = closestX - x;
        }
        const newWalls = walls.map(x => x.uuid === newWall.uuid ? newWall : x.uuid === nextWall.uuid ? nextWall : x);
        // Don't allow the room to collapse to a flat line
        if (getFloorAreaM2(newWalls, stepSize) !== 0)
            setWalls(newWalls);
    };
    const strokeWidth = isCurrentRoom
        ? 4
        : 1;
    const applicable = (_a = walls[wallIndex].material) === null || _a === void 0 ? void 0 : _a.applicable_to;
    const wallMaterialMappings = {
        'external-wall': { dash: [], stroke: isCurrentWall ? indigo600 : gray900 },
        'internal-wall': { dash: isCurrentRoom ? [8, 8] : [6, 6], stroke: isCurrentWall ? indigo600 : gray400 },
        'party-wall': { dash: isCurrentRoom ? [8, 8] : [6, 6], stroke: isCurrentWall ? indigo600 : gray900 }
    };
    const stroke = applicable && wallMaterialMappings[applicable];
    return React.createElement(React.Fragment, null,
        React.createElement(Line, { x: x, y: y, lineJoin: 'round', lineCap: 'round', dash: stroke === null || stroke === void 0 ? void 0 : stroke.dash, hitStrokeWidth: isCurrentRoom ? (25 - scale.x) : 0, onTap: onClick, onClick: onClick, points: points, stroke: stroke === null || stroke === void 0 ? void 0 : stroke.stroke, strokeWidth: strokeWidth, draggable: draggable, onDragStart: onDragStart, onDragEnd: onDragEnd, onDragMove: onDragMove, onMouseEnter: (e) => {
                const stage = e.currentTarget.getStage().container();
                stage.style.cursor = 'pointer';
            }, onMouseLeave: (e) => {
                const stage = e.currentTarget.getStage().container();
                stage.style.cursor = 'move';
            } }),
        isCurrentRoom && React.createElement(Text, { onClick: () => {
                setCurrentWallId(walls[wallIndex].uuid);
                setPage('WALL_LENGTH');
            }, onTap: () => {
                setCurrentWallId(walls[wallIndex].uuid);
                setPage('WALL_LENGTH');
            }, fontFamily: 'Manrope', fill: indigo600, text: text, x: x + midX, y: y + midY, rotation: textRotation === null || textRotation === void 0 ? void 0 : textRotation.rotation, fontSize: fontSize, offsetX: offsetX, offsetY: textRotation === null || textRotation === void 0 ? void 0 : textRotation.offset }),
        React.createElement(Text, { onClick: () => {
                setCurrentWallId(walls[wallIndex].uuid);
                setPage('WALL_LENGTH');
            }, onTap: () => {
                setCurrentWallId(walls[wallIndex].uuid);
                setPage('WALL_LENGTH');
            }, fontFamily: 'Manrope', fill: isCurrentRoom ? gray900 : indigo600, text: innerText, opacity: isCurrentRoom ? 1 : 0.5, x: x + midX, y: y + midY, rotation: textRotationInner === null || textRotationInner === void 0 ? void 0 : textRotationInner.rotation, fontSize: fontSizeSmall, offsetX: offsetXInnerSmall, offsetY: textRotationInner === null || textRotationInner === void 0 ? void 0 : textRotationInner.offset }));
};
