import { faCamera, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from '../containers/modal';
export const MAX_IMAGE_WIDTH = 600;
export const MAX_IMAGE_HEIGHT = 600;
export const PhotoScroll = ({ images, deleteImage, addImage, doNotScale, viewMode }) => {
    const [currentImageId, setCurrentImageId] = useState(undefined);
    // sometimes we got inconsistent data, so we need to filter out undefined images
    const cleanedImages = images.filter(x => x !== undefined);
    const currentImage = cleanedImages.find(x => x.uuid === currentImageId);
    const randomUUID = crypto.randomUUID();
    const handleFileSelect = (e) => {
        var _a, _b, _c, _d;
        if (!addImage)
            return;
        // if doNotScale is true, we don't need to scale the image
        if (doNotScale) {
            const newFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
            const newFileWrapper = newFile && { uuid: crypto.randomUUID(), file: newFile };
            newFileWrapper && addImage(newFileWrapper);
            return;
        }
        // otherwise, we need to scale the image
        const selectedFile = (_d = (_c = e.target) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d[0];
        if (!selectedFile)
            return;
        // change image size
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        // Ensure image is loaded before drawing
        image.onload = () => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            // Calculate the new dimensions, maintaining the aspect ratio
            if (width > height) {
                if (width > MAX_IMAGE_WIDTH) {
                    height *= MAX_IMAGE_WIDTH / width;
                    width = MAX_IMAGE_WIDTH;
                }
            }
            else {
                if (height > MAX_IMAGE_HEIGHT) {
                    width *= MAX_IMAGE_HEIGHT / height;
                    height = MAX_IMAGE_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            if (!context)
                return;
            context.drawImage(image, 0, 0, MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT);
            canvas.toBlob(blob => {
                const newFileWrapper = {
                    uuid: crypto.randomUUID(),
                    file: new File([blob], selectedFile.name, { type: selectedFile.type })
                };
                addImage(newFileWrapper);
            });
        };
    };
    // compact mode is only applicable to empty galleries
    const isCompactMode = viewMode === 'COMPACT' && cleanedImages.length === 0;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex w-full overflow-auto gap-2' },
            addImage && React.createElement(React.Fragment, null,
                React.createElement("label", { htmlFor: `dropzone-file-${randomUUID}` },
                    isCompactMode &&
                        React.createElement("div", { className: "px-4 py-3 rounded-md border border-gray-300 flex gap-2 items-center cursor-pointer" },
                            React.createElement(FontAwesomeIcon, { className: 'text-sm', icon: faPlus }),
                            React.createElement("div", { className: "text-sm font-bold" }, "Add photos")),
                    !isCompactMode &&
                        React.createElement("div", { className: 'flex flex-col gap-2 w-32 h-32 bg-gray-100 items-center justify-center cursor-pointer text-gray-600 rounded-lg' },
                            React.createElement(FontAwesomeIcon, { className: 'text-lg', icon: faCamera }),
                            React.createElement("div", null, "Add photo"))),
                React.createElement("input", { id: `dropzone-file-${randomUUID}`, className: 'hidden', type: "file", accept: 'image/*', onChange: handleFileSelect })),
            cleanedImages.reverse().map(x => React.createElement("div", { key: x.uuid, className: 'relative flex-shrink-0' },
                deleteImage && React.createElement(FontAwesomeIcon, { onClick: () => deleteImage(x.uuid), className: 'text-gray-600 cursor-pointer absolute top-0 right-0 z-10 p-2', icon: faTimes }),
                React.createElement("img", { className: 'rounded-lg h-32 cursor-pointer', onClick: () => setCurrentImageId(x.uuid), src: URL.createObjectURL(x.file) })))),
        currentImage &&
            React.createElement(Modal, { visible: true, setVisible: () => setCurrentImageId(undefined), title: '' },
                React.createElement("img", { className: 'rounded-lg w-full', src: URL.createObjectURL(currentImage.file) })));
};
export const resizeBase64Image = async (base64Str, maxWidth = MAX_IMAGE_WIDTH, maxHeight = MAX_IMAGE_HEIGHT) => {
    return await new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            let { width, height } = image;
            // Calculate new dimensions while maintaining aspect ratio
            if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                    width = maxWidth;
                    height = maxWidth / aspectRatio;
                }
                else {
                    height = maxHeight;
                    width = maxHeight * aspectRatio;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            const resizedBase64 = canvas.toDataURL('image/png');
            resolve(resizedBase64);
        };
        image.onerror = (error) => reject(error);
        image.src = base64Str;
    });
};
