import React from 'react';
import { Checkbox } from '../inputs_and_selections/checkbox';
const sizes = {
    SM: 'text-xs',
    MD: 'text-sm'
};
export const TableLite = ({ selectedIds, setSelectedIds, columns, rows, rowIdName, size = 'MD', showCheckboxes = true }) => {
    const toggleSelected = setSelectedIds && ((id) => setSelectedIds(prev => prev.includes(id) ? prev.filter(x => x !== id) : [...prev, id]));
    const allChecked = selectedIds && rowIdName && rows.every(x => selectedIds.includes(x[rowIdName]));
    return React.createElement("table", { className: `${sizes[size]} text-gray-600 w-full divide-y divide-dashed divide-gray-200 border-b border-dashed border-gray-200` },
        React.createElement("thead", { className: 'uppercase tracking-wide text-gray-500 font-semibold' },
            React.createElement("tr", null,
                showCheckboxes && setSelectedIds && rowIdName && React.createElement("th", { className: 'p-2 text-start' },
                    React.createElement(Checkbox, { checked: !!allChecked, setChecked: () => allChecked ? setSelectedIds([]) : setSelectedIds(rows.map(x => x[rowIdName])) })),
                columns.map((x, i) => React.createElement("th", { key: i, className: `p-2 ${i === 0 ? 'text-start' : 'text-end'}` }, x.name)))),
        React.createElement("tbody", { className: 'divide-y divide-dashed divide-gray-200' }, rows.map((x, xi) => {
            return React.createElement("tr", { key: xi, className: 'p-2' },
                showCheckboxes && selectedIds && toggleSelected && rowIdName && React.createElement("td", { className: 'p-2' },
                    React.createElement(Checkbox, { checked: selectedIds.includes(x[rowIdName]), setChecked: () => toggleSelected(x[rowIdName]) })),
                columns.map((y, yi) => React.createElement("td", { key: yi, className: `p-2 ${yi === 0 ? 'text-start' : 'text-end'}` }, y.render ? y.render(x) : React.createElement("div", null, x[y.key]))));
        })));
};
