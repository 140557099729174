import { trimPointerPosition } from '../util';
import { uniq } from 'lodash';
import { magnetiseSize } from './constants';
export const stageOnMouseMove = (e, scalingPoints, drawingRooms, scalingWindows, floor, setMousePosition, stageStep) => {
    // If we are adding a complex room.
    if (drawingRooms || stageStep || scalingWindows) {
        const pointerPosition = e.currentTarget.getRelativePointerPosition();
        const otherRooms = uniq(floor.rooms.flatMap(x => x.walls.flatMap(y => ({ x: y.x + x.x, y: y.y + x.y }))));
        const magnetisePoints = stageStep ? [] : [...otherRooms, ...scalingPoints]; // Don't magnetise if we are just drawing a scaling line.
        const horizontalOrVerticalLine = trimAndFixAngleAndMagnetise(scalingPoints, pointerPosition, magnetisePoints);
        // Once we have made the line vertical or horizontal, snapped to the canvas or another object, we can set the value.
        setMousePosition(horizontalOrVerticalLine);
    }
};
/**
* @Trim Always rounds the pointer position to our stepSize value so they are always increments of e.g. 5px and they snap together.
* @FixAngle A line should always be either fully vertical or fully horizontal
* @Magnetise If we are close to another line (stepSize * 2 distance) snap to that position instead of my current position.
*/
export const trimAndFixAngleAndMagnetise = (scalingPoints, cursorPosition, magnetisePoints) => {
    var _a, _b, _c, _d;
    const prevPoint = scalingPoints[scalingPoints.length - 1];
    const newPoint = trimPointerPosition(cursorPosition.x, cursorPosition.y);
    // Draw me a horizontal or vertical line only, based on which axis is closest to my cursor.
    const yAxisIsClosest = prevPoint && Math.abs(newPoint.y - prevPoint.y) < Math.abs(newPoint.x - prevPoint.x);
    // Using my magnetise tolerance, try to snap to any existing lines on the canvas.
    const closestX = yAxisIsClosest ? (_b = (_a = magnetisePoints.find(p => Math.abs(p.x - newPoint.x) <= magnetiseSize)) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : newPoint.x : newPoint.x;
    const closestY = !yAxisIsClosest ? (_d = (_c = magnetisePoints.find(p => Math.abs(p.y - newPoint.y) <= magnetiseSize)) === null || _c === void 0 ? void 0 : _c.y) !== null && _d !== void 0 ? _d : newPoint.y : newPoint.y;
    const horizontalOrVerticalLine = !prevPoint
        ? newPoint
        : yAxisIsClosest
            ? { x: closestX, y: prevPoint.y }
            : { y: closestY, x: prevPoint.x };
    return horizontalOrVerticalLine;
};
