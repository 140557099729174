import React, { useState } from 'react';
import { ButtonDemo } from './buttons/button_demo';
import { IconDemo } from './buttons/icon_demo';
import { InfoDemo } from './buttons/info_demo';
import { LinkDemo } from './buttons/link_demo';
import { BottomSheetHeaderDemo } from './containers/bottom_sheet_header_demo';
import { ModalDemo } from './containers/modal_demo';
import { SectionDemo } from './containers/section_demo';
import { AccordionDemo } from './content_display/accordion_demo';
import { CardDemo } from './content_display/card_demo';
import { EmptyStateDemo } from './content_display/empty_state_demo';
import { ListItemDemo } from './content_display/list_item_demo';
import { TabDemo } from './content_display/tab_demo';
import { TableDemo } from './content_display/table_demo';
import { TableLiteDemo } from './content_display/table_lite_demo';
import { AlertDemo } from './indicators_and_messaging/alert_demo';
import { BadgeDemo } from './indicators_and_messaging/badge_demo';
import { LoaderDemo } from './indicators_and_messaging/loader_demo';
import { ProgressChartDemo } from './indicators_and_messaging/progress_chart_demo';
import { ToastDemo } from './indicators_and_messaging/toast_demo';
import { CheckboxDemo } from './inputs_and_selections/checkbox_demo';
import { SelectDemo } from './inputs_and_selections/select_demo';
import { FormLabelDemo } from './inputs_and_selections/form_label_demo';
import { InputDemo } from './inputs_and_selections/input_demo';
import { InputGroupDemo } from './inputs_and_selections/input_group_demo';
import { PhotoScrollDemo } from './inputs_and_selections/photo_scroll_demo';
import { RadioDemo } from './inputs_and_selections/radio_demo';
import { SliderDemo } from './inputs_and_selections/slider_demo';
import { TextAreaDemo } from './inputs_and_selections/text_area_demo';
import { ToggleDemo } from './inputs_and_selections/toggle_demo';
import { ToggleInputGroupDemo } from './inputs_and_selections/toggle_input_group_demo';
export const Components = () => {
    const navbarItems = [
        {
            title: 'Buttons',
            page: undefined,
            children: [
                { title: 'Button', page: React.createElement(ButtonDemo, null), children: [] },
                { title: 'Icon', page: React.createElement(IconDemo, null), children: [] },
                { title: 'Info', page: React.createElement(InfoDemo, null), children: [] },
                { title: 'Link', page: React.createElement(LinkDemo, null), children: [] }
            ]
        },
        {
            title: 'Containers',
            page: undefined,
            children: [
                { title: 'Bottom Sheet Header', page: React.createElement(BottomSheetHeaderDemo, null), children: [] },
                { title: 'Modal', page: React.createElement(ModalDemo, null), children: [] },
                { title: 'Section', page: React.createElement(SectionDemo, null), children: [] }
            ]
        },
        {
            title: 'Content Display',
            page: undefined,
            children: [
                { title: 'Accordion', page: React.createElement(AccordionDemo, null), children: [] },
                { title: 'Card', page: React.createElement(CardDemo, null), children: [] },
                { title: 'Empty State', page: React.createElement(EmptyStateDemo, null), children: [] },
                { title: 'List Item', page: React.createElement(ListItemDemo, null), children: [] },
                { title: 'Tab', page: React.createElement(TabDemo, null), children: [] },
                { title: 'Table Lite', page: React.createElement(TableLiteDemo, null), children: [] },
                { title: 'Table', page: React.createElement(TableDemo, null), children: [] }
            ]
        },
        {
            title: 'Indicators & Messaging',
            page: undefined,
            children: [
                { title: 'Alert', page: React.createElement(AlertDemo, null), children: [] },
                { title: 'Badge', page: React.createElement(BadgeDemo, null), children: [] },
                { title: 'Loader', page: React.createElement(LoaderDemo, null), children: [] },
                { title: 'Progress Chart', page: React.createElement(ProgressChartDemo, null), children: [] },
                { title: 'Toast', page: React.createElement(ToastDemo, null), children: [] }
            ]
        },
        {
            title: 'Inputs & Selectors',
            page: undefined,
            children: [
                { title: 'Checkbox', page: React.createElement(CheckboxDemo, null), children: [] },
                { title: 'Select', page: React.createElement(SelectDemo, null), children: [] },
                { title: 'Form Label', page: React.createElement(FormLabelDemo, null), children: [] },
                { title: 'Input Group', page: React.createElement(InputGroupDemo, null), children: [] },
                { title: 'Input', page: React.createElement(InputDemo, null), children: [] },
                { title: 'Photo Scroll', page: React.createElement(PhotoScrollDemo, null), children: [] },
                { title: 'Radio', page: React.createElement(RadioDemo, null), children: [] },
                { title: 'Slider', page: React.createElement(SliderDemo, null), children: [] },
                { title: 'Text Area', page: React.createElement(TextAreaDemo, null), children: [] },
                { title: 'Toggle Input Group', page: React.createElement(ToggleInputGroupDemo, null), children: [] },
                { title: 'Toggle', page: React.createElement(ToggleDemo, null), children: [] }
            ]
        }
    ];
    const [page, setPage] = useState(React.createElement(ButtonDemo, null));
    return React.createElement("div", { className: 'h-full flex' },
        React.createElement("div", { className: 'overflow-y-auto border-r border-gray-200 flex-shrink-0' },
            React.createElement(RenderItems, { menuItems: navbarItems, depth: 1, setPage: setPage })),
        React.createElement("div", { className: 'flex-grow p-8 max-w-3xl overflow-y-auto' }, page));
};
const RenderItems = ({ menuItems, depth, setPage }) => {
    return menuItems.map(x => React.createElement("div", { className: `flex flex-col ${x.page ? 'cursor-pointer' : ''}`, onClick: () => x.page && setPage(x.page), key: x.title },
        React.createElement("div", { style: { paddingLeft: depth * 10 }, className: `p-4 ${x.children.length > 0 ? 'font-bold text-gray-900' : ''} border-b border-gray-200` }, x.title),
        x.children.length > 0 && React.createElement("div", null,
            React.createElement(RenderItems, { menuItems: x.children, depth: depth + 1, setPage: setPage }))));
};
