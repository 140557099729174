import * as _ from 'lodash';
import { sum } from 'lodash';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from './models/u_value';
import { yearDiff } from './time_since';
import { getDesignConditions } from './models/design_temp';
import { checkIfScottish } from './models/address';
import { FUELS, getFuelByName } from './models/fuel';
import { makeDictFromScottishImprovements } from './helpers';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from './models/range_heat_pump';
import { convertLatLngListToLatLongLiteral } from './geocoding';
export const roofUValueFromInsulationThickness = (loftInsulationThickness) => {
    var _a;
    // for back compatability if value is 300mm use value for 250+mm
    const thicknessUUID = loftInsulationThickness === '300mm' ? '250+mm' : loftInsulationThickness;
    return (_a = LOFT_INSULATION.find(x => x.uuid === thicknessUUID)) === null || _a === void 0 ? void 0 : _a.u_value;
};
export const calculateQuote = (lead, heatPumps, hotWaterCylinders, company, selectedHeatPumpUUID, selectedHotWaterCylinderUUID) => {
    var _a, _b, _c, _d, _e, _f;
    const { designTempC, internalTempC, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, partyWallWatts, externalWallWatts, windowWatts, floorWatts, roofWatts, ventilationWatts, totalWatts, calculatedAirChanges, htcWPerK, defaults, degreeDays } = calculatePeakHeatLossW(lead);
    const numberOfBedrooms = getNumberOfBedrooms(lead);
    // const noBathroomsOverride = lead.property.houseOverrides?.noBathrooms ? lead.property.houseOverrides.noBathrooms : lead.property.noBathrooms
    const yearlySpacekWhHeat = htcWPerK * degreeDays / (1000 / 24);
    const yearlyWaterkWhHeat = 365 * (3 * numberOfBedrooms); // This should be occupants. Assume 3kwh a day per person for hot water, taken from MCS standards.
    const yearlykWhHeat = yearlySpacekWhHeat + yearlyWaterkWhHeat;
    const currentFuelType = ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.fuelType) ? lead.property.houseOverrides.fuelType : lead.property.fuelType;
    const currentFuel = getFuelByName(currentFuelType);
    const yearlykWhCurrentFuel = yearlykWhHeat / currentFuel.defaultHeatingSystemEfficiency;
    const yearlyCO2CurrentKg = calculateYearlyCO2kg(currentFuel, yearlykWhCurrentFuel);
    const yearlyCostCurrentGBP = calculateYearlyCostGBP(currentFuel, yearlykWhCurrentFuel);
    const assumedSCOP = 3.6; // Vaillant Arotherm Plus 7kW, 50C flow. https://energy-stats.uk/how-to-measure-vaillant-arotherm-cop/
    const electricity = FUELS.find(x => x.name === 'Electric');
    const yearlykWhHeatPump = yearlykWhHeat / assumedSCOP;
    const yearlyCO2HeatPumpKg = calculateYearlyCO2kg(electricity, yearlykWhHeatPump);
    const yearlyCostHeatPumpGBP = calculateYearlyCostGBP(electricity, yearlykWhHeatPump);
    const CO2SavedKg = Math.round(yearlyCO2CurrentKg - yearlyCO2HeatPumpKg);
    const flightsSaved = getFlightsSaved(CO2SavedKg);
    const commutesSaved = getCommutesSaved(CO2SavedKg);
    // Below only used in estimate currently, so can hard code the flow temp at 45C for now
    const heatPump = selectHeatPump(totalWatts, heatPumps, selectedHeatPumpUUID, lead.heat_pump_uuid, company, designTempC, 45);
    const hotWaterCylinder = selectCylinder(numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, lead.hot_water_cylinder_uuid);
    const dayRate = (_b = company === null || company === void 0 ? void 0 : company.install_day_rate) !== null && _b !== void 0 ? _b : 0;
    const days = (_c = company === null || company === void 0 ? void 0 : company.install_days) !== null && _c !== void 0 ? _c : 0;
    const labour = dayRate * days;
    const survey = Math.round(((_d = company === null || company === void 0 ? void 0 : company.survey_cost) !== null && _d !== void 0 ? _d : 0) * 1.20); // add VAT to survey cost
    // const radiators = (quote.property.noBedrooms + quote.property.noBathrooms + 2) * 300; // living room + kitchen
    const isScottish = checkIfScottish(lead.property.postcode);
    const inspectionDate = ((_e = lead.epcData) === null || _e === void 0 ? void 0 : _e.inspectionDate) ? new Date((_f = lead.epcData) === null || _f === void 0 ? void 0 : _f.inspectionDate) : undefined;
    // BUS grant for English properties
    const busGrantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, lead.epc_recommendations);
    // HES grant for Scottish properties
    const hesGrantEligibleReasons = checkIfEligibleForHesGrant(lead.epc_scotland);
    const grant = isScottish ? 0 : -7500;
    const lineItemPrices = lead.lead_line_items ? sum(lead.lead_line_items.map(x => Number(x.value))) : 0;
    const totalPrice = (heatPump && hotWaterCylinder) ? lineItemPrices + parseFloat(labour.toString()) + parseFloat(heatPump.price.toString()) + parseFloat(hotWaterCylinder.price.toString()) + parseFloat(heatPump.parts.toString()) + grant + survey : 0;
    return {
        hotWaterCylinder,
        heatPump,
        labour,
        survey,
        grant,
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        dayRate,
        days,
        totalWatts,
        CO2SavedKg,
        flightsSaved,
        commutesSaved,
        calculatedAirChanges,
        yearlyCostCurrentGBP,
        yearlyCostHeatPumpGBP,
        busGrantEligibleReasons,
        hesGrantEligibleReasons,
        defaults,
        totalPrice,
        isScottish
    };
};
export const getNumberOfBedrooms = (lead) => {
    var _a;
    return ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.noBedrooms) ? lead.property.houseOverrides.noBedrooms : lead.property.noBedrooms;
};
export const selectHeatPump = (totalWatts, heatPumps, selectedHeatPumpUUID, heatPumpOverrideUUID, company, designTempC = -3, flowTempC = 45) => {
    var _a, _b, _c, _d;
    // We are just taking the output capacity from the bilinear interpolate here - we are are not checking if that value is interpolated outside the bounds that we have data for
    // Probably ok for the estimate stage, but wouldn't be if we were using this in design
    const heatPumpsHydrated = heatPumps.map(x => ({ ...x, kwatts: x.kwatts === 0 && x.range_heat_pump ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw : x.kwatts }));
    const defaultBrandHeatPumpUUID = (_b = ((_a = _.orderBy(heatPumpsHydrated, x => x.kwatts * 1000).filter(x => { var _a; return x.kwatts * 1000 >= totalWatts && ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid) === (company === null || company === void 0 ? void 0 : company.default_brand_range_uuid); })[0]) !== null && _a !== void 0 ? _a : undefined)) === null || _b === void 0 ? void 0 : _b.uuid;
    // If there is no heat pump large enough in the default brand, choose the one that is just big enough from the whole list
    const calculatedHeatPumpUUID = defaultBrandHeatPumpUUID !== null && defaultBrandHeatPumpUUID !== void 0 ? defaultBrandHeatPumpUUID : (_d = ((_c = _.orderBy(heatPumpsHydrated, x => x.kwatts * 1000).filter(x => x.kwatts * 1000 >= totalWatts)[0]) !== null && _c !== void 0 ? _c : undefined)) === null || _d === void 0 ? void 0 : _d.uuid;
    // If nothing is big enough, don't choose anything
    const heatPump = heatPumpsHydrated === null || heatPumpsHydrated === void 0 ? void 0 : heatPumpsHydrated.find(x => { var _a; return x.uuid === ((_a = selectedHeatPumpUUID !== null && selectedHeatPumpUUID !== void 0 ? selectedHeatPumpUUID : heatPumpOverrideUUID) !== null && _a !== void 0 ? _a : calculatedHeatPumpUUID); });
    return heatPump;
};
export const selectCylinder = (numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, hotWaterCylinderOverrideUUID) => {
    var _a;
    const litresRequired = (numberOfBedrooms + 1) * 45;
    // choose cheapest cylinder that is big enough. In edge cases may mean you select a too big cylinder because it's cheaper than a smaller one, but I don't think many people have enough cylinders set up for this to happen
    const calculatedHotWaterCylinderUUID = (_a = (_.orderBy(hotWaterCylinders, x => parseInt(x.price.toString())).filter(x => parseInt(x.litres.toString()) >= litresRequired)[0])) === null || _a === void 0 ? void 0 : _a.uuid;
    const hotWaterCylinder = hotWaterCylinders === null || hotWaterCylinders === void 0 ? void 0 : hotWaterCylinders.find(x => { var _a; return x.uuid === ((_a = selectedHotWaterCylinderUUID !== null && selectedHotWaterCylinderUUID !== void 0 ? selectedHotWaterCylinderUUID : hotWaterCylinderOverrideUUID) !== null && _a !== void 0 ? _a : calculatedHotWaterCylinderUUID); });
    return hotWaterCylinder;
};
export const calculatePeakHeatLossW = (lead) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const designConditionDefaults = getDesignConditions(latLng, lead.property.altitudeM, false);
    const designTempC = (_c = (_b = (_a = lead.property) === null || _a === void 0 ? void 0 : _a.houseOverrides) === null || _b === void 0 ? void 0 : _b.designTempOverride) !== null && _c !== void 0 ? _c : designConditionDefaults.designTempDefaultC;
    const airChangesDefault = 1; // Air changes per hour
    const internalTempDefault = 20;
    const heatCapacityOfAir = 0.33; // Physics number used as multiplier for how much heat the air will take away with it.
    const airChanges = ((_e = (_d = lead.property) === null || _d === void 0 ? void 0 : _d.houseOverrides) === null || _e === void 0 ? void 0 : _e.airChangeOverride)
        ? (_g = (_f = lead.property) === null || _f === void 0 ? void 0 : _f.houseOverrides) === null || _g === void 0 ? void 0 : _g.airChangeOverride
        : airChangesDefault;
    const internalTempC = ((_j = (_h = lead.property) === null || _h === void 0 ? void 0 : _h.houseOverrides) === null || _j === void 0 ? void 0 : _j.internalTempOverride)
        ? (_l = (_k = lead.property) === null || _k === void 0 ? void 0 : _k.houseOverrides) === null || _l === void 0 ? void 0 : _l.internalTempOverride
        : internalTempDefault;
    const tempDiff = internalTempC - designTempC;
    const tempDiffPartyWall = internalTempC - 10; // From MCS calculator. Conservative given other side of wall is likely heated. Will account for some thermal bridging though
    const externalWallUValueOverride = (_o = (_m = (lead.property)) === null || _m === void 0 ? void 0 : _m.houseOverrides) === null || _o === void 0 ? void 0 : _o.externalWallUValueOverride;
    const partyWallUValueOverride = (_q = (_p = (lead.property)) === null || _p === void 0 ? void 0 : _p.houseOverrides) === null || _q === void 0 ? void 0 : _q.partyWallUValueOverride;
    const windowsUValueOverride = (_s = (_r = (lead.property)) === null || _r === void 0 ? void 0 : _r.houseOverrides) === null || _s === void 0 ? void 0 : _s.windowsUValueOverride;
    const floorUValueOverride = (_u = (_t = (lead.property)) === null || _t === void 0 ? void 0 : _t.houseOverrides) === null || _u === void 0 ? void 0 : _u.floorUValueOverride;
    const roofUValueOverride = (_w = (_v = (lead.property)) === null || _v === void 0 ? void 0 : _v.houseOverrides) === null || _w === void 0 ? void 0 : _w.roofUValueOverride;
    const roomHeightOverride = ((_x = lead.property.houseOverrides) === null || _x === void 0 ? void 0 : _x.roomHeight) ? lead.property.houseOverrides.roomHeight : lead.property.roomHeight;
    const floorAreaOverride = ((_y = lead.property.houseOverrides) === null || _y === void 0 ? void 0 : _y.floorArea) ? lead.property.houseOverrides.floorArea : lead.property.floorArea;
    const propertyTypeOverride = ((_z = lead.property.houseOverrides) === null || _z === void 0 ? void 0 : _z.propertyType) ? lead.property.houseOverrides.propertyType : lead.property.propertyType;
    const builtFormOverride = ((_0 = lead.property.houseOverrides) === null || _0 === void 0 ? void 0 : _0.builtForm) ? lead.property.houseOverrides.builtForm : lead.property.builtForm;
    const wallGroupOverride = ((_1 = lead.property.houseOverrides) === null || _1 === void 0 ? void 0 : _1.wallGroup) ? lead.property.houseOverrides.wallGroup : lead.property.wallGroup;
    const wallTypeOverride = ((_2 = lead.property.houseOverrides) === null || _2 === void 0 ? void 0 : _2.wallType) ? lead.property.houseOverrides.wallType : lead.property.wallType;
    const floorTypeOverride = ((_3 = lead.property.houseOverrides) === null || _3 === void 0 ? void 0 : _3.floorType) ? lead.property.houseOverrides.floorType : lead.property.floorType;
    const loftInsulationOverride = ((_4 = lead.property.houseOverrides) === null || _4 === void 0 ? void 0 : _4.loftInsulation) ? lead.property.houseOverrides.loftInsulation : lead.property.loftInsulation;
    const windowTypeOverride = ((_5 = lead.property.houseOverrides) === null || _5 === void 0 ? void 0 : _5.windowType) ? lead.property.houseOverrides.windowType : lead.property.windowType;
    // Find how conductive each surface is of the house, pulled from EPC and form data.
    // A UValue is how many watts, per degree, per m2 the material will lose.
    const externalWallDefault = lead.property.wallUValue > 0
        ? lead.property.wallUValue
        : calculatedExternalWallUValue(wallTypeOverride, wallGroupOverride);
    const externalWallUValue = externalWallUValueOverride > 0
        ? externalWallUValueOverride
        : externalWallDefault;
    const partyWallDefault = lead.property.wallUValue > 0
        ? Math.min(lead.property.wallUValue, 0.5) // if U value defined for new build walls in EPC data, use the better of that and 0.5
        : 0.5; // hard coded as 0.5 in MCS. That's also the worst case option in RdSAP Table 15
    const partyWallUValue = partyWallUValueOverride > 0
        ? partyWallUValueOverride
        : partyWallDefault;
    const windowDefault = lead.property.windowUValue > 0
        ? lead.property.windowUValue
        : (_6 = WINDOW_TYPES.find(x => x.uuid === windowTypeOverride)) === null || _6 === void 0 ? void 0 : _6.u_value;
    const windowsUValue = windowsUValueOverride > 0
        ? windowsUValueOverride
        : windowDefault;
    const floorDefault = lead.property.floorUValue > 0
        ? lead.property.floorUValue
        : (_7 = FLOOR_INSULATION.find(x => x.uuid === floorTypeOverride)) === null || _7 === void 0 ? void 0 : _7.u_value;
    const floorUValue = floorUValueOverride > 0
        ? floorUValueOverride
        : floorDefault;
    const roofDefault = lead.property.roofUValue > 0
        ? lead.property.roofUValue
        : roofUValueFromInsulationThickness(loftInsulationOverride);
    const roofUValue = roofUValueOverride > 0
        ? roofUValueOverride
        : roofDefault;
    const calculatedAirChanges = airChanges > 0
        ? airChanges
        : airChangesDefault;
    const defaults = {
        externalWallDefault,
        partyWallDefault,
        windowDefault,
        floorDefault,
        roofDefault,
        airChangesDefault,
        internalTempDefault,
        designTempDefault: designConditionDefaults.designTempDefaultC
    };
    const areas = calculateBuildingElementAreas(roomHeightOverride, builtFormOverride, propertyTypeOverride, floorAreaOverride);
    // Multiply area by temperature difference required.
    // Multiply result by UValue of surface to calculate watts lost.
    const partyWallWatts = Math.round(areas.partyWallAreaM2 * tempDiffPartyWall * partyWallUValue);
    const externalWallWatts = Math.round(areas.externalWallAreaM2 * tempDiff * externalWallUValue);
    const windowWatts = Math.round(areas.windowAndDoorAreaM2 * tempDiff * windowsUValue);
    const floorWatts = Math.round(areas.heatLossFloorAreaM2 * tempDiff * floorUValue);
    const roofWatts = Math.round(areas.roofAreaM2 * tempDiff * roofUValue);
    const ventilationWatts = Math.round(areas.volumeM3 * calculatedAirChanges * heatCapacityOfAir * tempDiff);
    const totalWatts = partyWallWatts + externalWallWatts + windowWatts + floorWatts + roofWatts + ventilationWatts;
    const htcWPerK = totalWatts / tempDiff;
    return {
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        totalWatts,
        calculatedAirChanges,
        htcWPerK,
        defaults,
        degreeDays: designConditionDefaults.degreeDays
    };
};
export const calculatedExternalWallUValue = (wallType, wallGroupUUID) => {
    var _a, _b, _c;
    const wallGroup = WALL_GROUPS.find(x => x.name === wallGroupUUID);
    if (!wallGroup)
        return 0;
    if (wallGroup.name === CAVITY_WALL)
        return (_b = (_a = CAVITY_WALL_INSULATION.find(x => x.uuid === wallType)) === null || _a === void 0 ? void 0 : _a.u_value) !== null && _b !== void 0 ? _b : 0;
    if (wallGroup.name === TIMBER)
        return wallGroup.u_value;
    const insulationUValue = (_c = SOLID_WALL_INSULATION.find(x => x.uuid === wallType)) === null || _c === void 0 ? void 0 : _c.u_value;
    if (!insulationUValue)
        return wallGroup.u_value; // If we have no value for historic records, just return the material u_value
    return 1 / ((1 / wallGroup.u_value) + (1 / insulationUValue));
};
export const calculateBuildingElementAreas = (roomHeightM, builtForm, propertyType, totalFloorAreaM2) => {
    // TODO: check if we can make this an enum or similar (same for property type)
    // calcs based on the ticket: https://linear.app/spruce-eco/issue/SPR-158/heat-loss-calc-incorporate-the-property-type-and-built-form-to-improve
    const numberOfPartyWalls = builtForm === 'Detached' ? 0 : (builtForm === 'Semi-Detached' ? 1 : 2);
    // calcs based on the ticket: https://linear.app/spruce-eco/issue/SPR-158/heat-loss-calc-incorporate-the-property-type-and-built-form-to-improve
    const numberOfStories = ['Flat', 'Bungalow'].includes(propertyType) ? 1 : 2; // Assume average house has 2 floors.
    const averageAreaPerStoreyM2 = totalFloorAreaM2 / numberOfStories;
    const wallLengthM = Math.sqrt(averageAreaPerStoreyM2); // TODO later: don't assume square (differentiate between width and depth)
    //  TODO: check if MCS increases room height to deal with inter-floor space
    const externalWallAreaM2 = wallLengthM * (4 - numberOfPartyWalls) * roomHeightM * numberOfStories * 0.9;
    // currently assuming windows in party walls - TODO: fix this in seperate PR
    const partyWallAreaM2 = wallLengthM * numberOfPartyWalls * roomHeightM * numberOfStories * 0.9;
    const windowAndDoorAreaM2 = wallLengthM * 4 * roomHeightM * numberOfStories * 0.1; // TODO later calculate window area based on average percentage of wall area from RdSAP 2012 and doors somehow sensible
    const heatLossFloorAreaM2 = averageAreaPerStoreyM2;
    const roofAreaM2 = totalFloorAreaM2; // Note this is double what it should be - TODO: fix in a separate PR
    const volumeM3 = totalFloorAreaM2 * roomHeightM;
    return {
        externalWallAreaM2,
        partyWallAreaM2,
        windowAndDoorAreaM2,
        heatLossFloorAreaM2,
        roofAreaM2,
        volumeM3
    };
};
export const calculateYearlyCO2kg = (fuel, yearlykWh) => {
    return yearlykWh * fuel.gCO2PerkWh / 1000;
};
export const calculateYearlyCostGBP = (fuel, yearlykWh) => {
    return (yearlykWh * fuel.pPerkWh + 365.25 * fuel.pPerDay) / 100;
};
export const getFlightsSaved = (CO2SavedKg) => {
    const flightsToSpain = 192;
    return Math.floor(CO2SavedKg / flightsToSpain);
};
export const getCommutesSaved = (CO2SavedKg) => {
    const dailyCommute = 3.6;
    return Math.floor(CO2SavedKg / dailyCommute);
};
export const checkIfEligibleForHesGrant = (epcScotland) => {
    var _a, _b, _c, _d, _e;
    if (!epcScotland)
        return [{ hasPassed: false, message: 'Has a valid EPC' }];
    // recommendations for Scottish properties are in the epc_scotland object .improvements field
    const scottishImprovements = makeDictFromScottishImprovements((_a = epcScotland === null || epcScotland === void 0 ? void 0 : epcScotland.improvements) !== null && _a !== void 0 ? _a : '');
    return [
        {
            hasPassed: !((_b = scottishImprovements.description) === null || _b === void 0 ? void 0 : _b.includes('cavity wall insulation')),
            message: !((_c = scottishImprovements.description) === null || _c === void 0 ? void 0 : _c.includes('cavity wall insulation')) ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
        },
        {
            hasPassed: !((_d = scottishImprovements.description) === null || _d === void 0 ? void 0 : _d.includes('increase loft insulation to 270')),
            message: !((_e = scottishImprovements.description) === null || _e === void 0 ? void 0 : _e.includes('increase loft insulation to 270')) ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
        }
    ];
};
export const checkIfEligibleForBusGrant = (inspectionDate, recommendations) => {
    // Docs: https://files.bregroup.com/sap/RdSAP_2012_9.94-20-09-2019.pdf page 52 as a reference for improvement-id
    if (!inspectionDate)
        return [{ id: 'no_valid', warning: false, hasPassed: false, message: 'No valid EPC found' }];
    else {
        return [
            {
                id: 'epc_date',
                hasPassed: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99,
                warning: false,
                message: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99 ? 'EPC is under 10 years old' : 'EPC is more than 10 years old'
            },
            {
                id: 'cavity_wall',
                hasPassed: true,
                warning: recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('cavity')) || x['improvement-id'] === '6'; }),
                message: !recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('cavity')) || x['improvement-id'] === '6'; }) ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
            },
            {
                id: 'loft_insulation',
                hasPassed: true,
                warning: recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('loft')) || x['improvement-id'] === '5'; }),
                message: !recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('loft')) || x['improvement-id'] === '5'; }) ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
            }
        ];
    }
};
