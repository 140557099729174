import { useEffect, useState } from 'react';
export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    });
    const storeValue = (value) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    };
    useEffect(() => {
        if (!storedValue)
            return;
        storeValue(storedValue);
    }, [key, storedValue]);
    return [storedValue, setStoredValue];
};
