import { Button } from '../../components/buttons/button';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { AdminContext } from './admin_layout';
export const OfflinePage = ({ backURL, customHeader, customMessage }) => {
    const navigate = useNavigate();
    const adminContext = useContext(AdminContext);
    return React.createElement("div", { className: 'w-full' },
        React.createElement("div", { className: 'px-4 py-4 flex gap-x-4 items-center' },
            React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "lg:hidden h-8 w-8 cursor-pointer", onClick: () => {
                    adminContext.showSidebar();
                } })),
        React.createElement("div", { className: 'p-8 flex flex-col gap-4' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-lg' }, customHeader !== null && customHeader !== void 0 ? customHeader : 'Unable to connect to the internet.'),
                React.createElement("div", { className: '' }, customMessage !== null && customMessage !== void 0 ? customMessage : 'This page is not accessible offline')),
            backURL && React.createElement(Button, { className: 'w-32', onClick: () => {
                    if (backURL === 'back')
                        return navigate(-1);
                    navigate(backURL);
                } }, "Back")));
};
