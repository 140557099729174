import React from 'react';
import { useRouteError } from 'react-router-dom';
export const Error500Page = () => {
    const error = useRouteError();
    return (React.createElement("div", { className: "flex flex-col justify-center items-center mt-10 gap-y-2" },
        React.createElement("div", { className: "text-9xl font-semibold text-slate-700" }, "500"),
        React.createElement("div", null, error.message || JSON.stringify(error)),
        React.createElement("div", null,
            React.createElement("span", { onClick: () => { window.location.reload(); }, className: "text-blue-500 underline cursor-pointer" }, "Click here"),
            " to reload the app.")));
};
export const Error404Page = () => {
    return (React.createElement("div", { className: "flex flex-col justify-center items-center mt-10 gap-y-2" },
        React.createElement("div", { className: "text-9xl font-semibold text-slate-700" }, "404"),
        React.createElement("div", null, "Sorry, we couldn't find this page."),
        React.createElement("div", null, "Have you entered the correct company name?")));
};
