// Would be really nice to split out some of the below into a seperate file. It's pretty overwhelming.
import React, { memo } from 'react';
import { Layer, Line } from 'react-konva';
import { gray200 } from './code/constants';
import { FloorButtons } from './floor_buttons';
import { RoomButtons } from './room_buttons';
import { WallButtons } from './wall_buttons';
import { FloorPlanButtons } from './floor_plan_buttons';
import { RoomDrawingButtons } from '../room_drawing_buttons';
export const EntityToolbar = ({ setPage, currentWall, currentRoom, currentFloor, setTempImageAndScale, setStageStep, showFloorPlanButtons, setShowFloorPlanButtons, setFloor, drawingRooms, setDrawingRooms, cancelDrawingRooms, scalingPoints, setScalingPoints, intersectingShapes }) => {
    if (drawingRooms)
        return React.createElement(RoomDrawingButtons, { cancelDrawingRooms: cancelDrawingRooms, scalingPoints: scalingPoints, setScalingPoints: setScalingPoints });
    if (showFloorPlanButtons)
        return React.createElement(FloorPlanButtons, { floor: currentFloor, setFloor: setFloor, setShowFloorPlanButtons: setShowFloorPlanButtons, setTempImageAndScale: setTempImageAndScale, setStageStep: setStageStep });
    if (currentWall)
        return React.createElement(WallButtons, { setPage: setPage, currentWall: currentWall });
    if (currentRoom)
        return React.createElement(RoomButtons, { setPage: setPage, currentRoom: currentRoom });
    return React.createElement(FloorButtons, { setShowFloorPlanButtons: setShowFloorPlanButtons, setStageStep: setStageStep, setPage: setPage, currentFloor: currentFloor, setTempImageAndScale: setTempImageAndScale, setDrawingRooms: setDrawingRooms, intersectingShapes: intersectingShapes });
};
export const canvasSize = 1000;
export const GridLayer = memo(() => {
    return React.createElement(Layer, null,
        React.createElement(Line, { x: canvasSize / 2, points: [0, 0, 0, canvasSize], stroke: gray200, opacity: 1 }),
        React.createElement(Line, { x: 0, points: [0, canvasSize / 2, canvasSize, canvasSize / 2], stroke: gray200, opacity: 1 }));
});
