import { useEffect, useRef, useState } from 'react';
export const useSyncingDB = (db, uuid, initialValue, syncIntervalMs, initHydrationHandler, onInitialized) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isOffline, setIsOffline] = useState(false);
    // a flag to skip next sync cycle if it's already in progress
    const isInSyncCycle = useRef(false);
    // the value storage
    const [value, setValue] = useState(initialValue);
    // useRef solves the issue with the stale closure
    // without it the value of localDBValue will be the same as it was at the time of the first render
    const localSyncItem = useRef(undefined);
    // last sync time used to detect is there any changes made by user between sync cycles
    const lastSyncTime = useRef(undefined);
    // just a flag to skip the lastUserUpdateTime update in some cases when we update the value in the sync code
    const skipLastUserUpdateTime = useRef(false);
    // a flag to mark did user made any changes between sync cycles
    const userMadeChangesBetweenSyncs = useRef(false);
    const init = async () => {
        var _a;
        // get locally stored item
        const localItem = await db[db.getTableName()].where('uuid').equals(uuid).first();
        // if NOT exists
        if (!(localItem === null || localItem === void 0 ? void 0 : localItem.data)) {
            const item = { uuid, data: initialValue, checkpoint_time: 0 };
            await handleLocalStoring(item);
            setValue(item.data);
        }
        else {
            // next code is executed if local item exists
            const item = {
                uuid: localItem.uuid,
                // hydrate data if needed for this type of item
                // usually it's user to propagate default values for new fields because stored values may not have them
                data: initHydrationHandler ? initHydrationHandler(localItem.data) : localItem.data,
                // ensure the checkpoint time is not undefined (in case of older clients' local data)
                checkpoint_time: (_a = localItem.checkpoint_time) !== null && _a !== void 0 ? _a : 0
            };
            setValue(item.data);
            localSyncItem.current = item;
        }
        await handleIntervalPulling();
        // set last user update time to the current time
        // lastUserUpdateTime.current = (new Date()).getTime()
        setIsInitialized(true);
    };
    const handleIntervalPulling = async () => {
        var _a;
        // Lock the function to prevent multiple calls
        if (isInSyncCycle.current)
            return;
        try {
            // validation check
            // skip if not fully initialized
            if (!localSyncItem.current)
                return;
            isInSyncCycle.current = true;
            // get remote timestamp to check is there a new version or not
            const remoteTs = await db.getServerItemCheckpointTime(localSyncItem.current.uuid, () => setIsOffline(true));
            // if there is an error, skip the cycle
            // the offline flag is already set by the fetchRemoteTimestamp function's hook
            if (remoteTs === undefined)
                return;
            // if no data on the server and local data have not stored too,
            // update the local value
            if (remoteTs === 0 && localSyncItem.current.checkpoint_time === 0) {
                localSyncItem.current.checkpoint_time = (new Date()).getTime();
                userMadeChangesBetweenSyncs.current = false;
                return;
            }
            // if there is no error, reset the offline flag
            setIsOffline(false);
            // if local checkpoint_time equals to the remote one,
            // in that case all changes are in sync, do nothing
            if (localSyncItem.current.checkpoint_time === remoteTs) {
                userMadeChangesBetweenSyncs.current = false;
                return;
            }
            // in any other cases, POST data to the server
            const remoteItem = await db.postLocalItemToServer(localSyncItem.current.uuid, {
                sync_item: localSyncItem.current,
                // if user made any changes between sync cycles, set the flag to true
                store_conflicts: userMadeChangesBetweenSyncs.current
            }, () => setIsOffline(true));
            // if there is an error, skip the cycle
            // the offline flag is already set by the fetchRemoteItem function's hook
            if (!remoteItem)
                return;
            // is the same version, do nothing
            if (remoteItem.is_same) {
                userMadeChangesBetweenSyncs.current = false;
                return;
            }
            // if user did any changes during sync, skip the update
            // wait for the next sync cycle
            if (!userMadeChangesBetweenSyncs.current) {
                // update the local value
                skipLastUserUpdateTime.current = true;
                // build the item
                const item = {
                    uuid: remoteItem.uuid,
                    // hydrate data if needed for this type of item
                    // usually it's user to propagate default values for new fields because stored values may not have them
                    data: initHydrationHandler ? initHydrationHandler(remoteItem.data) : remoteItem.data,
                    // ensure the checkpoint time is not undefined (in case of older clients' local data)
                    checkpoint_time: (_a = remoteItem.checkpoint_time) !== null && _a !== void 0 ? _a : 0
                };
                setValue(item.data);
                localSyncItem.current = item;
                // save current time as the last sync time
                lastSyncTime.current = (new Date()).getTime();
            }
            // if user made changes between sync cycles, update the last user update time
            userMadeChangesBetweenSyncs.current = false;
        }
        finally {
            // unlock the function
            // the try block is used to unlock the function even if there is an error
            isInSyncCycle.current = false;
        }
    };
    const handleLocalStoring = async (syncItem) => {
        try {
            await db[db.getTableName()].put(syncItem);
            localSyncItem.current = syncItem;
        }
        catch (error) {
            console.error('Error handling local storing: ', error);
        }
    };
    useEffect(() => {
        if (!isInitialized)
            return;
        userMadeChangesBetweenSyncs.current = true;
        if (skipLastUserUpdateTime.current) {
            skipLastUserUpdateTime.current = false;
        }
        else {
            localSyncItem.current.checkpoint_time = (new Date()).getTime();
        }
        handleLocalStoring({ ...localSyncItem.current, data: value });
    }, [value]);
    // trigger onInitialized callback
    useEffect(() => {
        if (onInitialized && isInitialized) {
            onInitialized(value);
        }
    }, [isInitialized]);
    useEffect(() => {
        init();
        if (!syncIntervalMs || syncIntervalMs < 1000 || syncIntervalMs > 60000) {
            throw new Error('syncIntervalMs should be between 1000 and 60000 ms');
        }
        const intervalID = setInterval(async () => {
            await handleIntervalPulling();
        }, syncIntervalMs !== null && syncIntervalMs !== void 0 ? syncIntervalMs : 1000);
        // on unmount
        return () => {
            clearInterval(intervalID);
        };
    }, []);
    return [value, setValue, isOffline, lastSyncTime];
};
