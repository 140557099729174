import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Icon } from '../../../../components/buttons/icon';
import { OfflineBlock } from '../../particles/offline_block';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
export const DesignPageHeader = ({ title, onBack, children, rightHandChildren, completed }) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white" },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: 'flex gap-4 items-center cursor-pointer', onClick: onBack },
                    React.createElement(Icon, { icon: faChevronLeft }),
                    React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, title)),
                React.createElement("div", { className: 'flex gap-2' },
                    rightHandChildren,
                    completed !== undefined && React.createElement(Badge, { color: completed ? 'GREEN' : 'LIGHT', text: completed ? 'Complete' : 'Incomplete' }))),
            children),
        React.createElement(OfflineBlock, null));
};
