import React, { useState } from 'react';
import { DesignPageHeader } from '../components/design_page_header';
import { getRadiatorHeightWidthText, getEmitterTypeName, getEmitterSizeName } from '../../../../code/models/radiator';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Button } from '../../../../components/buttons/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { getRoomTemp, getRoomWatts } from '../../../../code/models/heat_loss';
import { getEmitterVelocityMPerS, getEmitterWatts, getRadiatorWatts } from '../../../../code/models/radiator_model';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { chain, noop, sum } from 'lodash';
import { Slider } from '../../../../components/inputs_and_selections/slider';
import { getRoomRadiatorWatts } from '../../../../code/models/room';
import { DEFAULT_DESIGN_RADIATOR, DEFAULT_SURVEY_RADIATOR, DEFAULT_SURVEY_UNDERFLOOR } from '../../../../code/survey_defaults';
import { ClickableCard } from '../../../../components/content_display/card';
import { Link } from '../../../../components/buttons/link';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
import { SelectRadiatorModelInner } from './radiator_model_selector';
import { stepSize } from '../../constants';
import { getPercentageText } from '../../floor/code/utils';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { ListItem } from '../../../../components/content_display/list_item';
import { UnderfloorEmitter } from '../../underfloor/underfloor_emitter';
export const EmitterDesignPage = ({ setDesignTab, customRadiatorModels, setCustomRadiatorModels, allRadiatorModels, floors, setFlowTemp, minFlowTemp, maxFlowTemp, designTempC, groundTempC, survey, design, setDesign, annualBillEstimateGBP, files, emitterDemandStatus, companyUuid, setSurvey, setFiles }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    // control link between each radiator card and the radiator type inner modal
    const [addRadiatorModal, setAddRadiatorModal] = useState({
        room: undefined,
        floor: undefined,
        roomTempC: undefined,
        requiredOutputW: undefined,
        originalEmitter: undefined,
        replacementEmitter: undefined
    });
    const setUnderfloorHeating = async (updateFnOrValue) => setAddRadiatorModal(prev => {
        const updatedEmitter = typeof updateFnOrValue === 'function' ? updateFnOrValue(prev.replacementEmitter) : updateFnOrValue;
        return { ...prev, replacementEmitter: updatedEmitter };
    });
    const [pages, setPages] = useState(['RADIATOR_MODEL']);
    const page = pages[pages.length - 1];
    const onBack = () => setPages(prev => prev.slice(0, -1));
    const setPage = (page) => setPages(prev => [...prev, page]);
    // Select an emitter type.
    if (addRadiatorModal.room && !addRadiatorModal.replacementEmitter) {
        return React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement(DesignPageHeader, { title: 'Select emitter', onBack: () => setAddRadiatorModal({ room: undefined, roomTempC: undefined, requiredOutputW: undefined, originalEmitter: undefined, replacementEmitter: undefined, floor: undefined }) }),
            React.createElement("div", { className: 'flex flex-col divide-y divide-gray-200' },
                React.createElement(ListItem, { primaryText: 'Radiator', onClick: () => {
                        setAddRadiatorModal(prev => ({
                            ...prev,
                            replacementEmitter: {
                                ...DEFAULT_SURVEY_RADIATOR,
                                survey_or_design: 'DESIGN',
                                emitter_type: 'RADIATOR',
                                room_uuid: prev.room.uuid,
                                pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                                updated_at: new Date().getTime(),
                                created_at: new Date().getTime()
                            }
                        }));
                    } }),
                !addRadiatorModal.originalEmitter && React.createElement(ListItem, { primaryText: 'Underfloor heating', onClick: () => {
                        setAddRadiatorModal(prev => ({
                            ...prev,
                            replacementEmitter: {
                                ...DEFAULT_SURVEY_UNDERFLOOR,
                                survey_or_design: 'DESIGN',
                                room_uuid: prev.room.uuid,
                                updated_at: new Date().getTime(),
                                created_at: new Date().getTime()
                            }
                        }));
                    } })));
    }
    // If we have selected an emitter type to add.
    if (addRadiatorModal.room && addRadiatorModal.replacementEmitter) {
        if (addRadiatorModal.replacementEmitter.emitter_type === 'UNDERFLOOR') {
            return React.createElement("div", { className: 'flex flex-col h-full' },
                React.createElement(DesignPageHeader, { title: 'Underfloor heating', onBack: () => setAddRadiatorModal({ room: undefined, roomTempC: undefined, requiredOutputW: undefined, originalEmitter: undefined, replacementEmitter: undefined, floor: undefined }) }),
                React.createElement(UnderfloorEmitter, { underfloorHeating: addRadiatorModal.replacementEmitter, setUnderfloorHeating: setUnderfloorHeating, room: addRadiatorModal.room, floor: addRadiatorModal.floor, survey: survey, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, onSave: () => {
                        var _a;
                        setDesign({
                            ...design,
                            radiators: ((_a = addRadiatorModal.replacementEmitter) === null || _a === void 0 ? void 0 : _a.uuid)
                                ? design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter.uuid ? addRadiatorModal.replacementEmitter : x)
                                : [...design.radiators, { ...addRadiatorModal.replacementEmitter, uuid: crypto.randomUUID() }]
                        });
                        setAddRadiatorModal({
                            room: undefined,
                            roomTempC: undefined,
                            requiredOutputW: undefined,
                            originalEmitter: undefined,
                            replacementEmitter: undefined,
                            floor: undefined
                        });
                    }, files: files, setFiles: setFiles, design: design, setSurvey: setSurvey, page: page, setPage: setPage, onBack: onBack }));
        }
        return React.createElement("div", { className: 'flex flex-col h-full' },
            page === 'RADIATOR_MODEL' && React.createElement(DesignPageHeader, { title: 'Select radiator', onBack: () => setAddRadiatorModal({ room: undefined, roomTempC: undefined, requiredOutputW: undefined, originalEmitter: undefined, replacementEmitter: undefined, floor: undefined }) }),
            page === 'ADD_CUSTOM_RADIATOR' && React.createElement(DesignPageHeader, { title: 'Add custom radiator', onBack: onBack }),
            React.createElement("div", { className: 'overflow-y-auto' },
                React.createElement(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, originallySelectedModelUuid: (_d = (((_a = addRadiatorModal.replacementEmitter) === null || _a === void 0 ? void 0 : _a.emitter_type) === 'RADIATOR' ? (_c = (_b = addRadiatorModal.replacementEmitter) === null || _b === void 0 ? void 0 : _b.radiator_type) === null || _c === void 0 ? void 0 : _c.uuid : '')) !== null && _d !== void 0 ? _d : (((_e = addRadiatorModal.originalEmitter) === null || _e === void 0 ? void 0 : _e.emitter_type) === 'RADIATOR' ? (_g = (_f = addRadiatorModal.originalEmitter) === null || _f === void 0 ? void 0 : _f.radiator_type) === null || _g === void 0 ? void 0 : _g.uuid : ''), maxWidthMm: ((_h = addRadiatorModal.originalEmitter) === null || _h === void 0 ? void 0 : _h.emitter_type) === 'RADIATOR' ? (_j = addRadiatorModal.originalEmitter) === null || _j === void 0 ? void 0 : _j.maximum_width : undefined, maxHeightMm: ((_k = addRadiatorModal.originalEmitter) === null || _k === void 0 ? void 0 : _k.emitter_type) === 'RADIATOR' ? (_l = addRadiatorModal.originalEmitter) === null || _l === void 0 ? void 0 : _l.maximum_height : undefined, flowTemp: design.flow_temp, deltaTFlowReturnC: design.delta_t_flow_return_c, roomTemp: addRadiatorModal.roomTempC, requiredOutput: addRadiatorModal.requiredOutputW, companyUuid: companyUuid, addRadiator: (radiatorModel) => {
                        var _a, _b, _c, _d;
                        if ((_a = addRadiatorModal.replacementEmitter) === null || _a === void 0 ? void 0 : _a.uuid) {
                            setDesign({
                                ...design,
                                radiators: design.radiators.map(x => x.uuid === addRadiatorModal.replacementEmitter.uuid ? {
                                    ...x,
                                    radiator_type: radiatorModel,
                                    radiator_type_uuid: radiatorModel.uuid,
                                    updated_at: new Date().getTime()
                                } : x)
                            });
                        }
                        else { // add
                            // if replacing another rad, use survey pipework diameter if defined. Otherwise assume same as default rad pipework for now. Should really let user choose on add
                            const pipeModelUuid = ((_b = addRadiatorModal.originalEmitter) === null || _b === void 0 ? void 0 : _b.emitter_type) === 'RADIATOR' || ((_c = addRadiatorModal.originalEmitter) === null || _c === void 0 ? void 0 : _c.emitter_type) === 'UNDERFLOOR'
                                ? addRadiatorModal.originalEmitter.pipe_model_uuid
                                : survey.existing_system_radiator_pipework_uuid;
                            const newRadiator = {
                                ...DEFAULT_DESIGN_RADIATOR,
                                uuid: crypto.randomUUID(),
                                emitter_type: 'RADIATOR',
                                radiator_type: radiatorModel,
                                radiator_type_uuid: radiatorModel.uuid,
                                pipe_model_uuid: pipeModelUuid,
                                pipe_diameter_uuid: undefined,
                                survey_design_uuid: design.uuid,
                                room_uuid: addRadiatorModal.room.uuid,
                                replaces_uuid: (_d = addRadiatorModal.originalEmitter) === null || _d === void 0 ? void 0 : _d.uuid,
                                created_at: new Date().getTime(),
                                updated_at: new Date().getTime(),
                                maximum_height: 0,
                                maximum_width: 0,
                                notes: ''
                            };
                            setDesign({ ...design, radiators: [...design.radiators, newRadiator] });
                        }
                        setAddRadiatorModal({
                            room: undefined,
                            roomTempC: undefined,
                            requiredOutputW: undefined,
                            originalEmitter: undefined,
                            replacementEmitter: undefined,
                            floor: undefined
                        });
                    }, designContextContent: React.createElement(SelectRadiatorModelDesignContext, { files: files, requiredOutput: addRadiatorModal.requiredOutputW, originalEmitter: addRadiatorModal.originalEmitter }), setPage: setPage, page: page, onBack: onBack })));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'bg-gray-100 flex flex-col h-full' },
            React.createElement(DesignPageHeader, { title: 'Emitters', onBack: () => setDesignTab(undefined) }),
            React.createElement("div", { className: 'flex flex-col gap-4 p-4 overflow-y-auto' },
                React.createElement(ClickableCard, { border: false, variant: 'WHITE' },
                    React.createElement("div", { className: 'flex flex-col gap-4 justify-between flex-grow' },
                        React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }),
                        React.createElement("div", { className: "gap-3 flex" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Running costs"),
                                React.createElement("div", { className: "text-gray-600 text-base" },
                                    annualBillEstimateGBP,
                                    " per year")),
                            React.createElement("div", { className: "flex-col gap-1.5 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Emitters"),
                                React.createElement(Badge, { color: emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED', text: emitterDemandStatus }))))),
                floors.map(f => {
                    const roomGroups = chain(f.rooms)
                        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
                        .map((values, key) => ({ key, values }))
                        .value();
                    return React.createElement("div", { key: f.uuid, className: 'flex flex-col gap-4' },
                        React.createElement("div", { className: 'font-bold text-lg text-gray-900' }, f.name),
                        roomGroups.map(rg => React.createElement(React.Fragment, null, getRoomGroupRadiatorCards(rg, f, designTempC, groundTempC, survey, design, allRadiatorModels, setAddRadiatorModal, setDesign))));
                }))));
};
const UndersizedToggle = ({ design, roomOrRoomGroupUUID, setDesign }) => {
    const undersized = design.undersized_emitter_rooms.find(x => x.room_uuid === roomOrRoomGroupUUID);
    return React.createElement("div", { className: "px-3 py-4 flex-col justify-center gap-4 flex bg-white rounded-md" },
        React.createElement("div", { className: "items-center gap-4 flex justify-between" },
            React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, "Accept undersized emitters?"),
            React.createElement(Toggle, { value: !!undersized, setValue: () => setDesign({
                    ...design,
                    undersized_emitter_rooms: undersized
                        ? design.undersized_emitter_rooms.filter(x => x.room_uuid !== roomOrRoomGroupUUID)
                        : [...design.undersized_emitter_rooms, { room_uuid: roomOrRoomGroupUUID, notes: '' }]
                }) })),
        !!undersized && React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, "Details"),
                React.createElement("div", { className: "text-gray-500 text-xs" }, "Please provide reasoning that will show on homeowner report")),
            React.createElement(TextArea, { value: undersized.notes, setValue: (e) => setDesign({ ...design, undersized_emitter_rooms: design.undersized_emitter_rooms.map(x => x.room_uuid === undersized.room_uuid ? { ...undersized, notes: e } : x) }) })));
};
const getRoomGroupRadiatorCards = (roomGroup, floor, designTempC, groundTempC, survey, design, allRadiatorModels, setAddRadiatorModal, setDesign) => {
    const roomGroupRadiatorWatts = sum(roomGroup.values.map(r => getRoomRadiatorWatts(r, design, survey, designTempC, groundTempC)));
    const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, designTempC, groundTempC, stepSize, survey)));
    const roomGroupShortfall = roomGroupRoomWatts - roomGroupRadiatorWatts;
    const isMultiRoomGroup = roomGroup.values.length > 1;
    return React.createElement("div", { key: roomGroup.key, className: 'flex flex-col gap-3' },
        isMultiRoomGroup && React.createElement("div", { className: 'flex justify-between' },
            React.createElement("div", { className: 'text-gray-900 font-bold' }, roomGroup.values.map(x => x.name).join(', ')),
            React.createElement(Badge, { color: roomGroupRadiatorWatts >= roomGroupRoomWatts
                    ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === roomGroup.key)
                    ? 'YELLOW'
                    : 'RED', text: getPercentageText(roomGroupRadiatorWatts, roomGroupRoomWatts) })),
        isMultiRoomGroup && roomGroupShortfall > 0 && React.createElement(UndersizedToggle, { design: design, roomOrRoomGroupUUID: roomGroup.key, setDesign: setDesign }),
        roomGroup.values.map(r => getRoomRadiatorCards(r, floor, designTempC, groundTempC, survey, design, isMultiRoomGroup, roomGroupShortfall, allRadiatorModels, setAddRadiatorModal, setDesign)));
};
const getRoomRadiatorCards = (room, floor, designTempC, groundTempC, survey, design, inMultiRoomGroup, roomGroupShortfall, allRadiatorModels, setAddRadiatorModal, setDesign) => {
    const replacementEmitters = design.radiators.filter(x => x.room_uuid === room.uuid);
    const roomRadiatorWatts = getRoomRadiatorWatts(room, design, survey, designTempC, groundTempC);
    const roomWatts = getRoomWatts(room, floor.rooms, designTempC, groundTempC, stepSize, survey);
    const roomTempC = getRoomTemp(room, survey);
    return React.createElement("div", { key: room.uuid, className: 'flex flex-col gap-2' },
        React.createElement("div", { className: 'flex justify-between items-center' },
            React.createElement("div", { className: `${inMultiRoomGroup ? '' : 'font-bold text-gray-900'}` }, room.name),
            React.createElement("div", { className: 'flex gap-2 items-center' },
                !inMultiRoomGroup &&
                    React.createElement(Badge, { color: roomRadiatorWatts >= roomWatts
                            ? 'GREEN' : design.undersized_emitter_rooms.some(x => x.room_uuid === room.uuid)
                            ? 'YELLOW'
                            : 'RED', text: getPercentageText(roomRadiatorWatts, roomWatts) }),
                inMultiRoomGroup && React.createElement("div", { className: 'text-sm' },
                    Math.round(roomWatts),
                    " W"),
                React.createElement(Button, { onClick: () => {
                        setAddRadiatorModal({
                            room,
                            floor,
                            roomTempC,
                            requiredOutputW: roomGroupShortfall,
                            originalEmitter: undefined,
                            replacementEmitter: undefined
                        });
                    }, colour: 'GRAY', size: 'SM' }, "Add"))),
        !inMultiRoomGroup && roomGroupShortfall > 0 && React.createElement(UndersizedToggle, { roomOrRoomGroupUUID: room.uuid, design: design, setDesign: setDesign }),
        React.createElement("div", { className: 'rounded-md bg-white divide divide-y divide-gray-100' }, [...replacementEmitters.filter(x => !x.replaces_uuid), ...room.radiators].map(ra => {
            var _a;
            const radiatorWatts = Math.round(getEmitterWatts(ra, room, design, survey, designTempC, groundTempC));
            const velocity = getEmitterVelocityMPerS(ra, radiatorWatts, design.delta_t_flow_return_c, design.flow_temp);
            const requiredOutputW = roomGroupShortfall + radiatorWatts;
            // Survey rad is defined if "surveyed/kept" or "replaced"
            const originalEmitter = ra.survey_or_design === 'SURVEY' ? ra : undefined;
            // Design radiator will be defined in cases 'Added' and 'Replacement'
            // Replacement rads filtered out above, so if it's a design rad it's an added rad
            const addedRadiator = ra.survey_or_design === 'DESIGN' ? ra : undefined;
            // If the survey rad is being replaced then we have a replacement radiator, we can only replace Radiators not any other Emitters
            const replacementRadiator = originalEmitter && replacementEmitters.find(x => x.replaces_uuid === (originalEmitter === null || originalEmitter === void 0 ? void 0 : originalEmitter.uuid));
            const replacementEmitter = (_a = addedRadiator !== null && addedRadiator !== void 0 ? addedRadiator : replacementRadiator) !== null && _a !== void 0 ? _a : undefined;
            const replacementRadiatorWatts = replacementRadiator ? getRadiatorWatts(replacementRadiator.radiator_type, design.flow_temp, design.delta_t_flow_return_c, roomTempC) : 0;
            const replacementRadiatorVelocity = replacementRadiator
                ? getEmitterVelocityMPerS(replacementRadiator, replacementRadiatorWatts, design.delta_t_flow_return_c, design.flow_temp)
                : undefined;
            if (ra.emitter_type === 'UNDERFLOOR') {
                return React.createElement(UnderfloorCard, { key: ra.uuid, radiator: ra, watts: radiatorWatts, velocity: velocity, removedRadiators: design.removed_radiator_uuids, survey: survey, removeRadiator: () => {
                        setDesign({
                            ...design,
                            radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                            removed_radiator_uuids: originalEmitter
                                ? [...design.removed_radiator_uuids, originalEmitter.uuid]
                                : design.removed_radiator_uuids
                        });
                    }, editRadiator: () => {
                        setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter });
                    }, restoreRadiator: () => {
                        setDesign({
                            ...design,
                            radiators: design.radiators.filter(x => x.uuid !== (replacementRadiator === null || replacementRadiator === void 0 ? void 0 : replacementRadiator.uuid)),
                            removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid)
                        });
                    } });
            }
            return React.createElement(React.Fragment, null,
                React.createElement(RadiatorCard, { hasReplacement: !!replacementRadiator, velocity: velocity, deltaTFlowReturnC: design.delta_t_flow_return_c, key: ra.uuid, radiator: ra, editRadiator: () => {
                        setAddRadiatorModal({ room, floor, roomTempC, requiredOutputW, originalEmitter, replacementEmitter });
                    }, watts: radiatorWatts, removeRadiator: () => {
                        setDesign({
                            ...design,
                            radiators: replacementEmitter ? design.radiators.filter(x => x.uuid !== replacementEmitter.uuid) : design.radiators,
                            removed_radiator_uuids: originalEmitter
                                ? [...design.removed_radiator_uuids, originalEmitter.uuid]
                                : design.removed_radiator_uuids
                        });
                    }, replaceRadiator: () => {
                        setAddRadiatorModal({
                            room,
                            floor,
                            roomTempC,
                            requiredOutputW,
                            originalEmitter,
                            replacementEmitter: addedRadiator
                        });
                    }, restoreRadiator: () => {
                        setDesign({
                            ...design,
                            radiators: design.radiators.filter(x => x.uuid !== (replacementRadiator === null || replacementRadiator === void 0 ? void 0 : replacementRadiator.uuid)),
                            removed_radiator_uuids: design.removed_radiator_uuids.filter(x => x !== ra.uuid)
                        });
                    }, isReplacement: false, removedRadiators: design.removed_radiator_uuids }),
                replacementRadiator && React.createElement(RadiatorCard, { hasReplacement: !!replacementRadiator, isReplacement: true, key: replacementRadiator.uuid, radiator: replacementRadiator, watts: replacementRadiatorWatts, removeRadiator: noop, replaceRadiator: noop, restoreRadiator: noop, editRadiator: () => {
                        setAddRadiatorModal({
                            room,
                            floor,
                            roomTempC,
                            requiredOutputW,
                            originalEmitter,
                            replacementEmitter: replacementRadiator
                        });
                    }, velocity: replacementRadiatorVelocity, deltaTFlowReturnC: design.delta_t_flow_return_c, removedRadiators: design.removed_radiator_uuids }));
        })));
};
const RadiatorCard = ({ radiator, watts, replaceRadiator, removeRadiator, restoreRadiator, editRadiator, isReplacement, velocity, removedRadiators, hasReplacement }) => {
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const isReplaced = radiator.survey_or_design !== 'DESIGN' && hasReplacement;
    const isRemoved = radiator.survey_or_design !== 'DESIGN' && removedRadiators.includes(radiator.uuid);
    const badge = getRadiatorBadge(radiator, hasReplacement, removedRadiators, false);
    const removedOrReplaced = isRemoved || isReplaced;
    return React.createElement("div", { className: 'text-gray-600 p-4 flex items-center justify-between' },
        React.createElement("div", { className: 'flex gap-4 items-center' },
            isReplacement && React.createElement(FontAwesomeIcon, { icon: faArrowRightLong }),
            React.createElement("div", { className: 'flex flex-col gap-1 text-sm' },
                React.createElement("div", { className: 'mb-2 self-start' }, badge),
                React.createElement("div", { className: `${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold` }, getEmitterTypeName(radiator)),
                React.createElement("div", null,
                    getEmitterSizeName(radiator),
                    " \u2022 ",
                    watts,
                    " W ",
                    velocity ? `• ${velocity.toFixed(2)} m/s` : '',
                    " "))),
        React.createElement("div", { className: 'flex gap-4 items-center' },
            (isAdded || isReplacement) && React.createElement(Link, { className: 'text-sm', onClick: editRadiator }, "Edit"),
            (!isReplacement && (removedOrReplaced
                ? React.createElement(Button, { onClick: restoreRadiator, size: 'SM', colour: 'LIGHT' }, "Restore")
                : radiator.survey_or_design === 'SURVEY' && radiator.emitter_type === 'RADIATOR' && React.createElement(Button, { onClick: replaceRadiator, size: 'SM', colour: 'LIGHT' }, "Replace"))),
            !isReplacement && (!removedOrReplaced && React.createElement(FontAwesomeIcon, { className: 'cursor-pointer', onClick: removeRadiator, icon: faTrashCan }))));
};
export const UnderfloorCard = ({ radiator, watts, velocity, removedRadiators, restoreRadiator, removeRadiator, editRadiator, survey }) => {
    var _a;
    const badge = getRadiatorBadge(radiator, false, removedRadiators, false);
    const isReplaced = false;
    const isRemoved = removedRadiators.includes(radiator.uuid);
    const isReplacement = false;
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const removedOrReplaced = isRemoved || isReplaced;
    return React.createElement("div", { className: 'text-gray-600 p-4 flex items-center justify-between' },
        React.createElement("div", { className: 'flex gap-4 items-center' },
            React.createElement("div", { className: 'flex flex-col gap-1 text-sm' },
                React.createElement("div", { className: 'mb-2 self-start' }, badge),
                React.createElement("div", { className: `${removedOrReplaced ? 'line-through' : ''} text-gray-900 font-bold` },
                    "UFH - ",
                    radiator.floor_construction_type),
                React.createElement("div", null, (_a = survey.manifolds.find(x => x.uuid === radiator.manifold_uuid)) === null || _a === void 0 ? void 0 :
                    _a.name,
                    " \u2022 ",
                    radiator.pipe_centres_uuid,
                    " centres \u2022 ",
                    watts,
                    " W \u2022 ",
                    velocity.toFixed(2),
                    " m/s"))),
        React.createElement("div", { className: 'flex gap-4 items-center' },
            (isAdded || isReplacement) && React.createElement(Link, { className: 'text-sm', onClick: editRadiator }, "Edit"),
            (!isReplacement && (removedOrReplaced
                ? React.createElement(Button, { onClick: restoreRadiator, size: 'SM', colour: 'LIGHT' }, "Restore")
                : React.createElement("div", null))),
            !isReplacement && (!removedOrReplaced && React.createElement(FontAwesomeIcon, { className: 'cursor-pointer', onClick: removeRadiator, icon: faTrashCan }))));
};
const SelectRadiatorModelDesignContext = ({ files, requiredOutput, originalEmitter }) => {
    var _a;
    return React.createElement("div", { className: 'flex flex-col gap-5' },
        originalEmitter && React.createElement("div", { className: 'flex flex-col gap-5' },
            originalEmitter.photos.length > 0 &&
                React.createElement(PhotoScroll, { images: files.filter(x => originalEmitter === null || originalEmitter === void 0 ? void 0 : originalEmitter.photos.map(x => x.image_uuid).includes(x.uuid)) }),
            originalEmitter.notes !== '' && React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Notes:"),
                React.createElement("div", { className: 'text-sm' }, originalEmitter.notes)),
            React.createElement("div", { className: 'flex flex-row justify-between' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Current emitter:"),
                React.createElement("div", { className: 'flex gap-2 items-center justify-between p1' },
                    React.createElement("div", { className: 'text-sm' }, (_a = originalEmitter.radiator_type) === null || _a === void 0 ? void 0 :
                        _a.type,
                        " - ",
                        getRadiatorHeightWidthText(originalEmitter.radiator_type)))),
            (originalEmitter.maximum_height > 0 || originalEmitter.maximum_width > 0) && React.createElement("div", { className: 'flex flex-row justify-between' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Max size:"),
                React.createElement("div", { className: 'text-sm' },
                    originalEmitter.maximum_height,
                    " x ",
                    originalEmitter.maximum_width,
                    " mm"))),
        requiredOutput && React.createElement("div", { className: 'flex flex-row justify-between' },
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Output required:"),
            React.createElement(Badge, { color: requiredOutput > 0 ? 'INDIGO' : 'GREEN', text: requiredOutput > 0 ? requiredOutput.toFixed() + ' W' : 'Demand met' })));
};
export const getRadiatorBadge = (radiator, hasReplacement, removedRadiators, isReport) => {
    const isAdded = radiator.survey_or_design === 'DESIGN' && !radiator.replaces_uuid;
    const isReplacement = radiator.survey_or_design === 'DESIGN' && radiator.replaces_uuid;
    const isReplaced = radiator.survey_or_design === 'SURVEY' && hasReplacement;
    const isRemoved = removedRadiators.includes(radiator.uuid);
    if (isAdded)
        return React.createElement(Badge, { color: 'BLUE', text: 'Added' });
    if (isReplacement)
        return React.createElement(Badge, { color: 'BLUE', text: 'Replacement' });
    if (isReplaced)
        return React.createElement(Badge, { color: 'RED', text: 'Replaced' });
    if (isRemoved)
        return React.createElement(Badge, { color: 'RED', text: 'Removed' });
    return React.createElement(Badge, { color: 'LIGHT', text: isReport ? 'Keep' : 'Surveyed' });
};
export const FlowTempSlider = ({ flowTemp, setFlowTemp, minFlowTemp, maxFlowTemp }) => {
    return React.createElement("div", { className: "flex-col gap-2 flex" },
        React.createElement("div", { className: "flex justify-between" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Flow temperature"),
            React.createElement("div", { className: "text-gray-900 font-semibold" },
                flowTemp,
                " \u00B0C")),
        React.createElement(Slider, { value: flowTemp, onChange: setFlowTemp, min: minFlowTemp !== null && minFlowTemp !== void 0 ? minFlowTemp : 35, max: maxFlowTemp !== null && maxFlowTemp !== void 0 ? maxFlowTemp : 65 }));
};
