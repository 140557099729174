import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const SiteDetailsMainsWaterPhotosBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey, files, setFiles } = prContext;
    const stopcockImagesHydrated = survey.stopcock_images.map(x => files.find(y => y.uuid === x.image_uuid));
    const setStopcockImages = (images) => setSurvey({ ...survey, stopcock_images: images });
    const isFullScreen = stopcockImagesHydrated.length === 0 && flowContext.viewMode === 'FLOW';
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4' },
            React.createElement("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1` },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Mains water"),
                React.createElement("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm` }, "Add photos of the incoming water main and stopcock")),
            React.createElement("div", { className: "flex-col gap-3 flex" },
                !isFullScreen &&
                    React.createElement("div", { className: 'flex justify-between' },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Photos")),
                React.createElement(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: stopcockImagesHydrated, addImage: (image) => {
                        setFiles([...files, image]);
                        const newImage = { uuid: crypto.randomUUID(), image_uuid: image.uuid, entity_uuid: 'survey', entity_attribute: 'stopcock_images' };
                        setStopcockImages([...survey.stopcock_images, newImage]);
                    }, deleteImage: (imageUUID) => {
                        setFiles(files.filter(x => x.uuid !== imageUUID));
                        setStopcockImages(survey.stopcock_images.filter(x => x.image_uuid !== imageUUID));
                    } }))));
};
