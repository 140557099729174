import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
export const AnimatedBlock = ({ fromElement, toElement, className, condition, onClickIfNegativeCondition }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [showNoSignal, setShowNoSignal] = useState(false);
    const handleClick = () => {
        if (isAnimating)
            return;
        // if condition set, check it
        if (condition !== undefined) {
            if (condition)
                setShowNoSignal(true);
        }
        else {
            setShowNoSignal(true);
        }
        if (!condition && typeof onClickIfNegativeCondition === 'function')
            onClickIfNegativeCondition();
    };
    useEffect(() => {
        if (isAnimating)
            return;
        setIsAnimating(true);
        setTimeout(async () => {
            setShowNoSignal(false);
            setIsAnimating(false);
        }, 1000); // Match this with animation duration
    }, [showNoSignal]);
    return (React.createElement("div", { className: className, onClick: handleClick },
        React.createElement(AnimatePresence, { initial: false, mode: "wait" }, !showNoSignal ? (React.createElement(motion.div, { key: "ellipsis", initial: { opacity: 0, scale: 0.8 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.8 }, transition: { duration: 0.2 } }, fromElement)) : (React.createElement(motion.div, { key: "nosignal", initial: { opacity: 0, scale: 0.8 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.8 }, transition: { duration: 0.2 } }, toElement)))));
};
