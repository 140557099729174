// Type: TypeScript file
// all survey pages
import { checkIfScottish } from '../models/address';
import { DEFAULT_LEAD } from '../models/lead';
import { CAVITY_WALL, WALL_GROUPS } from '../models/u_value';
export const stepHashPrefix = '#step';
export var SurveyPagesEnum;
(function (SurveyPagesEnum) {
    SurveyPagesEnum[SurveyPagesEnum["ChooseAddressStep"] = 0] = "ChooseAddressStep";
    SurveyPagesEnum[SurveyPagesEnum["ConfirmLocationStep"] = 1] = "ConfirmLocationStep";
    SurveyPagesEnum[SurveyPagesEnum["ManualAddressStep"] = 2] = "ManualAddressStep";
    SurveyPagesEnum[SurveyPagesEnum["NoEPCFoundStep"] = 3] = "NoEPCFoundStep";
    SurveyPagesEnum[SurveyPagesEnum["EPCFoundStep"] = 4] = "EPCFoundStep";
    SurveyPagesEnum[SurveyPagesEnum["PropertyTypeStep"] = 5] = "PropertyTypeStep";
    SurveyPagesEnum[SurveyPagesEnum["BuiltFormStep"] = 6] = "BuiltFormStep";
    SurveyPagesEnum[SurveyPagesEnum["OutdoorSpaceStep"] = 7] = "OutdoorSpaceStep";
    SurveyPagesEnum[SurveyPagesEnum["BedroomsStep"] = 8] = "BedroomsStep";
    SurveyPagesEnum[SurveyPagesEnum["BathroomsStep"] = 9] = "BathroomsStep";
    SurveyPagesEnum[SurveyPagesEnum["CurrentHeatingTypeStep"] = 10] = "CurrentHeatingTypeStep";
    SurveyPagesEnum[SurveyPagesEnum["EPCChangesStep"] = 11] = "EPCChangesStep";
    SurveyPagesEnum[SurveyPagesEnum["ConstructionAgeBandStep"] = 12] = "ConstructionAgeBandStep";
    SurveyPagesEnum[SurveyPagesEnum["FloorAreaStep"] = 13] = "FloorAreaStep";
    SurveyPagesEnum[SurveyPagesEnum["WallTypeStep"] = 14] = "WallTypeStep";
    SurveyPagesEnum[SurveyPagesEnum["WallCavityInsulationStep"] = 15] = "WallCavityInsulationStep";
    SurveyPagesEnum[SurveyPagesEnum["WallSolidInsulationStep"] = 16] = "WallSolidInsulationStep";
    SurveyPagesEnum[SurveyPagesEnum["GlazingStep"] = 17] = "GlazingStep";
    SurveyPagesEnum[SurveyPagesEnum["LoftInsulationStep"] = 18] = "LoftInsulationStep";
    SurveyPagesEnum[SurveyPagesEnum["EnquiryReasonStep"] = 19] = "EnquiryReasonStep";
    SurveyPagesEnum[SurveyPagesEnum["ScottishGrantStep"] = 20] = "ScottishGrantStep";
    SurveyPagesEnum[SurveyPagesEnum["GetContactsStep"] = 21] = "GetContactsStep";
    SurveyPagesEnum[SurveyPagesEnum["ThankYouStep"] = 22] = "ThankYouStep";
})(SurveyPagesEnum || (SurveyPagesEnum = {}));
// all actions that can be performed on the survey
export var SurveyActionType;
(function (SurveyActionType) {
    // common navigation actions
    SurveyActionType[SurveyActionType["NavigateNextPage"] = 0] = "NavigateNextPage";
    SurveyActionType[SurveyActionType["NavigatePreviousPage"] = 1] = "NavigatePreviousPage";
    SurveyActionType[SurveyActionType["NavigateToPageFromParams"] = 2] = "NavigateToPageFromParams";
    SurveyActionType[SurveyActionType["NavigateBack"] = 3] = "NavigateBack";
})(SurveyActionType || (SurveyActionType = {}));
export const getInitialSurveyState = (source) => {
    // we need to deep-copy of the default lead object to avoid any side effects
    // it solves the SPR-329: https://linear.app/spruce-eco/issue/SPR-329/enquiry-duplicates-information-from-the-previous-enquiry
    const lead = JSON.parse(JSON.stringify(DEFAULT_LEAD));
    return ({
        // first page in the survey process
        currentPage: SurveyPagesEnum.ChooseAddressStep,
        // pages stack
        pagesStack: [],
        // initialise the property object
        lead: {
            ...lead,
            customer: {
                ...lead.customer,
                source
            }
        },
        // progress bar percentage
        pbValue: 0
    });
};
// Reducer function for Survey functionality (docs: https://react.dev/learn/extracting-state-logic-into-a-reducer)
// It is not allowed to make any server requests here!
// You can only change the state of the survey and keep it in sync with the UI.
// Also, here you control the logic of page navigation.
export const surveyReducer = (survey, action) => {
    // DOCS, flow schema: https://www.figma.com/file/hmHMC8pX9eipFjwGWmVO8P/Spruce-Project-Workspace-(Client)?type=whiteboard&node-id=488-22951&t=CGu7VhkuacYhlbCn-0
    // NAVIGATION ACTION
    // if the user goes to the previous page, remove the last page from the stack
    if (action.type === SurveyActionType.NavigatePreviousPage) {
        if (survey.pagesStack.length === 0) {
            throw new Error('Survey reducer: pages stack is empty');
        }
        const stackItem = survey.pagesStack.pop();
        // remove last page from stack and go to it
        return {
            ...survey,
            currentPage: stackItem === null || stackItem === void 0 ? void 0 : stackItem.page,
            pbValue: stackItem === null || stackItem === void 0 ? void 0 : stackItem.pbStack
        };
    }
    // NAVIGATION ACTION
    // if the user goes to the page provided by payload params
    if (action.type === SurveyActionType.NavigateToPageFromParams) {
        if (action.payload.page === undefined) {
            throw new Error('Survey reducer: page parameter for NavigateToPageFromParams is not defined');
        }
        return {
            ...survey,
            ...action.payload,
            currentPage: action.payload.page
        };
    }
    // NB! all other actions below are navigation actions
    // if you need to define a specific action, do it above with SPECIFIC ACTION comment
    if (action.type !== SurveyActionType.NavigateNextPage) {
        throw new Error('Survey reducer: unknown action type');
    }
    // NB! we assume that any code below moves user to another page
    // so, we can save current page to stack without any conditions
    survey.pagesStack.push({
        page: survey.currentPage,
        pbStack: survey.pbValue
    });
    switch (survey.currentPage) {
        case SurveyPagesEnum.ChooseAddressStep:
            survey.lead = action.payload.lead;
            return { ...survey, currentPage: SurveyPagesEnum.ConfirmLocationStep, pbValue: 12 };
        case SurveyPagesEnum.ConfirmLocationStep:
            survey.lead = action.payload.lead;
            if (!survey.lead.epcData && !survey.lead.epc_scotland) {
                return { ...survey, currentPage: SurveyPagesEnum.NoEPCFoundStep, pbValue: 18 };
            }
            return { ...survey, currentPage: SurveyPagesEnum.EPCFoundStep, pbValue: 18 };
        case SurveyPagesEnum.ManualAddressStep:
            survey.lead.property.address = action.payload.address;
            survey.lead.property.postcode = action.payload.postcode;
            return { ...survey, currentPage: SurveyPagesEnum.NoEPCFoundStep, pbValue: 18 };
        case SurveyPagesEnum.NoEPCFoundStep:
            return { ...survey, currentPage: SurveyPagesEnum.PropertyTypeStep, pbValue: 24 };
        case SurveyPagesEnum.EPCFoundStep:
            return { ...survey, currentPage: SurveyPagesEnum.PropertyTypeStep, pbValue: 24 };
        case SurveyPagesEnum.PropertyTypeStep:
            (survey.lead.property).propertyType = action.payload.propertyType;
            return { ...survey, currentPage: SurveyPagesEnum.BuiltFormStep, pbValue: 28 };
        case SurveyPagesEnum.BuiltFormStep:
            (survey.lead.property).builtForm = action.payload.builtForm;
            if (survey.lead.property.propertyType === 'Flat') {
                return { ...survey, currentPage: SurveyPagesEnum.OutdoorSpaceStep, pbValue: 32 };
            }
            else {
                return { ...survey, currentPage: SurveyPagesEnum.FloorAreaStep, pbValue: 38 };
            }
        case SurveyPagesEnum.OutdoorSpaceStep:
            survey.lead.property.outdoorSpace = action.payload.outdoorSpace;
            return { ...survey, currentPage: SurveyPagesEnum.FloorAreaStep, pbValue: 38 };
        case SurveyPagesEnum.FloorAreaStep:
            survey.lead.property.floorArea = action.payload.floorArea;
            return { ...survey, currentPage: SurveyPagesEnum.BedroomsStep, pbValue: 42 };
        case SurveyPagesEnum.BedroomsStep:
            survey.lead.property.noBedrooms = action.payload.noBedrooms;
            return { ...survey, currentPage: SurveyPagesEnum.BathroomsStep, pbValue: 48 };
        case SurveyPagesEnum.BathroomsStep:
            survey.lead.property.noBathrooms = action.payload.noBathrooms;
            return { ...survey, currentPage: SurveyPagesEnum.CurrentHeatingTypeStep, pbValue: 54 };
        case SurveyPagesEnum.CurrentHeatingTypeStep:
            survey.lead.property.fuelType = action.payload.mainFuelSource;
            if (!survey.lead.property.construction_age_band_uuid) {
                return { ...survey, currentPage: SurveyPagesEnum.ConstructionAgeBandStep, pbValue: 60 };
            }
            return { ...survey, currentPage: SurveyPagesEnum.GlazingStep, pbValue: 65 };
        case SurveyPagesEnum.ConstructionAgeBandStep:
            survey.lead.property.construction_age_band_uuid = action.payload.constructionAgeBand.uuid;
            survey.lead.property.construction_age_band = action.payload.constructionAgeBand;
            survey.lead.property.floorType = survey.lead.property.floorType ? survey.lead.property.floorType : action.payload.constructionAgeBand.floorType; // We do not ask a question about floorType, so fill in if not already present.
            return { ...survey, currentPage: SurveyPagesEnum.GlazingStep, pbValue: 65 };
        case SurveyPagesEnum.GlazingStep:
            survey.lead.property.windowType = action.payload.windowsGlazing;
            return { ...survey, currentPage: SurveyPagesEnum.WallTypeStep, pbValue: 75 };
        case SurveyPagesEnum.WallTypeStep:
            survey.lead.property.wallGroup = action.payload.wallGroup;
            survey.lead.property.wallType = action.payload.wallType;
            if (survey.lead.property.wallGroup === CAVITY_WALL) {
                return { ...survey, currentPage: SurveyPagesEnum.WallCavityInsulationStep, pbValue: 80 };
            }
            if (WALL_GROUPS.filter(x => x.type === 'SOLID').some(x => x.name === survey.lead.property.wallGroup)) {
                return { ...survey, currentPage: SurveyPagesEnum.WallSolidInsulationStep, pbValue: 80 };
            }
            return { ...survey, currentPage: SurveyPagesEnum.LoftInsulationStep, pbValue: 85 };
        case SurveyPagesEnum.WallCavityInsulationStep:
            (survey.lead.property).wallType = action.payload.wallCavityInsulation;
            return { ...survey, currentPage: SurveyPagesEnum.LoftInsulationStep, pbValue: 85 };
        case SurveyPagesEnum.WallSolidInsulationStep:
            (survey.lead.property).wallType = action.payload.wallType;
            return { ...survey, currentPage: SurveyPagesEnum.LoftInsulationStep, pbValue: 85 };
        case SurveyPagesEnum.LoftInsulationStep:
            survey.lead.property.loftInsulation = action.payload.loftInsulation;
            if (checkIfScottish(survey.lead.property.postcode)) {
                return { ...survey, currentPage: SurveyPagesEnum.ScottishGrantStep, pbValue: 95 };
            }
            return { ...survey, currentPage: SurveyPagesEnum.GetContactsStep, pbValue: 95 };
        case SurveyPagesEnum.ScottishGrantStep:
            survey.lead.scottishGrant = {
                scottishGrantIsInTouch: action.payload.scottishGrantIsInTouch,
                scottishGrantReason: action.payload.scottishGrantReason
            };
            return { ...survey, currentPage: SurveyPagesEnum.GetContactsStep, pbValue: 100 };
        case SurveyPagesEnum.GetContactsStep:
            survey.lead = action.payload.lead;
            return { ...survey, currentPage: SurveyPagesEnum.ThankYouStep, pbValue: 100 };
    }
    return survey;
};
