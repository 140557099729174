import React, { useState } from 'react';
import { Icon } from '../../../components/buttons/icon';
import { Modal } from '../../../components/containers/modal';
const variants = {
    BLUE: 'text-blue-800 bg-indigo-100',
    DEFAULT: 'text-gray-600 bg-gray-50'
};
const iconVariants = {
    BLUE: 'text-blue-800',
    DEFAULT: 'text-gray-600'
};
const CanvasNavButton = ({ item }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: item.confirmText ? () => setModalOpen(true) : item.onClick, className: `p-2 ${variants[item.variant]} rounded-md flex flex-col gap-1 whitespace-nowrap items-center justify-center ${item.disabled ? 'opacity-50' : 'cursor-pointer'}` },
            React.createElement(Icon, { icon: item.icon, colour: iconVariants[item.variant] }),
            React.createElement("div", { className: "text-xs font-semibold" }, item.name)),
        React.createElement(Modal, { onConfirm: () => {
                item.onClick();
            }, visible: modalOpen, title: item.name, setVisible: setModalOpen }, item.confirmText)));
};
export const CanvasNav = ({ items, title }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "p-1 bg-white rounded-lg shadow flex flex-col gap-1 items-center" },
            React.createElement("div", { className: 'text-gray-900 text-sm font-semibold px-4' }, title),
            React.createElement("div", { className: 'flex gap-1 overflow-x-auto w-full items-center justify-center' }, items.map(x => React.createElement(CanvasNavButton, { key: x.name, item: x }))))));
};
