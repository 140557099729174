import React from 'react';
import { Button } from '../../../components/buttons/button';
import { noop } from 'lodash';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
export const ButtonDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Sizes"),
            React.createElement("div", { className: 'flex justify-start items-end gap-4' },
                React.createElement(Button, { onClick: noop, size: 'SM', colour: 'DARK', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'DARK', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'LG', colour: 'DARK', iconRight: faChevronRight }, "Hello World")),
            React.createElement("div", null, "Variants"),
            React.createElement("div", { className: 'flex justify-start items-end gap-4' },
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'DARK', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'LIGHT', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'GRAY', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'LOZENGE_DARK', iconRight: faChevronRight }, "Hello World"),
                React.createElement(Button, { onClick: noop, size: 'MD', colour: 'LOZENGE_LIGHT', iconRight: faChevronRight }, "Hello World"))));
};
const componentProps = [
    { name: 'onClick', description: 'Action to perform when clicked' },
    { name: 'block', description: 'Should it fill the width of container or not' },
    { name: 'disabled', description: 'Is it disabled' },
    { name: 'size', description: 'SM | MD | LG' },
    { name: 'className', description: 'Any additional styling or positioning required' },
    { name: 'colour', description: 'DARK | LIGHT | GRAY | LOZENGE_LIGHT | LOZENGE_DARK' },
    { name: 'iconLeft', description: 'What icon should display to the left of the text' },
    { name: 'iconRight', description: 'What icon should display to the right of the text' },
    { name: 'confirmText', description: 'If populated, show a confirmation modal with the specified text before actioning the onClick' }
];
const codeBlock = `<Button
    onClick={noop}
    block={true}
    disabled={false}
    size='MD'
    className=''
    colour='DARK'
    iconLeft={undefined}
    iconRight={faChevronRight}
    confirmText=''
>Hello World</Button>`;
