import React, { useState } from 'react';
import { SurveyEHSContext } from '../existing_heating_system';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getPipeModelName, PIPE_MODELS } from '../../../../code/models/pipes';
import { pipeMaterials } from '../../../../code/models/property';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
export const EHSDefaultRadiatorPipeworkBlock = () => {
    var _a;
    const ehsContext = React.useContext(SurveyEHSContext);
    const { survey, setSurvey } = ehsContext;
    const RADIATOR_VISIBILITY = ['Surface', 'Concealed', 'Mixed'];
    const radiatorPipeModel = PIPE_MODELS.find(x => x.uuid === survey.existing_system_radiator_pipework_uuid);
    const [radiatorPipeMaterial, setRadiatorPipeMaterial] = useState((_a = radiatorPipeModel === null || radiatorPipeModel === void 0 ? void 0 : radiatorPipeModel.material) !== null && _a !== void 0 ? _a : 'Copper');
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Default radiator pipework"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Radiator pipework visibility"),
                React.createElement(RadioGroup, { items: RADIATOR_VISIBILITY.map(x => ({
                        name: x,
                        onClick: () => setSurvey({ ...survey, existing_system_radiator_pipework_visibility: x }),
                        variant: survey.existing_system_radiator_pipework_visibility === x ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Radiator pipework material"),
                React.createElement(RadioGroup, { items: pipeMaterials.map(x => ({
                        name: x,
                        onClick: () => setRadiatorPipeMaterial(x),
                        variant: radiatorPipeMaterial === x ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: 'flex justify-between' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Radiator pipework diameter"),
                    React.createElement(Badge, { color: survey.existing_system_radiator_pipework_uuid ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.existing_system_radiator_pipework_uuid ? faCheck : undefined })),
                React.createElement(Select, { options: PIPE_MODELS.filter(x => x.material === radiatorPipeMaterial).map(x => ({
                        key: x.uuid,
                        value: getPipeModelName(x)
                    })), selectedKey: survey.existing_system_radiator_pipework_uuid, setSelectedKey: (e) => setSurvey({ ...survey, existing_system_radiator_pipework_uuid: e }) }))));
};
