import { stepSize } from '../../constants';
export const findBestCreateRoomPosition = (centerX, centerY, rooms) => {
    const distanceLength = Math.round(Number(2.5) * stepSize * 10);
    const distanceWidth = Math.round(Number(2.5) * stepSize * 10);
    const shapeCenterX = Math.round((centerX - (distanceWidth / 2)) / stepSize) * stepSize;
    const shapeCenterY = Math.round((centerY - (distanceLength / 2)) / stepSize) * stepSize;
    const roomsOnSameLocation = rooms.filter(x => x.x === shapeCenterX && x.y === shapeCenterY);
    const filteredRooms = rooms.filter(x => !roomsOnSameLocation.map(y => y.uuid).includes(x.uuid));
    if (roomsOnSameLocation.length > 0)
        return findBestCreateRoomPosition(centerX + 10, centerY + 10, filteredRooms);
    return { roomCenterX: centerX, roomCenterY: centerY };
};
