import React from 'react';
import { BUILT_FORM_TYPES, PROPERTY_TYPES, SURVEY_AGE_BANDS } from '../../../../code/models/u_value';
import { SurveySiteDetailsContext } from '../site_details';
import { Select } from '../../../../components/inputs_and_selections/select';
export const SiteDetailsTypeBlock = () => {
    var _a;
    const prContext = React.useContext(SurveySiteDetailsContext);
    const { survey, setSurvey } = prContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: " text-gray-900 text-xl font-bold" }, "Property"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Type"),
                React.createElement(Select, { selectedKey: survey.property_type, options: PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, property_type: e })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Built form"),
                React.createElement(Select, { selectedKey: survey.built_form, options: BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, built_form: e })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Age band"),
                React.createElement(Select, { selectedKey: (_a = survey.age_band) === null || _a === void 0 ? void 0 : _a.id, options: SURVEY_AGE_BANDS.map(x => ({ key: x.id, value: x.name })), setSelectedKey: (e) => setSurvey(prev => ({ ...prev, age_band: SURVEY_AGE_BANDS.find(x => x.id === e) })) }))));
};
