import React from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ACH_AGES } from '../../constants';
import { Select } from '../../../../components/inputs_and_selections/select';
import { Icon } from '../../../../components/buttons/icon';
import { ClickableCard } from '../../../../components/content_display/card';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../components/inputs_and_selections/input';
import { InputGroup } from '../../../../components/inputs_and_selections/input_group';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { ToggleInputGroup } from '../../../../components/inputs_and_selections/toggle_input_group';
export const FloorplanHeatLossSettingsBlock = ({ survey, setSurvey, designTempDefault, altitudeDefaultM }) => {
    var _a, _b, _c;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-col justify-start items-start gap-3 flex" },
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Settings"),
            React.createElement("div", { className: "" }, "Set the calculating conditions for the property.")),
        React.createElement("div", { className: "flex flex-col gap-5 " },
            React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Altitude', helperText: 'Automatically filled in based on the address' }), input: React.createElement(Input, { type: 'number', value: (_b = (_a = survey.altitude_override_m) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : altitudeDefaultM.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, altitude_override_m: Number(e) })), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" },
                        React.createElement("div", { className: "" }, "m"),
                        survey.altitude_override_m !== undefined && survey.altitude_override_m !== altitudeDefaultM &&
                            React.createElement(Icon, { icon: faTimesCircle, onClick: () => setSurvey(prev => ({ ...prev, altitude_override_m: undefined })), colour: 'text-gray-400' })) }) }),
            React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Design outdoor temperature', helperText: 'This defaults to the 99th percentile' }), input: React.createElement(Input, { type: 'number', value: (_c = survey.design_temp_override_c) !== null && _c !== void 0 ? _c : designTempDefault, setValue: (e) => setSurvey(prev => ({ ...prev, design_temp_override_c: Number(e) })), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" },
                        React.createElement("div", { className: "" }, "\u00B0C"),
                        survey.design_temp_override_c !== undefined && survey.design_temp_override_c !== designTempDefault &&
                            React.createElement(Icon, { icon: faTimesCircle, onClick: () => setSurvey(prev => ({ ...prev, design_temp_override_c: undefined })), colour: 'text-gray-400' })) }) }),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Indoor temperature based on CIBSE defaults', helperText: 'Based on room type' }), toggle: React.createElement(Toggle, { size: 'LG', value: survey.use_cibse_indoor_temps, setValue: () => setSurvey(prev => ({
                                ...prev, use_cibse_indoor_temps: !survey.use_cibse_indoor_temps
                            })) }) }),
                    !survey.use_cibse_indoor_temps &&
                        React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Indoor temperature default' }), input: React.createElement(Input, { type: 'number', value: survey.indoor_temp_overall_c.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, indoor_temp_overall_c: Number(e) })), postfix: React.createElement("span", { className: 'text-gray-500' }, "\u00B0C") }) }))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Air changes per hours based on CIBSE defaults', helperText: 'Based on room type and age band' }), toggle: React.createElement(Toggle, { size: 'LG', value: survey.use_cibse_air_change_values, setValue: () => setSurvey(prev => ({
                                ...prev,
                                use_cibse_air_change_values: !survey.use_cibse_air_change_values
                            })) }) }),
                    survey.use_cibse_air_change_values &&
                        React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Property age' }), input: React.createElement(Select, { selectedKey: survey.air_change_year_uuid, options: ACH_AGES, setSelectedKey: (e) => setSurvey(prev => ({ ...prev, air_change_year_uuid: e })) }) }),
                    !survey.use_cibse_air_change_values &&
                        React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Air changes per hour default' }), input: React.createElement(Input, { type: 'number', value: survey.air_change_per_hour_overall.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, air_change_per_hour_overall: Number(e) })) }) }))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Intermittent heating required', helperText: 'Home is heated intermittently so owner wants oversize system to reduce reheat time (not advised)' }), toggle: React.createElement(Toggle, { size: 'LG', value: survey.intermittent_heating, setValue: () => setSurvey(prev => ({
                            ...prev,
                            intermittent_heating: !survey.intermittent_heating
                        })) }) }),
                survey.intermittent_heating &&
                    React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Oversize factor' }), input: React.createElement(Input, { type: 'number', value: survey.intermittent_heating_oversize_factor_percentage.toString(), setValue: (e) => setSurvey(prev => ({
                                ...prev,
                                intermittent_heating_oversize_factor_percentage: Number(e)
                            })), postfix: '%' }) })),
            React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'MVHR installed', helperText: 'Mechanical ventilation with heat recovery is installed at the property' }), toggle: React.createElement(Toggle, { size: 'LG', value: survey.mvhr_installed, setValue: () => setSurvey(prev => ({ ...prev, mvhr_installed: !survey.mvhr_installed })) }) }),
            React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Exposed location', helperText: 'Property is exposed to adverse weather conditions (e.g. located close to the coast)' }), toggle: React.createElement(Toggle, { size: 'LG', value: survey.exposed_location, setValue: () => setSurvey(prev => ({
                        ...prev,
                        exposed_location: !survey.exposed_location,
                        design_temp_override_c: undefined
                    })) }) })));
};
