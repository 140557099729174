import { chain, orderBy, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../code/models/range_heat_pump';
import { Button } from '../../../components/buttons/button';
import { Modal } from '../../../components/containers/modal';
import { Table } from '../../../components/content_display/table';
import { TableLite } from '../../../components/content_display/table_lite';
import { Link } from '../../../components/buttons/link';
import { HeatPumpModal } from './heat_pump_modal';
import { Input } from '../../../components/inputs_and_selections/input';
import { updateCompany } from '../../../code/models/company';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { validateIsNumber } from '../../../code/validators';
import { Select } from '../../../components/inputs_and_selections/select';
export const HeatPumpTab = ({ heatPumps, deleteHeatPumpRow, updateOrInsertHeatPumpRow, addHeatPumps, brandRanges, company, setCompany }) => {
    const heatPumpsColumns = [
        { key: 'name', name: 'Name', render: (row) => !row.range_heat_pump_uuid ? React.createElement("div", null, row.name) : React.createElement(Link, { onClick: () => setModalHeatPumpId(row.uuid) }, row.name) },
        { key: 'price', name: 'Price: Heat pump (£)', validator: (row) => !validateIsNumber(row.price).message },
        { key: 'parts', name: 'Price: All other materials (£)', validator: (row) => !validateIsNumber(row.parts).message },
        {
            key: 'kwatts',
            name: 'Heat Capacity @ 45°C (kW)',
            render: (row) => React.createElement("div", null, row.range_heat_pump_uuid ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(row.range_heat_pump, -3, 45).capacityKw : row.kwatts.toString()),
            editRender: (row, onChange) => !row.range_heat_pump_uuid ? React.createElement(Input, { setValue: (e) => { onChange(e); }, value: row.kwatts.toString() }) : React.createElement("div", null, getHeatPumpCapacityAtOutsideTempAndFlowTemp(row.range_heat_pump, -3, 45).capacityKw)
        }
    ];
    const rangeHeatPumpColumns = [
        { key: 'name', name: 'Name' },
        { name: 'Heat Capacity @ 45°C (kW)', render: (row) => React.createElement("div", null, getHeatPumpCapacityAtOutsideTempAndFlowTemp(row, -3, 45).capacityKw) }
    ];
    const [selectedRangeId, setSelectedRangeId] = useState('');
    const [addRangeVisible, setAddRangeVisible] = useState(false);
    const [addHeatPumpVisible, setAddHeatPumpVisible] = useState(false);
    const [selectedHeatPumpIds, setSelectedHeatPumpIds] = useState([]);
    const [modalHeatPumpId, setModalHeatPumpId] = useState();
    const rangeHeatPumps = brandRanges.flatMap(x => x.range_heat_pumps).filter(x => x.deleted_at === null);
    const heatPumpsWithRange = heatPumps.map(x => ({ ...x, range_heat_pump: rangeHeatPumps.find(rhp => rhp.uuid === x.range_heat_pump_uuid) }));
    const heatsPumpsWithBrand = heatPumpsWithRange.map(x => ({ ...x, range_heat_pump: { ...x.range_heat_pump, brand_range: brandRanges.find(br => { var _a; return br.uuid === ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); }) } }));
    const hydratedHeatPumps = chain(orderBy(heatsPumpsWithBrand, x => { var _a; return (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.name; }))
        .groupBy(x => { var _a, _b, _c; return (_c = (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; })
        .map((values, key) => ({ key, values }))
        .value();
    const modalHeatPump = heatPumpsWithRange.find(x => x.uuid === modalHeatPumpId);
    return React.createElement("div", { className: 'w-full flex flex-col gap-y-4' },
        React.createElement("div", { className: 'flex justify-between items-center flex-wrap gap-4' },
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Default range"),
                React.createElement(Select, { selectedKey: company.default_brand_range_uuid, options: uniqBy(heatsPumpsWithBrand.filter(x => { var _a; return (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range; }).map(x => ({ key: x.range_heat_pump.brand_range.uuid, value: x.range_heat_pump.brand_range.name })), x => x.value), setSelectedKey: async (e) => {
                        const updatedCompany = { ...company, default_brand_range_uuid: e };
                        await updateCompany(updatedCompany);
                        setCompany(updatedCompany);
                    } })),
            React.createElement(Button, { onClick: () => setAddRangeVisible(true) }, "Add new range")),
        React.createElement("div", { className: 'flex flex-col gap-y-6' }, hydratedHeatPumps.map(({ key, values }) => {
            var _a, _b;
            return React.createElement("div", { className: 'flex flex-col gap-y-4', key: key },
                React.createElement("div", { className: 'text-gray-900 text-xl font-bold flex gap-2 items-center' },
                    React.createElement("div", null, key),
                    company.default_brand_range_uuid === ((_b = (_a = values[0].range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.uuid) && React.createElement(Badge, { color: 'DARK', text: 'Default' })),
                React.createElement(Table, { onDeleteRow: async (uuid) => { await deleteHeatPumpRow(uuid); }, onSaveRow: async (row) => { await updateOrInsertHeatPumpRow(row); }, rows: orderBy(values, x => { var _a; return ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.range_heat_pumps_by_flow_and_outside) ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, -3, 45).capacityKw : 0; }), columns: heatPumpsColumns, editable: true }));
        })),
        addRangeVisible && React.createElement(Modal, { allowOverflow: true, confirmButtonLabel: 'Continue', visible: true, setVisible: () => setAddRangeVisible(false), title: 'Add a heat pump range', onConfirm: () => {
                setAddRangeVisible(false);
                setAddHeatPumpVisible(true);
            } },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement(Select, { dataCy: "add_dropdown", filter: true, selectedKey: selectedRangeId, options: orderBy(brandRanges.map(x => ({ key: x.uuid, value: x.name })), x => x.value), setSelectedKey: setSelectedRangeId }),
                React.createElement("div", { className: "text-gray-600 text-xs" },
                    "Can't find the range you are looking for? ",
                    React.createElement("a", { target: "_blank", href: "mailto:support@spruce.eco", className: "underline", rel: "noreferrer" }, "Let us know"),
                    " and we'll add it for you."))),
        addHeatPumpVisible && React.createElement(Modal, { onConfirm: () => {
                const heatPumpsToAdd = rangeHeatPumps.filter(x => selectedHeatPumpIds.includes(x.uuid)).map(x => ({
                    uuid: crypto.randomUUID(),
                    name: x.name,
                    price: 0,
                    parts: 0,
                    kwatts: getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw,
                    scopAt50: 0,
                    range_heat_pump_uuid: x.uuid,
                    range_heat_pump: undefined
                }));
                addHeatPumps(heatPumpsToAdd);
                setSelectedHeatPumpIds([]);
            }, confirmButtonLabel: 'Add', confirmDisabled: selectedHeatPumpIds.length === 0, visible: true, setVisible: () => setAddHeatPumpVisible(false), title: 'Add a heat pump' },
            React.createElement(TableLite, { rows: orderBy(rangeHeatPumps.filter(x => x.brand_range_uuid === selectedRangeId && !heatPumps.map(x => x.range_heat_pump_uuid).includes(x.uuid)), x => getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw), rowIdName: 'uuid', columns: rangeHeatPumpColumns, selectedIds: selectedHeatPumpIds, setSelectedIds: setSelectedHeatPumpIds })),
        modalHeatPump && React.createElement(HeatPumpModal, { heatPump: modalHeatPump, setVisible: () => setModalHeatPumpId(undefined) }));
};
