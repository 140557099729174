import React, { useEffect, useState } from 'react';
import { updateCalculatedQuote } from '../../../code/models/calculated_quote';
import { getCompanyNudgeLeadEmailTemplate } from '../../../code/models/company';
import { renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../code/tiptap_placeholders';
import { numberFormat } from '../../../code/number_format';
import { formatPrice } from '../../../code/format_price';
import { Modal } from '../../../components/containers/modal';
import { logEvent } from '../../../code/log_event';
import { sendNudgeEmail } from '../../../code/models/lead';
import { Tiptap } from '../../../components/inputs_and_selections/tiptap';
export const SendNudgeLeadEmailModal = ({ isVisible, setIsVisible, adminContext, calculatedQuote, lead, reloadTrigger }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!calculatedQuote)
        return null;
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    const [quoteToSend, setQuoteToSend] = useState();
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        const quoteToSend = {
            ...calculatedQuote,
            status: 'Reminder sent',
            updated_at: new Date().toString()
        };
        // the email template used to send the estimate
        const emailTemplate = getCompanyNudgeLeadEmailTemplate(adminContext.data.company.nudge_lead_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(emailTemplate, {
            [TTPlaceholderCustomerName.id]: quoteToSend.name,
            [TTPlaceholderCustomerAddress.id]: `${quoteToSend.address}, ${quoteToSend.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: quoteToSend.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(quoteToSend.co2_saved_kg),
            [TTPlaceholderCompanyName.id]: (_d = (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.public_info) === null || _d === void 0 ? void 0 : _d.name,
            [TTPlaceholderCompanyPhone.id]: (_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.phone
        });
        setEmailTemplateJSON(emailJSON);
        setQuoteToSend(quoteToSend);
    }, [adminContext, calculatedQuote]);
    if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) || !quoteToSend)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    return React.createElement(Modal, { title: 'Send reminder to lead', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f, _g;
            logEvent({
                name: 'Send Nudge Lead Email',
                properties: {}
            }, (_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.subdomain) !== null && _d !== void 0 ? _d : '');
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + ((_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.subdomain) + '/estimate/' + quoteToSend.uuid
            });
            await sendNudgeEmail(lead.uuid, calculatedQuote.uuid, companyUUID, emailHTML);
            await updateCalculatedQuote(quoteToSend, companyUUID);
            reloadTrigger();
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("div", null,
                "This will send an estimate of ",
                React.createElement("span", { className: "font-bold" }, formatPrice(quoteToSend.total_price)),
                " to ",
                React.createElement("span", { className: "font-bold" }, quoteToSend.name),
                " at ",
                React.createElement("span", { className: "font-bold" }, (_b = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _b === void 0 ? void 0 : _b.email),
                "."),
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("div", { className: "text-xl font-medium" }, "Customer message"),
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setEmailTemplateJSON(editor.getJSON());
                    }, placeholders: [
                        TTPlaceholderViewEstimate
                    ], content: emailTemplateJSON }),
                ((_e = (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.public_info) === null || _e === void 0 ? void 0 : _e.logo) && React.createElement("img", { src: (_h = (_g = (_f = adminContext.data) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g.public_info) === null || _h === void 0 ? void 0 : _h.logo, className: "max-w-40", alt: "Installer logo" }))));
};
