export const getFloorAndCeilingDefaultMaterialsForFloor = (floor, survey) => {
    let floorMaterial;
    switch (floor.default_floor_material_type) {
        case 'ground-floor':
            floorMaterial = survey.default_materials.groundFloor;
            break;
        case 'intermediate-floor-and-ceiling':
            floorMaterial = survey.default_materials.intermediateFloorAndCeiling;
            break;
        default:
            throw new Error('Unknown floor material type');
    }
    let ceilingMaterial;
    switch (floor.default_ceiling_material_type) {
        case 'intermediate-floor-and-ceiling':
            ceilingMaterial = survey.default_materials.intermediateFloorAndCeiling;
            break;
        case 'roof':
            ceilingMaterial = survey.default_materials.roof;
            break;
        default:
            throw new Error('Unknown ceiling material type');
    }
    return [floorMaterial, ceilingMaterial];
};
