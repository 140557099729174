import React, { useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { logEvent } from '../../../code/log_event';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { buildSingleLineAddressFromLines } from '../../../code/models/address';
export const ManualAddressStep = () => {
    var _a, _b, _c, _d, _e;
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    // component state
    const [addressLine1, setAddressLine1] = React.useState(((_a = survey.address) === null || _a === void 0 ? void 0 : _a.line_1) || '');
    const [addressLine2, setAddressLine2] = React.useState(((_b = survey.address) === null || _b === void 0 ? void 0 : _b.line_2) || '');
    const [addressLine3, setAddressLine3] = React.useState(((_c = survey.address) === null || _c === void 0 ? void 0 : _c.line_3) || '');
    const [town, setTown] = React.useState(((_d = survey.address) === null || _d === void 0 ? void 0 : _d.town) || '');
    const [postcode, setPostcode] = React.useState(((_e = survey.address) === null || _e === void 0 ? void 0 : _e.postcode) || '');
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Manual address step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                address: buildSingleLineAddressFromLines({ line_1: addressLine1, line_2: addressLine2, line_3: addressLine3, post_town: town, postcode }),
                postcode
            }
        });
    };
    const validateAddress = (x) => ({ value: x, message: x.length > 0 ? '' : 'Please enter a valid address' });
    return React.createElement(React.Fragment, null,
        React.createElement(StepHeader, { headerText: "Enter your address", captionText: 'Fields marked with * are required.' }),
        React.createElement("div", { className: "w-full flex flex-col gap-3" },
            React.createElement("div", { className: "space-y-1" },
                React.createElement("div", { className: "text-gray-600 font-bold" }, "Address line 1 *"),
                React.createElement(Input, { size: 'LG', setValue: setAddressLine1, type: 'text', value: addressLine1, validator: validateAddress })),
            React.createElement("div", { className: "space-y-1" },
                React.createElement("div", { className: "text-gray-600 font-bold" }, "Address line 2"),
                React.createElement(Input, { size: 'LG', setValue: setAddressLine2, type: 'text', value: addressLine2 })),
            React.createElement("div", { className: "space-y-1" },
                React.createElement("div", { className: "text-gray-600 font-bold" }, "Address line 3"),
                React.createElement(Input, { size: 'LG', setValue: setAddressLine3, type: 'text', value: addressLine3 })),
            React.createElement("div", { className: "space-y-1" },
                React.createElement("div", { className: "text-gray-600 font-bold" }, "Town or city *"),
                React.createElement(Input, { size: 'LG', setValue: setTown, type: 'text', value: town, validator: validateAddress })),
            React.createElement("div", { className: "space-y-1" },
                React.createElement("div", { className: "text-gray-600 font-bold" }, "Postcode *"),
                React.createElement(Input, { size: 'LG', setValue: setPostcode, type: 'text', value: postcode, validator: (x) => x.length > 0 ? { value: x, message: '' } : { value: x, message: 'Please enter a valid postcode' } }))),
        React.createElement(Button, { size: 'LG', disabled: addressLine1.length === 0 || postcode.length === 0, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-5 w-5" }))));
};
