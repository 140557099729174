import React from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { EHSPhotosBlock } from './existing_heating_system/ehs_photos_block';
import { EHSDetailsBlock } from './existing_heating_system/ehs_details_block';
import { EHSEnergyUseBlock } from './existing_heating_system/ehs_energy_use_block';
import { EHSDefaultRadiatorPipeworkBlock } from './existing_heating_system/ehs_default_radiator_pipework_block';
import { getExistingHeatingChecks } from './validations';
export const SurveyEHSContext = React.createContext(undefined);
export const ExistingHeatingSystem = ({ setSurveyTab, survey, files, setFiles, setSurvey }) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x001, pbValue: 10, page: React.createElement(EHSPhotosBlock, null) },
        { flagBit: 0x002, pbValue: 30, page: React.createElement(EHSDetailsBlock, null) },
        { flagBit: 0x004, pbValue: 60, page: React.createElement(EHSEnergyUseBlock, null) },
        { flagBit: 0x008, pbValue: 100, page: React.createElement(EHSDefaultRadiatorPipeworkBlock, null) }
    ];
    return React.createElement(SurveyEHSContext.Provider, { value: { survey, setSurvey, files, setFiles, setSurveyTab } },
        React.createElement(SurveySectionFlowWrapper, { completeSectionID: 'EXISTING_HEATING', sectionUIName: 'Existing system', pages: pages, flags_attribute_name: 'flags_ehs_pages_completed', onPageComplete: (flagBit) => {
                setSurvey({ ...survey, flags_ehs_pages_completed: survey.flags_ehs_pages_completed | flagBit });
            }, survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, completionChecks: getExistingHeatingChecks(survey) }));
};
