import React from 'react';
import { faImage, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CanvasNav } from './canvas_nav';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import * as pdfjs from 'pdfjs-dist/webpack.mjs';
import { removeUndefinedFromList } from '../../../code/helpers';
export const FloorButtons = ({ currentFloor, setPage, setShowFloorPlanButtons, setDrawingRooms, intersectingShapes }) => {
    return React.createElement(React.Fragment, null,
        React.createElement(CanvasNav, { title: currentFloor.name, items: removeUndefinedFromList([
                { name: 'Details', icon: faFileLines, onClick: () => setPage('FLOOR_DETAILS', true), variant: 'DEFAULT' },
                { name: 'Add room', disabled: intersectingShapes.length > 0, icon: faPlus, onClick: () => setPage('ADD_ROOM', true), variant: 'DEFAULT' },
                currentFloor.floor_plan_image ? { name: 'Draw room', icon: faPencil, onClick: () => setDrawingRooms(true), variant: 'DEFAULT' } : undefined,
                {
                    name: `${currentFloor.floor_plan_image ? '1 ' : ''}Floor plan`,
                    icon: faImage,
                    onClick: () => setShowFloorPlanButtons(true),
                    variant: currentFloor.floor_plan_image ? 'BLUE' : 'DEFAULT'
                }
            ]) }));
};
export const FloorPlanInput = ({ inputRef, setTempImageAndScale, setStageStep }) => {
    return React.createElement("input", { ref: inputRef, className: 'hidden', type: "file", accept: '*', onChange: (e) => {
            var _a, _b;
            const selectedFile = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
            if (!selectedFile)
                return;
            const fileReader = new FileReader();
            if (selectedFile.type === 'application/pdf') {
                fileReader.onload = async (e) => {
                    var _a;
                    const pdfData = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                    const image = await stitchPDFPages(pdfData);
                    setTempImageAndScale({ image, scale: 1 });
                    setStageStep(1);
                };
                fileReader.readAsArrayBuffer(selectedFile);
            }
            else {
                fileReader.onload = async (e) => {
                    var _a;
                    const image = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                    setTempImageAndScale({ image, scale: 1 });
                    setStageStep(1);
                };
                fileReader.readAsDataURL(selectedFile);
            }
        } });
};
const stitchPDFPages = async (pdfData) => {
    const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
    const numPages = pdf.numPages;
    // Create an array to hold the canvas elements for each page
    const canvases = [];
    // Render each page to a canvas
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const renderContext = {
            canvasContext: context,
            viewport
        };
        await page.render(renderContext).promise;
        canvases.push(canvas);
    }
    // Combine all canvases horizontally
    const totalWidth = canvases.reduce((sum, canvas) => sum + canvas.width, 0);
    const maxHeight = Math.max(...canvases.map(canvas => canvas.height));
    const combinedCanvas = document.createElement('canvas');
    combinedCanvas.width = totalWidth;
    combinedCanvas.height = maxHeight;
    const combinedContext = combinedCanvas.getContext('2d');
    let xOffset = 0;
    canvases.forEach(canvas => {
        combinedContext === null || combinedContext === void 0 ? void 0 : combinedContext.drawImage(canvas, xOffset, 0);
        xOffset += canvas.width;
    });
    // Convert combined canvas to base64 image
    const base64Image = combinedCanvas.toDataURL('image/png');
    return base64Image;
};
