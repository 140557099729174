import React from 'react';
import { noop } from 'lodash';
import { InputGroup } from '../../../components/inputs_and_selections/input_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Input } from '../../../components/inputs_and_selections/input';
export const InputGroupDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(InputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Hello world', helperText: 'some helper' }), input: React.createElement(Input, { value: '1234', setValue: noop }), size: 'MD' })));
};
const componentProps = [
    { name: 'formLabel', description: 'Any react element, but expects FormLabel component' },
    { name: 'input', description: 'Any react element, but expects Input component' },
    { name: 'size', description: 'SM | MD | LG' }
];
const codeBlock = `<InputGroup
  formLabel={<FormLabel labelText='Hello world' />}
  input={<Input value='1234' setValue={noop} />}
  size='MD'
/>`;
