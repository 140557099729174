import React, { useEffect, useState } from 'react';
import { logEvent } from '../../code/log_event';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { validatePasswordResetToken, updateUserPassword } from '../../code/models/auth';
export const NewPasswordPage = () => {
    const navigate = useNavigate();
    // common admin/ preloaded data
    const companyPublicInfo = useRouteLoaderData('company_root');
    // component specific
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    useEffect(() => {
        // get query params
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (!token) {
            return;
        }
        setToken(token);
        const result = async () => {
            const valid = await validatePasswordResetToken(token);
            if (!valid) {
                navigate('../login');
            }
        };
        result();
    }, []);
    const handleSubmit = async (event) => {
        if (token.length === 0 || password.length === 0 || passwordRepeat.length === 0) {
            return;
        }
        if (password !== passwordRepeat) {
            return;
        }
        logEvent({ name: 'New Password Set', properties: {} }, companyPublicInfo.subdomain);
        await updateUserPassword(token, password);
        navigate('../login');
    };
    const isValid = () => {
        return !(password.length === 0 || passwordRepeat.length === 0 || password !== passwordRepeat);
    };
    return React.createElement("div", { className: "flex flex-col items-center justify-center gap-16 max-w-80 mx-auto" },
        React.createElement("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: companyPublicInfo.logo }),
        React.createElement("div", { className: "flex flex-col gap-4 w-full" },
            React.createElement("div", { className: "space-y-2" },
                React.createElement("div", { className: "text-slate-600 text-lg font-bold" }, "Password"),
                React.createElement(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPassword, type: "password", value: password, placeholder: 'Enter password' })),
            React.createElement("div", { className: "space-y-2" },
                React.createElement("div", { className: "text-slate-600 text-lg font-bold" }, "Repeat password"),
                React.createElement(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPasswordRepeat, type: "password", value: passwordRepeat, placeholder: 'Repeat password' })),
            React.createElement(Button, { disabled: !isValid(), onClick: handleSubmit }, "Save")));
};
