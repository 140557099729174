// Unique data from propertyType: Bungalow, Flat, House, Maisonette, Park home
import { InsulationTypeCavityInsulatedIcon, InsulationTypeCavityUninsluatedIcon, InsulationTypeInnerIcon, InsulationTypeOuterIcon, InsulationTypeUnsureIcon, PropertyTypeBungalowIcon, PropertyTypeDetachedIcon, PropertyTypeFlatIcon, PropertyTypeHouseIcon, PropertyTypeMidTerraceIcon, PropertyTypeSemiDetachedIcon, QuestionMarkIcon, WallTypeCavityIcon, WallTypeOtherIcon, WallTypeSolidBrickIcon, WallTypeSolidConcreteIcon, WallTypeSolidStoneIcon } from '../../assets/images/domain_icons/domain_icons';
export const TIMBER = 'Timber';
export const CAVITY_WALL = 'Cavity Wall';
export const WALL_GROUPS = [
    { icon: WallTypeCavityIcon, name: CAVITY_WALL, type: 'CAVITY' },
    { icon: WallTypeSolidBrickIcon, name: 'Solid Brick', u_value: 2.24, type: 'SOLID' },
    { icon: WallTypeSolidStoneIcon, name: 'Solid Stone', u_value: 2.23, type: 'SOLID' },
    { icon: WallTypeSolidConcreteIcon, name: 'Solid Concrete', u_value: 2.99, type: 'SOLID' },
    { icon: WallTypeOtherIcon, name: TIMBER, u_value: 0.36, type: 'TIMBER' }
];
export const CAVITY_WALL_INSULATION = [
    { uuid: 'Insulated', name: 'Insulated', u_value: 0.45, synonyms: ['filled'], icon: InsulationTypeCavityInsulatedIcon },
    { uuid: 'Uninsulated', name: 'Uninsulated', u_value: 0.87, icon: InsulationTypeCavityUninsluatedIcon }
];
export const SOLID_WALL_INSULATION = [
    { uuid: 'internal', name: 'Internal', description: 'Insulation on the interior surfaces of external walls.', group: '', u_value: 1 / 2.75, icon: InsulationTypeInnerIcon },
    { uuid: 'external', name: 'External', description: 'A protective insulation layer on the outer surfaces of external walls.', u_value: 1 / 2.75, icon: InsulationTypeOuterIcon },
    { uuid: 'none', name: 'None', u_value: 0, icon: InsulationTypeUnsureIcon },
    { uuid: 'idk', name: 'I don\'t know', u_value: 0, icon: QuestionMarkIcon }
];
export const OUTDOOR_SPACE = [
    { label: 'On the ground floor', sublabel: 'E.g. garden or alongside the house', key: 'On the ground floor' },
    { label: 'On an upper floor', sublabel: 'E.g. balcony or terrace', key: 'On an upper floor' },
    { label: 'No', key: 'None' }
];
export const FLOOR_INSULATION = [
    { uuid: 'None', name: 'None', u_value: 1.82 },
    { uuid: '100mm', name: '100mm', u_value: 0.57 }
];
// MCS U values calculator
// Pitched roof - Slates or tiles, sarking felt, ventilated air space, 9.5 mm plasterboard
// + x mm insulation between joists
// For interpolation calculation see shared version of MCS Heat Pump Calculator
export const LOFT_INSULATION = [
    { uuid: 'None', name: 'None', u_value: 2.51 }, // MCS
    { uuid: '50mm', name: '50mm', u_value: 0.6 }, // MCS
    { uuid: '100mm', name: '100mm', u_value: 0.34 }, // MCS
    { uuid: '150mm', name: '150mm', u_value: 0.23 }, // Interpolated from MCS
    { uuid: '200mm', name: '200mm', u_value: 0.18 }, // MCS
    { uuid: '250+mm', name: '250+mm', u_value: 0.15 } // Interpolated from MCS
];
export const WINDOW_TYPES = [
    { uuid: 'Single', name: 'Single Glazed', u_value: 4.8 },
    { uuid: 'Double', name: 'Double Glazed', u_value: 2.8 },
    { uuid: 'Triple', name: 'Triple Glazed', u_value: 2.1 }
];
export const DOOR_UVALUES = [
    { uuid: 'plastic', name: 'Plastic', u_value: 0.5 },
    { uuid: 'wood', name: 'Wood', u_value: 0.5 },
    { uuid: 'metal', name: 'Metal', u_value: 0.5 }
];
export const getConstructionAge = (epc) => {
    var _a;
    if (epc.constructionAgeBand && /\d/.test(epc.constructionAgeBand))
        return epc.constructionAgeBand;
    if (((_a = epc.transactionType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'new dwelling')
        return epc.inspectionDate && new Date(epc.inspectionDate).getFullYear().toString();
    return '';
};
export const getConstructionAgeScotland = (epc) => {
    var _a;
    if (/\d/.test(epc.construction_age_band))
        return epc.construction_age_band;
    if (((_a = epc.transaction_type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'new dwelling')
        return new Date(epc.inspection_date).getFullYear().toString();
    return '';
};
// Unique data from builtForm: Detached, Enclosed End-Terrace, Enclosed Mid-Terrace, End-Terrace, Mid-Terrace, Semi-Detached
export const getPropertyType = (epc) => {
    if (epc.propertyType.toLowerCase() === 'house')
        return 'House';
    if (epc.propertyType.toLowerCase() === 'bungalow')
        return 'Bungalow';
    if (epc.propertyType.toLowerCase() === 'flat')
        return 'Flat';
    if (epc.propertyType.toLowerCase() === 'maisonette')
        return 'Flat';
    return '';
};
export const getBuiltForm = (epc) => {
    if (epc.builtForm.toLowerCase() === 'semi-detached')
        return 'Semi-Detached';
    // it includes 'enclosed end-terrace' which is not in the list of options
    if (epc.builtForm.toLowerCase().includes('end-'))
        return 'Semi-Detached';
    // it also includes 'enclosed mid-terrace' which is not in the list of options
    if (epc.builtForm.toLowerCase().includes('mid-'))
        return 'Mid-Terrace';
    if (epc.builtForm.toLowerCase() === 'detached')
        return 'Detached';
    return '';
};
export const getPropertyTypeScotland = (epc) => {
    if (epc.property_type.toLowerCase() === 'house')
        return 'House';
    if (epc.property_type.toLowerCase() === 'bungalow')
        return 'Bungalow';
    if (epc.property_type.toLowerCase() === 'flat')
        return 'Flat';
    if (epc.property_type.toLowerCase() === 'maisonette')
        return 'Flat';
    return '';
};
export const getBuiltFormScotland = (epc) => {
    if (epc.built_form.toLowerCase() === 'semi-detached')
        return 'Semi-Detached';
    // it includes 'enclosed end-terrace' which is not in the list of options
    if (epc.built_form.toLowerCase().includes('end-'))
        return 'Semi-Detached';
    // it also includes 'enclosed mid-terrace' which is not in the list of options
    if (epc.built_form.toLowerCase().includes('mid-'))
        return 'Mid-Terrace';
    if (epc.built_form.toLowerCase() === 'detached')
        return 'Detached';
    return '';
};
export const PROPERTY_TYPES = [
    { icon: PropertyTypeHouseIcon, name: 'House', uuid: 'House' },
    { icon: PropertyTypeFlatIcon, name: 'Flat', uuid: 'Flat' },
    { icon: PropertyTypeBungalowIcon, name: 'Bungalow', uuid: 'Bungalow' }
];
export const BUILT_FORM_TYPES = [
    { icon: PropertyTypeDetachedIcon, name: 'Detached', uuid: 'Detached' },
    { icon: PropertyTypeSemiDetachedIcon, name: 'Semi-Detached or End-Terrace', uuid: 'Semi-Detached' },
    { icon: PropertyTypeMidTerraceIcon, name: 'Mid-Terrace', uuid: 'Mid-Terrace' }
];
export const ESTIMATE_AGE_BANDS = [
    { uuid: '1', startYear: 0, endYear: 1899, name: 'Before 1900', wallGroup: 'Solid Stone', floorType: 'None', windowType: 'Single', loftInsulation: '100mm' },
    { uuid: '2', startYear: 1900, endYear: 1929, name: '1900-1929', wallGroup: 'Solid Brick', floorType: 'None', windowType: 'Single', loftInsulation: '100mm' },
    { uuid: '3', startYear: 1930, endYear: 1975, name: '1930-1975', wallGroup: CAVITY_WALL, wallType: 'Uninsulated', floorType: 'None', windowType: 'Double', loftInsulation: '100mm' },
    { uuid: '4', startYear: 1976, endYear: 1995, name: '1976-1995', wallGroup: CAVITY_WALL, wallType: 'Uninsulated', floorType: 'None', windowType: 'Double', loftInsulation: '100mm' },
    { uuid: '5', startYear: 1996, endYear: 2006, name: '1996-2006', wallGroup: CAVITY_WALL, wallType: 'Insulated', floorType: '100mm', windowType: 'Double', loftInsulation: '200mm' },
    { uuid: '6', startYear: 2007, endYear: 9999, name: '2007 or later', wallGroup: CAVITY_WALL, wallType: 'Insulated', floorType: '100mm', windowType: 'Double', loftInsulation: '250+mm' }
];
export const SURVEY_AGE_BANDS = [
    { id: '-1990', name: 'before 1900', min_year: undefined, max_year: 1899, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1990-1929', name: '1900-1929', min_year: 1900, max_year: 1929, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1930-1949', name: '1930-1949', min_year: 1930, max_year: 1949, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1950-1966', name: '1950-1966', min_year: 1950, max_year: 1966, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1967-1975', name: '1967-1975', min_year: 1967, max_year: 1975, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1976-1982', name: '1976-1982', min_year: 1976, max_year: 1982, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1983-1990', name: '1983-1990', min_year: 1983, max_year: 1990, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1991-1995', name: '1991-1995', min_year: 1991, max_year: 1995, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '1996-2002', name: '1996-2002', min_year: 1996, max_year: 2002, jurisdiction: 'England and Wales', ach_age_key: 'pre_2000' },
    { id: '2003-2006', name: '2003-2006', min_year: 2003, max_year: 2006, jurisdiction: 'England and Wales', ach_age_key: 'ach_2000_to_2006' },
    { id: '2007-2011', name: '2007-2011', min_year: 2007, max_year: 2011, jurisdiction: 'England and Wales', ach_age_key: 'ach_post_2006' },
    { id: '2012-2022', name: '2012-2022', min_year: 2012, max_year: 2022, jurisdiction: 'England and Wales', ach_age_key: 'ach_post_2006' },
    { id: '2023-', name: '2023 onwards', min_year: 2023, max_year: undefined, jurisdiction: 'England and Wales', ach_age_key: 'ach_post_2006' }
];
export const leadAgeBandToSurveyAgeBand = (lead) => {
    var _a, _b, _c;
    // if Lead has EPC
    if (lead.epcData) {
        //  for RETROFIT builds: try constructionAgeBand
        if ((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.constructionAgeBand) {
            // the regex to test:  https://rubular.com/r/vfvDZPBqlQekWh
            const epcParse = /([a-z ]+): *([0-9]{4}|before)[- ]([0-9]{4}|onwards)/i.exec((_b = lead.epcData) === null || _b === void 0 ? void 0 : _b.constructionAgeBand);
            if (epcParse) {
                const jurisdiction = epcParse[1];
                const startYear = epcParse[2] === 'before' ? undefined : Number(epcParse[2]);
                const endYear = epcParse[3] === 'onwards' ? undefined : Number(epcParse[3]);
                // manual correction for "before 1900" cases:
                // the SURVEY_AGE_BANDS has 0-1899 only, so if min_year is undefined (i.e. == 'before'), make max_year=1899
                if (startYear === undefined && endYear === 1900) {
                    const ab = SURVEY_AGE_BANDS.find(x => x.min_year === undefined && x.max_year === 1899 && x.jurisdiction === jurisdiction);
                    if (ab)
                        return ab;
                }
                // manual correction for "XXXX-onwards" cases:
                // if max_year is undefined (i.e. == 'onwards'), lookup by min_year only
                if (typeof startYear === 'number' && endYear === undefined) {
                    const ab = SURVEY_AGE_BANDS.find(x => x.min_year === startYear && x.jurisdiction === jurisdiction);
                    if (ab)
                        return ab;
                }
                // all other cases: find the matching age band
                const ab = SURVEY_AGE_BANDS.find(x => x.min_year === startYear && x.max_year === endYear && x.jurisdiction === jurisdiction);
                if (ab)
                    return ab;
            }
        }
        // for NEW BUILDINGS: check the inspectionDate
        if (((_c = lead.epcData.transactionType) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'new dwelling' && lead.epcData.inspectionDate) {
            const inspectionDate = new Date(lead.epcData.inspectionDate).getFullYear();
            // iterate over the SURVEY_AGE_BANDS and find the one that matches the inspectionDate
            // FIXME: no jurisdiction used in this mapping
            const ab = SURVEY_AGE_BANDS.find(x => {
                if (x.min_year === undefined && x.max_year === 1899)
                    return inspectionDate < 1900;
                if (x.min_year && x.max_year && inspectionDate >= x.min_year && inspectionDate <= x.max_year)
                    return true;
                if (x.min_year && x.max_year === undefined && inspectionDate >= x.min_year)
                    return true;
                return false;
            });
            if (ab)
                return ab;
        }
    }
    else {
        // if no successful EPC age band found, map the user selected age band from the estimate age band
        if (lead.property.construction_age_band) {
            const estStartYear = lead.property.construction_age_band.startYear;
            const estEndYear = lead.property.construction_age_band.endYear;
            // the mapping used: https://share.cleanshot.com/NzXvv265
            // TODO: no jurisdiction used in this mapping
            if (estEndYear === 1899) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === undefined && x.max_year === 1899);
            }
            if (estStartYear === 1900 && estEndYear === 1929) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1900 && x.max_year === 1929);
            }
            if (estStartYear === 1930 && estEndYear === 1975) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1950 && x.max_year === 1966);
            }
            if (estStartYear === 1976 && estEndYear === 1995) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1983 && x.max_year === 1990);
            }
            if (estStartYear === 1996 && estEndYear === 2006) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 1996 && x.max_year === 2002);
            }
            if (estStartYear === 2007) {
                return SURVEY_AGE_BANDS.find(x => x.min_year === 2007 && x.max_year === 2011);
            }
        }
    }
    // TODO: this is probably should never happen, raise an error
    return undefined;
};
export const parseConstructionAgeBand = (constructionAge) => {
    var _a;
    if (!constructionAge)
        return undefined;
    const age = (_a = /\b\d{4}\b/.exec(constructionAge)) === null || _a === void 0 ? void 0 : _a[0];
    if (!age)
        return undefined;
    const ageAsNumber = Number(age);
    return ESTIMATE_AGE_BANDS.find(x => ageAsNumber >= x.startYear && ageAsNumber <= x.endYear);
};
export const getWallGroup = (value) => {
    if (!value)
        return '';
    const valueLower = value.toLowerCase();
    if (valueLower.includes('solid') && valueLower.includes('brick'))
        return 'Solid Brick';
    if (valueLower.includes('solid') && valueLower.includes('stone'))
        return 'Solid Stone';
    if (valueLower.includes('solid') && valueLower.includes('concrete'))
        return 'Solid Concrete';
    if (valueLower.includes('cavity'))
        return CAVITY_WALL;
    if (valueLower.includes('timber'))
        return TIMBER;
};
export const getWallType = (value, wallGroup) => {
    var _a;
    const lowerValue = value.toLowerCase();
    return (_a = (wallGroup === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).find(x => { var _a; return (lowerValue.includes(x.uuid.toLowerCase()) || ((_a = x.synonyms) === null || _a === void 0 ? void 0 : _a.some(y => lowerValue.includes(y)))); })) === null || _a === void 0 ? void 0 : _a.uuid;
};
export const getFloorInsulation = (value) => {
    if (value.includes('100'))
        return '100mm';
    return 'None';
};
const extractNumberBeforeMM = (input) => {
    const regex = /(\d+)\s*\+?mm/i; // find a number followed by mm or +mm (not case sensitive)
    const match = input.match(regex);
    if (input === 'None')
        return 0; // deal with None in LOFT_INSULATION
    if (match === null || match === void 0 ? void 0 : match[1]) {
        return parseInt(match[1], 10);
    }
    return null;
};
export const getLoftInsulation = (value) => {
    var _a;
    if (value.includes('no insulation') || value.includes('dim inswleiddio')) {
        return 'None';
    }
    const thickness = extractNumberBeforeMM(value);
    // if a number followed by mm is not in the EPC roof description, set insulation thickness to 100 mm
    // TODO: at some point could update this to use RdSAP default for age band
    if (thickness === null) {
        return '100mm';
    }
    // Find the closest option within our tabulated U values. Will take lower thickness if right in the middle
    const optionNameThicknessPairs = LOFT_INSULATION.map(x => { var _a; return ({ uuid: x.uuid, thickness: (_a = extractNumberBeforeMM(x.name)) !== null && _a !== void 0 ? _a : 0 }); });
    const optionThicknesses = optionNameThicknessPairs.map(x => x.thickness);
    const closest = optionThicknesses.reduce((prev, curr) => {
        return (Math.abs(curr - thickness) < Math.abs(prev - thickness) ? curr : prev);
    });
    return (_a = optionNameThicknessPairs.find(x => x.thickness === closest)) === null || _a === void 0 ? void 0 : _a.uuid;
};
// We expect this to fail sometimes empty catch is okay.
// Only used when the U value itself is passed (i.e., for new build)
export const getUValue = (value) => {
    try {
        if (!(value.includes('Average thermal') || value.includes('Trawsyriannedd thermol')))
            return;
        const result = value.match(/\d+\.?\d*/);
        return (result === null || result === void 0 ? void 0 : result[0]) ? parseFloat(result[0]) : undefined;
    }
    catch (e) { }
};
// Many potential options from EPC, ordered this way so in combinations of "some triple, some double" we are optimistic and go for triple.
export const getWindowType = (epc) => {
    if (epc.windowsDescription.toLowerCase().includes('triple'))
        return 'Triple';
    if (epc.windowsDescription.toLowerCase().includes('double'))
        return 'Double';
    if (epc.windowsDescription.toLowerCase().includes('single'))
        return 'Single';
    return 'Double';
};
export const getWindowTypeScotland = (epc) => {
    if (epc.windows_description.toLowerCase().includes('triple'))
        return 'Triple';
    if (epc.windows_description.toLowerCase().includes('double'))
        return 'Double';
    if (epc.windows_description.toLowerCase().includes('single'))
        return 'Single';
    return 'Double';
};
export const PREV_QUOTE = [
    'Yes',
    'No'
];
export const WHY_HEAT_PUMP = [
    'Save money',
    'Go Green',
    'Better Comfort'
];
export const WHEN_HEAT_PUMP = [
    'ASAP',
    'Within 6 Months',
    'Next 1-2 Years',
    'Unsure'
];
