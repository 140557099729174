import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { getRoomWatts } from '../../../code/models/heat_loss';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../../code/models/range_heat_pump';
import { getFullEmittersListByStatus } from '../../../code/models/radiator';
import { numberFormat } from '../../../code/number_format';
import { formatPrice } from '../../../code/format_price';
import { stepSize } from '../constants';
import { CylinderIcon, HeatPumpIcon2, RadiatorIcon } from '../../../assets/images/survey_images/survey_images';
import { area } from '../floor/code/utils';
export const HLRSummaryPage = () => {
    var _a, _b, _c, _d;
    const hlrContext = React.useContext(HLRContext);
    const totalData = {
        floorArea: 0,
        heatLoss: 0,
        emittersNew: 0,
        emittersReplaced: 0,
        emittersRemoved: 0,
        emittersUnchanged: 0
    };
    const estimateSummary = hlrContext.performanceEstimateSummary;
    hlrContext.survey.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            const roomHeatLoss = getRoomWatts(room, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, stepSize, hlrContext.survey);
            const roomArea = area(stepSize, room.walls.map(w => ({ x: w.x, y: w.y })));
            totalData.heatLoss += roomHeatLoss;
            totalData.floorArea += roomArea;
        });
    });
    const radiators = getFullEmittersListByStatus(hlrContext.survey, hlrContext.design);
    radiators.forEach(radiator => {
        if (radiator.status === 'ADDED') {
            totalData.emittersNew++;
        }
        else if (radiator.status === 'REPLACED') {
            totalData.emittersReplaced++;
        }
        else if (radiator.status === 'REMOVED') {
            totalData.emittersRemoved++;
        }
        else if (radiator.status === 'UNCHANGED') {
            totalData.emittersUnchanged++;
        }
    });
    const scop = getHeatPumpScopAtFlowTemp((_a = hlrContext.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump, hlrContext.design.flow_temp);
    const hpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(hlrContext.currentHeatPump.range_heat_pump, hlrContext.designTempC, hlrContext.design.flow_temp);
    const hpImage = process.env.S3_BUCKET_URL + '/hp-images/' + ((_c = (_b = hlrContext.currentHeatPump) === null || _b === void 0 ? void 0 : _b.range_heat_pump) === null || _c === void 0 ? void 0 : _c.uuid) + '.png';
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBLock, { title: "Summary" }),
        React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Your home\u2019s heat loss"),
            React.createElement("div", { className: 'grid grid-cols-3 gap-3' },
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Floor area"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(0).format(totalData.floorArea)),
                        React.createElement("div", { className: " text-lg" }, "m\u00B2"))),
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Total heat loss"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(2).format(totalData.heatLoss / 1000)),
                        React.createElement("div", { className: " text-lg" }, "kW"))),
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Average heat load"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(0).format(totalData.heatLoss / totalData.floorArea)),
                        React.createElement("div", { className: " text-lg" }, "W/m\u00B2"))))),
        React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Heat pump design proposal"),
            React.createElement("div", { className: 'grid grid-cols-3 gap-3' },
                React.createElement("div", { className: 'h-44 col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8' },
                    React.createElement("img", { alt: '', className: "w-24 max-h-32", src: hpImage, onError: (e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = HeatPumpIcon2;
                        } }),
                    React.createElement("div", { className: 'w-full flex flex-col gap-4' },
                        React.createElement("div", { className: "" }, "Heat pump"),
                        React.createElement("div", { className: 'flex flex-col gap-3' },
                            React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, hlrContext.currentHeatPump.name),
                            React.createElement("div", { className: 'flex flex-col gap-2' },
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: " text-xs font-semibold" },
                                        "Capacity at ",
                                        hpCapacityResult.flowTempC,
                                        " \u00B0C flow temp and ",
                                        hpCapacityResult.outsideTempC,
                                        " \u00B0C outdoor air temperature"),
                                    React.createElement("div", { className: "text-right  text-xs font-semibold text-nowrap" },
                                        hpCapacityResult.capacityKw,
                                        " kW")),
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: " text-xs font-semibold" },
                                        "SCOP at ",
                                        hlrContext.design.flow_temp,
                                        " \u00B0C"),
                                    React.createElement("div", { className: "text-right  text-xs font-semibold text-nowrap" }, scop)))))),
                React.createElement("div", { className: 'row-span-2 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: 'w-full h-full flex flex-col justify-between' },
                        React.createElement("div", { className: "" }, "Emitter changes"),
                        React.createElement("img", { alt: '', className: "w-36 h-36", src: RadiatorIcon }),
                        React.createElement("div", { className: "flex-col gap-2 flex" },
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, totalData.emittersNew + totalData.emittersReplaced),
                                React.createElement("div", { className: "text-lg" }, "new radiators")),
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, totalData.emittersUnchanged),
                                React.createElement("div", { className: "text-lg" }, "retained")),
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, totalData.emittersRemoved + totalData.emittersReplaced),
                                React.createElement("div", { className: "text-lg" }, "removed"))))),
                React.createElement("div", { className: 'h-44 col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8' },
                    React.createElement("img", { alt: '', className: "w-24 h-24", src: CylinderIcon }),
                    React.createElement("div", { className: 'w-full flex flex-col gap-4' },
                        React.createElement("div", { className: "" }, "Cylinder"),
                        React.createElement("div", { className: 'flex flex-col gap-3' },
                            React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, ((_d = hlrContext.currentHotWaterCylinder) === null || _d === void 0 ? void 0 : _d.name) || '?'),
                            React.createElement("div", { className: 'flex flex-col gap-2' },
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: "text-xs font-semibold" }, "Capacity"),
                                    React.createElement("div", { className: "text-right text-xs font-semibold" },
                                        Math.round(hlrContext.currentHotWaterCylinder.litres),
                                        " litres")))))))),
        React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Performance comparison"),
            React.createElement("div", { className: 'grid grid-cols-2 gap-3' },
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Annual CO2 savings"),
                    React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold flex" },
                            React.createElement("div", { className: `${estimateSummary.minEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, numberFormat(1).format(estimateSummary.minEmissionsTonnesSavings)),
                            React.createElement("div", null, " \u2013 "),
                            React.createElement("div", { className: `${estimateSummary.maxEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, numberFormat(1).format(estimateSummary.maxEmissionsTonnesSavings))),
                        React.createElement("div", { className: " text-lg" }, "tonnes"))),
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Annual running cost savings"),
                    React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold px-1 gap-1 flex" },
                            React.createElement("div", { className: `${estimateSummary.minBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, formatPrice(estimateSummary.minBillSavings, 0)),
                            React.createElement("div", null, "to"),
                            React.createElement("div", { className: `${estimateSummary.maxBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, formatPrice(estimateSummary.maxBillSavings, 0))),
                        React.createElement("div", { className: "text-lg" }, "per year")))))));
};
