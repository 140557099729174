import React from 'react';
import { Link } from '../../../components/buttons/link';
import { noop } from 'lodash';
export const LinkDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Link, { onClick: noop, className: '' }, "Hello world")));
};
const componentProps = [
    { name: 'onClick', description: 'Action to perform when clicked, shows cursor or not if populated' },
    { name: 'className', description: 'Any additional styling or positioning required' }
];
const codeBlock = `<Link
  onClick={noop}
  className=''
>Hello world</Link>`;
