import React, { useContext, useRef, useState } from 'react';
import { EllipsisHorizontalIcon, SignalSlashIcon } from '@heroicons/react/16/solid';
import { patchLead } from '../../../code/models/lead';
import { useRouteLoaderData } from 'react-router-dom';
import { leadSurveyStatusToBadge } from '../../../components/indicators_and_messaging/badge';
import { AdminContext } from '../../admin/admin_layout';
import _ from 'lodash';
import { AnimatedBlock } from '../../admin/components/animated_block';
export const SurveysListItemOptions = ({ lead, callback }) => {
    const companyPublicInfo = useRouteLoaderData('company_root');
    const adminContext = useContext(AdminContext);
    const [isOpen, setIsOpen] = useState(false);
    // Use case: user opens the dropdown and then clicks outside it
    // This will close the dropdown
    const selectorRef = useRef(null);
    document.addEventListener('mousedown', (e) => {
        if (selectorRef.current && !selectorRef.current.contains(e.target))
            setIsOpen(false);
    });
    const handleOnClickStatus = async (status) => {
        await patchLead(lead.uuid, { survey_status: status }, companyPublicInfo.uuid);
        setIsOpen(false);
        if (callback)
            callback();
    };
    const items = _.compact([
        !lead.survey_status || lead.survey_status === 'IN_PROGRESS' ? undefined : {
            label: leadSurveyStatusToBadge('IN_PROGRESS').text,
            onClick: async () => {
                await handleOnClickStatus('IN_PROGRESS');
            }
        },
        lead.survey_status === 'COMPLETED' ? undefined : {
            label: leadSurveyStatusToBadge('COMPLETED').text,
            onClick: async () => {
                await handleOnClickStatus('COMPLETED');
            }
        }
    ]);
    let view;
    if (adminContext.isOffline) {
        view = React.createElement(AnimatedBlock, { fromElement: React.createElement(EllipsisHorizontalIcon, { className: 'text-gray-400 w-5 h-5 cursor-pointer' }), toElement: React.createElement(SignalSlashIcon, { className: 'w-5 h-5 cursor-pointer' }), className: 'w-5 h-5 cursor-pointer' });
    }
    else {
        view = React.createElement(EllipsisHorizontalIcon, { className: `${isOpen ? 'text-gray-900' : 'text-gray-400'} w-5 h-5 cursor-pointer`, onClick: () => {
                setIsOpen(prev => !prev);
            } });
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { ref: selectorRef },
            view,
            React.createElement("div", { className: `${!isOpen ? 'hidden' : ''} relative` },
                React.createElement("div", { className: "absolute -start-28 w-32 py-2 bg-white rounded-md shadow border border-gray-200 flex-col flex" }, items.map((item, index) => {
                    // separator
                    if (item.label === '---') {
                        return React.createElement("div", { key: index, className: "self-stretch z-10 py-2 bg-white gap-2.5 inline-flex" },
                            React.createElement("div", { className: "h-px border border-gray-200" }));
                        // normal item
                    }
                    else {
                        return React.createElement("div", { key: index, className: "self-stretch z-10 hover:bg-gray-100 px-3 py-2 bg-white gap-2.5 inline-flex", onClick: async () => { await (item === null || item === void 0 ? void 0 : item.onClick()); } },
                            React.createElement("div", { className: "select-none text-gray-600 text-sm " }, item === null || item === void 0 ? void 0 : item.label));
                    }
                })))));
};
