import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { getSoundAssessmentData } from '../../../code/sound_assessment';
import { HLRListBlock } from './partials/hlr_list';
export const HLRSoundAssessment = () => {
    var _a, _b, _c;
    const hlrContext = React.useContext(HLRContext);
    const { barrier, distanceReductionDba, barrierReductionDba, soundPressureLevelDba, backgroundNoiseLevelDba, differentialDba, correctionDB, finalResultDba } = getSoundAssessmentData(hlrContext.survey.sound_barrier_uuid, hlrContext.survey.reflective_surfaces, hlrContext.survey.sound_distance, (_b = (_a = hlrContext.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump) === null || _b === void 0 ? void 0 : _b.sound_power_max_dba);
    let reflectiveSurfacesText = '';
    switch (hlrContext.survey.reflective_surfaces) {
        case 1:
            reflectiveSurfacesText = 'one reflecting surface (Q=2)';
            break;
        case 2:
            reflectiveSurfacesText = 'two reflecting surfaces (Q=4)';
            break;
        case 3:
            reflectiveSurfacesText = 'three reflecting surfaces (Q=8)';
            break;
    }
    return React.createElement("div", { className: 'flex-col gap-6 flex' },
        React.createElement(HLRSubheaderBLock, { section: "Your Heat pump system", title: "Sound Assessment" }),
        React.createElement("div", { className: 'flex flex-col gap-3' },
            React.createElement("div", null, "To class as \u201Cpermitted development\u201D the heat pump design must comply with regulations regarding the sound level at your neighbour\u2019s nearest window/door. We have conducted an assessment for your proposed heat pump and heat pump location and are pleased to report that it complies with the requirements."),
            React.createElement("div", { className: 'grid grid-cols-2 gap-6' },
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "This assessment accounts for:"),
                    React.createElement(HLRListBlock, { items: [
                            React.createElement(React.Fragment, null, "The sound level of the heat pump itself"),
                            React.createElement(React.Fragment, null, "The influence of the space that it is in"),
                            React.createElement(React.Fragment, null, "Any barriers between the heat pump and the assessment position")
                        ] })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Assessment position:"),
                    React.createElement("div", { className: "" }, hlrContext.survey.assessment_description)))),
        React.createElement("div", { className: 'grid grid-cols-2 gap-6' },
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Sound pressure level"),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Sound power level"),
                                React.createElement("div", { className: "text-xs font-semibold" }, "Manufacturer\u2019s data")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, (_c = hlrContext.currentHeatPump.range_heat_pump) === null || _c === void 0 ? void 0 :
                                _c.sound_power_max_dba,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Reduction due to distance"),
                                React.createElement("div", { className: "text-xs font-semibold" },
                                    "Reflective surfaces: ",
                                    reflectiveSurfacesText),
                                React.createElement("div", { className: "text-xs font-semibold" },
                                    "Distance from heat pump to assessment position: ",
                                    hlrContext.survey.sound_distance,
                                    " m")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                distanceReductionDba,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Barriers"),
                                React.createElement("div", { className: "text-xs font-semibold" },
                                    "Between heat pump and assessment position: ",
                                    barrier.name),
                                React.createElement("div", { className: "text-xs font-semibold" },
                                    "Distance from heat pump to assessment position: ",
                                    hlrContext.survey.sound_distance,
                                    " m")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                barrierReductionDba,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Total"),
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold text-nowrap" },
                                soundPressureLevelDba,
                                " dB(A)"))))),
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Adjustment due to background noise"),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Background noise level")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                backgroundNoiseLevelDba,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Difference"),
                                React.createElement("div", { className: "text-xs font-semibold" }, "Difference between background noise level and heat pump sound pressure level")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                differentialDba,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "flex-col gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Adjustment")),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                correctionDB,
                                " dB(A)")),
                        React.createElement("div", { className: "py-2 gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Max of sound pressure level and background noise"),
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold text-nowrap" },
                                finalResultDba,
                                " dB(A)")))))),
        React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'flex flex-row justify-between' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Result"),
                    React.createElement(Badge, { color: hlrContext.soundAssessment === 0 ? 'YELLOW' : hlrContext.soundAssessment <= 42 ? 'GREEN' : 'RED', text: hlrContext.soundAssessment === 0 ? 'Incomplete' : hlrContext.soundAssessment <= 42 ? 'Pass' : 'Fail' })),
                React.createElement("div", null,
                    React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                        React.createElement("div", { className: "flex-col gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Maximum allowed value")),
                        React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, "42 dB(A)")),
                    React.createElement("div", { className: "py-2 gap-2 flex justify-between" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Final result at assessment position"),
                        React.createElement("div", { className: "text-gray-900 text-xs font-bold text-nowrap" },
                            finalResultDba,
                            " dB(A)"))))));
};
