import { ceilingShapeAImg } from '../../assets/images/ceiling_shapes/ceiling_shape_a';
import { ceilingShapeBImg } from '../../assets/images/ceiling_shapes/ceiling_shape_b';
import { ceilingShapeCImg } from '../../assets/images/ceiling_shapes/ceiling_shape_c';
import { ceilingShapeDImg } from '../../assets/images/ceiling_shapes/ceiling_shape_d';
import { getFloorAreaM2, getExposedPerimeterM } from './heat_loss';
import { sum } from 'lodash';
import { getEmitterWatts } from './radiator_model';
export const CEILING_SHAPE_TYPES = ['Flat', 'Vaulted'];
export const CEILING_TYPES = [
    { uuid: 'flat', name: 'Flat', category_uuid: 'Flat', showCeilingWidth: false, img: undefined, getAverageRoomHeightM: (room) => { var _a; return (_a = room.height_m) !== null && _a !== void 0 ? _a : 0; } },
    { uuid: 'a', name: 'A', category_uuid: 'Vaulted', showCeilingWidth: false, img: ceilingShapeAImg, getAverageRoomHeightM: (room) => { var _a, _b; return (((_a = room.height_m) !== null && _a !== void 0 ? _a : 0) + ((_b = room.height_2_m) !== null && _b !== void 0 ? _b : 0)) / 2; } },
    {
        uuid: 'b',
        name: 'B',
        category_uuid: 'Vaulted',
        showCeilingWidth: true,
        img: ceilingShapeBImg,
        getAverageRoomHeightM: (room) => {
            if (!room.height_2_m || !room.ceiling_width_1_m || !room.height_m || !room.ceiling_width_2_m)
                return 0;
            return (room.height_2_m * room.ceiling_width_1_m + (room.height_m + room.height_2_m) / 2 * (room.ceiling_width_2_m - room.ceiling_width_1_m)) / room.ceiling_width_2_m;
        }
    },
    { uuid: 'c', name: 'C', category_uuid: 'Vaulted', showCeilingWidth: false, img: ceilingShapeCImg, getAverageRoomHeightM: (room) => { var _a, _b; return (((_a = room.height_m) !== null && _a !== void 0 ? _a : 0) + ((_b = room.height_2_m) !== null && _b !== void 0 ? _b : 0)) / 2; } },
    {
        uuid: 'd',
        name: 'D',
        category_uuid: 'Vaulted',
        showCeilingWidth: true,
        img: ceilingShapeDImg,
        getAverageRoomHeightM: (room) => {
            if (!room.height_m || !room.height_2_m || !room.ceiling_width_1_m || !room.ceiling_width_2_m)
                return 0;
            return ((room.height_2_m * room.ceiling_width_1_m + (room.height_m + room.height_2_m) / 2 * (room.ceiling_width_2_m - room.ceiling_width_1_m)) / room.ceiling_width_2_m);
        }
    }
];
export const FLUE_TYPES = [
    { uuid: 'no', name: 'None', ach_volume_less_than_or_equal_to_40_m3: 0, ach_volume_greater_than_40_m3: 0 },
    { uuid: 'yes_throat', name: 'Yes (with throat restrictor)', ach_volume_less_than_or_equal_to_40_m3: 3, ach_volume_greater_than_40_m3: 2 },
    { uuid: 'yes_without_throat', name: 'Yes (without throat restrictor)', ach_volume_less_than_or_equal_to_40_m3: 5, ach_volume_greater_than_40_m3: 4 }
];
export const ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M = 0x000001;
export const ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL = 0x000002;
export const ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL = 0x000004;
export const getCeilingUValue = (room) => {
    if (room.ceiling_material === undefined) {
        // FIXME: this is a temporary fix, we should not have rooms without ceiling material
        return 0;
    }
    if (['custom_layered', 'custom_simple'].includes(room.ceiling_material.type)) {
        return room.ceiling_material.extra_data.u_value;
    }
    if (room.ceiling_material.applicable_to === 'roof') {
        return room.ceiling_material.extra_data.u_value;
    }
    if (room.ceiling_material.applicable_to === 'intermediate-floor-and-ceiling') {
        const uValue = room.ceiling_material.extra_data.u_value_flow_up;
        if (uValue === undefined) {
            throw new Error(`The exposed floor material ${room.ceiling_material.name} has been selected for the ceiling`);
        }
        return uValue;
    }
    throw new Error('Invalid ceiling material type');
};
export const getFloorUValue = (room, stepSize) => {
    // Consider doing this at the whole floor level for ground floors? But then what about if they put in different materials for different rooms?
    // Think that kills that idea
    if (room.floor_material === undefined) {
        // FIXME: this is a temporary fix, we should not have rooms without ceiling material
        return 0;
    }
    if (['custom_layered', 'custom_simple'].includes(room.floor_material.type)) {
        return room.floor_material.extra_data.u_value;
    }
    if (room.floor_material.applicable_to === 'ground-floor') {
        return getGroundFloorUValue(room, stepSize);
    }
    if (room.floor_material.applicable_to === 'intermediate-floor-and-ceiling') {
        return room.floor_material.extra_data.u_value_flow_down;
    }
    throw new Error(`Invalid floor material type ${room.floor_material.applicable_to}`);
};
export const getGroundFloorUValue = (room, stepSize) => {
    const floorMaterialExtraData = room.floor_material.extra_data;
    if (floorMaterialExtraData.u_value)
        return floorMaterialExtraData.u_value; // new build case
    const floorInsulationUValueWPerM2K = floorMaterialExtraData.insulation_u_value;
    const floorInsulationRValueM2KPerW = floorInsulationUValueWPerM2K ? 1 / floorInsulationUValueWPerM2K : 0;
    // Section 6.12 of the SAP 10 document: https://bregroup.com/expertise/energy/sap/sap10
    const areaM2 = getFloorAreaM2(room.walls, stepSize);
    const exposedPerimeterM = getExposedPerimeterM(room.walls, stepSize);
    // if exposed perimeter is 0 then BRatioM will be infinity so set to 0.05 in accordance with CIBSE equation 5
    if (exposedPerimeterM === 0) {
        const uValueInternalRoom = 0.05;
        return 1 / (1 / uValueInternalRoom + floorInsulationRValueM2KPerW);
    }
    const BRatioM = 2 * areaM2 / exposedPerimeterM;
    const wallThicknessM = 0.3; // later maybe take actual thickness of selected material
    // constants
    const soilConductivityWPerMK = 1.5;
    const RSiM2KPerW = 0.17;
    const RSeM2KPerW = 0.04;
    const heightAboveGroundM = 0.3;
    const UWallsToUnderfloorSpaceWPerM2K = 1.5;
    const ventilationOpeningsPerExposedPerimeterM2PerM = 0.003;
    const averageWindSpeedMPerS = 5;
    const windShieldingFactor = 0.05;
    if (floorMaterialExtraData.construction === 'Suspended') {
        const dgM = wallThicknessM + soilConductivityWPerMK * (RSiM2KPerW + RSeM2KPerW);
        const Ug = 2 * soilConductivityWPerMK * Math.log(Math.PI * BRatioM / dgM + 1) / (Math.PI * BRatioM + dgM);
        const Ux = (2 * heightAboveGroundM * UWallsToUnderfloorSpaceWPerM2K / BRatioM) + (1450 * ventilationOpeningsPerExposedPerimeterM2PerM * averageWindSpeedMPerS * windShieldingFactor / BRatioM);
        return 1 / (2 * RSiM2KPerW + floorInsulationRValueM2KPerW + 1 / (Ug + Ux));
    }
    // solid
    const dtM = wallThicknessM + soilConductivityWPerMK * (RSiM2KPerW + RSeM2KPerW + floorInsulationRValueM2KPerW);
    if (dtM < BRatioM)
        return 2 * soilConductivityWPerMK * Math.log(Math.PI * BRatioM / dtM + 1) / (Math.PI * BRatioM + dtM);
    return soilConductivityWPerMK / (0.457 * BRatioM + dtM);
};
export const getRoomRadiatorWatts = (room, design, survey, designTempC, groundTempC) => {
    const designRadiators = design.radiators.filter(x => x.room_uuid === room.uuid);
    const allRadiators = [...designRadiators, ...room.radiators];
    const radiatorsToCalculate = allRadiators.filter(x => !design.removed_radiator_uuids.includes(x.uuid) && !design.radiators.some(y => y.replaces_uuid === x.uuid));
    const radiatorWatts = sum(radiatorsToCalculate.map(x => getEmitterWatts(x, room, design, survey, designTempC, groundTempC)));
    return radiatorWatts;
};
