import React, { useEffect, useState } from 'react';
import { CEILING_SHAPE_TYPES, FLUE_TYPES, CEILING_TYPES, ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL } from '../../code/models/room';
import { Input } from '../../components/inputs_and_selections/input';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/buttons/button';
import { RadiatorPage } from './radiator';
import { TabGroup } from '../../components/content_display/tab';
import { PhotoScroll } from '../../components/inputs_and_selections/photo_scroll';
import { ROOM_TYPES, stepSize } from './constants';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { getRoomACH, getRoomTemp } from '../../code/models/heat_loss';
import { Icon } from '../../components/buttons/icon';
import { getEmitterSizeName, getEmitterTypeName } from '../../code/models/radiator';
import { DEFAULT_SURVEY_SECONDARY_EMITTER, DEFAULT_SURVEY_RADIATOR, DEFAULT_SURVEY_ROOFLIGHT, DEFAULT_SURVEY_UNDERFLOOR } from '../../code/survey_defaults';
import { ClickableCard } from '../../components/content_display/card';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { MaterialInputField } from './materials/material_input_field';
import { RooflightPage } from './rooflight';
import { getRooflightMaterialUValue, getRooflightUValue, getRoofligthUValuePitchCorrection } from '../../code/models/rooflight';
import { SelectRadiatorModelInner } from './design/pages/radiator_model_selector';
import { getRoomPostfix } from './add_room';
import { getDefaultUfhFloorConstructionFromFloorMaterial } from '../../code/models/underfloor_heating';
import { UnderfloorEmitter } from './underfloor/underfloor_emitter';
import { BottomSheetHeader } from '../../components/containers/bottom_sheet_header';
import { SecondaryHeatingPage } from './secondary_heating_page';
import { Select } from '../../components/inputs_and_selections/select';
import { ListItem } from '../../components/content_display/list_item';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { validateH1LessThanH2, validateIsPositiveNumber, validateW1LessThanW2 } from '../../code/validators';
import { calculateSimplifiedWallLengths } from './floor/code/utils';
export const RoomPage = ({ survey, setSurvey, room, floor, setRoom, files, setFiles, page, materials, setMsProps, customRadiatorModels, setCustomRadiatorModels, allRadiatorModels, design, groundTempC, designTempC, setFlowTemp, minFlowTemp, maxFlowTemp, setHeader, currentRooflight, setCurrentRooflight, companyUuid, onBack, setPage }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const roomImages = room.images.map(x => files.find(y => y.uuid === x.image_uuid));
    const ceilingType = CEILING_TYPES.find(x => x.uuid === room.ceiling_type_uuid);
    const [currentRadiator, setCurrentRadiator] = useState();
    const [ceilingTypeCategory, setCeilingTypeCategory] = useState((_a = ceilingType === null || ceilingType === void 0 ? void 0 : ceilingType.category_uuid) !== null && _a !== void 0 ? _a : 'Flat');
    const deleteImage = (imageUUID) => {
        setRoom({ ...room, images: room.images.filter(x => x.image_uuid !== imageUUID) });
        setFiles(files.filter(x => x.uuid !== imageUUID));
    };
    const saveRooflight = () => {
        setRoom({
            ...room,
            rooflights: (currentRooflight === null || currentRooflight === void 0 ? void 0 : currentRooflight.uuid)
                ? room.rooflights.map(x => x.uuid === currentRooflight.uuid ? currentRooflight : x) // edit existing
                : [...room.rooflights, { ...currentRooflight, uuid: crypto.randomUUID() }] // add new
        });
        onBack();
    };
    const deleteRooflight = (id) => setRoom({ ...room, rooflights: room.rooflights.filter(x => x.uuid !== id) });
    const initNewRooflight = () => {
        var _a;
        return setCurrentRooflight({
            ...DEFAULT_SURVEY_ROOFLIGHT,
            material: (_a = survey.default_materials) === null || _a === void 0 ? void 0 : _a.window
        });
    };
    const saveRadiator = () => {
        setRoom({
            ...room,
            radiators: (currentRadiator === null || currentRadiator === void 0 ? void 0 : currentRadiator.uuid)
                ? room.radiators.map(x => x.uuid === currentRadiator.uuid ? { ...currentRadiator, updated_at: new Date().getTime() } : x)
                : [...room.radiators, { ...currentRadiator, uuid: crypto.randomUUID(), created_at: new Date().getTime(), updated_at: new Date().getTime() }]
        });
        // If default radiator values are not set on the survey level then set them from the first radiator added
        if ((currentRadiator === null || currentRadiator === void 0 ? void 0 : currentRadiator.emitter_type) === 'RADIATOR' && !survey.existing_system_radiator_pipework_uuid && (currentRadiator === null || currentRadiator === void 0 ? void 0 : currentRadiator.pipe_model_uuid)) {
            setSurvey({ ...survey, existing_system_radiator_pipework_uuid: currentRadiator.pipe_model_uuid });
        }
        onBack();
    };
    const deleteRadiator = (id) => {
        const newRoom = { ...room, radiators: room.radiators.filter(x => x.uuid !== id) };
        const newFloor = { ...floor, rooms: floor.rooms.map(x => x.uuid === newRoom.uuid ? newRoom : x) };
        setSurvey({
            ...survey,
            floors: survey.floors.map(x => x.uuid === newFloor.uuid ? newFloor : x),
            designs: survey.designs.map(x => ({ ...x, radiators: x.radiators.filter(y => y.replaces_uuid !== id) }))
        });
    };
    const radiatorsHydrated = room.radiators.map(x => ({
        ...x,
        radiator_type: x.emitter_type === 'RADIATOR' ? allRadiatorModels.find(y => y.uuid === x.radiator_type_uuid) : undefined
    }));
    // Get last radiator model used in the survey so can default next radiator model to this
    const surveyRads = survey.floors
        .flatMap(x => x.rooms)
        .flatMap(x => x.radiators)
        .filter(x => x.emitter_type === 'RADIATOR');
    // NB! using `as any` here to make a trick with Linter.
    // It changes code and removes `as SurveyRadiator` thinking that it's not required.
    // Code does not work after that because below it expects some attributes from the SurveyRadiator. So `as any` prevents this behaviour.
    const lastRadiator = surveyRads
        .sort((a, b) => { var _a, _b; return ((_a = b.updated_at) !== null && _a !== void 0 ? _a : 0) - ((_b = a.updated_at) !== null && _b !== void 0 ? _b : 0); })[0];
    const lastRadiatorModelUUID = (_b = lastRadiator === null || lastRadiator === void 0 ? void 0 : lastRadiator.radiator_type) === null || _b === void 0 ? void 0 : _b.uuid;
    const lastRadiatorModel = allRadiatorModels.find(x => x.uuid === lastRadiatorModelUUID);
    // Get details of last UFH used in the survey so can default next UFH to this to save user filling in the same details over and over again
    const lastUnderfloorHeating = survey.floors
        .flatMap(x => x.rooms)
        .flatMap(x => x.radiators)
        .filter(x => x.emitter_type === 'UNDERFLOOR')
        .sort((a, b) => { var _a, _b; return ((_a = b.updated_at) !== null && _a !== void 0 ? _a : 0) - ((_b = a.updated_at) !== null && _b !== void 0 ? _b : 0); })[0];
    // If any UFH emitter is found then set default values for the next UFH emitter, if not then use the floor material to choose the UFH floor construction type
    const defaultUnderfloorHeating = lastUnderfloorHeating
        ? {
            ...DEFAULT_SURVEY_UNDERFLOOR,
            type: lastUnderfloorHeating.emitter_type,
            manifold_uuid: lastUnderfloorHeating.manifold_uuid,
            floor_construction_type: lastUnderfloorHeating.floor_construction_type,
            floor_covering_uuid: lastUnderfloorHeating.floor_covering_uuid,
            pipe_centres_uuid: lastUnderfloorHeating.pipe_centres_uuid,
            pipe_model_uuid: lastUnderfloorHeating.pipe_model_uuid
        }
        : {
            ...DEFAULT_SURVEY_UNDERFLOOR,
            floor_construction_type: getDefaultUfhFloorConstructionFromFloorMaterial(room.floor_material),
            manifold_uuid: survey.manifolds.length > 0 ? survey.manifolds[0].uuid : undefined // default to first manifold if available
        };
    useEffect(() => {
        if (page === 'CHOOSE_EMITTER')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Choose emitter type', goBack: onBack }));
        else if (page === 'RADIATOR_MODEL')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Choose a radiator', goBack: onBack }));
        else if (page === 'RADIATOR')
            setHeader(React.createElement(BottomSheetHeader, { goBack: onBack, onBack: () => setCurrentRadiator(undefined), title: 'Radiator' }));
        else if (page === 'ADD_CUSTOM_RADIATOR')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Add custom radiator', goBack: onBack }));
        else if (page === 'UNDERFLOOR')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Underfloor heating', goBack: onBack, onBack: () => setCurrentRadiator(undefined) }));
        else if (page === 'MANIFOLD')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Manifold', goBack: onBack }));
        else if (page === 'ROOFLIGHT')
            setHeader(React.createElement(BottomSheetHeader, { goBack: onBack, title: 'Rooflight' }));
        else if (page === 'SLOPED_CEILING')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Select sloped ceiling type', goBack: onBack }));
        else if (page === 'SECONDARY_HEATING')
            setHeader(React.createElement(BottomSheetHeader, { title: 'Secondary heating details', goBack: onBack }));
        else
            setHeader(React.createElement(BottomSheetHeader, { title: room.name, goBack: onBack }));
    }, [page]);
    if ((page === 'UNDERFLOOR' || page === 'MANIFOLD') && currentRadiator && currentRadiator.emitter_type === 'UNDERFLOOR') {
        return React.createElement(UnderfloorEmitter, { underfloorHeating: currentRadiator, setUnderfloorHeating: setCurrentRadiator, room: room, floor: floor, survey: survey, designTempC: designTempC, groundTempC: groundTempC, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, onSave: saveRadiator, files: files, setFiles: setFiles, design: design, setSurvey: setSurvey, page: page, setPage: setPage, onBack: onBack });
    }
    if ((page === 'RADIATOR' || page === 'ADD_CUSTOM_RADIATOR') && currentRadiator && currentRadiator.emitter_type === 'RADIATOR' && !currentRadiator.radiator_type) {
        // New radiator
        return React.createElement(SelectRadiatorModelInner, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, defaultType: lastRadiatorModel === null || lastRadiatorModel === void 0 ? void 0 : lastRadiatorModel.type, defaultHeightMm: lastRadiatorModel === null || lastRadiatorModel === void 0 ? void 0 : lastRadiatorModel.height_mm, deltaTFlowReturnC: design.delta_t_flow_return_c, roomTemp: getRoomTemp(room, survey), flowTemp: design.flow_temp, companyUuid: companyUuid, addRadiator: (rm) => {
                setCurrentRadiator(prev => ({
                    ...prev,
                    radiator_type: rm,
                    radiator_type_uuid: rm.uuid,
                    updated_at: new Date().getTime()
                }));
            }, page: page, setPage: setPage, onBack: onBack });
    }
    if ((page === 'RADIATOR' || page === 'RADIATOR_MODEL' || page === 'ADD_CUSTOM_RADIATOR') && (currentRadiator === null || currentRadiator === void 0 ? void 0 : currentRadiator.emitter_type) === 'RADIATOR') {
        // Modify existing rad
        return React.createElement(RadiatorPage, { setHeader: setHeader, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, deltaTFlowReturnC: design.delta_t_flow_return_c, flowTemp: design.flow_temp, roomTemp: getRoomTemp(room, survey), onSave: saveRadiator, radiator: currentRadiator, setRadiator: setCurrentRadiator, files: files, setFiles: setFiles, setPage: setPage, page: page, companyUuid: companyUuid, onBack: onBack });
    }
    if (page === 'ROOFLIGHT' && currentRooflight) {
        return React.createElement(RooflightPage, { onSave: saveRooflight, rooflight: currentRooflight, setRooflight: setCurrentRooflight, materials: materials, survey: survey, setMsProps: setMsProps, setPage: setPage });
    }
    if (page === 'SECONDARY_HEATING' && (currentRadiator === null || currentRadiator === void 0 ? void 0 : currentRadiator.emitter_type) === 'SECONDARY') {
        return React.createElement(SecondaryHeatingPage, { secondaryHeating: currentRadiator, setSecondaryHeating: setCurrentRadiator, onSave: () => {
                saveRadiator();
                setPage('ROOM_EMITTERS');
            } });
    }
    if (page === 'CHOOSE_EMITTER') {
        return React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'flex flex-col divide-y divide-gray-200' },
                React.createElement(ListItem, { primaryText: 'Radiator', onClick: () => {
                        setPage('RADIATOR');
                        setCurrentRadiator({
                            ...DEFAULT_SURVEY_RADIATOR,
                            emitter_type: 'RADIATOR',
                            room_uuid: room.uuid,
                            pipe_model_uuid: survey.existing_system_radiator_pipework_uuid,
                            updated_at: new Date().getTime(),
                            created_at: new Date().getTime()
                        });
                    } }),
                React.createElement(ListItem, { primaryText: 'Underfloor heating', onClick: () => {
                        setPage('UNDERFLOOR');
                        setCurrentRadiator({
                            ...defaultUnderfloorHeating,
                            room_uuid: room.uuid,
                            updated_at: new Date().getTime(),
                            created_at: new Date().getTime()
                        });
                    } }),
                React.createElement(ListItem, { primaryText: 'Secondary heating', onClick: () => {
                        setPage('SECONDARY_HEATING');
                        setCurrentRadiator({
                            ...DEFAULT_SURVEY_SECONDARY_EMITTER,
                            room_uuid: room.uuid,
                            updated_at: new Date().getTime(),
                            created_at: new Date().getTime()
                        });
                    } })));
    }
    if (page === 'SLOPED_CEILING') {
        return React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'grid grid-cols-2 gap-3 p-3' }, CEILING_TYPES.filter(x => x.category_uuid === 'Vaulted').map(x => React.createElement(CeilingShapeCard, { key: x.uuid, ceilingShape: x, onClick: () => {
                    setRoom({ ...room, ceiling_type_uuid: x.uuid });
                    onBack();
                } }))));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col w-full pt-4' },
            React.createElement("div", { className: 'flex flex-col gap-2 w-full' },
                React.createElement("div", { className: 'px-4' },
                    React.createElement(TabGroup, { items: [
                            { name: 'Details', onClick: () => setPage('ROOM_DETAILS'), variant: page === 'ROOM_DETAILS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Floor', onClick: () => setPage('ROOM_FLOOR'), variant: page === 'ROOM_FLOOR' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Ceiling', onClick: () => setPage('ROOM_CEILING'), variant: page === 'ROOM_CEILING' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Emitters', onClick: () => setPage('ROOM_EMITTERS'), variant: page === 'ROOM_EMITTERS' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'Photos', onClick: () => setPage('ROOM_PHOTOS'), variant: page === 'ROOM_PHOTOS' ? 'ACTIVE' : 'DEFAULT' }
                        ] }))),
            React.createElement("div", { className: 'p-5 flex flex-col gap-5' },
                page === 'ROOM_DETAILS' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Room type"),
                        React.createElement(Select, { options: ROOM_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: room.room_type_uuid, setSelectedKey: (e) => {
                                const roomType = ROOM_TYPES.find(x => x.uuid === e);
                                setRoom({ ...room, room_type_uuid: e, name: roomType.name + getRoomPostfix(roomType.uuid, survey) });
                            } })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Name"),
                        React.createElement(Input, { value: room === null || room === void 0 ? void 0 : room.name, setValue: (e) => setRoom(({ ...room, name: e })) })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", null,
                            React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Flues or chimney"),
                            React.createElement("div", { className: 'text-gray-500 text-xs' }, "Do not include closed appliances (e.g. a wood-burning stove that only operates with the door closed) as no additional allowance needs to be made for them.")),
                        React.createElement(Select, { options: FLUE_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: room.flue_type_uuid, setSelectedKey: (e) => setRoom({ ...room, flue_type_uuid: e }) })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Air changes per hour"),
                        React.createElement(Input, { type: "number", step: 0.1, value: getRoomACH(room, survey, stepSize).toString(), setValue: (e) => setRoom({ ...room, ach_override: Number(e) }), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" }, room.ach_override !== undefined && room.ach_override !== getRoomACH(room, survey, stepSize, true) && React.createElement(Icon, { icon: faTimesCircle, onClick: () => setRoom(({ ...room, ach_override: undefined })), colour: 'text-gray-400' })) })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Internal temperature"),
                        React.createElement(Input, { type: 'number', step: 0.1, value: getRoomTemp(room, survey).toString(), setValue: (e) => setRoom({ ...room, indoor_temp_override_c: Number(e) }), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" }, room.indoor_temp_override_c !== undefined && room.indoor_temp_override_c !== getRoomTemp(room, survey, true) &&
                                React.createElement(Icon, { icon: faTimesCircle, onClick: () => setRoom(({ ...room, indoor_temp_override_c: undefined })), colour: 'text-gray-400' })) })),
                    React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Notes"),
                        React.createElement(TextArea, { value: room.notes.toString(), setValue: (e) => setRoom({ ...room, notes: e }) }))),
                page === 'ROOM_FLOOR' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Floor type"),
                        React.createElement(RadioGroup, { items: [
                                {
                                    name: 'Ground',
                                    onClick: () => setRoom({
                                        ...room,
                                        floor_material: survey.default_materials.groundFloor,
                                        // flag floor material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL
                                    }),
                                    variant: room.floor_material.applicable_to === 'ground-floor' ? 'ACTIVE' : 'DEFAULT'
                                },
                                {
                                    name: 'Intermediate floor',
                                    onClick: () => setRoom({
                                        ...room,
                                        floor_material: survey.default_materials.intermediateFloorAndCeiling,
                                        // flag floor material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL
                                    }),
                                    variant: room.floor_material.applicable_to === 'intermediate-floor-and-ceiling' ? 'ACTIVE' : 'DEFAULT'
                                }
                            ] })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Floor material"),
                        React.createElement(MaterialInputField, { selectorProps: {
                                materials,
                                title: room.floor_material.applicable_to === 'ground-floor' ? 'Ground' : 'Intermediate floor',
                                surfaceType: room.floor_material.applicable_to,
                                selectedMaterial: room.floor_material,
                                ageBand: survey.age_band,
                                onSelectCallback: (material) => {
                                    setRoom({
                                        ...room,
                                        floor_material: material,
                                        // flag floor material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL
                                    });
                                }
                            }, setMsProps: setMsProps, setPage: setPage }))),
                page === 'ROOM_CEILING' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Ceiling type"),
                        React.createElement(RadioGroup, { items: [
                                {
                                    name: 'Intermediate ceiling',
                                    onClick: () => setRoom({
                                        ...room,
                                        ceiling_material: survey.default_materials.intermediateFloorAndCeiling,
                                        // flag ceiling material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                    }),
                                    variant: room.ceiling_material.applicable_to === 'intermediate-floor-and-ceiling' ? 'ACTIVE' : 'DEFAULT'
                                },
                                {
                                    name: 'Roof',
                                    onClick: () => setRoom({
                                        ...room,
                                        ceiling_material: survey.default_materials.roof,
                                        // flag ceiling material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                    }),
                                    variant: room.ceiling_material.applicable_to === 'roof' ? 'ACTIVE' : 'DEFAULT'
                                }
                            ] })),
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Ceiling material"),
                        React.createElement(MaterialInputField, { selectorProps: {
                                materials,
                                title: room.ceiling_material.applicable_to === 'roof' ? 'Roof' : 'Intermediate ceiling',
                                surfaceType: room.ceiling_material.applicable_to,
                                selectedMaterial: room.ceiling_material,
                                ageBand: survey.age_band,
                                onSelectCallback: (material) => {
                                    setRoom({
                                        ...room,
                                        ceiling_material: material,
                                        // flag ceiling material as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL
                                    });
                                }
                            }, setMsProps: setMsProps, setPage: setPage })),
                    ((_c = room.ceiling_material) === null || _c === void 0 ? void 0 : _c.applicable_to) === 'intermediate-floor-and-ceiling' && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                            React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Ceiling height"),
                            React.createElement(Input, { type: 'number', value: (_e = (_d = room.height_m) === null || _d === void 0 ? void 0 : _d.toString()) !== null && _e !== void 0 ? _e : '', setValue: (e) => setRoom({
                                    ...room,
                                    height_m: Number(e),
                                    // mark the height as overwritten
                                    overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                }), postfix: 'm' }))),
                    ((_f = room.ceiling_material) === null || _f === void 0 ? void 0 : _f.applicable_to) === 'roof' && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                            React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Vaulted ceiling?"),
                            React.createElement(RadioGroup, { items: CEILING_SHAPE_TYPES.map(x => ({
                                    name: x,
                                    onClick: () => {
                                        var _a, _b, _c;
                                        setCeilingTypeCategory(x);
                                        setRoom({
                                            ...room,
                                            ceiling_type_uuid: x === 'Flat'
                                                ? CEILING_TYPES.find(x => x.category_uuid === 'Flat').uuid
                                                : CEILING_TYPES.filter(x => x.category_uuid !== 'Flat')[0].uuid,
                                            height_2_m: (_a = room.height_2_m) !== null && _a !== void 0 ? _a : room.height_m, // initialise height 2 to height 1 value to reduce risk of user missing this and entering 0 without meaning to
                                            //   Set width 2 to the length of the shortest wall in the room
                                            ceiling_width_2_m: (_b = room.ceiling_width_2_m) !== null && _b !== void 0 ? _b : Math.min(...calculateSimplifiedWallLengths(room, stepSize)),
                                            ceiling_width_1_m: (_c = room.ceiling_width_1_m) !== null && _c !== void 0 ? _c : Math.min(...calculateSimplifiedWallLengths(room, stepSize))
                                        });
                                    },
                                    variant: ceilingTypeCategory === x ? 'ACTIVE' : 'DEFAULT'
                                })) })),
                        ceilingTypeCategory !== 'Flat' && React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Shape"),
                            React.createElement(CeilingShapeCard, { ceilingShape: ceilingType, onClick: () => setPage('SLOPED_CEILING') })),
                        ceilingTypeCategory === 'Flat' ? React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Ceiling height"),
                                React.createElement(Input, { type: 'number', value: (_h = (_g = room.height_m) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : '', setValue: (e) => setRoom({
                                        ...room,
                                        height_m: Number(e),
                                        // mark the height as overwritten
                                        overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                    }), postfix: 'm' }))) : React.createElement("div", { className: 'flex flex-col gap-2' },
                            ceilingType.showCeilingWidth && React.createElement("div", { className: 'flex gap-3' },
                                React.createElement("div", { className: 'flex flex-col gap-2 flex-1' },
                                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "W1"),
                                    React.createElement(Input, { validator: (e) => validateW1LessThanW2(e, room.ceiling_width_2_m), validateImmediately: true, type: 'number', value: (_k = (_j = room.ceiling_width_1_m) === null || _j === void 0 ? void 0 : _j.toString()) !== null && _k !== void 0 ? _k : '', setValue: (e) => setRoom({ ...room, ceiling_width_1_m: e.length === 0 ? undefined : Number(e) }), postfix: 'm' })),
                                React.createElement("div", { className: 'flex flex-col gap-2 flex-1' },
                                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "W2"),
                                    React.createElement(Input, { validator: validateIsPositiveNumber, validateImmediately: true, type: 'number', value: (_m = (_l = room.ceiling_width_2_m) === null || _l === void 0 ? void 0 : _l.toString()) !== null && _m !== void 0 ? _m : '', setValue: (e) => {
                                            setRoom({
                                                ...room,
                                                ceiling_width_2_m: e.length === 0 ? undefined : Number(e)
                                            });
                                        }, postfix: 'm' }))),
                            React.createElement("div", { className: 'flex gap-3' },
                                React.createElement("div", { className: 'flex flex-col gap-2 flex-1' },
                                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "H1"),
                                    React.createElement(Input, { validator: (e) => validateH1LessThanH2(e, room.height_2_m), validateImmediately: true, type: 'number', value: (_p = (_o = room.height_m) === null || _o === void 0 ? void 0 : _o.toString()) !== null && _p !== void 0 ? _p : '', setValue: (e) => setRoom({
                                            ...room,
                                            height_m: e.length === 0 ? undefined : Number(e),
                                            // mark the height as overwritten
                                            overwritten_attributes_flags: room.overwritten_attributes_flags | ROOM_OVERWRITTEN_ATTRIBUTE_HEIGHT_M
                                        }), postfix: 'm' })),
                                React.createElement("div", { className: 'flex flex-col gap-2 flex-1' },
                                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "H2"),
                                    React.createElement(Input, { validator: validateIsPositiveNumber, validateImmediately: true, type: 'number', value: (_r = (_q = room.height_2_m) === null || _q === void 0 ? void 0 : _q.toString()) !== null && _r !== void 0 ? _r : '', setValue: (e) => setRoom({ ...room, height_2_m: e.length === 0 ? undefined : Number(e) }), postfix: 'm' }))))),
                    ((_s = room.ceiling_material) === null || _s === void 0 ? void 0 : _s.applicable_to) === 'roof' && React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Rooflights"),
                        room.rooflights.length === 0 && React.createElement(React.Fragment, null,
                            React.createElement(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => {
                                    initNewRooflight();
                                    setPage('ROOFLIGHT');
                                } },
                                React.createElement("div", { className: 'text-center' }, "No rooflights found"))),
                        room.rooflights.length !== 0 && React.createElement("div", { className: 'divide-y divide-y-gray-200' }, room.rooflights.map(rl => {
                            var _a, _b;
                            return React.createElement(ListItem, { key: rl.uuid, primaryText: (_b = (_a = rl.material) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '', secondaryText: rl.width_mm + ' x ' + rl.height_mm + ' mm • ' +
                                    getRooflightMaterialUValue(rl).toFixed(2) +
                                    ' + ' +
                                    getRoofligthUValuePitchCorrection(room.ceiling_type_uuid).toFixed(2) +
                                    ' = ' +
                                    getRooflightUValue(rl, room.ceiling_type_uuid).toFixed(2) +
                                    ' W/m²K', onClick: () => {
                                    setCurrentRooflight(rl);
                                    setPage('ROOFLIGHT');
                                }, rightClickableIcon: React.createElement(Icon, { icon: faTrashCan, confirmTextHeader: 'Delete rooflight?', onClick: () => { deleteRooflight(rl.uuid); } }) });
                        })),
                        React.createElement(Button, { iconLeft: faPlus, onClick: () => { initNewRooflight(); setPage('ROOFLIGHT'); }, block: true, colour: 'DARK', className: 'gap-2' }, "Add rooflight"))),
                page === 'ROOM_EMITTERS' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        radiatorsHydrated.length === 0 &&
                            React.createElement(ClickableCard, { className: 'mb-2', variant: 'PLACEHOLDER', onClick: () => setPage('CHOOSE_EMITTER') },
                                React.createElement("div", { className: 'text-center' }, "No emitters found")),
                        radiatorsHydrated.length > 0 && React.createElement("div", { className: 'divide-y divide-y-gray-200' }, radiatorsHydrated.map(x => React.createElement(ListItem, { key: x.uuid, primaryText: getEmitterTypeName(x), secondaryText: getEmitterSizeName(x), onClick: () => {
                                setCurrentRadiator(x);
                                setPage(x.emitter_type === 'UNDERFLOOR'
                                    ? 'UNDERFLOOR'
                                    : x.emitter_type === 'SECONDARY'
                                        ? 'SECONDARY_HEATING'
                                        : 'RADIATOR');
                            }, rightClickableIcon: React.createElement(Icon, { icon: faTrashCan, confirmTextHeader: 'Delete emitter?', onClick: () => { deleteRadiator(x.uuid); } }) }))),
                        React.createElement(Button, { iconLeft: faPlus, onClick: () => setPage('CHOOSE_EMITTER'), block: true, colour: 'DARK', className: 'gap-2' }, "Add emitter"))),
                page === 'ROOM_PHOTOS' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Photos"),
                        React.createElement(PhotoScroll, { images: roomImages, addImage: (fileWrapper) => {
                                fileWrapper && setFiles([...files, fileWrapper]);
                                fileWrapper && setRoom(({ ...room, images: [...room.images, { uuid: crypto.randomUUID(), entity_uuid: room.uuid, image_uuid: fileWrapper.uuid }] }));
                            }, deleteImage: (id) => deleteImage(id) }))))));
};
const CeilingShapeCard = ({ ceilingShape, onClick }) => {
    var _a;
    return React.createElement(ClickableCard, { key: ceilingShape.uuid, variant: 'WHITE', onClick: onClick },
        React.createElement("div", { className: 'flex flex-col justify-center items-center gap-2' },
            React.createElement("div", { className: 'flex justify-center items-center' }, (_a = ceilingShape.img) === null || _a === void 0 ? void 0 : _a.call(ceilingShape, 'w-32 h-32')),
            React.createElement("div", { className: "text-center text-xs font-bold" }, ceilingShape.name)));
};
