import React, { useState } from 'react';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
export const UValueForm = ({ closeCallback, ACH, internalTemp, designTemp, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, colour, onSubmit, defaults }) => {
    const [editACHUValue, setEditACHUValue] = useState(ACH.toString());
    const [editInternalTemp, setEditInternalTemp] = useState(internalTemp.toString());
    const [editDesignTemp, setEditDesignTemp] = useState(designTemp.toString());
    const [editExternalWallUValue, setEditExternalWallUValue] = useState(externalWallUValue.toFixed(2));
    const [editPartyWallUValue, setEditPartyWallUValue] = useState(partyWallUValue.toFixed(2));
    const [editWindowsUValue, setEditWindowsUValue] = useState(windowsUValue.toFixed(2));
    const [editFloorUValue, setEditFloorUValue] = useState(floorUValue.toFixed(2));
    const [editRoofUValue, setEditRoofUValue] = useState(roofUValue.toFixed(2));
    const validator = (e) => isNaN(Number(e)) || !e ? { value: undefined, message: 'Not a valid number.' } : { value: Number(e), message: '' };
    const values = {
        internalTempOverride: validator(editInternalTemp).value,
        designTempOverride: validator(editDesignTemp).value,
        airChangeOverride: validator(editACHUValue).value,
        externalWallUValueOverride: validator(editExternalWallUValue).value,
        partyWallUValueOverride: validator(editPartyWallUValue).value,
        windowsUValueOverride: validator(editWindowsUValue).value,
        floorUValueOverride: validator(editFloorUValue).value,
        roofUValueOverride: validator(editRoofUValue).value
    };
    const allValid = Object.values(values).every(x => !!x);
    return React.createElement("div", { className: "space-y-2 text-sm" },
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Design temp."),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editDesignTemp, setValue: setEditDesignTemp })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Internal temp."),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editInternalTemp, setValue: setEditInternalTemp })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "ACH"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editACHUValue, setValue: setEditACHUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "External wall UValue"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editExternalWallUValue, setValue: setEditExternalWallUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Party Wall UValue"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editPartyWallUValue, setValue: setEditPartyWallUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Windows UValue"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editWindowsUValue, setValue: setEditWindowsUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Floor UValue"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editFloorUValue, setValue: setEditFloorUValue })),
        React.createElement("div", { className: "flex items-center justify-between space-x-4" },
            React.createElement("div", { className: "flex-grow text-gray-600" }, "Roof UValue"),
            React.createElement(Input, { validator: validator, shrink: true, size: 'SM', type: "number", step: 0.1, value: editRoofUValue, setValue: setEditRoofUValue })),
        React.createElement("div", { className: 'flex justify-between space-x-1' },
            React.createElement(Button, { onClick: () => {
                    setEditACHUValue(defaults.airChangesDefault.toString());
                    setEditExternalWallUValue(defaults.externalWallDefault.toString());
                    setEditPartyWallUValue(defaults.partyWallDefault.toString());
                    setEditWindowsUValue(defaults.windowDefault.toString());
                    setEditFloorUValue(defaults.floorDefault.toString());
                    setEditRoofUValue(defaults.roofDefault.toString());
                    setEditDesignTemp(defaults.designTempDefault.toString());
                    setEditInternalTemp(defaults.internalTempDefault.toString());
                } }, "Reset Defaults"),
            React.createElement("div", { className: "space-x-1 flex" },
                React.createElement(Button, { onClick: () => {
                        closeCallback();
                    } }, "Cancel"),
                React.createElement(Button, { disabled: !allValid, onClick: () => {
                        closeCallback();
                        onSubmit(values);
                    } }, "Save"))));
};
