import { faChevronLeft, faX } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { OfflineBlock } from '../../particles/offline_block';
import { ProgressBar } from '../../../survey/components/progress_bar';
import { Icon } from '../../../../components/buttons/icon';
import { Link } from '../../../../components/buttons/link';
export const FlowPageHeader = ({ title, progress, onBack, onClose, onSkip }) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${progress ? '' : 'border-b border-gray-300'} px-5 pt-4 pb-3 gap-2 flex flex-col bg-white` },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: 'flex gap-4 items-center cursor-pointer', onClick: onBack },
                    React.createElement(Icon, { icon: faChevronLeft }),
                    React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, title)),
                React.createElement("div", { className: 'flex gap-3 items-center' },
                    onSkip && React.createElement(Link, { onClick: onSkip, className: 'font-bold no-underline' }, "Skip"),
                    onClose && React.createElement(Icon, { onClick: onClose, icon: faX })))),
        progress && React.createElement(ProgressBar, { progress: progress }),
        React.createElement(OfflineBlock, null));
};
