import React from 'react';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
export const HLRPEMCSKeyFacts = () => {
    return (React.createElement("div", { className: 'flex gap-6 flex-col' },
        React.createElement(HLRSubheaderBLock, { section: "Your performance estimate", title: "MCS Key facts - Energy Performance Estimate" }),
        React.createElement("div", null, "Predicting the heat demand of a building, and therefore the performance and running costs of heating systems, is difficult to predict with certainty due to the variables discussed here. These variables apply to all types of heating systems, although the efficiency of heat pumps is more sensitive to good system design and installation. For these reasons your estimate is given as guidance only and should not be considered as a guarantee."),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Seasonal Coefficient of Performance:"),
            React.createElement("div", { className: "" }, "MCS Seasonal Coefficient of Performance (SCoP) is derived from the EU ErP labelling requirements, and is a theoretical indication of the anticipated efficiency of a heat pump over a whole year using standard (i.e. not local) climate data for 3 locations in Europe. It is used to compare the relative performance of heat pumps under fixed conditions and indicates the units of total heat energy generated (output) for each unit of electricity consumed (input). As a guide, a heat pump with a MCS SCoP of 3 indicates that 3 kWh of heat energy would be generated for every 1 kWh of electrical energy it consumes over a \u2018standard\u2019 annual cycle.")),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Energy Performance Estimate"),
            React.createElement("div", { className: "" }, "An Energy Performance Certificate (EPC) is produced in accordance with a methodology approved by the government. As with all such calculations, it relies on the accuracy of the information input. Some of this information, such as the insulating and air tightness properties of the building may have to be assumed and this can affect the final figures significantly leading to uncertainty especially with irregular or unusual buildings.")),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Identifying the uncertainties of energy predictions for heating systems"),
            React.createElement("div", { className: "" }, "We have identified 3 key types of factor that can affect how much energy a heating system will consume and how much energy it will deliver into a home. These are \u2018Fixed\u2019, \u2018Variable\u2019 and \u2018Random\u2019. Most factors are common to ALL heating systems regardless of the type (e.g oil, gas, solid fuel, heat pump etc.) although the degree of effect varies between different types of heating system as given in the following table."),
            React.createElement("div", { className: "" }, "The combined effect of these factors on energy consumption and the running costs makes overall predictions difficult however an accuracy + 25-30% would not be unreasonable in many instances. Under some conditions even this could be exceeded (e.g. considerable opening of windows). Therefore it is advised that when making choices based on mainly financial criteria (e.g. payback based on capital cost versus net benefits such as fuel savings and financial incentives) this variability is taken into account as it could extend paybacks well beyond the period of any incentives received, intended occupancy period, finance agreement period etc."))));
};
