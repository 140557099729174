import { sum } from 'lodash';
import { getRoomTemp, getRoomACH, getRoomWatts } from '../../../../code/models/heat_loss';
import { getEmitterWatts } from '../../../../code/models/radiator_model';
import { calculateCentroid, stepSize } from '../../constants';
import { area, getPercentageText } from './utils';
export const getRoomsWithMetaData = (stageScale, survey, floor, designTempC, groundTempC, design, fontSize, fontSizeSmall) => {
    const roomsWithMetaData = floor.rooms.map(x => {
        const points = x.walls.map(x => [x.x, x.y]);
        const text = x.name;
        const center = calculateCentroid(points);
        const centerXWithTextLength = center[0] - (text.length * (fontSize / 4));
        const areaCalculated = `${area(stepSize, x.walls.map(w => ({ x: w.x, y: w.y })))} m²`;
        const roomTempC = getRoomTemp(x, survey);
        const ACH = getRoomACH(x, survey, stepSize);
        const radiatorText = x.radiators.length > 0
            // if it's UFH show "UFH" rather than "1 Rads". If they also have radiators, just still only show UFH as it's rare that you would have UFH and radiators
            ? x.radiators.find(r => r.emitter_type === 'UNDERFLOOR') ? 'UFH' : `${x.radiators.length} Rads`
            : '';
        const roomText = [radiatorText, `${roomTempC}°C`, `${ACH} ACH`].filter(x => !!x).join(' • ');
        const centerXWithAreaLength = center[0] - (roomText.length * (fontSizeSmall / 4.5));
        const centerY = center[1];
        const wattsLost = Math.round(getRoomWatts(x, floor.rooms, designTempC, groundTempC, stepSize, survey));
        const wattsEmitted = Math.round(sum(x.radiators.map(y => getEmitterWatts(y, x, design, survey, designTempC, groundTempC))));
        const wattsRequired = getPercentageText(wattsEmitted, wattsLost);
        return {
            ...x,
            centerXWithTextLength,
            centerXWithAreaLength,
            centerY,
            text,
            areaCalculated,
            wattsRequired,
            wattsLost,
            roomText
        };
    });
    return roomsWithMetaData;
};
