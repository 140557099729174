import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from '../containers/modal';
export const Icon = ({ icon, onClick, confirmTextHeader, confirmTextBody = 'This action cannot be undone.', colour = 'text-gray-500', className, disabled, spin }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const onClickToUse = disabled ? undefined : onClick;
    const colourToUse = disabled ? 'text-gray-300' : colour;
    return React.createElement(React.Fragment, null,
        React.createElement(FontAwesomeIcon, { className: `${className} ${colourToUse} ${onClickToUse ? 'cursor-pointer' : ''} ${spin ? 'animate-spin' : ''}`, icon: icon, onClick: confirmTextHeader ? () => setModalOpen(true) : onClickToUse }),
        confirmTextHeader && modalOpen &&
            React.createElement(Modal, { onConfirm: onClickToUse, visible: true, title: confirmTextHeader, setVisible: () => setModalOpen(!modalOpen) }, confirmTextBody));
};
