import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
export const Accordion = ({ rows }) => {
    const [expandedId, setExpandedId] = useState();
    if (rows.length === 0)
        return;
    const toggleExpanded = (id) => { setExpandedId((prev) => prev === id ? undefined : id); };
    return React.createElement("div", { className: "rounded divide-y bg-white text-sm w-full" }, rows.map((x, i) => React.createElement("div", { key: i },
        React.createElement("div", { onClick: () => { toggleExpanded(i); }, className: "py-3 flex flex-grow justify-between items-center cursor-pointer" },
            React.createElement("div", null, x.title),
            React.createElement(FontAwesomeIcon, { icon: expandedId === i ? faChevronUp : faChevronDown })),
        expandedId === i && React.createElement("div", { className: "py-3 text-gray-500" },
            " ",
            x.body))));
};
