import React, { useContext, useEffect, useState } from 'react';
import { calculateQuote } from '../../code/calculate_quote';
import { logEvent } from '../../code/log_event';
import { getCalculatedQuotes } from '../../code/models/calculated_quote';
import { BASE_COLOURS } from '../../code/models/colour';
import { getLead, patchLead } from '../../code/models/lead';
import { numberFormat } from '../../code/number_format';
import { Button } from '../../components/buttons/button';
import { Modal } from '../../components/containers/modal';
import { Section } from '../../components/containers/section';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { ensureLeadHasEPCRecommendations, ensureLeadHasLocation3D, parseEnglishEpcInner, parseScottishEpcInner } from '../../code/models/property';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { ArrowLeftIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { DocumentTextIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { AdminContext } from './admin_layout';
import { BUILT_FORM_TYPES, CAVITY_WALL, CAVITY_WALL_INSULATION, ESTIMATE_AGE_BANDS, FLOOR_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, PROPERTY_TYPES, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from '../../code/models/u_value';
import { Input } from '../../components/inputs_and_selections/input';
import { UValueForm } from './uvalue_form';
import { checkIfScottish } from '../../code/models/address';
import { ExclamationCircleIcon, NoSymbolIcon } from '@heroicons/react/16/solid';
import { deleteLeadLineItem, insertLeadLineItem } from '../../code/models/lead_line_item';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { Link } from '../../components/buttons/link';
import { FUELS } from '../../code/models/fuel';
import { EstimatePageInner } from '../estimate_page';
import { HistoryPage } from './history_page';
import { formatPrice } from '../../code/format_price';
import { TabGroup } from '../../components/content_display/tab';
import { noop } from 'lodash';
import { formatDate } from '../../code/format_date';
import { SendDeclinedModalBlock } from './lead_page/send_declined_modal_block';
import { SendEstimateModalBlock } from './lead_page/send_estimate_modal_block';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { OfflinePage } from './offline_page';
import { convertLatLngListToLatLongLiteral } from '../../code/geocoding';
import { validateEmail, validateNotNull } from '../../code/validators';
import { hasEnquriesAccess, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../code/models/user';
import { RequireRole } from '../../require_role';
import { UnauthorizedPage } from './unauthorized_page';
import { Select } from '../../components/inputs_and_selections/select';
export const LeadPage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70;
    const { leadUUID } = useParams();
    const navigate = useNavigate();
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [lead, setLead] = React.useState();
    const [leadOverrides, setLeadOverrides] = useState();
    const [selectedHeatPumpUUID, setSelectedHeatPumpUUID] = useState();
    const [selectedHotWaterCylinderUUID, setSelectedHotWaterCylinderUUID] = useState();
    const [estimates, setEstimates] = useState([]);
    const [page, setPage] = useState('MAIN');
    const loadData = async () => {
        var _a;
        if (adminContext.isOffline)
            return;
        const lead = await ensureLeadHasEPCRecommendations((await getLead(leadUUID, adminContext.data.company.public_info.uuid)), adminContext.data.company.public_info.uuid, true);
        if (!lead)
            return;
        const estimates = (_a = await getCalculatedQuotes(leadUUID, adminContext.data.company.public_info.uuid)) !== null && _a !== void 0 ? _a : [];
        setLead(lead);
        setEstimates(estimates);
    };
    useEffect(() => {
        if (adminContext.isOffline)
            return;
        if (!leadUUID)
            return;
        if (adminContext.data.company === undefined)
            return;
        if (!hasEnquriesAccess(adminContext.data.company, adminContext.data.user))
            return;
        loadData();
    }, [adminContext]);
    const [isEstimateModalVisible, setIsEstimateModalVisible] = useState(false);
    const [hesModalVisible, setHesModalVisible] = useState(false);
    const [busModalVisible, setBusModalVisible] = useState(false);
    const [declineModalVisible, setDeclineModalVisible] = useState(false);
    const [previewEstimateModalVisible, setPreviewEstimateModalVisible] = useState(false);
    const [isEditingUValues, setIsEditingUValues] = useState(false);
    // save lead property location if not already present
    useEffect(() => {
        if (adminContext.isOffline)
            return;
        if (!lead)
            return;
        const updateLead = async () => {
            const newLead = await ensureLeadHasLocation3D(lead, adminContext.data.company.public_info.uuid);
            setLead(newLead);
        };
        updateLead();
    }, [lead]);
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    if (!hasEnquriesAccess(adminContext.data.company, adminContext.data.user)) {
        return React.createElement(UnauthorizedPage, null);
    }
    if (!lead)
        return;
    const setOverride = (overrides) => {
        setLead((prev) => ({ ...prev, property: { ...prev.property, houseOverrides: overrides } }));
    };
    const { labour, survey, grant, designTempC, internalTempC, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, partyWallWatts, externalWallWatts, windowWatts, floorWatts, roofWatts, ventilationWatts, totalWatts, calculatedAirChanges, CO2SavedKg, busGrantEligibleReasons, hesGrantEligibleReasons, defaults, commutesSaved, flightsSaved, heatPump, hotWaterCylinder, totalPrice, isScottish } = calculateQuote(lead, (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) !== null && _b !== void 0 ? _b : [], (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) !== null && _d !== void 0 ? _d : [], (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company, selectedHeatPumpUUID, selectedHotWaterCylinderUUID);
    // If they have not selected a specific heat pump, use the calculated heat pump
    const calculatedQuote = {
        name: lead.customer.name,
        address: lead.property.address,
        postcode: lead.property.postcode,
        total_watts: totalWatts,
        heat_pump_name: (_f = heatPump === null || heatPump === void 0 ? void 0 : heatPump.name) !== null && _f !== void 0 ? _f : '',
        heat_pump_price: (_g = heatPump === null || heatPump === void 0 ? void 0 : heatPump.price) !== null && _g !== void 0 ? _g : 0,
        hot_water_cylinder_name: (_h = hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.name) !== null && _h !== void 0 ? _h : '',
        hot_water_cylinder_price: (_j = hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.price) !== null && _j !== void 0 ? _j : 0,
        heat_pump_parts: (_k = heatPump === null || heatPump === void 0 ? void 0 : heatPump.parts) !== null && _k !== void 0 ? _k : 0,
        calculated_estimate_line_items: (_l = lead.lead_line_items) === null || _l === void 0 ? void 0 : _l.map(x => ({
            uuid: '',
            name: x.name,
            value: x.value,
            calculated_estimate_uuid: ''
        })),
        labour,
        survey,
        grant,
        total_price: totalPrice,
        co2_saved_kg: CO2SavedKg,
        lead_uuid: leadUUID,
        commutesSaved,
        flightsSaved,
        lmk_key: (_o = (_m = lead.epcData) === null || _m === void 0 ? void 0 : _m.lmkKey) !== null && _o !== void 0 ? _o : '',
        status: undefined
    };
    const propertyMappedFromEpc = lead.epcData ? parseEnglishEpcInner(lead.epcData, lead.property) : parseScottishEpcInner(lead.epc_scotland, lead.property);
    const notGrantEligible = (!lead.epc_scotland && (!((_p = lead.epcData) === null || _p === void 0 ? void 0 : _p.lmkKey) || busGrantEligibleReasons.some(x => !x.hasPassed)));
    const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed);
    const STATUS_ITEMS = [
        { key: 'New', value: 'Estimate Required', colour: 'YELLOW' },
        { key: 'Quoted', value: 'Estimate Sent', colour: 'BLUE' },
        { key: 'Converted', value: 'Estimate Accepted', colour: 'GREEN' },
        { key: 'Rejected', value: 'Estimate Rejected', colour: 'RED' },
        { key: 'Declined', value: 'Enquiry Declined', colour: 'RED' },
        { key: 'SurveyBooked', value: 'Survey Booked', colour: 'BLUE' },
        { key: 'Archived', value: 'Archived', colour: 'LIGHT' }
    ];
    const statusColour = (_q = STATUS_ITEMS.find(x => x.key === lead.status)) === null || _q === void 0 ? void 0 : _q.colour;
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement("div", { className: "bg-gray-100 h-full overflow-auto" },
            React.createElement("div", { className: 'bg-white py-4 px-8 space-y-4' },
                React.createElement("div", { className: 'flex flex-wrap justify-between gap-4' },
                    React.createElement("div", { className: 'flex flex-grow items-center gap-x-6' },
                        React.createElement(ArrowLeftIcon, { className: 'lg:h-6 lg:w-6 h-5 w-5 cursor-pointer', onClick: () => { var _a; return navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/enquiries`); } }),
                        React.createElement("div", null,
                            React.createElement("div", { className: 'font-bold text-2xl lg:text-3xl' }, calculatedQuote.name),
                            React.createElement("div", { className: 'text-gray-600 lg:text-base text-sm' }, calculatedQuote.address + ', ' + calculatedQuote.postcode))),
                    React.createElement("div", { className: 'w-full lg:w-56' },
                        React.createElement(Select, { colour: statusColour, label: 'Status', options: STATUS_ITEMS, selectedKey: lead.status, setSelectedKey: async (e) => {
                                await patchLead(lead.uuid, { status: e }, adminContext.data.company.public_info.uuid);
                                setLead(prev => ({ ...prev, status: e }));
                            } }))),
                React.createElement("div", { className: 'flex flex-wrap justify-between gap-2' },
                    React.createElement(TabGroup, { items: [
                            { name: 'Estimate details', onClick: () => setPage('MAIN'), variant: page === 'MAIN' ? 'ACTIVE' : 'DEFAULT' },
                            { name: 'History', onClick: () => setPage('HISTORY'), variant: page === 'HISTORY' ? 'ACTIVE' : 'DEFAULT' }
                        ] }),
                    React.createElement("div", { className: 'flex gap-2 flex-wrap' },
                        lead.status !== 'Declined' && React.createElement(Button, { size: 'SM', colour: 'LIGHT', onClick: () => { setDeclineModalVisible(true); } },
                            React.createElement(NoSymbolIcon, { className: 'h-5 w-5 me-2' }),
                            "Decline"),
                        React.createElement(Button, { size: 'SM', colour: 'LIGHT', disabled: !hotWaterCylinder || !heatPump, onClick: () => setPreviewEstimateModalVisible(true) },
                            React.createElement(DocumentTextIcon, { className: 'h-5 w-5 me-2' }),
                            " Preview Estimate"),
                        React.createElement(Button, { size: 'SM', disabled: !hotWaterCylinder || !heatPump, onClick: () => { setIsEstimateModalVisible(true); } },
                            React.createElement(EnvelopeIcon, { className: 'h-5 w-5 me-2' }),
                            "Send Estimate")))),
            page === 'HISTORY' && React.createElement(HistoryPage, { lead: lead, estimates: estimates, reloadTrigger: loadData, subdomain: adminContext.data.company.public_info.subdomain }),
            page === 'MAIN' && React.createElement("div", { className: 'p-8 gap-5 flex flex-col' },
                React.createElement(Section, { title: React.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 items-center justify-between" },
                        React.createElement("div", { className: 'font-bold' },
                            calculatedQuote.address,
                            ", ",
                            React.createElement("span", { className: 'font-normal text-gray-600' }, calculatedQuote.postcode)),
                        React.createElement("div", { className: 'flex lg:justify-end justify-between gap-4 items-center' },
                            React.createElement("div", { onClick: () => isScottish ? setHesModalVisible(true) : setBusModalVisible(true), className: 'cursor-pointer' },
                                React.createElement(BUSGrantBadge, { isScottish: isScottish, notGrantEligible: notGrantEligible, notHesGrantEligible: notHesGrantEligible, lead: lead })),
                            React.createElement("div", { className: 'flex gap-2' },
                                !leadOverrides && React.createElement(Button, { onClick: () => { var _a; return setLeadOverrides((_a = lead.property.houseOverrides) !== null && _a !== void 0 ? _a : {}); }, colour: 'LIGHT' }, "Edit"),
                                leadOverrides && React.createElement(Button, { onClick: () => setLeadOverrides(undefined), colour: 'LIGHT' }, "Cancel"),
                                leadOverrides && React.createElement(Button, { disabled: !((_r = leadOverrides.wallType) !== null && _r !== void 0 ? _r : lead.property.wallType) && ((_s = leadOverrides === null || leadOverrides === void 0 ? void 0 : leadOverrides.wallGroup) !== null && _s !== void 0 ? _s : lead.property.wallGroup) !== TIMBER, onClick: async () => {
                                        const newLead = { ...lead, property: { ...lead.property, houseOverrides: leadOverrides } };
                                        await patchLead(lead.uuid, newLead, adminContext.data.company.public_info.uuid);
                                        setLead(newLead);
                                        setLeadOverrides(undefined);
                                    } }, "Save")))) },
                    React.createElement("div", { className: 'grid xs:grid-cols-1 md:grid-cols-2 gap-8' },
                        React.createElement("div", { className: "flex flex-col gap-y-2 flex-grow" }, latLng
                            ? React.createElement(React.Fragment, null,
                                React.createElement(Map, { zoom: 18, className: "min-h-[200px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: latLng },
                                    React.createElement(Marker, { position: latLng })))
                            : React.createElement(Alert, { type: "WARNING" }, "Sorry, Google Maps can't find this address.")),
                        leadOverrides && React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200' },
                            React.createElement("div", { className: 'grid grid-cols-4 gap-2 py-1' },
                                React.createElement("div", null),
                                React.createElement("div", { className: 'text-sm font-semibold' }, "EPC"),
                                React.createElement("div", { className: 'text-sm font-semibold' }, "ENQUIRY"),
                                React.createElement("div", { className: 'text-sm font-semibold' }, "ESTIMATE VALUE")),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Name"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'BLUE' })),
                                React.createElement(Input, { type: 'text', size: 'SM', value: (_u = (_t = lead.customer) === null || _t === void 0 ? void 0 : _t.name) !== null && _u !== void 0 ? _u : '', validator: validateNotNull, setValue: (e) => setLead(prev => ({ ...prev, customer: { ...prev.customer, name: e } })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Email"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'BLUE' })),
                                React.createElement(Input, { type: 'text', size: 'SM', value: (_w = (_v = lead.customer) === null || _v === void 0 ? void 0 : _v.email) !== null && _w !== void 0 ? _w : '', validator: validateEmail, setValue: (e) => setLead(prev => ({ ...prev, customer: { ...prev.customer, email: e } })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Phone"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'BLUE' })),
                                React.createElement(Input, { type: 'text', size: 'SM', value: (_y = (_x = lead.customer) === null || _x === void 0 ? void 0 : _x.phone) !== null && _y !== void 0 ? _y : '', setValue: (e) => setLead(prev => ({ ...prev, customer: { ...prev.customer, phone: e } })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Property type"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_0 = (_z = lead.epcData) === null || _z === void 0 ? void 0 : _z.propertyType) !== null && _0 !== void 0 ? _0 : '' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.propertyType) !== lead.property.propertyType ? 'BLUE' : 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.propertyType })),
                                React.createElement(Select, { options: PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_1 = leadOverrides.propertyType) !== null && _1 !== void 0 ? _1 : lead.property.propertyType, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, propertyType: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Built form"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_3 = (_2 = lead.epcData) === null || _2 === void 0 ? void 0 : _2.builtForm) !== null && _3 !== void 0 ? _3 : '' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: ((_4 = lead.epcData) === null || _4 === void 0 ? void 0 : _4.builtForm) !== lead.property.builtForm ? 'BLUE' : 'LIGHT', text: lead.property.builtForm })),
                                React.createElement(Select, { options: BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_5 = leadOverrides.builtForm) !== null && _5 !== void 0 ? _5 : lead.property.builtForm, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, builtForm: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Built"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_6 = ESTIMATE_AGE_BANDS.find(x => x.uuid === (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.construction_age_band_uuid))) === null || _6 === void 0 ? void 0 : _6.name })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.construction_age_band_uuid) !== lead.property.construction_age_band_uuid ? 'BLUE' : 'LIGHT', text: (_7 = ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)) === null || _7 === void 0 ? void 0 : _7.name })),
                                React.createElement(Select, { options: ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_8 = leadOverrides.construction_age_band_uuid) !== null && _8 !== void 0 ? _8 : lead.property.construction_age_band_uuid, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, construction_age_band_uuid: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Wall construction"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.wallGroup })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.wallGroup) !== lead.property.wallGroup ? 'BLUE' : 'LIGHT', text: lead.property.wallGroup })),
                                React.createElement(Select, { options: WALL_GROUPS.map(x => ({ key: x.name, value: x.name })), size: 'SM', selectedKey: (_9 = leadOverrides.wallGroup) !== null && _9 !== void 0 ? _9 : lead.property.wallGroup, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, wallGroup: e, wallType: undefined })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Wall insulation"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.wallType })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.wallType) !== lead.property.wallType ? 'BLUE' : 'LIGHT', text: lead.property.wallType })),
                                ((_10 = leadOverrides === null || leadOverrides === void 0 ? void 0 : leadOverrides.wallGroup) !== null && _10 !== void 0 ? _10 : lead.property.wallGroup) !== TIMBER && React.createElement(Select, { options: (((_11 = leadOverrides.wallGroup) !== null && _11 !== void 0 ? _11 : lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).map(x => ({
                                        key: x.uuid,
                                        value: x.name
                                    })), size: 'SM', selectedKey: (_12 = leadOverrides.wallType) !== null && _12 !== void 0 ? _12 : lead.property.wallType, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, wallType: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Windows"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.windowType })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.windowType) !== lead.property.windowType ? 'BLUE' : 'LIGHT', text: lead.property.windowType })),
                                React.createElement(Select, { options: WINDOW_TYPES.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_13 = leadOverrides.windowType) !== null && _13 !== void 0 ? _13 : lead.property.windowType, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, windowType: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Floor insulation"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.floorType })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.floorType) !== lead.property.floorType ? 'BLUE' : 'LIGHT', text: lead.property.floorType })),
                                React.createElement(Select, { options: FLOOR_INSULATION.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_14 = leadOverrides.floorType) !== null && _14 !== void 0 ? _14 : lead.property.floorType, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, floorType: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Loft insulation"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.loftInsulation })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.loftInsulation) !== lead.property.loftInsulation ? 'BLUE' : 'LIGHT', text: (_15 = lead.property.loftInsulation) !== null && _15 !== void 0 ? _15 : '' })),
                                React.createElement(Select, { options: LOFT_INSULATION.map(x => ({ key: x.uuid, value: x.name })), size: 'SM', selectedKey: (_16 = leadOverrides.loftInsulation) !== null && _16 !== void 0 ? _16 : lead.property.loftInsulation, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, loftInsulation: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Bedrooms"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'BLUE', text: lead.property.noBedrooms.toString() })),
                                React.createElement(Input, { type: 'number', size: 'SM', value: ((_18 = (_17 = leadOverrides.noBedrooms) !== null && _17 !== void 0 ? _17 : lead.property.noBedrooms) !== null && _18 !== void 0 ? _18 : 0.0).toString(), setValue: (e) => setLeadOverrides(prev => ({ ...prev, noBedrooms: parseFloat(e) })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Bathrooms"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'BLUE', text: lead.property.noBathrooms.toString() })),
                                React.createElement(Input, { type: 'number', size: 'SM', value: ((_20 = (_19 = leadOverrides.noBathrooms) !== null && _19 !== void 0 ? _19 : lead.property.noBathrooms) !== null && _20 !== void 0 ? _20 : 0).toString(), setValue: (e) => setLeadOverrides(prev => ({ ...prev, noBathrooms: parseFloat(e) })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Fuel type"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_21 = propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.fuelType) !== null && _21 !== void 0 ? _21 : '' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.fuelType) !== lead.property.fuelType ? 'BLUE' : 'LIGHT', text: lead.property.fuelType })),
                                React.createElement(Select, { options: FUELS.map(x => ({ key: x.name, value: x.name })), size: 'SM', selectedKey: (_22 = leadOverrides.fuelType) !== null && _22 !== void 0 ? _22 : lead.property.fuelType, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, fuelType: e })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Floor area (m\u00B2)"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_24 = (_23 = propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.floorArea) === null || _23 === void 0 ? void 0 : _23.toString()) !== null && _24 !== void 0 ? _24 : '' })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: Number(propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.floorArea) !== lead.property.floorArea ? 'BLUE' : 'LIGHT', text: (_25 = lead.property.floorArea) === null || _25 === void 0 ? void 0 : _25.toString() })),
                                React.createElement(Input, { type: 'number', size: 'SM', value: ((_26 = leadOverrides.floorArea) !== null && _26 !== void 0 ? _26 : lead.property.floorArea).toString(), setValue: (e) => setLeadOverrides(prev => ({ ...prev, floorArea: parseFloat(e) })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Room height (m)"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: (_28 = (_27 = propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.roomHeight) === null || _27 === void 0 ? void 0 : _27.toString()) !== null && _28 !== void 0 ? _28 : '' })),
                                React.createElement("div", null),
                                React.createElement(Input, { type: 'number', size: 'SM', value: ((_29 = leadOverrides.roomHeight) !== null && _29 !== void 0 ? _29 : lead.property.roomHeight).toString(), setValue: (e) => setLeadOverrides(prev => ({ ...prev, roomHeight: parseFloat(e) })) })),
                            React.createElement("div", { className: 'grid grid-cols-4 items-center justify-start gap-2 py-1' },
                                React.createElement("div", { className: 'text-sm font-semibold' }, "Outdoor space"),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: 'LIGHT', text: propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.outdoorSpace })),
                                React.createElement("div", { className: 'flex justify-start' },
                                    React.createElement(Badge, { color: (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.outdoorSpace) !== lead.property.outdoorSpace ? 'BLUE' : 'LIGHT', text: lead.property.outdoorSpace })),
                                React.createElement(Select, { options: OUTDOOR_SPACE.map(x => ({ key: x.key, value: x.label })), size: 'SM', selectedKey: (_30 = leadOverrides.outdoorSpace) !== null && _30 !== void 0 ? _30 : lead.property.outdoorSpace, setSelectedKey: (e) => setLeadOverrides(prev => ({ ...prev, outdoorSpace: e })) }))),
                        !leadOverrides && React.createElement("div", { "data-cy": 'property_info', className: "grid grid-cols-3 gap-3 text-sm p-4" },
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Name"),
                                React.createElement("div", { className: "text-gray-600" }, (_31 = lead.customer) === null || _31 === void 0 ? void 0 : _31.name)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Email"),
                                React.createElement("div", { className: "text-gray-600 break-all" }, (_32 = lead.customer) === null || _32 === void 0 ? void 0 : _32.email)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Phone"),
                                React.createElement("div", { className: "text-gray-600" }, (_33 = lead.customer) === null || _33 === void 0 ? void 0 : _33.phone)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Property type"),
                                React.createElement("div", { className: "text-gray-600" }, [(_35 = (_34 = lead.property.houseOverrides) === null || _34 === void 0 ? void 0 : _34.propertyType) !== null && _35 !== void 0 ? _35 : lead.property.propertyType, (_37 = (_36 = lead.property.houseOverrides) === null || _36 === void 0 ? void 0 : _36.builtForm) !== null && _37 !== void 0 ? _37 : lead.property.builtForm].join(', '))),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Built"),
                                React.createElement("div", { className: "text-gray-600" }, lead.property.yearBuilt ? lead.property.yearBuilt : (_38 = ESTIMATE_AGE_BANDS.find(x => { var _a, _b; return (_b = x.uuid === ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.construction_age_band_uuid)) !== null && _b !== void 0 ? _b : lead.property.construction_age_band_uuid; })) === null || _38 === void 0 ? void 0 : _38.name)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold " }, "Walls"),
                                React.createElement("div", { className: "text-gray-600" }, [(_40 = (_39 = lead.property.houseOverrides) === null || _39 === void 0 ? void 0 : _39.wallGroup) !== null && _40 !== void 0 ? _40 : lead.property.wallGroup, (_42 = (_41 = lead.property.houseOverrides) === null || _41 === void 0 ? void 0 : _41.wallType) !== null && _42 !== void 0 ? _42 : lead.property.wallType].join(', '))),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Windows"),
                                React.createElement("div", { className: "text-gray-600" }, (_44 = (_43 = lead.property.houseOverrides) === null || _43 === void 0 ? void 0 : _43.windowType) !== null && _44 !== void 0 ? _44 : lead.property.windowType)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Floor insulation"),
                                React.createElement("div", { className: "text-gray-600" }, (_46 = (_45 = lead.property.houseOverrides) === null || _45 === void 0 ? void 0 : _45.floorType) !== null && _46 !== void 0 ? _46 : lead.property.floorType)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Loft insulation"),
                                React.createElement("div", { className: "text-gray-600" }, (_48 = (_47 = lead.property.houseOverrides) === null || _47 === void 0 ? void 0 : _47.loftInsulation) !== null && _48 !== void 0 ? _48 : lead.property.loftInsulation)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Bedrooms"),
                                React.createElement("div", { className: "text-gray-600" }, (_50 = (_49 = lead.property.houseOverrides) === null || _49 === void 0 ? void 0 : _49.noBedrooms) !== null && _50 !== void 0 ? _50 : lead.property.noBedrooms)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Bathrooms"),
                                React.createElement("div", { className: "text-gray-600" }, (_52 = (_51 = lead.property.houseOverrides) === null || _51 === void 0 ? void 0 : _51.noBathrooms) !== null && _52 !== void 0 ? _52 : lead.property.noBathrooms)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Fuel type"),
                                React.createElement("div", { className: "text-gray-600" }, (_54 = (_53 = lead.property.houseOverrides) === null || _53 === void 0 ? void 0 : _53.fuelType) !== null && _54 !== void 0 ? _54 : lead.property.fuelType)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Floor area"),
                                React.createElement("div", { className: "text-gray-600" }, (_56 = (_55 = lead.property.houseOverrides) === null || _55 === void 0 ? void 0 : _55.floorArea) !== null && _56 !== void 0 ? _56 : lead.property.floorArea,
                                    " m\u00B2")),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Room height"),
                                React.createElement("div", { className: "text-gray-600" }, (_58 = (_57 = lead.property.houseOverrides) === null || _57 === void 0 ? void 0 : _57.roomHeight) !== null && _58 !== void 0 ? _58 : lead.property.roomHeight,
                                    " m")),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Outdoor space"),
                                React.createElement("div", { className: "text-gray-600" }, (_61 = (_60 = (_59 = lead.property.houseOverrides) === null || _59 === void 0 ? void 0 : _59.outdoorSpace) !== null && _60 !== void 0 ? _60 : lead.property.outdoorSpace) !== null && _61 !== void 0 ? _61 : 'Yes (assumed)',
                                    " "))))),
                !!totalWatts &&
                    React.createElement("div", { className: "grid xl:grid-cols-2 md:grid-cols-2 xs:grid-cols-1 gap-5" },
                        React.createElement(Section, { title: React.createElement("div", { className: 'flex justify-between items-center' },
                                React.createElement("div", null, "Heat Loss Estimate"),
                                React.createElement("div", { className: 'flex gap-4 items-center' },
                                    !isEditingUValues && React.createElement(Button, { size: 'SM', "data-cy": 'uvalue_edit', onClick: () => setIsEditingUValues(true) }, "Edit"),
                                    React.createElement("div", { className: "text-gray-600 font-normal" },
                                        (totalWatts / 1000).toFixed(2),
                                        " kW"))) },
                            React.createElement("div", { className: "flex flex-col justify-between gap-4" },
                                isEditingUValues && React.createElement(UValueForm, { closeCallback: () => setIsEditingUValues(false), colour: BASE_COLOURS[0], defaults: defaults, ACH: calculatedAirChanges, designTemp: designTempC, internalTemp: internalTempC, externalWallUValue: externalWallUValue, partyWallUValue: partyWallUValue, windowsUValue: windowsUValue, roofUValue: roofUValue, floorUValue: floorUValue, onSubmit: async ({ airChangeOverride, externalWallUValueOverride, partyWallUValueOverride, windowsUValueOverride, floorUValueOverride, roofUValueOverride, internalTempOverride, designTempOverride }) => {
                                        lead.property.houseOverrides = {
                                            ...lead.property.houseOverrides,
                                            partyWallUValueOverride: (defaults.partyWallDefault === partyWallUValueOverride) ? undefined : partyWallUValueOverride,
                                            externalWallUValueOverride: (defaults.externalWallDefault === externalWallUValueOverride) ? undefined : externalWallUValueOverride,
                                            windowsUValueOverride: (defaults.windowDefault === windowsUValueOverride) ? undefined : windowsUValueOverride,
                                            floorUValueOverride: (defaults.floorDefault === floorUValueOverride) ? undefined : floorUValueOverride, // TODO: Note that these being 0 meaning they are ignored might be an issue if we set the floor U value to 0.
                                            roofUValueOverride: (defaults.roofDefault === roofUValueOverride) ? undefined : roofUValueOverride,
                                            airChangeOverride: (defaults.airChangesDefault === airChangeOverride) ? undefined : airChangeOverride,
                                            internalTempOverride: (defaults.internalTempDefault === internalTempOverride) ? undefined : internalTempOverride,
                                            designTempOverride: (defaults.designTempDefault === designTempOverride) ? undefined : designTempOverride
                                        };
                                        await patchLead(lead.uuid.toString(), { property: lead.property }, adminContext.data.company.public_info.uuid);
                                        logEvent({ name: 'UValue Save Override Clicked', properties: {} }, adminContext.data.company.public_info.subdomain);
                                        setOverride(lead.property.houseOverrides); // TODO: FIX: this overrides previous overrides
                                    } }),
                                !isEditingUValues &&
                                    React.createElement("div", { className: 'flex flex-col gap-4' },
                                        React.createElement("div", { className: "justify-start items-start gap-4 flex center" },
                                            React.createElement("div", { className: "rounded flex-col justify-start items-start gap-2 inline-flex" },
                                                React.createElement("div", { className: "self-stretch text-gray-900 text-xs font-semibold" }, "Design temp."),
                                                React.createElement(Input, { value: designTempC.toString(), setValue: noop, size: 'SM', disabled: !isEditingUValues })),
                                            React.createElement("div", { className: "rounded flex-col justify-start items-start gap-2 inline-flex" },
                                                React.createElement("div", { className: "self-stretch text-gray-900 text-xs font-semibold" }, "Internal temp."),
                                                React.createElement(Input, { value: internalTempC.toString(), setValue: noop, size: 'SM', disabled: !isEditingUValues })),
                                            React.createElement("div", { className: "rounded flex-col justify-start items-start gap-2 inline-flex" },
                                                React.createElement("div", { className: "self-stretch text-gray-900 text-xs font-semibold" }, "ACH"),
                                                React.createElement(Input, { value: calculatedAirChanges.toString(), disabled: true, setValue: noop, size: 'SM' }))),
                                        React.createElement("table", { className: "w-full" },
                                            React.createElement("thead", null,
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-gray-500 text-xs font-semibold uppercase tracking-wide text-right" },
                                                    React.createElement("th", null),
                                                    React.createElement("th", { className: "py-2" }, "U-Value (W/m\u00B2K)"),
                                                    React.createElement("th", { className: "py-2" }, "Estimate"))),
                                            React.createElement("tbody", null,
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { className: "py-2 text-gray-600" }, "External walls"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, externalWallUValue.toFixed(2)),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(externalWallWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { className: "py-2 text-gray-600" }, "Party walls"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, partyWallUValue.toFixed(2)),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(partyWallWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { className: "py-2 text-gray-600" }, "Windows"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, windowsUValue.toFixed(2)),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(windowWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { className: "py-2 text-gray-600" }, "Floor"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, floorUValue.toFixed(2)),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(floorWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { className: "py-2 text-gray-600" }, "Roof"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, roofUValue.toFixed(2)),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(roofWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 text-sm font-medium" },
                                                    React.createElement("td", { colSpan: 2, className: "py-2 text-gray-600" }, "Ventilation"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" }, numberFormat(0).format(ventilationWatts) + ' W')),
                                                React.createElement("tr", { className: "border-b border-dashed border-gray-200 font-bold" },
                                                    React.createElement("td", { colSpan: 2, className: "py-2 text-gray-900" }, "Total"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" },
                                                        (totalWatts / 1000).toFixed(2),
                                                        " kW")),
                                                React.createElement("tr", { className: "" },
                                                    React.createElement("td", { colSpan: 2, className: "py-2 text-gray-900 text-sm font-medium" }, "Heat loss per m\u00B2"),
                                                    React.createElement("td", { className: "py-2 text-right text-gray-900" },
                                                        numberFormat(2).format(totalWatts / lead.property.floorArea),
                                                        " W/m\u00B2"))))),
                                React.createElement("div", { className: "w-full justify-center items-start gap-3 inline-flex" },
                                    React.createElement("div", { className: "grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex" },
                                        React.createElement("div", { className: "self-stretch text-gray-900 text-sm font-bold" },
                                            "CO",
                                            React.createElement("sub", null, "2"),
                                            " Saving"),
                                        React.createElement("div", { className: "self-stretch text-gray-600 text-xs font-semi-bold" }, "Based on UK government forecasts for decarbonisation of the grid")),
                                    React.createElement("div", { className: "h-5 justify-start items-start gap-2.5 flex" },
                                        React.createElement("div", { className: "grow shrink basis-0 text-right text-gray-900 text-sm font-bold font-['Manrope'] leading-tight" },
                                            numberFormat(0).format(CO2SavedKg),
                                            " kg/year"))))),
                        React.createElement(Section, { title: React.createElement("div", { className: 'flex justify-between' },
                                React.createElement("div", null, "Cost Estimate"),
                                React.createElement("div", { className: 'font-normal text-gray-600' }, formatPrice(calculatedQuote === null || calculatedQuote === void 0 ? void 0 : calculatedQuote.total_price))) },
                            React.createElement("div", { className: "flex flex-col h-full gap-y-2" },
                                React.createElement("div", { className: "divide-y divide-dashed divide-gray-200 text-sm text-gray-600", "data-cy": "cost_breakdown_table" },
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2' },
                                        React.createElement("div", { className: 'col-span-8 uppercase tracking-wide text-gray-500 text-xs font-semibold' }, "Item"),
                                        React.createElement("div", { className: 'col-span-3 uppercase tracking-wide text-gray-500 text-xs font-semibold justify-self-end' }, "Cost"),
                                        React.createElement("div", { className: 'uppercase tracking-wide text-gray-500 text-xs font-semibold' })),
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: "col-span-8" },
                                            (heatPump === null || heatPump === void 0 ? void 0 : heatPump.deleted_at) && React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: 'py-2' }, heatPump === null || heatPump === void 0 ? void 0 : heatPump.name),
                                                React.createElement("div", { className: 'py-2 text-red-800' }, "The heat pump was deleted from your inventory list."),
                                                React.createElement("div", { className: 'py-2 text-red-800' }, "You can't select it again if you change it to a different one.")),
                                            React.createElement(Select, { placeholder: "Please select a heat pump", selectedKey: heatPump === null || heatPump === void 0 ? void 0 : heatPump.uuid, setSelectedKey: async (x) => {
                                                    await patchLead(lead.uuid, { heat_pump_uuid: x }, adminContext.data.company.public_info.uuid);
                                                    setSelectedHeatPumpUUID(x);
                                                }, options: (_64 = (_63 = (_62 = adminContext.data) === null || _62 === void 0 ? void 0 : _62.heatPumps) === null || _63 === void 0 ? void 0 : _63.filter(x => (x.deleted_at === null)).map(x => ({ key: x.uuid, value: x.name }))) !== null && _64 !== void 0 ? _64 : [] })),
                                        React.createElement("div", { className: 'font-semibold text-sm text-gray-900 col-span-3 justify-self-end' }, formatPrice(heatPump === null || heatPump === void 0 ? void 0 : heatPump.price, 2)),
                                        React.createElement("div", null)),
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: "col-span-8" },
                                            (hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.deleted_at) && React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: 'py-2' }, hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.name),
                                                React.createElement("div", { className: 'py-2 text-red-800' }, "The hot water cylinder was deleted from your inventory list."),
                                                React.createElement("div", { className: 'py-2 text-red-800' }, "You can't select it again if you change it to a different one.")),
                                            React.createElement(Select, { placeholder: "Please select a hot water cylinder", selectedKey: hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.uuid, setSelectedKey: async (x) => {
                                                    await patchLead(lead.uuid, { hot_water_cylinder_uuid: x }, adminContext.data.company.public_info.uuid);
                                                    setSelectedHotWaterCylinderUUID(x);
                                                }, options: (_67 = (_66 = (_65 = adminContext.data) === null || _65 === void 0 ? void 0 : _65.hotWaterCylinders) === null || _66 === void 0 ? void 0 : _66.filter(x => (x.deleted_at === null)).map(x => ({ key: x.uuid, value: x.name }))) !== null && _67 !== void 0 ? _67 : [] })),
                                        React.createElement("div", { className: 'font-semibold text-sm text-gray-900 col-span-3 justify-self-end' }, formatPrice(hotWaterCylinder === null || hotWaterCylinder === void 0 ? void 0 : hotWaterCylinder.price, 2)),
                                        React.createElement("div", null)),
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: "text-gray-600 col-span-8" }, "Parts"),
                                        React.createElement("div", { className: "font-semibold text-gray-900 col-span-3 justify-self-end" }, formatPrice(heatPump ? heatPump.parts : 0, 2)),
                                        React.createElement("div", null)),
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: "text-gray-600 col-span-8" }, "Labour"),
                                        React.createElement("div", { className: "font-semibold text-gray-900 col-span-3 justify-self-end" }, formatPrice(labour, 2)),
                                        React.createElement("div", null)),
                                    React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: "text-gray-600 col-span-8" }, "Survey/Design"),
                                        React.createElement("div", { className: "font-semibold text-gray-900 col-span-3 justify-self-end" }, formatPrice(survey, 2)),
                                        React.createElement("div", null)),
                                    ((_68 = lead.lead_line_items) !== null && _68 !== void 0 ? _68 : []).map(x => React.createElement("div", { key: x.uuid, className: 'grid grid-cols-12 py-2 items-center' },
                                        React.createElement("div", { className: 'col-span-8' }, x.name),
                                        React.createElement("div", { className: "font-semibold text-gray-900 col-span-3 justify-self-end" }, formatPrice(x.value, 2)),
                                        React.createElement(XCircleIcon, { onClick: async () => {
                                                await deleteLeadLineItem(x.uuid, adminContext.data.company.public_info.uuid);
                                                setLead(prev => ({ ...prev, lead_line_items: prev.lead_line_items.filter(y => y.uuid !== x.uuid) }));
                                            }, className: 'h-5 w-5 fill-gray-400 cursor-pointer justify-self-end' }))),
                                    !checkIfScottish(lead === null || lead === void 0 ? void 0 : lead.property.postcode) && React.createElement("div", { className: 'grid grid-cols-12 py-2 items-center' },
                                        notGrantEligible ? React.createElement(Link, { onClick: () => setBusModalVisible(true), className: 'col-span-8' }, "Not currently eligible for BUS Grant") : React.createElement("div", { className: "text-gray-600 col-span-8" }, "Grant"),
                                        React.createElement("div", { className: "font-semibold text-gray-900 col-span-3 justify-self-end" }, formatPrice(grant, 2)),
                                        React.createElement("div", null)),
                                    React.createElement("div", { className: "grid grid-cols-12 py-2 items-center text-lg font-semibold text-gray-900" },
                                        React.createElement("div", { className: "col-span-8" }, "Total"),
                                        React.createElement("div", { className: "col-span-3 justify-self-end" }, formatPrice(calculatedQuote === null || calculatedQuote === void 0 ? void 0 : calculatedQuote.total_price, 2)),
                                        React.createElement("div", null))),
                                React.createElement(AdditionalCosts, { setLead: setLead, lead: lead, companyPublicInfo: adminContext.data.company.public_info }),
                                isScottish &&
                                    React.createElement("div", { className: "mt-4" }, (lead === null || lead === void 0 ? void 0 : lead.scottish_rural)
                                        ? '✅ This property is likely to be eligible for the rural uplift.'
                                        : '❌ This property is unlikely to be eligible for the rural uplift.')))),
                React.createElement("div", { className: "grid xl:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 gap-5" },
                    React.createElement(Section, { title: "Customer enquiry notes" },
                        React.createElement("div", { className: "space-y-4 text-sm" },
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Customer comments"),
                                React.createElement("div", { className: "text-gray-600" }, lead.notes === '' || !lead.notes ? 'N/A' : lead.notes)),
                            React.createElement("div", { className: "space-y-1" },
                                React.createElement("div", { className: "font-semibold" }, "Lead source"),
                                React.createElement("div", { className: "text-gray-600" }, lead.customer.source === '' || !lead.customer.source ? 'N/A' : lead.customer.source)),
                            lead.scottishGrant &&
                                React.createElement("div", { className: "space-y-1" },
                                    React.createElement("div", { className: "font-semibold" }, "Have you been in touch with Home Energy Scotland?"),
                                    React.createElement("div", { className: "text-gray-600" }, lead.scottishGrant.scottishGrantIsInTouch + ' — ' + (lead === null || lead === void 0 ? void 0 : lead.scottishGrant.scottishGrantReason))))),
                    React.createElement(InstallerNotes, { lead: lead }))),
            React.createElement(SendEstimateModalBlock, { setEstimates: setEstimates, adminContext: adminContext, isVisible: isEstimateModalVisible, setIsVisible: setIsEstimateModalVisible, calculatedQuote: calculatedQuote, lead: lead, setLead: setLead }),
            React.createElement(SendDeclinedModalBlock, { adminContext: adminContext, isVisible: declineModalVisible, setIsVisible: setDeclineModalVisible, lead: lead, setLead: setLead }),
            React.createElement(Modal, { title: 'BUS grant eligibility', visible: busModalVisible, setVisible: setBusModalVisible },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'space-y-2' },
                        React.createElement("div", { className: 'text-gray-900 font-semibold' },
                            "This property is ",
                            notGrantEligible ? 'not currently' : 'likely to be',
                            " eligible for a BUS grant."),
                        React.createElement("div", { className: 'text-gray-600 text-sm' },
                            lead.epcData ? React.createElement(React.Fragment, null,
                                "The latest EPC we have access to was lodged on ",
                                React.createElement("span", { className: 'font-bold' }, formatDate(lead.epcData.inspectionDate.toString())),
                                ".") : '',
                            " Please note that EPC data only updates monthly, typically during the final working week of the month.")),
                    ((_69 = lead.epcData) === null || _69 === void 0 ? void 0 : _69.lmkKey)
                        ? React.createElement("div", null,
                            React.createElement("div", { className: 'space-y-2 text-gray-600' }, busGrantEligibleReasons.filter(x => !['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                                x.warning ? React.createElement(ExclamationCircleIcon, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                    : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                                React.createElement("div", null, x.message)))),
                            React.createElement("div", { className: 'mt-5 flex flex-col gap-2' },
                                React.createElement("div", { className: 'text-gray-900 font-semibold mt-5' }, "Energy efficiency opportunities"),
                                React.createElement("div", { className: 'text-gray-600 text-sm' }, "On the 8th May 2024 the requirement to have no outstanding recommendations for loft or cavity wall insulation was removed. We continue to flag them here for your reference only."),
                                React.createElement("div", { className: 'space-y-2 text-gray-600 mt-2' }, busGrantEligibleReasons.filter(x => ['cavity_wall', 'loft_insulation'].includes(x.id)).map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                                    x.warning ? React.createElement(ExclamationCircleIcon, { className: 'w-6 h-6 fill-yellow-500' }) : x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                        : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                                    React.createElement("div", null, x.message))))))
                        : React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address. The property will need an EPC before it can be assessed for BUS grant eligibility."))),
            React.createElement(Modal, { title: 'HES grant eligibility', visible: hesModalVisible, setVisible: setHesModalVisible },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    !(lead === null || lead === void 0 ? void 0 : lead.epc_scotland) &&
                        React.createElement("div", { className: 'flex flex-col gap-8' },
                            React.createElement("div", { className: 'space-y-1' },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "This property cannot be assessed for a HES grant."),
                                React.createElement("div", { className: 'text-gray-600 text-sm' }, "Please note that EPC data only updates quarterly.")),
                            React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address so we cannot assess whether loft insulation or cavity wall insulation would be recommended in the post-install EPC.")),
                    (lead === null || lead === void 0 ? void 0 : lead.epc_scotland) &&
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'space-y-1' },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' },
                                    "This property ",
                                    notHesGrantEligible ? 'is unlikely to be' : 'may be',
                                    " eligible for a HES grant."),
                                React.createElement("div", { className: 'text-gray-600 text-sm' },
                                    lead.epc_scotland ? React.createElement(React.Fragment, null,
                                        "Data from the EPC lodged on ",
                                        React.createElement("span", { className: 'font-bold' }, formatDate(lead.epc_scotland.inspection_date.toString())),
                                        ".") : '',
                                    " Please note that EPC data only updates quarterly.")),
                            ((_70 = lead === null || lead === void 0 ? void 0 : lead.epc_scotland) === null || _70 === void 0 ? void 0 : _70.improvements)
                                ? React.createElement("div", { className: 'space-y-2 text-gray-600' }, hesGrantEligibleReasons.map((x, i) => React.createElement("div", { key: i, className: 'flex gap-x-2' },
                                    x.hasPassed ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 fill-green-500' })
                                        : React.createElement(XCircleIcon, { className: 'w-6 h-6 fill-red-500' }),
                                    React.createElement("div", null, x.message))))
                                : React.createElement(Alert, { type: "WARNING" }, "No EPC found for this address. The property will need an EPC before it can be assessed for HES grant eligibility.")))),
            React.createElement(Modal, { title: 'Preview Estimate', visible: previewEstimateModalVisible, setVisible: setPreviewEstimateModalVisible },
                React.createElement(EstimatePageInner, { estimate: calculatedQuote, companyPublicInfo: adminContext.data.company.public_info, isScottish: isScottish, grantEligible: !notGrantEligible })))));
};
const InstallerNotes = ({ lead }) => {
    var _a;
    const adminContext = useContext(AdminContext);
    const [notes, setNotes] = useState((_a = lead.installer_notes) !== null && _a !== void 0 ? _a : '');
    const [isEditing, setIsEditing] = useState(false);
    return React.createElement(Section, { title: React.createElement("div", { className: "flex justify-between items-center" },
            "Installer notes",
            !isEditing && React.createElement(Button, { size: "SM", colour: "LIGHT", className: "", onClick: () => setIsEditing(prev => !prev) }, isEditing ? 'Cancel' : 'Edit'),
            isEditing && React.createElement(Button, { size: "SM", colour: "DARK", onClick: async () => {
                    await patchLead(lead.uuid, { installer_notes: notes }, adminContext.data.company.public_info.uuid);
                    setIsEditing(false);
                } }, "Save")) },
        React.createElement("div", { className: "space-y-4 text-sm" },
            !isEditing && React.createElement("pre", { className: "text-wrap font-sans space-y-1 text-gray-600" }, notes === '' || !notes ? 'N/A' : notes),
            isEditing && React.createElement("div", { className: 'space-y-1' },
                React.createElement(TextArea, { value: notes, setValue: setNotes, disabled: !isEditing, rows: 9 }))));
};
const AdditionalCosts = ({ lead, setLead, companyPublicInfo }) => {
    var _a, _b;
    const [selectedLineItemUUID, setSelectedLineItemUUID] = useState();
    const companyLineItem = (_a = companyPublicInfo.company_line_items) === null || _a === void 0 ? void 0 : _a.find(x => x.uuid === selectedLineItemUUID);
    return React.createElement("div", null,
        React.createElement("div", { className: 'font-semibold text-gray-900 mb-2' }, "Add additional cost item"),
        React.createElement("div", { className: 'flex items-center gap-x-4' },
            React.createElement(Select, { dataCy: "cost_item_dropdown", setSelectedKey: setSelectedLineItemUUID, selectedKey: selectedLineItemUUID, options: ((_b = companyPublicInfo.company_line_items) !== null && _b !== void 0 ? _b : []).map(x => ({ key: x.uuid, value: `${x.name} - ${formatPrice(x.value, 2)}` })) }),
            React.createElement(Button, { disabled: !companyLineItem, onClick: async () => {
                    const newItem = { uuid: crypto.randomUUID(), name: companyLineItem.name, value: companyLineItem.value, lead_uuid: lead.uuid };
                    await insertLeadLineItem(newItem, companyPublicInfo.uuid);
                    setLead(prev => {
                        var _a;
                        return ({
                            ...prev,
                            lead_line_items: [...(_a = prev.lead_line_items) !== null && _a !== void 0 ? _a : [], newItem]
                        });
                    });
                } }, "Add")));
};
export const BUSGrantBadge = ({ isScottish, notHesGrantEligible, notGrantEligible, lead }) => {
    if (isScottish) {
        if (!lead.epc_scotland)
            return React.createElement(Badge, { color: 'YELLOW', text: "Cannot assess HES eligibility" });
        return React.createElement(Badge, { color: notHesGrantEligible ? 'RED' : 'GREEN', text: notHesGrantEligible ? 'Unlikely to be eligible for HES Grant' : 'May be eligible for HES Grant' });
    }
    return React.createElement(Badge, { color: notGrantEligible ? 'RED' : 'GREEN', text: notGrantEligible ? 'Not currently eligible for BUS Grant' : 'Eligible for BUS Grant' });
};
