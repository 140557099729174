import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { validateInMm } from '../../../../code/validators';
export const HPLProposedLocationDetailsBlock = () => {
    var _a, _b;
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { survey, setSurvey } = hplContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Location"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Available space"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "Space between windows, doors and drains that the heat pump plus protective zone must fit into.")),
            React.createElement("div", { className: "gap-4 flex" },
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Width"),
                    React.createElement(Input, { type: "number", value: survey.available_space_width_mm.toString(), setValue: (e) => setSurvey({ ...survey, available_space_width_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Height"),
                    React.createElement(Input, { type: 'number', value: survey.available_space_height_mm.toString(), setValue: (e) => setSurvey({ ...survey, available_space_height_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Depth"),
                    React.createElement(Input, { type: 'number', value: survey.available_space_depth_mm.toString(), setValue: (e) => setSurvey({ ...survey, available_space_depth_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })))),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Location description"),
                React.createElement(Badge, { color: ((_a = survey.location_description) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 'GREEN' : 'YELLOW', text: 'Required', icon: ((_b = survey.location_description) === null || _b === void 0 ? void 0 : _b.length) > 0 ? faCheck : undefined })),
            React.createElement(TextArea, { placeholder: 'Note any details that will impact installation, such as required ground works or wall/roof material.', value: survey.location_description, setValue: (e) => setSurvey({ ...survey, location_description: e }) })));
};
