import React, { useEffect } from 'react';
import { AppProvider } from './app_context';
import { mainRouter } from './router';
import { RouterProvider } from 'react-router-dom';
import { ToastPage } from './components/indicators_and_messaging/toast';
export const Main = () => {
    // keep some OHM specific logic here
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const source = params.get('source');
        if (window.location.pathname === '/ohm' && !['ohm', 'heatpumpsussex'].includes(source)) {
            window.location.href = '/ohm?source=ohm';
            return;
        }
        if (window.location.pathname === '/heatpumpsussex') {
            window.location.href = '/ohm?source=heatpumpsussex';
        }
    }, []);
    return React.createElement("div", { className: 'text-gray-600 font-medium' },
        React.createElement(AppProvider, null,
            React.createElement(ToastPage, null),
            React.createElement("div", { className: 'flex flex-col h-screen', style: {
                    paddingTop: 'env(safe-area-inset-top)',
                    paddingRight: 'env(safe-area-inset-right)',
                    paddingBottom: 'env(safe-area-inset-bottom)',
                    paddingLeft: 'env(safe-area-inset-left)'
                } },
                React.createElement("div", { className: 'flex-grow overflow-y-auto print:overflow-visible' },
                    React.createElement(RouterProvider, { router: mainRouter })))));
};
