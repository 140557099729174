import { getCompanyDeclinedEmailTemplate, updateCompanySettings } from '../../../code/models/company';
import React, { useState } from 'react';
import { Section } from '../../../components/containers/section';
import { Tiptap } from '../../../components/inputs_and_selections/tiptap';
import { TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName } from '../../../code/tiptap_placeholders';
import { Button } from '../../../components/buttons/button';
export const DeclineEnquiryEmailTemplateBlock = ({ company, setCompany }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [template, setTemplate] = useState(getCompanyDeclinedEmailTemplate(company.decline_template));
    const [editor, setEditor] = useState();
    const [beforeEditValues, setBeforeEditValues] = useState({
        template: undefined
    });
    const handleStartEditing = () => {
        setBeforeEditValues({ template });
        setIsEditing(true);
    };
    const handleCancelEditing = () => {
        editor === null || editor === void 0 ? void 0 : editor.commands.setContent(beforeEditValues.template);
        setTemplate(beforeEditValues.template);
        setBeforeEditValues({ template: undefined });
        setIsEditing(false);
    };
    const handleOnSave = async () => {
        if (isEditing) {
            const tmpCompany = { ...company, decline_template: JSON.stringify(template) };
            await updateCompanySettings(tmpCompany);
            setCompany(tmpCompany);
            setIsEditing(false);
        }
    };
    return React.createElement(Section, { border: true, title: "Decline enquiry email template" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'space-y-2' },
                React.createElement(Tiptap, { onEditorInit: (editor) => setEditor(editor), editable: isEditing, noPlaceholderButtons: !isEditing, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setTemplate(editor.getJSON());
                    }, placeholders: [
                        TTPlaceholderCustomerName,
                        TTPlaceholderCustomerAddress,
                        TTPlaceholderCompanyName,
                        TTPlaceholderCompanyPhone
                    ], content: template })),
            React.createElement("div", { className: 'flex justify-end gap-x-2' },
                !isEditing && React.createElement(Button, { onClick: handleStartEditing }, "Edit"),
                isEditing && React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: handleCancelEditing }, "Cancel"),
                    React.createElement(Button, { onClick: handleOnSave }, "Save")))));
};
