import React from 'react';
import { Icon } from '../../../components/buttons/icon';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
export const BottomModalHeader = ({ title, onClose }) => {
    return React.createElement("div", { className: "px-4 py-3 items-center gap-2.5 flex border-b border-gray-200" },
        React.createElement("div", { className: 'flex-1 flex-shrink' }),
        React.createElement("div", { className: "flex-1 text-gray-900 font-bold flex-grow text-center whitespace-nowrap" }, title),
        React.createElement("div", { className: 'flex-1 flex-shrink flex justify-end items-center' },
            React.createElement(Icon, { icon: faTimesCircle, colour: 'text-gray-300', onClick: onClose })));
};
