import React, { useContext } from 'react';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { AdminContext } from './admin_layout';
import { SendEstimateEmailTemplateBlock } from './settings_page/send_estimate_email_template_block';
import { NudgeLeadEmailTemplateBlock } from './settings_page/nudge_lead_email_template_block';
import { BookingConfirmationOptionsBlock } from './settings_page/booking_confirmation_options_block';
import { DeclineEnquiryEmailTemplateBlock } from './settings_page/decline_enquiry_email_template_block';
import { AccountDetailsBlock } from './settings_page/account_details_block';
import { MiscControlsBlock } from './settings_page/misc_controls_block';
import { OfflinePage } from './offline_page';
import { useRouteLoaderData } from 'react-router-dom';
import { BrandingBlock } from './settings_page/branding_block';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../code/models/user';
import { RequireRole } from '../../require_role';
export const SettingsPage = () => {
    const adminContext = useContext(AdminContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    if (!adminContext.data.company)
        return;
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement("div", { className: 'w-full' },
            React.createElement("div", { className: 'z-50 px-4 py-4 flex gap-x-4 items-center justify-start lg:sticky lg:top-0 lg:bg-white lg:border lg:border-solid lg:border-b lg:shadow' },
                React.createElement(Bars3Icon, { "data-cy": 'nav_open', className: "lg:hidden h-10 w-10 cursor-pointer", onClick: () => {
                        adminContext.showSidebar();
                    } }),
                adminContext.data.company.public_info.logo && React.createElement("img", { src: adminContext.data.company.public_info.logo, className: "max-h-12", alt: "Installer logo" })),
            React.createElement("div", { className: 'p-8 flex justify-center' },
                React.createElement("div", { className: 'max-w-2xl w-full flex flex-col gap-8' },
                    React.createElement(AccountDetailsBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(BrandingBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(MiscControlsBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(SendEstimateEmailTemplateBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(NudgeLeadEmailTemplateBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(BookingConfirmationOptionsBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }),
                    React.createElement(DeclineEnquiryEmailTemplateBlock, { company: adminContext.data.company, setCompany: adminContext.setCompany }))))));
};
