import React from 'react';
import { HLRContext } from './heatloss_report';
import { formatPrice } from '../../../code/format_price';
export const HLRPESummary = () => {
    const hlrContext = React.useContext(HLRContext);
    const estimateSummary = hlrContext.performanceEstimateSummary;
    return React.createElement("div", { className: 'flex gap-9 flex-col' },
        React.createElement("div", { className: "flex-col justify-start items-start gap-5 flex p-9 bg-gray-100" },
            React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, "Your performance estimate"),
            React.createElement("div", { className: "text-lg" }, "Predicting your running costs with a heat pump is hard as it depends on a lot of factors, many of which are outside our control. But it\u2019s clearly really important that you\u2019re able to make an informed decision. So in this section we give you our best estimate of what the running costs and carbon savings will look like for your home if and when you switch to a heat pump."),
            React.createElement("div", { className: "text-lg" }, "We then provide an in-depth description of how we\u2019ve calculated these figures in case you want to dig into the details. Please do ask us if anything doesn't make sense!")),
        React.createElement("div", { className: 'flex flex-col gap-6' },
            React.createElement("div", { className: 'text-3xl font-bold text-gray-900' }, "Summary"),
            React.createElement("div", { className: 'grid grid-cols-2 gap-3' },
                React.createElement("div", { className: 'p-5 rounded-md flex-col gap-10 flex bg-gray-100 border-gray-100 border' },
                    React.createElement("div", { className: "text-gray-900 text-2xl font-bold" }, "Bills"),
                    React.createElement("div", { className: "flex flex-col gap-2" },
                        React.createElement("div", { className: 'flex flex-row justify-between' },
                            React.createElement("div", { className: "text-gray-900 font-semibold" }, "Current"),
                            React.createElement("div", { className: "font-semibold" }, estimateSummary.currentBillRange)),
                        React.createElement("div", { className: 'flex flex-row justify-between' },
                            React.createElement("div", { className: "text-gray-900 font-semibold" }, "Proposed system"),
                            React.createElement("div", { className: "font-semibold" }, estimateSummary.proposedBillRange))),
                    React.createElement("div", { className: "flex flex-row justify-between" },
                        React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Savings"),
                        React.createElement("div", { className: "px-1 rounded-md gap-1 flex" },
                            React.createElement("div", { className: `${estimateSummary.minBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` },
                                React.createElement("div", { className: "text-green-950 text-3xl font-bold" }, formatPrice(estimateSummary.minBillSavings, 0))),
                            React.createElement("div", { className: "text-green-950 text-3xl font-bold" }, "to"),
                            React.createElement("div", { className: `${estimateSummary.maxBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` },
                                React.createElement("div", { className: "text-green-950 text-3xl font-bold" }, formatPrice(estimateSummary.maxBillSavings, 0)))))),
                React.createElement("div", { className: 'p-5 rounded-md flex-col gap-10 flex bg-gray-100 border-gray-100 border' },
                    React.createElement("div", { className: "text-gray-900 text-2xl font-bold" }, "Carbon"),
                    React.createElement("div", { className: "flex flex-col gap-2" },
                        React.createElement("div", { className: 'flex flex-row justify-between' },
                            React.createElement("div", { className: "text-gray-900 font-semibold" }, "Current"),
                            React.createElement("div", { className: "font-semibold" }, estimateSummary.currentEmissionsRange)),
                        React.createElement("div", { className: 'flex flex-row justify-between' },
                            React.createElement("div", { className: "text-gray-900 font-semibold" }, "Proposed system"),
                            React.createElement("div", { className: "font-semibold" }, estimateSummary.proposedEmissionsRange))),
                    React.createElement("div", { className: "flex flex-row justify-between" },
                        React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Savings"),
                        React.createElement("div", { className: "px-1 rounded-md gap-1 flex" },
                            React.createElement("div", { className: `${estimateSummary.minEmissionsPercentageSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` },
                                React.createElement("div", { className: "text-green-950 text-3xl font-bold" },
                                    (estimateSummary.minEmissionsPercentageSavings * 100).toFixed(0),
                                    "%")),
                            (estimateSummary.minEmissionsPercentageSavings * 100).toFixed(0) !== (estimateSummary.maxEmissionsPercentageSavings * 100).toFixed() && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "text-green-950 text-3xl font-bold" }, "to"),
                                React.createElement("div", { className: `${estimateSummary.maxEmissionsPercentageSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` },
                                    React.createElement("div", { className: "text-green-950 text-3xl font-bold" },
                                        (estimateSummary.maxEmissionsPercentageSavings * 100).toFixed(0),
                                        "%")))))))));
};
