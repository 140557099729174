import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from '../containers/modal';
const colours = {
    DARK: 'bg-gray-900 text-white border border-gray-900 text-white font-bold rounded-md',
    LIGHT: 'bg-white border border-gray-300 font-bold rounded-md ',
    GRAY: 'bg-gray-50 border border-gray-50 rounded-md',
    LOZENGE_LIGHT: 'bg-gray-100 rounded-full font-bold',
    LOZENGE_DARK: 'bg-gray-200 rounded-full font-bold'
};
const sizes = {
    SM: 'text-xs py-2 px-2.5',
    MD: 'text-sm px-4 py-2',
    LG: 'px-4 py-3'
};
export const Button = ({ children, onClick, block, disabled, size = 'MD', className, colour = 'DARK', iconLeft, iconRight, confirmText, ...rest }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return React.createElement(React.Fragment, null,
        React.createElement("button", { disabled: disabled, onClick: confirmText ? () => setModalOpen(true) : onClick, className: `${colours[colour]} ${sizes[size]} disabled:opacity-50 cursor-pointer items-center justify-center flex disabled:cursor-default outline-none ${block ? 'block w-full' : ''} ${className}`, ...rest },
            React.createElement("div", { className: `${children ? 'gap-2' : ''} flex items-center` },
                iconLeft ? React.createElement(FontAwesomeIcon, { icon: iconLeft }) : React.createElement("div", null),
                children,
                iconRight ? React.createElement(FontAwesomeIcon, { icon: iconRight }) : React.createElement("div", null))),
        confirmText && modalOpen && React.createElement(Modal, { onConfirm: onClick, visible: true, title: confirmText, setVisible: () => setModalOpen(!modalOpen) }, "This action cannot be undone."));
};
