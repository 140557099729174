import { chain, uniq, cloneDeep, max, min, minBy, indexOf } from 'lodash';
import { stepSize, calculateCentroid } from '../../constants';
import { createNewTouchingWalls } from '../code';
import { magnetiseSize, maxScale, minScale } from './constants';
import * as Sentry from '@sentry/browser';
import { getFloorAreaM2 } from '../../../../code/models/heat_loss';
export const calculateRotation = (x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const radians = Math.atan2(dy, dx);
    const degrees = radians * (180 / Math.PI);
    return degrees;
};
export const fixRotation = (x1, x2, y1, y2) => {
    if (x1 > x2)
        return { x1: x2, x2: x1, y1, y2 };
    if (y1 > y2)
        return { x1, x2, y1: y2, y2: y1 };
    return { x1, x2, y1, y2 };
};
export const getLineDiff = (x1, y1, x2, y2, newLength) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const currentLength = Math.sqrt(dx * dx + dy * dy) / stepSize / 10;
    const scaleFactor = newLength - currentLength;
    return Math.round((scaleFactor * stepSize * 10) / stepSize) * stepSize;
};
export const getCenter = (p1, p2) => {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
    };
};
export const getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};
export const wallToLine = (wall, nextWall, room) => {
    return { uuid: wall.uuid, x1: wall.x + room.x, y1: wall.y + room.y, x2: nextWall.x + room.x, y2: nextWall.y + room.y, room_uuid: room.uuid, other_room_uuid: undefined, material: wall.material };
};
export const simplifyWalls = (walls) => {
    const simplifiedWalls = [];
    // Shapes can collapse walls onto themselves,
    // we need to remove pure dupes before hand otherwise they will destroy each other in logic below and we want to retain at least one.
    const wallsWithoutDupes = walls.reduce((prev, curr) => {
        return prev.some(x => x.x === curr.x && x.y === curr.y) ? prev : [...prev, curr];
    }, []);
    // Iterate through vertices and identify consecutive duplicates
    for (let i = 0; i < wallsWithoutDupes.length; i++) {
        const currentVertex = wallsWithoutDupes[i];
        const prevVertex = getPrevWall(currentVertex, wallsWithoutDupes);
        const nextVertex = getNextWall(currentVertex, wallsWithoutDupes);
        // Check if current vertex is redundant
        // We can have a straight line attached to 2 different rooms so don't simplify in those scenerios (other_room_uuid check)
        if ((currentVertex.x === prevVertex.x && currentVertex.x === nextVertex.x && currentVertex.other_room_uuid === nextVertex.other_room_uuid) ||
            (currentVertex.y === prevVertex.y && currentVertex.y === nextVertex.y && currentVertex.other_room_uuid === nextVertex.other_room_uuid) ||
            (currentVertex.x === nextVertex.x && currentVertex.y === nextVertex.y && currentVertex.other_room_uuid === nextVertex.other_room_uuid)) {
            continue; // Skip redundant vertex
        }
        simplifiedWalls.push(currentVertex);
    }
    return simplifiedWalls;
};
export const removeInvalidRoomGroups = (rooms) => {
    return chain(rooms)
        .groupBy(x => x.room_group_uuid)
        .flatMap((values, key) => {
        return values.map(x => {
            const otherRoomUUIDs = uniq(x.walls.map(x => x.other_room_uuid));
            return values.some(y => otherRoomUUIDs.includes(y.uuid)) ? x : { ...x, room_group_uuid: undefined };
        });
    })
        .value();
};
export const calculateNewTouchingWalls = (rooms, defaultMaterials) => {
    const newRooms = cloneDeep(rooms);
    for (const room of newRooms) {
        const otherWalls = rooms.filter(x => x.uuid !== room.uuid).flatMap(x => x.walls.map(y => {
            const nextWall = getNextWall(y, x.walls);
            return wallToLine(y, nextWall, x);
        }));
        const newWalls = [];
        for (const wall of room.walls) {
            const nextWall = getNextWall(wall, room.walls);
            const newWall = createNewTouchingWalls(wallToLine(wall, nextWall, room), otherWalls, defaultMaterials);
            const keepWindowsAndDoors = newWall.filter(x => !x.other_room_uuid).length < 2;
            newWalls.push(...newWall.map(x => ({ ...wall, windows: !x.other_room_uuid && keepWindowsAndDoors ? wall.windows : [], doors: !x.other_room_uuid && keepWindowsAndDoors ? wall.doors : [], uuid: x.uuid, x: x.x1 - room.x, y: x.y1 - room.y, other_room_uuid: x.other_room_uuid, material: x.material })));
        }
        room.walls = newWalls;
    }
    return newRooms;
};
export const findMagnetiseXandY = (cursorX, cursorY, currRoom, otherWallsX, otherWallsY) => {
    let newX = cursorX;
    let newY = cursorY;
    const cursorDiffX = Math.abs(cursorX - currRoom.x);
    const cursorDiffY = Math.abs(cursorY - currRoom.y);
    for (const wall of currRoom.walls) {
        for (const otherWall of otherWallsX) {
            const distance = otherWall - (wall.x + currRoom.x);
            if (Math.abs(distance) <= magnetiseSize && cursorDiffX <= magnetiseSize) {
                newX = currRoom.x + distance;
                break;
            }
        }
    }
    for (const wall of currRoom.walls) {
        for (const otherWall of otherWallsY) {
            const distance = otherWall - (wall.y + currRoom.y);
            if (Math.abs(distance) <= magnetiseSize && cursorDiffY <= magnetiseSize) {
                newY = currRoom.y + distance;
                break;
            }
        }
    }
    return { newX, newY };
};
export const getPercentageText = (wattsEmitted, wattsLost) => {
    if (wattsLost < 0) {
        return `${Math.round(wattsLost)} W`;
    } // Don't show percentage of negative heat loss values as very confusing
    const percentage = Math.round((wattsEmitted / wattsLost) * 100);
    return `${percentage}% of ${Math.round(wattsLost)} W`;
};
// This method assumes 2 adjacent polygons have had their wall split already if they are only partially covering another wall.
// Find the room furthest left (x) on the map, set it to room1 if not already
// Starting in room1 go counterclockwise, add walls until you find a matching x,y pair in room2 (ignore if your starting x,y matches)
// in room2 go counterclockwise until my start x,y equals the start x,y of room1
// add the remainder of room1 until you are back at your starting location
// https://spruceretrofit.slack.com/files/U057AGU4750/F07HEE6KG72/screenshare_-_2024-08-13_5_20_25_pm.webm
export const mergeRooms = (room1, room2) => {
    if (!room1.walls.find(x => !!x.other_room_uuid) && !room2.walls.find(x => !!x.other_room_uuid))
        return;
    const roomBaseXandY = room1.x <= room2.x ? room1 : room2;
    // Circular rotate both arrays so that the left/top most point always starts first, and the room1 shape is always the left most shape.
    // Now you can always assume for any 2 merge shapes start in the top left corner and work anti-clockwise around the perimiter.
    const absolutePositionRoom1Walls = room1.walls.map(x => ({ ...x, x: x.x + room1.x, y: x.y + room1.y }));
    const room1MinIndex = indexOf(absolutePositionRoom1Walls, minBy(absolutePositionRoom1Walls, x => x.x + x.y));
    const rotatedAbsolutePositionRoom1Walls = [...absolutePositionRoom1Walls.slice(room1MinIndex), ...absolutePositionRoom1Walls.slice(0, room1MinIndex)];
    const absolutePositionRoom2Walls = room2.walls.map(x => ({ ...x, x: x.x + room2.x, y: x.y + room2.y }));
    const room2MinIndex = indexOf(absolutePositionRoom2Walls, minBy(absolutePositionRoom2Walls, x => x.x + x.y));
    const rotatedAbsolutePositionRoom2Walls = [...absolutePositionRoom2Walls.slice(room2MinIndex), ...absolutePositionRoom2Walls.slice(0, room2MinIndex)];
    const firstRoom = rotatedAbsolutePositionRoom1Walls[0].x + rotatedAbsolutePositionRoom1Walls[0].y < rotatedAbsolutePositionRoom2Walls[0].x + rotatedAbsolutePositionRoom2Walls[0].y ? rotatedAbsolutePositionRoom1Walls : rotatedAbsolutePositionRoom2Walls;
    const secondRoom = rotatedAbsolutePositionRoom1Walls[0].x + rotatedAbsolutePositionRoom1Walls[0].y < rotatedAbsolutePositionRoom2Walls[0].x + rotatedAbsolutePositionRoom2Walls[0].y ? rotatedAbsolutePositionRoom2Walls : rotatedAbsolutePositionRoom1Walls;
    // Now we have sorted the arrays do the main merging logic.
    const room1EndIndex = firstRoom.findIndex(r1 => secondRoom.some(r2 => r1.x === r2.x && r1.y === r2.y));
    const room2StartIndex = secondRoom.findIndex(x => firstRoom[room1EndIndex].x === x.x && firstRoom[room1EndIndex].y === x.y);
    const room2EndIndex = findIndexCircular(secondRoom, room2StartIndex + 1, (r1) => firstRoom.some(r2 => r1.x === r2.x && r1.y === r2.y));
    const room1StartIndex = firstRoom.findIndex(x => secondRoom[room2EndIndex].x === x.x && secondRoom[room2EndIndex].y === x.y);
    const newWalls = [
        ...firstRoom.slice(0, room1EndIndex),
        ...secondRoom.slice(room2StartIndex, room2EndIndex < room2StartIndex ? secondRoom.length : room2EndIndex),
        ...(room2EndIndex < room2StartIndex ? secondRoom.slice(0, room2EndIndex) : []),
        ...firstRoom.slice(room1StartIndex, firstRoom.length)
    ].map(x => ({ ...x, x: x.x - roomBaseXandY.x, y: x.y - roomBaseXandY.y }));
    const simplifiedWalls = simplifyWalls(newWalls);
    if (simplifiedWalls.length === 0)
        Sentry.captureException('No walls result when merging 2 rooms.', { extra: { room1, room2 } });
    if (getFloorAreaM2(simplifiedWalls, stepSize) <= 0)
        Sentry.captureException('Area is <= 0 when merging 2 rooms.', { extra: { room1, room2 } });
    const mergedRoom = { ...room1, radiators: [...room1.radiators, ...room2.radiators], images: [...room1.images, ...room2.images], x: roomBaseXandY.x, y: roomBaseXandY.y, walls: simplifiedWalls };
    return mergedRoom;
};
export const getNextWall = (wall, walls) => {
    const wallIndex = walls.findIndex(x => x.uuid === wall.uuid);
    const nextIndex = walls.length - 1 === wallIndex ? 0 : wallIndex + 1;
    return walls[nextIndex];
};
export const getNextIndex = (index, walls) => {
    const nextIndex = walls.length - 1 === index ? 0 : index + 1;
    return nextIndex;
};
export const getPrevWall = (wall, walls) => {
    const wallIndex = walls.findIndex(x => x.uuid === wall.uuid);
    const prevIndex = getPrevIndex(wallIndex, walls);
    return walls[prevIndex];
};
export const getPrevIndex = (index, walls) => {
    const prevIndex = index === 0 ? walls.length - 1 : index - 1;
    return prevIndex;
};
export const findIndexCircular = (arr, startIndex, condition) => {
    const n = arr.length;
    let index = startIndex % n; // Make sure startIndex is within bounds
    for (let i = 0; i < n; i++) {
        if (condition(arr[index])) {
            return index;
        }
        index = (index + 1) % n; // Move to the next index, looping back if necessary
    }
    return -1; // If no index meets the condition
};
// Sometimes want to scale to a sensible level and centre on room. Scale on floor because zooming in on room is disorientating
export const centreOnRoomScaleOnFloor = (room, floor, setStagePosition, setStageScale, width, height) => {
    const scale = getStageScaleForFloor(floor, width, height);
    setStageScale(scale);
    // Need to scale before we can centre to get the centre of the room correct
    const { x, y } = getStagePositionToCentreOnRoom(room, scale, width, height);
    setStagePosition({ x, y });
};
export const centreAndScaleOnFloor = (floor, setStagePosition, setStageScale, width, height) => {
    const scale = getStageScaleForFloor(floor, width, height);
    setStageScale(scale);
    // scale first so passing correct scale when centring
    const { x, y } = getStagePositionToCentreOnFloor(floor, width, height, scale);
    setStagePosition({ x, y });
};
export const getStagePositionToCentreOnRoom = (room, stageScale, width, height) => {
    // Find the center of the shape
    const roomCenterRelative = calculateCentroid(room.walls.map(x => [x.x, x.y]));
    // Add the room center x, y to get the room absolute position on canvas
    const roomCenterAbsoluteX = roomCenterRelative[0] + room.x;
    const roomCenterAbsoluteY = roomCenterRelative[1] + room.y;
    return getPositionToCentreOnObject({ x: roomCenterAbsoluteX, y: roomCenterAbsoluteY }, width, height, stageScale);
};
export const getStagePositionToCentreOnFloor = (floor, width, height, stageScale) => {
    // Wall x and y are relative to room - so combined here to get the absolute position of all the points
    const wallsAbsoluteX = floor.rooms.map(r => r.walls.flatMap(w => w.x + r.x)).flat();
    const wallsAbsoluteY = floor.rooms.map(r => r.walls.flatMap(w => w.y + r.y)).flat();
    const floorCentreAbsoluteX = (max(wallsAbsoluteX) + min(wallsAbsoluteX)) / 2;
    const floorCentreAbsoluteY = (max(wallsAbsoluteY) + min(wallsAbsoluteY)) / 2;
    return getPositionToCentreOnObject({ x: floorCentreAbsoluteX, y: floorCentreAbsoluteY }, width, height, stageScale);
};
const getPositionToCentreOnObject = (objectCentreAbsolute, width, height, stageScale) => {
    // The canvas x and y depend on the scale (confusingly!) so scale the x and y by the stage scale
    // The canvas position describes the top left, so putting the canvas in the position below would put the object centroid in the top left corner
    const canvasXPutsRoomTopLeft = -objectCentreAbsolute.x * stageScale;
    const canvasYPutsRoomTopLeft = -objectCentreAbsolute.y * stageScale;
    // To put the object in the center of the screen we shift the canvas right (positive x) and down (positive y) by half the screen width and height
    const newX = canvasXPutsRoomTopLeft + width / 2;
    const newY = canvasYPutsRoomTopLeft + height / 2;
    return { x: newX, y: newY };
};
// Same as above but the bounding box of the whole floor.
export const getStageScaleForFloor = (floor, width, height) => {
    // Wall x and y are relative to room - so combined here to get the absolute position of all the points
    const wallsX = floor.rooms.map(r => r.walls.flatMap(w => w.x + r.x)).flat();
    const wallsY = floor.rooms.map(r => r.walls.flatMap(w => w.y + r.y)).flat();
    return scaleObject(wallsX, wallsY, width, height);
};
// Same as above but the bounding box of the whole floor.
export const getStageScaleForRoom = (room, width, height) => {
    // Wall x and y are relative to room - so combined here to get the absolute position of all the points
    const wallsX = room.walls.flatMap(w => w.x + room.x);
    const wallsY = room.walls.flatMap(w => w.y + room.y);
    return scaleObject(wallsX, wallsY, width, height, 5);
};
export const scaleObject = (allXs, allYs, width, height, bufferPixels = 50) => {
    // Max percent of window defines how much of the window the shape should take when zoomed in
    // Find the bounding box of the shape by finding the min/max of x and y
    const objectWidthPixels = (max(allXs) - min(allXs));
    const objectLengthPixels = (max(allYs) - min(allYs));
    // - Divide screen width by shape width and screen height by shape height, get the min of these values and that is how far we can zoom in
    const newScale = max([min([width / (objectWidthPixels + bufferPixels),
            height / (objectLengthPixels + bufferPixels),
            maxScale]),
        minScale]);
    return newScale;
};
// Always move down and right
// Update either my point or my next point so we can ensure above by taking the larger of the 2 values
// If updating my point, go clockwise around the shape, make any lines that were previously straight straight again by setting its x/y to the new calculated x/y value
// If update my next point, go anti clockwise around the shape, make any lines that were previously straight straight again by setting its x/y to the new calculated x/y value
// Assumes you are the end of a split line if trying to update a split line, o
export const updateLength = (room, wallIndex, newLength, stepSize) => {
    // TODO: stepSize unused?
    const { ...oldWall } = room.walls[wallIndex];
    const nextWallIndex = getNextIndex(room.walls.findIndex(x => x.uuid === oldWall.uuid), room.walls);
    const { ...nextWall } = room.walls[nextWallIndex];
    const lineDiff = getLineDiff(oldWall.x, oldWall.y, nextWall.x, nextWall.y, newLength);
    const newWalls = [oldWall];
    // Should we update the oldWall point or nextWall to ensure we are always moving down and right?
    if ((oldWall.x === nextWall.x && oldWall.y > nextWall.y) || (oldWall.y === nextWall.y && oldWall.x > nextWall.x)) {
        // If line is horizontal update the y position, otherwise x
        if (oldWall.x === nextWall.x) {
            oldWall.y = oldWall.y + lineDiff;
        }
        else {
            oldWall.x = oldWall.x + lineDiff;
        }
        // I am updating myself, so find me all other walls to loop through, clockwise, until I hit myself again (the array is circular).
        const wallsToIterate = [
            ...room.walls.slice(wallIndex + 1),
            ...room.walls.slice(0, wallIndex)
        ].reverse();
        wallsToIterate.map(x => {
            const { ...currWall } = x;
            const nextWall = getNextWall(currWall, room.walls);
            // If the line was previously horizontal or vertical, align it back to that state.
            if (currWall.x === nextWall.x) {
                currWall.x = newWalls[newWalls.length - 1].x;
            }
            else {
                currWall.y = newWalls[newWalls.length - 1].y;
            }
            newWalls.push(currWall);
        });
        return newWalls.reverse();
    }
    // I am updating my next wall instead, repeat the above logic but for couter-clockwise, it is not possible to merge the 2 functions into one easily.
    if (oldWall.x === nextWall.x) {
        nextWall.y = nextWall.y + lineDiff;
    }
    else {
        nextWall.x = nextWall.x + lineDiff;
    }
    newWalls.push(nextWall);
    const wallsToIterate = [
        ...(nextWallIndex + 1 > wallIndex ? room.walls.slice(nextWallIndex + 1) : []),
        ...room.walls.slice(nextWallIndex + 1 > wallIndex ? 0 : 1, wallIndex)
    ];
    wallsToIterate.map(x => {
        const { ...currWall } = x;
        const prevWall = getPrevWall(currWall, room.walls);
        if (currWall.x === prevWall.x) {
            currWall.x = newWalls[newWalls.length - 1].x;
        }
        else {
            currWall.y = newWalls[newWalls.length - 1].y;
        }
        newWalls.push(currWall);
    });
    return newWalls;
};
/*
    This function is used to calculate the length of a wall in meters. It simplifies the distinction between internal and external walls and just calculates the straight lengths - irrespective of whether they are internal or external.
 */
export const calculateSimplifiedWallLengths = (room, stepSize) => {
    const simplifiedWalls = simplifyWalls(room.walls.map(x => ({ ...x, other_room_uuid: undefined })));
    return (simplifiedWalls.map((w, i) => calculateWallLength(w, getNextWall(w, simplifiedWalls), stepSize)));
};
export const calculateWallLength = (wall, nextWall, stepSize) => {
    return calculateLineLength(wall.x, wall.y, nextWall.x, nextWall.y, stepSize);
};
export const calculateLineLength = (x1, y1, x2, y2, stepSize) => {
    // TODO: duplicate of above? Use above and then do the scaling?
    const dx = x2 - x1;
    const dy = y2 - y1;
    const lengthPixels = Math.sqrt(dx * dx + dy * dy);
    // 1 step is 10cm. Rounding to 1 dp rounds to nearest 10 cm
    return Number((lengthPixels / stepSize / 10).toFixed(1));
};
export const area = (stepSize, vertices) => {
    // Vertices must in order for this to work but can be either clockwise or anticlockwise
    // Formula from here: https://en.wikipedia.org/wiki/Shoelace_formula
    let total = 0;
    for (let i = 0, l = vertices.length; i < l; i++) {
        const x1 = vertices[i].x / stepSize;
        const y2 = vertices[i === vertices.length - 1 ? 0 : i + 1].y / stepSize;
        const x2 = vertices[i === vertices.length - 1 ? 0 : i + 1].x / stepSize;
        const y1 = vertices[i].y / stepSize;
        total += (x1 * y2 * 0.5);
        total -= (x2 * y1 * 0.5);
    }
    return Math.abs(total) / 100;
};
