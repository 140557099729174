import React from 'react';
import { EmptyState } from '../../../components/content_display/empty_state';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../components/buttons/button';
export const EmptyStateDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(EmptyState, { icon: faSquare, primaryText: 'Hello world', secondaryText: 'My secondary text', primaryButton: React.createElement(Button, { iconLeft: faSquare }, "Primary"), secondaryButton: React.createElement(Button, { iconLeft: faSquare }, "Secondary") })));
};
const componentProps = [
    { name: 'icon', description: 'Main icon of empty state' },
    { name: 'primaryText', description: 'Bold primary text' },
    { name: 'secondaryText', description: 'Secondary text' },
    { name: 'primaryButton', description: 'The left side button, accepts a whole button as props' },
    { name: 'secondaryButton', description: 'The right side button, accepts a whole button as props' }
];
const codeBlock = `<EmptyState
  icon={faSquare}
  primaryText='Hello world'
  secondaryText='My secondary text'
  primaryButton={<Button iconLeft={faSquare}>Primary</Button>}
  secondaryButton={<Button iconLeft={faSquare}>Secondary</Button>}
/>`;
