import { apiUrl, client } from '../axios';
import { getEpcScotlandByUPRN } from './epc_scotland';
export const getEpcRecommendations = async (epcId) => {
    var _a;
    try {
        const result = await client.get(`${apiUrl}recommendations?id=${epcId}`);
        return result.data;
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return [];
        throw e;
    }
};
// Returns the latest EPC for a given English or Welsh UPRN
export const getEpcForUprn = async (uprn) => {
    var _a;
    try {
        // English EPCs are fetched from the EPC API
        const result = await client.get(`${apiUrl}epcs/${uprn}`);
        return result.data;
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return undefined;
        throw e;
    }
};
// Returns the latest EPC for a given Scottish UPRN
export const getEpcScottishForUprn = async (uprn) => {
    var _a;
    try {
        // Scottish EPCs are in the database
        return await getEpcScotlandByUPRN(uprn);
    }
    catch (e) {
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 404)
            return undefined;
        throw e;
    }
};
export const epcToBackgroundColour = (rating) => {
    if (rating === 'A')
        return 'bg-green-600';
    if (rating === 'B')
        return 'bg-green-500';
    if (rating === 'C')
        return 'bg-green-400';
    if (rating === 'D')
        return 'bg-yellow-500';
    if (rating === 'E')
        return 'bg-orange-400';
    if (rating === 'F')
        return 'bg-orange-500';
    if (rating === 'G')
        return 'bg-red-500';
};
