import React, { useContext, useEffect, useState } from 'react';
import { AdminContext } from './admin_layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getAddressIncludingPostcode, getLeads, getSurveyLeads, patchLead, searchLeads } from '../../code/models/lead';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';
import { hasSurveyAccess, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR } from '../../code/models/user';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { OfflineBlock } from '../heat_loss/particles/offline_block';
import { TabGroup } from '../../components/content_display/tab';
import { SurveysListItemOptions } from '../survey/components/surveys_list_item_options';
import { Button } from '../../components/buttons/button';
import { PlusIcon, SignalSlashIcon } from '@heroicons/react/16/solid';
import { AnimatedBlock } from './components/animated_block';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { BottomModalHeader } from '../heat_loss/floor/bottom_modal_header';
import { contextSensitiveFormatDate } from '../../code/format_date';
import { RequireRole } from '../../require_role';
import { Input } from '../../components/inputs_and_selections/input';
import { Badge, leadStatusToBadge, leadSurveyStatusToBadge } from '../../components/indicators_and_messaging/badge';
import { Link } from '../../components/buttons/link';
export const SurveysListPage = ({ stateFilter }) => {
    var _a, _b, _c, _d, _e;
    const adminContext = useContext(AdminContext);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(undefined);
    const [allLeads, setAllLeads] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isLeadsSheetOpen, setIsLeadsSheetOpen] = useState(false);
    const [surveyLeads, , , reloadSurveyLeads] = useIndexedDBFallback('admin_data', 'all_leads', undefined, async () => {
        var _a, _b;
        // skip if user is not loaded or has no access
        if (!hasSurveyAccess((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company, (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.user))
            return undefined;
        // skip if admin layout data is not loaded
        if (adminContext.isLoading)
            return undefined;
        // fetch all the lead data
        // if the user is a surveyor role, this endpoint will only fetch the leads that they're invited to
        // we don't do any extra filtering here.
        return await getSurveyLeads(adminContext.data.company.public_info.uuid);
    }, (value) => {
        if (!value)
            return;
        setIsLoading(false);
    });
    useEffect(() => {
        const r = async () => await reloadSurveyLeads();
        if (!adminContext.isLoading)
            r();
    }, [adminContext.isLoading]);
    useEffect(() => {
        // call loadAllLeads in 0.5 seconds
        const timeout = setTimeout(() => {
            if (isLeadsSheetOpen)
                loadAllLeads();
        }, 500);
        // cleanup
        return () => clearTimeout(timeout);
    }, [searchText]);
    const loadAllLeads = async () => {
        var _a;
        // Filter out 'Survey booked' leads since they can't have a new survey booked
        const nonSurveyBookedLeads = (_a = (searchText && searchText.length > 0
            ? await searchLeads(adminContext.data.company.public_info.uuid, searchText)
            : await getLeads(adminContext.data.company.public_info.uuid))) === null || _a === void 0 ? void 0 : _a.filter((lead) => lead.status !== 'SurveyBooked');
        setAllLeads(undefined);
        setAllLeads(orderBy(nonSurveyBookedLeads, (x) => new Date(x.createdAt), 'desc'));
    };
    const handleNewSurveyClick = async (lead) => {
        var _a;
        await patchLead(lead.uuid, { status: 'SurveyBooked', survey_status: 'IN_PROGRESS' }, adminContext.data.company.public_info.uuid);
        navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/surveys/${lead.uuid}`);
    };
    if (isLoading || adminContext.isLoading)
        return React.createElement("div", { className: 'p-5 flex justify-center' }, "Loading...");
    const orderedSurveyLeads = orderBy(surveyLeads
        .filter(x => x.status === 'SurveyBooked')
        .filter(x => stateFilter === undefined
        // if "All" selected, the stateFilter === undefined
        ? true
        // x.surveyStatus can be undefined. This means that the survey is in progress
        : (!x.survey_status ? 'IN_PROGRESS' : x.survey_status) === stateFilter), x => new Date(x.createdAt), 'desc');
    return (React.createElement(RequireRole, { roles: [USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR] },
        React.createElement("div", null,
            React.createElement("div", { className: 'px-4 py-4 flex gap-x-4 items-center justify-start lg:sticky lg:top-0 lg:bg-white lg:border lg:border-solid lg:border-b lg:shadow' },
                React.createElement(FontAwesomeIcon, { "data-cy": 'nav_open', icon: faBars, className: "lg:hidden cursor-pointer", onClick: () => {
                        adminContext.showSidebar();
                    } }),
                ((_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.logo) && React.createElement("img", { src: (_b = adminContext.data.company) === null || _b === void 0 ? void 0 : _b.public_info.logo, className: "max-h-12", alt: "Installer logo" })),
            React.createElement(OfflineBlock, null),
            React.createElement("div", { className: 'p-4 md:p-8 flex flex-col' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: 'text-xl md:text-3xl font-bold text-gray-900' }, "Surveys"),
                    React.createElement(TabGroup, { items: [
                            {
                                name: 'All',
                                secondaryText: (_c = surveyLeads === null || surveyLeads === void 0 ? void 0 : surveyLeads.length.toString()) !== null && _c !== void 0 ? _c : '0',
                                onClick: () => {
                                    var _a;
                                    navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/surveys`);
                                },
                                variant: stateFilter === undefined ? 'ACTIVE' : 'DEFAULT'
                            },
                            {
                                name: 'In progress',
                                secondaryText: (_d = surveyLeads === null || surveyLeads === void 0 ? void 0 : surveyLeads.filter(x => x.survey_status === 'IN_PROGRESS' || !x.survey_status).length.toString()) !== null && _d !== void 0 ? _d : '0',
                                onClick: () => {
                                    var _a;
                                    navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/surveys/in_progress`);
                                },
                                variant: stateFilter === 'IN_PROGRESS' ? 'ACTIVE' : 'DEFAULT'
                            },
                            {
                                name: 'Completed',
                                secondaryText: (_e = surveyLeads === null || surveyLeads === void 0 ? void 0 : surveyLeads.filter(x => x.survey_status === 'COMPLETED').length.toString()) !== null && _e !== void 0 ? _e : '0',
                                onClick: () => {
                                    var _a;
                                    navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/surveys/completed`);
                                },
                                variant: stateFilter === 'COMPLETED' ? 'ACTIVE' : 'DEFAULT'
                            }
                        ] })),
                React.createElement(AnimatedBlock, { fromElement: React.createElement(Button, { size: 'LG', "data-cy": "survey-new" },
                        React.createElement(PlusIcon, { className: 'w-5 h-5' }),
                        "\u00A0New"), toElement: React.createElement(Button, { size: 'MD' },
                        React.createElement(SignalSlashIcon, { className: 'w-5 h-5' }),
                        "\u00A0No\u00A0internet"), className: 'fixed bottom-6 right-6', onClickIfNegativeCondition: () => {
                        setIsLeadsSheetOpen(true);
                        setSearchText(undefined);
                        loadAllLeads();
                    }, condition: adminContext.isOffline }),
                React.createElement(BottomSheet, { blocking: true, initialFocusRef: false, scrollLocking: false, open: isLeadsSheetOpen, onDismiss: () => setIsLeadsSheetOpen(false), snapPoints: ({ maxHeight }) => maxHeight * 0.75 },
                    React.createElement("div", { className: 'flex flex-col gap-4' },
                        React.createElement(BottomModalHeader, { onClose: () => setIsLeadsSheetOpen(false), title: 'Choose an enquiry' }),
                        React.createElement(Input, { placeholder: 'Search', prefix: React.createElement(FontAwesomeIcon, { icon: faSearch }), className: 'px-4', value: searchText !== null && searchText !== void 0 ? searchText : '', setValue: setSearchText }),
                        React.createElement("div", { className: 'overflow-x-auto px-4' },
                            allLeads === undefined && React.createElement("div", { className: 'p-4' }, "Loading..."),
                            (allLeads === null || allLeads === void 0 ? void 0 : allLeads.length) === 0 && React.createElement("div", { className: 'p-4' }, "No enquiries found"), allLeads === null || allLeads === void 0 ? void 0 :
                            allLeads.map(x => {
                                var _a, _b;
                                return React.createElement("div", { key: x.uuid, className: 'flex flex-col gap-4 border-1 border-b border-gray-200 py-4 cursor-pointer hover:bg-gray-100', onClick: async () => await handleNewSurveyClick(x) },
                                    React.createElement("div", { className: 'flex flex-col' },
                                        React.createElement("div", { className: 'font-bold' }, (_a = x.customer) === null || _a === void 0 ? void 0 : _a.name),
                                        React.createElement("div", { className: 'text-sm' }, getAddressIncludingPostcode(x))),
                                    React.createElement("div", { className: 'flex gap-2 justify-between text-sm' },
                                        React.createElement("div", { className: 'flex gap-2 items-end' },
                                            React.createElement("div", { className: 'font-bold text-gray-900' }, (_b = x.customer) === null || _b === void 0 ? void 0 : _b.phone),
                                            React.createElement("div", { className: 'text-xs text-gray-500' }, contextSensitiveFormatDate(new Date(x.createdAt)))),
                                        React.createElement(Badge, { ...leadStatusToBadge(x.status) })));
                            })))),
                React.createElement("div", { className: 'overflow-x-auto' }, orderedSurveyLeads.map(x => {
                    var _a, _b, _c;
                    return React.createElement("div", { key: x.uuid, className: 'flex flex-col gap-4 border-1 border-b border-gray-200 py-4', "data-cy": 'survey-lead' },
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: 'flex justify-between' },
                                React.createElement(Link, { onClick: () => { var _a; return navigate(`/${(_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.subdomain}/admin/surveys/${x.uuid}`); }, className: 'text-lg' }, (_a = x.customer) === null || _a === void 0 ? void 0 : _a.name),
                                React.createElement(SurveysListItemOptions, { lead: x, callback: reloadSurveyLeads })),
                            React.createElement("div", { className: 'text-sm' }, getAddressIncludingPostcode(x))),
                        React.createElement("div", { className: 'flex gap-2 justify-between text-sm' },
                            React.createElement("div", { className: 'flex gap-2 items-end' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, (_b = x.customer) === null || _b === void 0 ? void 0 : _b.phone),
                                React.createElement("div", { className: 'text-xs text-gray-500' }, contextSensitiveFormatDate(new Date(x.createdAt)))),
                            React.createElement(Badge, { ...leadSurveyStatusToBadge((_c = x.survey_status) !== null && _c !== void 0 ? _c : 'IN_PROGRESS') })));
                }))))));
};
