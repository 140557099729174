import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const badgeColours = {
    DARK: 'bg-gray-900 text-gray-100',
    LIGHT: 'bg-gray-100 text-gray-900',
    YELLOW: 'bg-yellow-100 text-yellow-900',
    RED: 'bg-red-100 text-red-900',
    BLUE: 'bg-blue-100 text-blue-900',
    INDIGO: 'bg-purple-100 text-purple-900',
    GREEN: 'bg-green-100 text-green-900'
};
export const leadStatusToBadge = (status) => {
    if (status === 'New')
        return { color: 'YELLOW', text: 'Estimate required' };
    if (status === 'Quoted')
        return { color: 'BLUE', text: 'Estimate sent' };
    if (status === 'Converted')
        return { color: 'GREEN', text: 'Accepted' };
    if (status === 'Rejected')
        return { color: 'RED', text: 'Rejected' };
    if (status === 'Declined')
        return { color: 'RED', text: 'Enquiry Declined' };
    if (status === 'SurveyBooked')
        return { color: 'BLUE', text: 'Survey Booked' };
    if (status === 'Archived')
        return { color: 'LIGHT', text: 'Archived' };
    return { color: 'DARK', text: '' };
};
export const leadSurveyStatusToBadge = (status) => {
    if (status === 'IN_PROGRESS')
        return { color: 'YELLOW', text: 'In progress' };
    if (status === 'COMPLETED')
        return { color: 'GREEN', text: 'Completed' };
    return { color: 'DARK', text: '' };
};
export const Badge = ({ color, text, icon }) => {
    if (!text)
        return;
    return React.createElement("div", { className: `${badgeColours[color]} px-2 py-1 rounded flex items-center justify-center` },
        React.createElement("div", { className: 'flex gap-2 items-center' },
            icon && React.createElement(FontAwesomeIcon, { className: 'text-xs', icon: icon }),
            React.createElement("div", { className: "text-xs font-semibold text-center" }, text)));
};
