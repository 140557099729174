import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
export const SurveyCheckbox = ({ label, sublabel, selected, onSelect }) => {
    const handleSelect = () => {
        if (onSelect)
            onSelect(!selected);
    };
    const classNames = selected ? 'border-solid border-2 border-gray-700 px-5 py-3' : 'border-solid border-2 border-gray-100 bg-gray-100 px-5 py-3';
    return React.createElement("div", { onClick: handleSelect, className: `${classNames} cursor-pointer rounded-xl gap-4 flex justify-between w-full` },
        React.createElement("div", { className: "flex-col flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, label),
            sublabel && React.createElement("div", { className: "text-gray-600 text-sm" }, sublabel)),
        selected ? React.createElement("div", { className: "bg-gray-900 rounded border text-white border-gray-900 w-5 h-5 justify-center items-center flex font-bold" },
            React.createElement(CheckIcon, { className: "w-4 h-4 stroke-2" })) : React.createElement("div", { className: "w-5 h-5 bg-white rounded border border-neutral-300" }));
};
