import React from 'react';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { MaterialInputField } from './materials/material_input_field';
import { validateInMm } from '../../code/validators';
export const WindowPage = ({ floor, wallWindow, setScalingWindow, setWindow, onSave, survey, materials, setMsProps, setPage, setStageStep, onBack }) => {
    const isValidForm = [validateInMm(wallWindow.width_mm), validateInMm(wallWindow.height_mm)].every(x => x.value);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: "p-5 bg-white flex-col gap-5 flex" },
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Window material"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Window',
                            surfaceType: 'window',
                            materials,
                            ageBand: survey.age_band,
                            selectedMaterial: wallWindow.material,
                            onSelectCallback: (material) => {
                                setWindow(prev => ({ ...prev, material }));
                            }
                        }, setMsProps: setMsProps, setPage: setPage })),
                React.createElement("div", { className: 'flex gap-2' },
                    React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Width"),
                        React.createElement(Input, { step: 10, type: 'number', value: wallWindow.width_mm.toString(), setValue: (e) => setWindow(prev => ({ ...prev, width_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' })),
                    React.createElement("div", { className: 'flex flex-col gap-2 flex-grow' },
                        React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Height"),
                        React.createElement(Input, { step: 10, type: 'number', value: wallWindow.height_mm.toString(), setValue: (e) => setWindow(prev => ({ ...prev, height_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' }))),
                React.createElement("div", { className: 'flex gap-2' },
                    floor.floor_plan_is_showing && floor.floor_plan_image && React.createElement(Button, { className: 'flex-1', colour: 'LIGHT', onClick: () => { setScalingWindow(true); } }, "Measure width"),
                    React.createElement(Button, { className: 'flex-1', onClick: () => { onSave(); setWindow(undefined); onBack(); }, disabled: !isValidForm }, "Apply")))));
};
