import React from 'react';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
export const HLRContentsPage = ({ excludedSections }) => {
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBLock, { title: "Contents" }),
        React.createElement("div", { className: "flex-col flex" },
            !excludedSections.includes('SUMMARY') && React.createElement("div", { className: "py-5 border-t border-gray-300 grid grid-cols-2 gap-9" },
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Summary"),
                    React.createElement("div", { className: "text-xs tracking-tight" }, "An overview of heat loss report findings, design proposal and performance comparison.")),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Home heat loss")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Heat pump design proposal")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Performance comparison")))),
            !excludedSections.includes('HEAT_LOSS') && React.createElement("div", { className: "py-5 border-t border-gray-300 grid grid-cols-2 gap-9" },
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Heat loss report"),
                    React.createElement("div", { className: "text-gray-600 text-xs tracking-tight" }, "Detailed heat loss calculations for your home. The overall heat loss determines how big your heat pump needs to be, and the room-by-room breakdown allows us to size the radiators correctly to keep each room warm.")),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Introduction")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Calculation conditions")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Overall heat loss by element")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Overall heat loss by room")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Floorplans")),
                    !excludedSections.includes('HEAT_LOSS_ROOM_BY_ROOM') &&
                        React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Room by room heat loss")))),
            !excludedSections.includes('DESIGN') && React.createElement("div", { className: "py-5 border-t border-gray-300 grid grid-cols-2 gap-9" },
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Your heat pump system"),
                    React.createElement("div", { className: "text-gray-600 text-xs tracking-tight" }, "Design specifications for your new heating system including heat pump, cylinder and emitter changes.")),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Proposed heat pump")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Heat pump location")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Proposed cylinder")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Cylinder location")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Emitters")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Current emitters")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Proposed emitter changes")))),
            !excludedSections.includes('PERFORMANCE_ESTIMATE') && React.createElement("div", { className: "py-5 border-t border-gray-300 grid grid-cols-2 gap-9" },
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Performance comparison"),
                    React.createElement("div", { className: "text-gray-600 text-xs tracking-tight" }, "A comparison of your new heat pump system to your existing system and benchmarks.")),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Energy use")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Emissions")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 gap-2.5 flex" },
                        React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Running costs")))))));
};
