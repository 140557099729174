import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
export const getBrandRanges = async () => {
    try {
        const result = await client.get(`${apiUrl}brand_ranges`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.map(x => ({ ...x, deleted_at: x.deleted_at ? new Date(x.deleted_at) : undefined }));
    }
    catch (e) {
        console.error('Error loading Brand Ranges Layers', e);
        return undefined;
    }
};
