import React, { useState } from 'react';
import { PhotoScroll } from '../../../components/inputs_and_selections/photo_scroll';
export const PhotoScrollDemo = () => {
    const [files, setFiles] = useState([]);
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(PhotoScroll, { images: files, addImage: (e) => setFiles(prev => [...prev, e]), deleteImage: (id) => setFiles(prev => prev.filter(x => x.uuid !== id)), doNotScale: false })));
};
const componentProps = [
    { name: 'images', description: 'The list of existing images we should render, horizontally scrolls on overflow' },
    { name: 'addImage', description: 'What to do when user uploads an image' },
    { name: 'deleteImage', description: 'What to do when use clicks cross icon on specific image' },
    { name: 'doNotScale', description: 'If false, scale the image to specified max dimensions in codebase' }
];
const codeBlock = `const [files, setFiles] = useState<FileWrapper[]>([])

<PhotoScroll
  images={files}
  addImage={(e) => setFiles(prev => [...prev, e])}
  deleteImage={(id) => setFiles(prev => prev.filter(x => x.uuid !== id))}
  doNotScale={false}
/>
`;
