import React, { useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { logEvent } from '../../../code/log_event';
import { ColoredBlockWrapper } from '../components/colored_block_wrapper';
import { StepHeader } from '../components/steps_header';
import { SubHeader } from '../components/sub_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { Caption } from '../components/caption_1';
import { formatDate } from '../../../code/format_date';
export const EPCFoundStep = () => {
    var _a, _b, _c, _d, _e, _f;
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const handleNavigateNextPage = () => {
        logEvent({ name: 'EPC found step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "We have found an Energy Performance Certificate (EPC) for your home" }),
        React.createElement("div", { className: "flex flex-col gap-y-2 divide-gray-200 divide-y" },
            React.createElement("div", { className: "grid grid-cols-2 gap-x-2 pt-2" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Energy rating"),
                React.createElement("div", { className: "text-gray-600" }, (_b = (_a = survey.lead.epcData) === null || _a === void 0 ? void 0 : _a.currentEnergyRating) !== null && _b !== void 0 ? _b : (_c = survey.lead.epc_scotland) === null || _c === void 0 ? void 0 : _c.current_energy_rating)),
            React.createElement("div", { className: "grid grid-cols-2 gap-x-2 pt-2" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Date of EPC"),
                React.createElement("div", { className: "text-gray-600" }, formatDate((_e = (_d = survey.lead.epcData) === null || _d === void 0 ? void 0 : _d.inspectionDate) !== null && _e !== void 0 ? _e : (_f = survey.lead.epc_scotland) === null || _f === void 0 ? void 0 : _f.inspection_date, true))),
            React.createElement("div", { className: "grid grid-cols-2 gap-x-2 pt-2" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Property type"),
                React.createElement("div", { className: "text-gray-600" }, survey.lead.property.propertyType)),
            React.createElement("div", { className: "grid grid-cols-2 gap-x-2 pt-2" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Floor area"),
                React.createElement("div", { className: "text-gray-600" },
                    survey.lead.property.floorArea,
                    " square metres"))),
        React.createElement(ColoredBlockWrapper, { color: "gray" },
            React.createElement("div", { className: "flex flex-col gap-y-4" },
                React.createElement("div", { className: "flex flex-col gap-y-2" },
                    React.createElement(SubHeader, { content: "We've used the data from your EPC to pre-fill the following steps" }),
                    React.createElement(Caption, { size: "sm", content: "Please correct anything that doesn't look right in the following steps." })),
                React.createElement(Button, { size: 'LG', onClick: handleNavigateNextPage },
                    React.createElement("div", { className: "flex items-center gap-x-2" },
                        React.createElement("div", null, "Check property details"),
                        React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))))));
};
