import { ACH_AGES } from '../pages/heat_loss/constants';
export const DEFAULT_SURVEY_IMAGE = {
    uuid: undefined,
    image_uuid: ''
};
export const DEFAULT_SURVEY_DOOR = {
    height_mm: 1980,
    width_mm: 760,
    material: undefined
};
export const DEFAULT_SURVEY_WINDOW = {
    height_mm: 0,
    width_mm: 0,
    material: undefined
};
export const DEFAULT_SURVEY_WALL = {
    uuid: undefined,
    windows: [],
    doors: [],
    other_room_uuid: undefined,
    material: undefined
};
export const DEFAULT_SURVEY_RADIATOR = {
    uuid: undefined,
    radiator_type_uuid: 'type_21',
    survey_or_design: 'SURVEY',
    emitter_type: 'RADIATOR',
    photos: [],
    pipe_model_uuid: undefined,
    pipe_diameter_uuid: undefined,
    maximum_height: 0,
    maximum_width: 0,
    notes: '',
    created_at: undefined,
    updated_at: undefined
};
export const DEFAULT_SURVEY_UNDERFLOOR = {
    uuid: undefined,
    survey_or_design: 'SURVEY',
    emitter_type: 'UNDERFLOOR',
    manifold_uuid: undefined,
    photos: [],
    room_uuid: '',
    pct_of_floor_area_heated: 100,
    floor_construction_type: 'Solid (Screed)',
    floor_covering_uuid: 'timber_laminate',
    pipe_centres_uuid: '200mm',
    pipe_model_uuid: 'pex_16',
    pipe_diameter_uuid: undefined,
    updated_at: 0,
    created_at: 0
};
export const DEFAULT_SURVEY_SECONDARY_EMITTER = {
    uuid: undefined,
    survey_or_design: 'SURVEY',
    emitter_type: 'SECONDARY',
    name: '',
    room_heating_load_covered_percentage: 10,
    notes: '',
    updated_at: 0,
    created_at: 0,
    room_uuid: '',
    photos: []
};
export const DEFAULT_MANIFOLD = {
    uuid: undefined,
    name: '',
    location_description: '',
    photos: [],
    ufh_temp_differs_from_system_temp: false,
    max_mean_water_temp_c: 40,
    flow_temp_c: undefined,
    return_temp_c: undefined,
    number_of_loops: undefined
};
export const DEFAULT_RADIATOR_MODEL = {
    uuid: undefined,
    company_uuid: undefined,
    type: '',
    height_mm: 0,
    width_mm: 0,
    category: '',
    output_capacity_w_at_50_c: 0,
    n_factor: 1.3
};
export const DEFAULT_SURVEY_FLOOR = {
    uuid: crypto.randomUUID(),
    name: 'Ground floor',
    rooms: [],
    default_room_height: 2.4,
    default_floor_material_type: 'ground-floor',
    default_ceiling_material_type: 'intermediate-floor-and-ceiling',
    floor_plan_image: '',
    floor_plan_scale: 1,
    floor_plan_is_showing: true
};
export const DEFAULT_SURVEY_ROOFLIGHT = {
    uuid: undefined,
    width_mm: 780,
    height_mm: 1180,
    material: undefined
};
export const DEFAULT_SURVEY_ROOM = {
    uuid: undefined,
    overwritten_attributes_flags: 0,
    walls: [],
    name: '',
    radiators: [],
    images: [],
    rooflights: [],
    height_m: DEFAULT_SURVEY_FLOOR.default_room_height,
    height_2_m: undefined,
    ceiling_width_1_m: undefined,
    ceiling_width_2_m: undefined,
    ceiling_type_uuid: 'flat',
    flue_type_uuid: 'no',
    notes: '',
    ceiling_material: undefined,
    floor_material: undefined,
    room_group_uuid: undefined
};
export const DEFAULT_QUOTE_LINE_ITEM = {
    uuid: undefined,
    value: 1,
    name: 'HP-1',
    type: 'HEAT_PUMP',
    quantity: 1
};
export const DEFAULT_SURVEY_DESIGN_BOILER = {
    uuid: undefined,
    code: '',
    name: '',
    boiler_uuid: '',
    price_per_kwh: 0,
    efficiency_percentage: 0,
    enabled: false,
    survey_design_uuid: undefined
};
export const DEFAULT_DESIGN_RADIATOR = {
    uuid: undefined,
    emitter_type: 'RADIATOR',
    survey_or_design: 'DESIGN',
    radiator_type_uuid: '',
    pipe_model_uuid: undefined,
    created_at: undefined,
    updated_at: undefined,
    photos: [],
    pipe_diameter_uuid: undefined,
    maximum_height: 0,
    maximum_width: 0,
    notes: ''
};
export const DEFAULT_SURVEY_DESIGN = {
    uuid: undefined,
    survey_uuid: '',
    delta_t_flow_return_c: 5,
    flow_temp: 45,
    hot_water_storage_temperature_c: 50,
    antifreeze_percentage: 0,
    allowance_for_emitters_and_fittings: 33,
    current_heat_pump_uuid: undefined,
    current_hot_water_cylinder_uuid: undefined,
    quote_line_items: [],
    boilers: [],
    radiators: [],
    removed_radiator_uuids: [],
    primary_pipework_uuid_override: undefined,
    secondary_pipework_uuid_override: undefined,
    index_pipework_uuid_override: undefined,
    primary_pipework_length_m_override: undefined,
    secondary_pipework_length_m_override: undefined,
    index_pipework_length_m_override: undefined,
    secondary_index_emitter_uuids: [],
    index_emitter_uuid_override: undefined,
    removed_secondary_index_emitter_uuids: [],
    undersized_emitter_rooms: []
};
export const DEFAULT_PROPERTY_SURVEY = {
    uuid: undefined,
    created_at: 0,
    flags_site_details_pages_completed: 0,
    flags_electrics_pages_completed: 0,
    flags_hpl_pages_completed: 0,
    flags_ehs_pages_completed: 0,
    flags_cylinder_location_pages_completed: 0,
    flags_floorplan_pages_completed: 0,
    flags_estimate_mappings: 0,
    age_band: undefined,
    default_materials: undefined,
    floors: [],
    location_description: '',
    location_images: [],
    property_images: [],
    current_primary_pipework_mm: 0,
    primary_pipework_material: '',
    sound_distance: 0,
    reflective_surfaces: 0,
    sound_barrier_uuid: '',
    electrics_images: [],
    epc_heating_kwh: undefined,
    epc_hot_water_kwh: undefined,
    epc_certificate_number: '',
    use_epc_performance: true,
    bathrooms: 0,
    occupants_override: undefined,
    bedrooms: 0,
    volume_per_person_l: 45,
    legionnaires_cycle_weeks: 2,
    legionnaire_cycle_in_heat_pump: true,
    designs: [],
    intermittent_heating: false,
    exposed_location: false,
    mvhr_installed: false,
    use_cibse_indoor_temps: true,
    use_cibse_air_change_values: true,
    parking_available: true,
    permit_required: false,
    space_for_kit_to_be_delivered: true,
    notes: '',
    stopcock_works: false,
    stopcock_location: '',
    stopcock_images: [],
    size_of_water_main_mm: 0,
    mains_flow_rate_l_per_min: 0,
    mains_pressure_bar: 0,
    intermittent_heating_oversize_factor_percentage: 10, // CIBSE Design Guide 3.5.5 suggests 10 to 15%, max 20%
    air_change_year_uuid: ACH_AGES[0].key,
    air_change_per_hour_overall: 1,
    indoor_temp_overall_c: 20,
    available_space_depth_mm: 0,
    available_space_height_mm: 0,
    available_space_width_mm: 0,
    mounting_location: '',
    base_to_be_build_by: '',
    condensate_drain: '',
    drain_photos: [],
    assessment_description: '',
    sound_assessment_images: [],
    // Planning
    within_1_metre_of_boundary: false,
    listing_building: false,
    conservation_area: false,
    location_fronts_highway: false,
    location_above_ground_storey: false,
    access_notes: '',
    roof_height_m: 0,
    location_closer_to_highway: false,
    has_existing_cylinder: false,
    cylinder_photos: [],
    cylinder_available_width_mm: 0,
    cylinder_available_depth_mm: 0,
    cylinder_available_height_mm: 0,
    cylinder_location_description: '',
    pipe_run_distance_metre: 0,
    pipe_run_description: '',
    existing_cylinder_photos: [],
    existing_cylinder_volume_l: 0,
    existing_cylinder_type: '',
    existing_cylinder_coil_size: 0,
    existing_cylinder_brand_and_model: '',
    existing_cylinder_location_description: '',
    existing_cylinder_pipe_material: '',
    existing_cylinder_pipe_size_uuid: '',
    existing_system_photos: [],
    existing_system_fuel_uuid: 'mains_gas',
    existing_system_type_uuid: '',
    existing_system_age_uuid: '',
    existing_system_location: '',
    existing_system_annual_consumption_kWh: 0,
    existing_system_pipework_uuid: '',
    primary_pipework_length_m: undefined,
    existing_system_radiator_pipework_visibility: '',
    existing_system_radiator_pipework_uuid: '',
    index_includes_secondary: true,
    secondary_index_pipework_uuid: undefined,
    secondary_index_pipework_length_m: undefined,
    secondary_index_emitter_uuids: [],
    index_emitter_uuid: undefined,
    index_emitter_pipe_length_m: undefined,
    electrics_phase: '',
    electrics_main_fuse_size_amps: 0,
    electrics_looped_service: false,
    electrics_earthing_images: [],
    electrics_arrangement_uuid: '',
    electrics_consumer_unit_images: [],
    electrics_spare_way: false,
    electrics_breaker_make: '',
    electrics_notes: '',
    electrics_has_car_charger: false,
    electrics_car_charger_fuse_size_amps: 32,
    electrics_car_charger_is_load_limiting: false,
    electrics_has_battery: false,
    electrics_battery_notes: '',
    electrics_has_solar: false,
    electrics_solar_notes: '',
    electrics_solar_wants_diverter: false,
    electrics_has_hot_tub: false,
    electrics_mpan_number: '',
    electrics_has_smart_meter: false,
    electrics_peak_electricity_demand_kwh: 0,
    electrics_distance_from_board_to_heat_pump_m: 0,
    electrics_distance_from_cylinder_to_heat_pump_m: 0,
    electrics_cylinder_immersion_required: false,
    electrics_distance_from_board_to_cylinder_m: 0,
    electrics_cable_notes: '',
    electrics_consumer_unit_items: [],
    completed_sections: [],
    manifolds: [],
    custom_radiator_models: []
};
