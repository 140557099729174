import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
export const ElectricsEarthingArrangementDetailsBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    const ARRANGEMENTS = [
        { title: 'TN-C-S (PME)', description: "Connected to supplier's neutral conductor." },
        { title: 'TN-S (SNE)', description: 'Connected to the metallic sheath/armour of the distributor’s service cable.' },
        { title: 'TT (Direct)', description: 'Connected to a spike in the ground at the installation itself.' }
    ];
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Earthing arrangement"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Arrangement"),
            React.createElement(RadioGroup, { items: ARRANGEMENTS.map(x => ({
                    name: x.title,
                    description: x.description,
                    onClick: () => setSurvey({ ...survey, electrics_arrangement_uuid: x.title }),
                    variant: x.title === survey.electrics_arrangement_uuid ? 'ACTIVE' : 'DEFAULT'
                })) })));
};
