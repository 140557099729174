import React from 'react';
import { HLRSubheaderBLock } from './partials/hlr_subheader';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { HLRContext } from './heatloss_report';
import { reversePriceOrNumberFormat } from '../../../code/models/performance_estimate';
import { AGILE_PENCE_PER_KWH, OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH, PRICE_CAP_ELECTRICITY_PENCE_PER_KWH } from '../../../code/models/heating_fuel';
export const HLRPEResultsTable = () => {
    const hlrContext = React.useContext(HLRContext);
    const fuelName = hlrContext.performanceEstimateSummary.hddEstimate.existing.fuelName;
    const estimatesGroup = hlrContext.performanceEstimateSummary.estimatesGroup;
    const numberOfMethods = hlrContext.performanceEstimateSummary.numberOfMethods;
    const columnNamesInOrder = Object.keys(estimatesGroup);
    const tables = {
        Energy: [
            {
                Heating: Object.fromEntries(// package list of single key-value pairs into an object
                Object.entries(estimatesGroup).map(([key, value]) => {
                    return [key, value ? [{ text: value.existing.consumptionSpaceHeatingKwh }, { text: value.proposed.consumptionSpaceHeatingKwh }] : undefined];
                }))
            },
            {
                'Hot Water': Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.consumptionHotWaterKwh }, { text: value.proposed.consumptionHotWaterKwh }] : undefined];
                }))
            },
            {
                Total: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.kwh }, { text: value.proposed.kwh }] : undefined];
                }))
            }
        ],
        Bills: [
            {
                [`Price cap (${PRICE_CAP_ELECTRICITY_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value ? [{ text: value.existing.costPriceCap },
                            { text: value.proposed.costPriceCap, badgeText: value.savings.costPriceCap, badgeStyle: reversePriceOrNumberFormat(value.savings.costPriceCap) > 0 ? 'GREEN' : 'RED' }] : undefined];
                }))
            },
            ...(hlrContext === null || hlrContext === void 0 ? void 0 : hlrContext.eligibleForHeatPumpPlus)
                ? [{
                        [`OVO Heat Pump Plus (${OVO_HEAT_PUMP_PLUS_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                            return [[key], value ? [{ text: value.existing.costOvoHeatPumpPlus },
                                    { text: value.proposed.costOvoHeatPumpPlus, badgeText: value.savings.costOvoHeatPumpPlus, badgeStyle: reversePriceOrNumberFormat(value.savings.costOvoHeatPumpPlus) > 0 ? 'GREEN' : 'RED' }] : undefined];
                        }))
                    }] : [], // empty list if not eligible
            {
                [`Octopus Agile (${AGILE_PENCE_PER_KWH} p/kWh)`]: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [[key], value
                            ? [{ text: value.existing.costAgile }, { text: value.proposed.costAgile, badgeText: value.savings.costAgile, badgeStyle: reversePriceOrNumberFormat(value.savings.costAgile) > 0 ? 'GREEN' : 'RED' }]
                            : undefined];
                }))
            }
        ],
        Carbon: [
            {
                Totals: Object.fromEntries(Object.entries(estimatesGroup).map(([key, value]) => {
                    return [key, value
                            ? [{ text: value.existing.emissionsKG }, { text: value.proposed.emissionsKG, badgeText: value.savings.emissionsKG, badgeStyle: reversePriceOrNumberFormat(value.savings.emissionsKG) > 0 ? 'GREEN' : 'RED' }]
                            : undefined];
                }))
            }
        ]
    };
    const colContent = (colData) => {
        return React.createElement(React.Fragment, null,
            colData.badgeText !== '' && React.createElement("div", { className: 'inline-flex flex-col gap-2' },
                React.createElement("div", null, colData.text),
                React.createElement(Badge, { color: colData.badgeStyle, text: colData.badgeText })),
            colData.badgeText === '' && React.createElement("div", null, colData.text));
    };
    return (React.createElement("div", { className: 'flex gap-6 flex-col' },
        React.createElement(HLRSubheaderBLock, { section: "Your performance estimate", title: "Results table" }),
        React.createElement("div", { className: 'grid grid-cols-7' },
            React.createElement("div", { className: 'pb-2' }),
            React.createElement("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end' }, columnNamesInOrder[0]),
            React.createElement("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end' }, columnNamesInOrder[1]),
            React.createElement("div", { className: 'col-span-2 pb-2 pr-2 text-gray-900 text-xs font-semibold uppercase tracking-wide flex items-end' }, columnNamesInOrder[2]),
            Object.entries(tables).map((table, idx) => {
                const tableTitle = table[0];
                const tableRows = table[1];
                return React.createElement(React.Fragment, { key: 'table-' + idx },
                    React.createElement("div", { className: 'bg-gray-100 rounded-tl-md pl-3 pt-6 pb-3 text-gray-900 text-lg font-bold' }, tableTitle),
                    React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                        "Existing ",
                        React.createElement("br", null),
                        "(",
                        fuelName,
                        ")"),
                    React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                        "Heat pump ",
                        React.createElement("br", null),
                        "(Electricity)"),
                    numberOfMethods > 1 && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                            "Existing ",
                            React.createElement("br", null),
                            "(",
                            fuelName,
                            ")"),
                        React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                            "Heat pump ",
                            React.createElement("br", null),
                            "(Electricity)")),
                    numberOfMethods <= 1 && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2' }, "Unavailable")),
                    numberOfMethods > 2 && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'bg-gray-100 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                            "Existing ",
                            React.createElement("br", null),
                            "(",
                            fuelName,
                            ")"),
                        React.createElement("div", { className: 'bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight' },
                            "Heat pump ",
                            React.createElement("br", null),
                            "(Electricity)")),
                    numberOfMethods <= 2 && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'bg-gray-100 rounded-tr-md pr-3 pt-6 pb-3 text-gray-500 text-xs tracking-tight col-span-2' }, "Unavailable")),
                    tableRows.map((row, rowIdx) => {
                        const rowTitle = Object.keys(row)[0];
                        const rowData = Object.keys(row).map(key => row[key])[0];
                        const lastRow = rowIdx === tableRows.length - 1;
                        const padding = lastRow ? 'pb-6 pt-3' : 'py-3';
                        const firstPairCells = rowData[columnNamesInOrder[0]];
                        const secondPairCells = rowData[columnNamesInOrder[1]];
                        const thirdPairCells = rowData[columnNamesInOrder[2]];
                        return React.createElement(React.Fragment, { key: 'row-' + rowIdx },
                            React.createElement("div", { className: `${lastRow ? 'rounded-bl-md' : ''} bg-gray-100 pl-3 text-gray-900 text-xs font-bold` },
                                React.createElement("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300 pr-2` }, rowTitle)),
                            React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold` }, colContent(firstPairCells[0])),
                            React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold` }, colContent(firstPairCells[1])),
                            !secondPairCells && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold col-span-2` })),
                            secondPairCells && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold` }, colContent(secondPairCells[0])),
                                React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold` }, colContent(secondPairCells[1]))),
                            !thirdPairCells && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: `${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold col-span-2` },
                                    React.createElement("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300` }))),
                            thirdPairCells && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: `${padding} border-t border-dashed border-gray-300 bg-gray-100 text-xs font-semibold` }, colContent(thirdPairCells[0])),
                                React.createElement("div", { className: `${lastRow ? 'rounded-br-md' : ''} bg-gray-100 pr-3 text-xs font-semibold` },
                                    React.createElement("div", { className: `${padding} w-full flex border-t border-dashed border-gray-300` }, colContent(thirdPairCells[1])))));
                    }),
                    idx < Object.keys(tables).length - 1 && React.createElement("div", { className: 'col-span-7 py-3' }));
            }))));
};
