import React, { useState } from 'react';
import { validateEmail } from '../../../code/validators';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Button } from '../../../components/buttons/button';
import { Section } from '../../../components/containers/section';
import { Table } from '../../../components/content_display/table';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Input } from '../../../components/inputs_and_selections/input';
export const Invitations = ({ title, helpText, invitations, handleSendInvitation, handleRevokeInvitation }) => {
    const [invitationEmail, setInvitationEmail] = useState('');
    const sendInvitation = async () => {
        try {
            await handleSendInvitation(invitationEmail);
            setInvitationEmail('');
        }
        catch (e) {
            console.error('Error sending invitation', e);
        }
    };
    const revokeSurveyInvitation = async (uuid) => {
        await handleRevokeInvitation(uuid);
    };
    return (React.createElement(Section, { border: true, title: title },
        React.createElement("div", { className: "flex flex-col gap-4" },
            helpText && React.createElement("p", { className: "text-sm text-gray-500" }, helpText),
            React.createElement("div", { className: 'flex flex-col sm:flex-row gap-4 sm:items-start sm:justify-between' },
                React.createElement(Input, { className: 'flex-1', value: invitationEmail, setValue: setInvitationEmail, placeholder: 'Email address', validator: validateEmail, doNotValidateWhenEmpty: true }),
                React.createElement(Button, { onClick: sendInvitation, disabled: !validateEmail(invitationEmail).value }, "Send invitation")),
            invitations.length > 0 &&
                React.createElement(Table, { columns: [
                        { name: 'Email', key: 'email' },
                        { name: 'Status', render: (row) => React.createElement(Badge, { color: row.status === 'accepted' ? 'GREEN' : 'YELLOW', text: _.capitalize(row.status) }) },
                        { name: 'Actions', render: (row) => React.createElement(Button, { size: "SM", colour: "LIGHT", onClick: async () => await revokeSurveyInvitation(row.uuid), iconLeft: faBan }, "Revoke") }
                    ], rows: invitations }))));
};
