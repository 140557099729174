import React from 'react';
import { faRuler, faCube, faDoorClosed, faWindowRestore, faScissors } from '@fortawesome/free-solid-svg-icons';
import { CanvasNav } from './canvas_nav';
import { MATERIAL_ELEMENT_NAMES } from '../../../code/models/material';
export const WallButtons = ({ setPage, currentWall }) => {
    var _a;
    return React.createElement(CanvasNav, { title: (_a = MATERIAL_ELEMENT_NAMES[currentWall.material.applicable_to]) !== null && _a !== void 0 ? _a : 'Wall', items: [
            { name: 'Length', onClick: () => setPage('WALL_LENGTH', true), icon: faRuler, variant: 'DEFAULT' },
            { name: 'Materials', onClick: () => setPage('WALL_MATERIALS', true), icon: faCube, variant: 'DEFAULT' },
            { name: `${currentWall.windows.length > 0 ? currentWall.windows.length : ''} Window${currentWall.windows.length > 1 || currentWall.windows.length === 0 ? 's' : ''}`, onClick: () => setPage('WALL_WINDOWS', true), icon: faWindowRestore, variant: currentWall.windows.length > 0 ? 'BLUE' : 'DEFAULT' },
            { name: `${currentWall.doors.length > 0 ? currentWall.doors.length : ''} Door${currentWall.doors.length > 1 || currentWall.doors.length === 0 ? 's' : ''}`, onClick: () => setPage('WALL_DOORS', true), icon: faDoorClosed, variant: currentWall.doors.length > 0 ? 'BLUE' : 'DEFAULT' },
            { name: 'Remove', onClick: () => setPage('REMOVE_WALL', true), icon: faScissors, disabled: !currentWall.other_room_uuid, variant: 'DEFAULT' }
        ] });
};
