export const getWallUValue = (wall) => {
    var _a, _b, _c, _d, _e;
    if (['custom_layered', 'custom_simple'].includes(wall.material.type)) {
        return ((_a = wall.material) === null || _a === void 0 ? void 0 : _a.extra_data).u_value;
    }
    switch ((_b = wall.material) === null || _b === void 0 ? void 0 : _b.applicable_to) {
        case 'internal-wall':
            return ((_c = wall.material) === null || _c === void 0 ? void 0 : _c.extra_data).u_value;
        case 'external-wall':
            return ((_d = wall.material) === null || _d === void 0 ? void 0 : _d.extra_data).u_value;
        case 'party-wall':
            return ((_e = wall.material) === null || _e === void 0 ? void 0 : _e.extra_data).u_value;
        default:
            return 0;
    }
};
