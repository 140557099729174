import React from 'react';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { ClickableCard } from '../../../../components/content_display/card';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const HPLPlanningConsiderationsBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { survey, setSurvey } = hplContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Planning considerations"),
        React.createElement("div", { className: "gap-3 flex justify-between" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Within 1 metre of boundary"),
            React.createElement(Toggle, { value: survey.within_1_metre_of_boundary, setValue: () => setSurvey({
                    ...survey,
                    within_1_metre_of_boundary: !survey.within_1_metre_of_boundary
                }) })),
        React.createElement("div", { className: "gap-3 flex justify-between" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Listed building"),
            React.createElement(Toggle, { value: survey.listing_building, setValue: () => setSurvey({ ...survey, listing_building: !survey.listing_building }) })),
        React.createElement(ClickableCard, { variant: 'GREY', className: 'gap-6' },
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Conservation area"),
                React.createElement(Toggle, { value: survey.conservation_area, setValue: () => setSurvey({ ...survey, conservation_area: !survey.conservation_area }) })),
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Location fronts a highway"),
                React.createElement(Toggle, { value: survey.location_fronts_highway, setValue: () => setSurvey({ ...survey, location_fronts_highway: !survey.location_fronts_highway }) })),
            !survey.conservation_area && React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Location is above ground storey"),
                React.createElement(Toggle, { value: survey.location_above_ground_storey, setValue: () => setSurvey({
                        ...survey,
                        location_above_ground_storey: !survey.location_above_ground_storey
                    }) })),
            survey.conservation_area && React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Location is closer to highway than building"),
                React.createElement(Toggle, { value: survey.location_closer_to_highway, setValue: () => setSurvey({
                        ...survey,
                        location_closer_to_highway: !survey.location_closer_to_highway
                    }) }))));
};
