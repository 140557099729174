import React from 'react';
export const HLRIntroductionPage = () => {
    return (React.createElement("div", { className: "flex-col justify-start items-start gap-16 flex" },
        React.createElement("div", { className: "flex-col justify-start items-start gap-9 flex" },
            React.createElement("div", { className: "flex-col justify-start items-start gap-5 flex p-9 bg-gray-100" },
                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, "Heat loss report"),
                React.createElement("div", { className: "text-lg" }, "This section presents the results of our detailed heat loss calculations for your home. The overall heat loss determines how big your heat pump needs to be, and the room-by-room breakdown allows us to size the emitters (radiators and underfloor heating) correctly to keep each room warm on a cold day.")),
            React.createElement("div", { className: 'flex flex-col gap-6' },
                React.createElement("div", { className: "text-gray-900 text-3xl font-bold" }, "Introduction"),
                React.createElement("div", { className: "" }, "How quickly your home loses heat depends on how big your home is, the materials it is made from, and how air tight it is. Our detailed survey captured all this information and we have laid it out in this report so you can understand how and why we produce our recommendations. There are several key questions that we\u2019re looking to answer as we go through this process for you:"),
                React.createElement("div", { className: "flex flex-col gap-2" },
                    React.createElement("div", { className: "text-lg font-bold" }, "Are the heat pump and radiators big enough?"),
                    React.createElement("div", { className: "" }, "Your heat pump needs to keep you warm on cold days, and the room-by-room calculations allow us to ensure that your new system will do this. To keep you warm, the heat pump needs to deliver heat to your heat emitters (by which we just mean radiators and underfloor heating) as quickly as your home loses it, and in turn the emitters in every room need to deliver that heat to the room as quickly as that room loses it."),
                    React.createElement("div", { className: "" }, "We cannot rely on the fact that your existing radiators keep your home warm with your existing heating system, because heat pumps typically work at a much lower flow temperature than fossil-fuel boilers."),
                    React.createElement("div", { className: "" }, "Whilst heat pumps are capable of making very hot water, they work most efficiently when generating low-temperature heat, typically between 35-50C. Gas boilers have traditionally been set up to generate high-temperature heat, with water temperatures of 70-80C (although they also work more efficiently at lower temperatures!)."),
                    React.createElement("div", { className: "" }, "Lower flow temperature, while more efficient, does mean the heat output from each radiator will be reduced. We use the heat loss survey to calculate how that reduced output compares to the room\u2019s demand for heat and then determine which (if any) radiators need replacing. In this way we ensure your new system will run efficiently with a low flow temperature, while still keeping you warm on cold days.")),
                React.createElement("div", { className: "flex flex-col gap-2" },
                    React.createElement("div", { className: "text-lg font-bold" }, "Is the heat pump too big?"),
                    React.createElement("div", { className: "" }, "This strikes many as a funny question, but we do not want to \u201Coversize\u201D your heat pump. While we design your heating system to ensure it can keep you warm on cold days, most of the time it isn\u2019t that cold! On milder days, your house will lose heat more slowly and so the heat pump will in turn need to provide less heat, more slowly."),
                    React.createElement("div", { className: "" }, "Heat pumps have to be sized quite precisely, as opposed to the majority of gas or oil boilers which are typically far more powerful than is actually needed for the property. If heat pumps turn on and off all the time, it can be really inefficient and unnecessarily increase your energy bills. So we want to choose a model that can keep you warm on a cold day, but \u201Cmodulate down\u201D to keep running efficiently on milder days.")),
                React.createElement("div", { className: "flex flex-col gap-2" },
                    React.createElement("div", { className: "text-lg font-bold" }),
                    React.createElement("div", { className: "" }, "We hope this all makes sense! Please do give the report a thorough read through and let us know if you have any questions \u2013 we\u2019re more than happy to explain what bits mean in more detail if you would like!"),
                    React.createElement("div", { className: "" }, "(All calculations have been done in compliance with BS EN12831 (UK National Annex) and comply with the standards laid out in the Microgeneration Certification Scheme.)"))))));
};
