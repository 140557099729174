import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import { logEvent } from '../../../code/log_event';
import { useRouteLoaderData } from 'react-router-dom';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { Caption } from '../components/caption_1';
import { StepHeader } from '../components/steps_header';
import { SubHeader } from '../components/sub_header';
import { SurveyCheckbox } from '../components/survey_checkbox';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
export const EnquiryReasonStep = () => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [heatPumpReasons, setHeatPumpReasons] = useState([]);
    const [whenToInstall, setWhenToInstall] = useState('');
    const [comments, setComments] = useState('');
    const handleNavigatePreviousPage = () => {
        dispatch({
            type: SurveyActionType.NavigatePreviousPage
        });
    };
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Enquiry reasoning step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                heatPumpReason: heatPumpReasons.join(', '),
                notes: comments,
                whenHeatPump: whenToInstall
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { colour: 'LIGHT', onClick: handleNavigatePreviousPage, block: false, className: "self-start" },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement(ArrowLeftIcon, { className: "h-4 w-4" }),
                React.createElement("div", null, "Back"))),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "Your enquiry" }),
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("div", { className: "flex flex-col gap-1" },
                React.createElement(SubHeader, { content: "Why do you want a heat pump (optional)?" }),
                React.createElement(Caption, { content: "Select all that apply." })),
            React.createElement(SurveyCheckbox, { label: "Save money", key: "save-money", onSelect: () => setHeatPumpReasons(prev => prev.includes('save-money') ? prev.filter(x => x !== 'save-money') : [...prev, 'save-money']), selected: heatPumpReasons.includes('save-money') }),
            React.createElement(SurveyCheckbox, { label: "Go green", key: "go-green", onSelect: (v) => setHeatPumpReasons(prev => prev.includes('go-green') ? prev.filter(x => x !== 'go-green') : [...prev, 'go-green']), selected: heatPumpReasons.includes('go-green') }),
            React.createElement(SurveyCheckbox, { label: "Better comfort", key: "better-comfort", onSelect: (v) => setHeatPumpReasons(prev => prev.includes('better-comfort') ? prev.filter(x => x !== 'better-comfort') : [...prev, 'better-comfort']), selected: heatPumpReasons.includes('better-comfort') })),
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement(SubHeader, { content: "When do you want the heat pump (optional)?" }),
            React.createElement(SurveyOptionGroup, { options: [
                    { label: 'As soon as possible', key: 'asap' },
                    { label: 'Within 6 months', key: '6mo' },
                    { label: 'In the next 1-2 years', key: '1-2y' },
                    { label: 'I’m unsure', key: 'unsure' }
                ], onSelect: setWhenToInstall, selectedOptionKey: whenToInstall })),
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement(SubHeader, { content: "Any final information we should know (optional)?" }),
            React.createElement(Input, { size: 'LG', setValue: setComments, type: "text", value: comments, placeholder: 'E.g. I have a mix of double and single glazing' })),
        React.createElement(Button, { size: 'LG', onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
