import { updateCompanySettings } from '../../../code/models/company';
import { Section } from '../../../components/containers/section';
import React from 'react';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
export const MiscControlsBlock = ({ company, setCompany }) => {
    var _a;
    return React.createElement(Section, { border: true, title: "Other settings" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'flex flex-row gap-3 items-center' },
                React.createElement(Toggle, { value: company.public_info.show_line_item_costs_on_estimate, setValue: async () => {
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                show_line_item_costs_on_estimate: !company.public_info.show_line_item_costs_on_estimate
                            }
                        };
                        setCompany(newCompany);
                        await updateCompanySettings(newCompany);
                    } }),
                React.createElement("div", null, "Show the cost of each line item on the estimate")),
            React.createElement("div", { className: 'flex flex-row gap-2 items-center' },
                React.createElement(Toggle, { value: (_a = company.public_info.no_survey_booking) !== null && _a !== void 0 ? _a : false, setValue: async () => {
                        const newCompany = {
                            ...company,
                            public_info: { ...company.public_info, no_survey_booking: !company.public_info.no_survey_booking }
                        };
                        setCompany(newCompany);
                        await updateCompanySettings(newCompany);
                    } }),
                React.createElement("div", null,
                    "Disable ",
                    React.createElement("span", { className: "font-semibold" }, "\u201CBook a survey\u201D"),
                    " option on estimate"))));
};
