import { generateComplexRoom } from '../../add_room';
import { calculateLineLength, calculateNewTouchingWalls } from './utils';
import { trimAndFixAngleAndMagnetise } from './stage_on_mouse_move';
import booleanClockwise from '@turf/boolean-clockwise';
import { stepSize } from '../../constants';
import { getFloorAreaM2 } from '../../../../code/models/heat_loss';
export const stageOnClick = (e, drawingRooms, mousePosition, scalingWindow, scalingPoints, floor, survey, stageStep, showUploadWidthPage, setScalingPoints, setCurrentRoomId, setStageStep, setPage, setCurrentWallId, setDrawingRooms, setMousePosition, addEvent, setScalingWindow, setWindow) => {
    // Mouse position is updated on hover (onMouseMove), for mobile devices this is the first time we see mouse so get pointer position.
    const newPoint = mousePosition !== null && mousePosition !== void 0 ? mousePosition : trimAndFixAngleAndMagnetise(scalingPoints, e.currentTarget.getRelativePointerPosition(), []);
    // If I am drawing a room either create a point, or I have completed my shape so create a new room.
    if (drawingRooms) {
        if (!newPoint)
            return;
        // If the shape is greater than a line (points > 2) we can form a shape.
        // Check if the last point I made is equal to the first point I made, if so I have a closed room and can finish the room drawing.
        // Otherwise add a new point to the canvas and continue drawing.
        if (scalingPoints.length > 2 && newPoint.x === scalingPoints[0].x && newPoint.y === scalingPoints[0].y) {
            const newRoom = generateComplexRoom(floor, survey, scalingPoints, floor.default_room_height);
            const wallsToXY = newRoom.walls.map(x => [x.x, x.y]);
            // Don't let invalid room shapes get created.
            const area = getFloorAreaM2(newRoom.walls, stepSize);
            if (area <= 0)
                return;
            // Make sure the room is always drawn counter clockwise, turf requires a closed shape to repeat its first element.
            // Because the canvas north is negative and south is positive (reverse of cartesian coordinates) turfs clockwise is actually counter clockwise.
            const newRoomFixRotation = booleanClockwise([...wallsToXY, wallsToXY[0]])
                ? newRoom
                : { ...newRoom, walls: newRoom.walls.reverse() };
            const newRooms = calculateNewTouchingWalls([...floor.rooms, newRoomFixRotation], survey.default_materials);
            setDrawingRooms(false);
            setScalingPoints([]);
            setMousePosition(undefined);
            addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: floor, newValue: { ...floor, rooms: newRooms } }]);
            setCurrentRoomId(newRoom.uuid);
            setPage('ROOM_DETAILS');
        }
        else {
            setScalingPoints(prev => [...prev, newPoint]);
        }
        return;
    }
    if (scalingWindow && newPoint && scalingPoints.length < 2) {
        if (scalingPoints.length < 1) {
            setScalingPoints(prev => [...prev, newPoint]);
        }
        else {
            const lineLengthMM = calculateLineLength(scalingPoints[0].x, scalingPoints[0].y, newPoint.x, newPoint.y, stepSize) * 1000;
            setScalingWindow(false);
            setMousePosition(undefined);
            setScalingPoints([]);
            setWindow(prev => ({ ...prev, width_mm: lineLengthMM }));
        }
        return;
    }
    // If I am trying to scale a floor plan not draw a room.
    if (stageStep && newPoint && scalingPoints.length < 2 && !showUploadWidthPage) {
        setScalingPoints(prev => [...prev, newPoint]);
        setStageStep(prev => prev + 1);
        return;
    }
    // If I have clicked the stage and not another element I want to remove my room or wall selection.
    if (e.target === e.currentTarget) {
        setCurrentRoomId(undefined);
        setCurrentWallId(undefined);
        setPage('FLOOR_DETAILS');
    }
};
