import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { faBatteryFull, faBoltLightning, faChevronRight, faDatabase, faHome, faHouse, faSyncAlt, faWrench } from '@fortawesome/free-solid-svg-icons';
import { isPreFlowFeatureSurvey } from '../../../code/models/property';
import { DesignPageHeader } from '../design/components/design_page_header';
import { sum } from 'lodash';
import { FloorAttributesBlock } from '../floor/floor_attributes_block';
import { area } from '../floor/code/utils';
import { Badge } from '../../../components/indicators_and_messaging/badge';
export const Survey = ({ lead, surveyTab, setSurveyTab, survey, setSurvey, stepSize, setCurrentFloorId, setPage }) => {
    var _a;
    if (surveyTab === 'NEW_FLOOR') {
        return React.createElement(NewFloor, { showHeader: false, setSurveyTab: setSurveyTab, setSurvey: setSurvey, setCurrentFloorId: setCurrentFloorId, floors: survey.floors });
    }
    const totalArea = Math.round(sum(survey.floors.flatMap(x => x.rooms.flatMap(y => area(stepSize, y.walls.map(z => ({ x: z.x, y: z.y })))))));
    const estimatedArea = (_a = lead === null || lead === void 0 ? void 0 : lead.property.floorArea) !== null && _a !== void 0 ? _a : 0;
    const areaPercentage = Math.round((totalArea / estimatedArea) * 100);
    const siteDetailsCompleted = survey.completed_sections.includes('PROPERTY');
    const existingHeatingSystemCompleted = survey.completed_sections.includes('EXISTING_HEATING');
    const heatPumpCompleted = survey.completed_sections.includes('HEAT_PUMP');
    const cylinderCompleted = survey.completed_sections.includes('CYLINDER');
    const electricsCompleted = survey.completed_sections.includes('ELECTRICS');
    const pipeworkCompleted = survey.completed_sections.includes('PIPEWORK');
    const settingsCompleted = survey.completed_sections.includes('SETTINGS');
    const materialsCompleted = survey.completed_sections.includes('MATERIALS');
    const floorplanCompleted = survey.completed_sections.includes('FLOORPLAN');
    const useFloorplanFlow = survey.flags_floorplan_pages_completed === 0 &&
        (!settingsCompleted || !materialsCompleted) &&
        !isPreFlowFeatureSurvey(survey);
    return React.createElement("div", { className: 'flex flex-col h-full bg-gray-100' },
        React.createElement(DesignPageHeader, { title: 'Survey', onBack: () => setPage(undefined) }),
        React.createElement("div", { className: 'flex flex-col p-5 gap-4 overflow-y-auto' },
            React.createElement("div", { className: 'text-xl font-bold text-gray-900' }, "Site survey"),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Site details"),
                    React.createElement(Badge, { color: siteDetailsCompleted ? 'GREEN' : 'LIGHT', text: siteDetailsCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faHome, onClick: () => setSurveyTab('PROPERTY'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Existing heating system"),
                    React.createElement(Badge, { color: existingHeatingSystemCompleted ? 'GREEN' : 'LIGHT', text: existingHeatingSystemCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faBatteryFull, onClick: () => setSurveyTab('EXISTING_HEATING'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Heat pump location"),
                    React.createElement(Badge, { color: heatPumpCompleted ? 'GREEN' : 'LIGHT', text: heatPumpCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faSyncAlt, onClick: () => setSurveyTab('HEAT_PUMP'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faDatabase, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Cylinder location"),
                    React.createElement(Badge, { color: cylinderCompleted ? 'GREEN' : 'LIGHT', text: cylinderCompleted ? 'Complete' : 'Incomplete' })), onClick: () => setSurveyTab('CYLINDER'), submitIcon: faChevronRight }),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faBoltLightning, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Electrics"),
                    React.createElement(Badge, { color: electricsCompleted ? 'GREEN' : 'LIGHT', text: electricsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => setSurveyTab('ELECTRICS'), submitIcon: faChevronRight }),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: 'text-xl font-bold text-gray-900' }, "Heat loss")),
            useFloorplanFlow &&
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Start floor plan"),
                        React.createElement(Badge, { color: 'LIGHT', text: 'Incomplete' })), onClick: () => setSurveyTab('FLOORPLAN_FLOW') }),
            !useFloorplanFlow && React.createElement(React.Fragment, null,
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Settings"),
                        React.createElement(Badge, { color: settingsCompleted ? 'GREEN' : 'LIGHT', text: settingsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => setSurveyTab('SETTINGS') }),
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, className: 'flex-1', title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Materials"),
                        React.createElement(Badge, { color: materialsCompleted ? 'GREEN' : 'LIGHT', text: materialsCompleted ? 'Complete' : 'Incomplete' })), onClick: () => setSurveyTab('MATERIALS') }),
                React.createElement(ClickableCard, { variant: 'WHITE', border: false, onClick: () => {
                        survey.floors.length === 0 ? setSurveyTab('NEW_FLOOR') : setSurveyTab('FLOOR_LIST');
                    }, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Floorplan"),
                        React.createElement(Badge, { color: floorplanCompleted ? 'GREEN' : 'LIGHT', text: floorplanCompleted ? 'Complete' : 'Incomplete' })), titleIcon: faHouse, submitIcon: faChevronRight },
                    React.createElement("div", { className: 'flex gap-4' },
                        React.createElement("div", null,
                            React.createElement("div", { className: 'text-sm text-gray-600' }, "Estimated area"),
                            React.createElement("div", { className: 'text-gray-900 font-bold' }, lead === null || lead === void 0 ? void 0 :
                                lead.property.floorArea,
                                " m\u00B2")),
                        React.createElement("div", null,
                            React.createElement("div", { className: 'text-sm text-gray-600' }, "Measured area"),
                            React.createElement("div", { className: 'text-gray-900 font-bold' },
                                totalArea,
                                " m\u00B2 ",
                                React.createElement("span", { className: 'text-gray-600 font-medium' },
                                    "(",
                                    areaPercentage,
                                    "%)")))))),
            React.createElement(ClickableCard, { variant: 'WHITE', border: false, titleIcon: faWrench, title: React.createElement("div", { className: 'flex flex-grow justify-between' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Pipework"),
                    React.createElement(Badge, { color: pipeworkCompleted ? 'GREEN' : 'LIGHT', text: pipeworkCompleted ? 'Complete' : 'Incomplete' })), onClick: () => setSurveyTab('PIPEWORK'), submitIcon: faChevronRight })));
};
export const FLOOR_NAMES = ['Ground floor', 'First floor', 'Second floor', 'Third floor'];
export const NewFloor = ({ setSurveyTab, setSurvey, setCurrentFloorId, floors, showHeader = true }) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col h-full' },
            showHeader && React.createElement(DesignPageHeader, { title: 'Add floor', onBack: () => setSurveyTab('FLOOR_LIST') }),
            React.createElement("div", { className: 'p-6' },
                React.createElement(FloorAttributesBlock, { floors: floors, onSave: (newFloor) => {
                        setSurvey(prev => ({ ...prev, floors: [...floors, newFloor] }));
                        setCurrentFloorId(newFloor.uuid);
                    } }))));
};
