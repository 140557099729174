import React from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { getSiteDetailsChecks } from './validations';
import { SiteDetailsLocationBlock } from './site_details/site_details_location_block';
import { SiteDetailsPhotosBlock } from './site_details/site_details_photos_block';
import { SiteDetailsTypeBlock } from './site_details/site_details_type_block';
import { SiteDetailsParkingBlock } from './site_details/site_details_parking_block';
import { SiteDetailsMainsWaterPhotosBlock } from './site_details/site_details_mains_water_photos_block';
import { SiteDetailsMainsWaterDetailsBlock } from './site_details/site_details_mains_water_details_block';
export const SurveySiteDetailsContext = React.createContext(undefined);
export const SiteDetails = (props) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x001, pbValue: 15, page: React.createElement(SiteDetailsLocationBlock, null) },
        { flagBit: 0x002, pbValue: 30, page: React.createElement(SiteDetailsPhotosBlock, null) },
        { flagBit: 0x004, pbValue: 45, page: React.createElement(SiteDetailsTypeBlock, null) },
        { flagBit: 0x008, pbValue: 60, page: React.createElement(SiteDetailsParkingBlock, null) },
        { flagBit: 0x010, pbValue: 75, page: React.createElement(SiteDetailsMainsWaterPhotosBlock, null) },
        { flagBit: 0x020, pbValue: 100, page: React.createElement(SiteDetailsMainsWaterDetailsBlock, null) }
    ];
    return React.createElement(SurveySiteDetailsContext.Provider, { value: props },
        React.createElement(SurveySectionFlowWrapper, { completeSectionID: 'PROPERTY', sectionUIName: 'Site settings', pages: pages, flags_attribute_name: 'flags_site_details_pages_completed', onPageComplete: (flagBit) => {
                props.setSurvey({ ...props.survey, flags_site_details_pages_completed: props.survey.flags_site_details_pages_completed | flagBit });
            }, survey: props.survey, setSurvey: props.setSurvey, setSurveyTab: props.setSurveyTab, completionChecks: getSiteDetailsChecks(props.survey) }));
};
