import { uniq, noop } from 'lodash';
import React, { useState } from 'react';
import { Group, Line, Text } from 'react-konva';
import { stepSize } from '../constants';
import { gray900, indigo600 } from './code/constants';
import { simplifyWalls, calculateNewTouchingWalls, removeInvalidRoomGroups, findMagnetiseXandY } from './code/utils';
import { LineWithText } from './line_with_text';
export const RoomDrawing = ({ wallsWithoutDupes, room, floor, setFloor, dragStopped, currentWall, stageScale, setStageScale, page, setPage, setCurrentWallId, setWalls, currentRoomId, currentRoom, setCurrentRoomId, fontSize, fontSizeSmall, defaultMaterials, intersectingShapes, addEvent, touches, isDrawing }) => {
    var _a;
    const otherRooms = floor.rooms.filter(x => x.uuid !== room.uuid);
    const otherRoomsX = uniq(otherRooms.flatMap(x => x.walls.flatMap(y => y.x + x.x)));
    const otherRoomsY = uniq(otherRooms.flatMap(x => x.walls.flatMap(y => y.y + x.y)));
    // Drag move updates the floor value each time, so onDragStart we store the old floor so we can fully revert back for undo.
    const [oldFloor, setOldFloor] = useState();
    const onDragEnd = () => {
        // simplify rooms, if we split a wall and then drag the shape away again, we need to simplify any splits.
        if (intersectingShapes.length === 0) {
            for (const room of floor.rooms) {
                room.walls = simplifyWalls(room.walls);
            }
            const newRooms = calculateNewTouchingWalls(floor.rooms, defaultMaterials);
            const removedInvalidRoomGroups = removeInvalidRoomGroups(newRooms);
            const events = [{ type: 'FLOOR', action: 'UPDATE', oldValue: oldFloor, newValue: { ...floor, rooms: removedInvalidRoomGroups } }];
            addEvent(events);
        }
        else {
            addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: oldFloor, newValue: floor }]);
        }
    };
    const onDragMove = (e) => onDragInner(e);
    const onDragInner = (e) => {
        const pos = e.currentTarget.position();
        e.currentTarget.position(({ x: room.x, y: room.y }));
        if (dragStopped)
            return;
        const x = Math.round(pos.x / stepSize) * stepSize;
        const y = Math.round(pos.y / stepSize) * stepSize;
        const mag = findMagnetiseXandY(x, y, room, otherRoomsX, otherRoomsY);
        const newRooms = floor.rooms.map(r => r.uuid === room.uuid ? { ...r, x: mag.newX, y: mag.newY } : r);
        setFloor({ ...floor, rooms: newRooms });
    };
    const isCurrentRoom = room.uuid === currentRoomId;
    const onClick = (e) => {
        setCurrentRoomId(room.uuid);
        setCurrentWallId('');
        setPage('ROOM_DETAILS');
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Group, { opacity: isCurrentRoom ? 1 : 0.5, onTap: isDrawing ? noop : onClick, onClick: isDrawing ? noop : onClick },
            React.createElement(Line, { points: [...(_a = room.walls) === null || _a === void 0 ? void 0 : _a.map(x => [x.x, x.y]).flat()], fill: 'white', draggable: isCurrentRoom && touches < 2, onDragStart: () => setOldFloor(floor), onDragEnd: onDragEnd, onDragMove: onDragMove, x: room.x, y: room.y, closed: true }),
            !isCurrentRoom && React.createElement(Text, { x: room.x + room.centerXWithTextLength, y: room.y + room.centerY - fontSize - 2, fontStyle: 'bold', fontFamily: 'Manrope', fill: gray900, fontSize: fontSize, text: room.text, verticalAlign: 'middle', align: "center" }),
            !isCurrentRoom && React.createElement(Text, { x: room.x + room.centerXWithAreaLength, y: room.y + room.centerY, fontFamily: 'Manrope', fill: indigo600, fontSize: fontSizeSmall, text: room.roomText, verticalAlign: 'middle', align: "center" })),
        room.walls.map((x, i) => {
            if (currentRoomId && x.other_room_uuid === currentRoomId)
                return null;
            if (x.other_room_uuid && !(wallsWithoutDupes === null || wallsWithoutDupes === void 0 ? void 0 : wallsWithoutDupes.some(y => y.uuid === x.uuid)) && !isCurrentRoom)
                return null;
            const otherRoom = floor.rooms.find(y => y.uuid === x.other_room_uuid);
            if (room.room_group_uuid !== undefined && room.room_group_uuid === (otherRoom === null || otherRoom === void 0 ? void 0 : otherRoom.room_group_uuid))
                return null;
            const innerText = [
                x.windows.length > 0 ? `${x.windows.length} W` : '',
                x.doors.length > 0 ? `${x.doors.length} D` : ''
            ].filter(x => x.length > 0).join(', ');
            return React.createElement(LineWithText, { onDragStart: () => setOldFloor(floor), otherRoomId: x.other_room_uuid, onDragEnd: onDragEnd, draggable: isCurrentRoom && touches < 2 && (currentWall === null || currentWall === void 0 ? void 0 : currentWall.uuid) === x.uuid && !dragStopped, isCurrentWall: (currentWall === null || currentWall === void 0 ? void 0 : currentWall.uuid) === x.uuid, scale: { x: stageScale, y: stageScale }, innerText: innerText, key: i, onClick: isCurrentRoom && !isDrawing ? () => { setCurrentWallId(x.uuid); setPage('WALL_MATERIALS'); } : noop, x: room.x, y: room.y, wallIndex: i, walls: room.walls, setWalls: setWalls, setCurrentWallId: setCurrentWallId, setPage: setPage, isCurrentRoom: isCurrentRoom, rooms: floor.rooms, room: currentRoom });
        }));
};
