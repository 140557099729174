import { orderBy } from 'lodash';
// Take a single wall and return a new array of 1 or more walls based on what other walls it is touching.
export const createNewTouchingWalls = (wallA, otherWalls, defaults) => {
    // To compare values correctly we need to rotate all lines to be on the same rotation (not rotated e.g. 180 degrees).
    const otherWallsFixRotation = otherWalls.map(w => {
        let tempX1 = 0;
        let tempY1 = 0;
        if (w.x1 > w.x2) {
            tempX1 = w.x1;
            w.x1 = w.x2;
            w.x2 = tempX1;
        }
        if (w.y1 > w.y2) {
            tempY1 = w.y1;
            w.y1 = w.y2;
            w.y2 = tempY1;
        }
        return w;
    });
    // If the line is horizontal
    if (wallA.x1 === wallA.x2) {
        let y1 = wallA.y1;
        let y2 = wallA.y2;
        if (wallA.y2 < wallA.y1) {
            y1 = wallA.y2;
            y2 = wallA.y1;
        }
        const matchingOtherWalls = orderBy(otherWallsFixRotation
            .filter(w => w.x1 === w.x2 && wallA.x1 === w.x1 && ((w.y1 < y2 && w.y2 >= y2) ||
            (w.y1 <= y1 && w.y2 > y1) ||
            (w.y1 >= y1 && w.y2 <= y2))), x => x.y1);
        if (matchingOtherWalls.length === 0)
            return [{ ...wallA, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material }];
        // orderBy Y axis, fill in missing spots, return wall as new set
        const newWalls = [];
        let startY = y1;
        matchingOtherWalls.map((x, i) => {
            if (startY >= x.y1) {
                newWalls.push({ uuid: crypto.randomUUID(), x1: wallA.x1, y1: startY, x2: wallA.x2, y2: Math.min(y2, x.y2), other_room_uuid: x.room_uuid, room_uuid: wallA.room_uuid, material: defaults.internalWall });
            }
            else {
                newWalls.push({ uuid: crypto.randomUUID(), x1: wallA.x1, y1: startY, x2: wallA.x2, y2: x.y1, room_uuid: wallA.room_uuid, other_room_uuid: undefined, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material });
                newWalls.push({ uuid: crypto.randomUUID(), x1: wallA.x1, y1: x.y1, x2: wallA.x2, y2: Math.min(y2, x.y2), room_uuid: wallA.room_uuid, other_room_uuid: x.room_uuid, material: defaults.internalWall });
            }
            startY = newWalls[newWalls.length - 1].y2;
        });
        if (newWalls[newWalls.length - 1].y2 !== y2)
            newWalls.push({ uuid: crypto.randomUUID(), x1: wallA.x1, y1: startY, x2: wallA.x2, y2, room_uuid: wallA.room_uuid, other_room_uuid: undefined, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material });
        return wallA.y2 < wallA.y1 ? newWalls.reverse().map(x => ({ uuid: x.uuid, x1: x.x1, x2: x.x2, y1: x.y2, y2: x.y1, other_room_uuid: x.other_room_uuid, room_uuid: x.room_uuid, material: x.material })) : newWalls;
    }
    // If the line is vertical
    if (wallA.y1 === wallA.y2) {
        let x1 = wallA.x1;
        let x2 = wallA.x2;
        if (wallA.x2 < wallA.x1) {
            x1 = wallA.x2;
            x2 = wallA.x1;
        }
        const matchingOtherWalls = orderBy(otherWallsFixRotation
            .filter(w => w.y1 === w.y2 && wallA.y1 === w.y1 && ((w.x1 < x2 && w.x2 >= x2) ||
            (w.x1 <= x1 && w.x2 > x1) ||
            (w.x1 >= x1 && w.x2 <= x2))), x => x.x1);
        if (matchingOtherWalls.length === 0)
            return [{ ...wallA, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material }];
        // Order by X axis, fill in missing spots, return wall as new set
        const newWalls = [];
        let startX = x1;
        matchingOtherWalls.map((x, i) => {
            if (startX >= x.x1) {
                newWalls.push({ uuid: crypto.randomUUID(), x1: startX, y1: wallA.y1, x2: Math.min(x2, x.x2), y2: wallA.y2, other_room_uuid: x.room_uuid, room_uuid: wallA.room_uuid, material: defaults.internalWall });
            }
            else {
                newWalls.push({ uuid: crypto.randomUUID(), x1: startX, y1: wallA.y1, x2: x.x1, y2: wallA.y2, room_uuid: wallA.room_uuid, other_room_uuid: undefined, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material });
                newWalls.push({ uuid: crypto.randomUUID(), x1: x.x1, y1: wallA.y1, x2: Math.min(x2, x.x2), y2: wallA.y2, room_uuid: wallA.room_uuid, other_room_uuid: x.room_uuid, material: defaults.internalWall });
            }
            startX = newWalls[newWalls.length - 1].x2;
        });
        if (newWalls[newWalls.length - 1].x2 !== x2)
            newWalls.push({ uuid: crypto.randomUUID(), x1: startX, y1: wallA.y1, x2, y2: wallA.y2, room_uuid: wallA.room_uuid, other_room_uuid: undefined, material: wallA.material.applicable_to === 'internal-wall' ? defaults.externalWall : wallA.material });
        return wallA.x2 < wallA.x1 ? newWalls.reverse().map(x => ({ uuid: x.uuid, x1: x.x2, x2: x.x1, y1: x.y1, y2: x.y2, other_room_uuid: x.other_room_uuid, room_uuid: x.room_uuid, material: x.material })) : newWalls;
    }
    return [wallA];
};
