import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report';
import { HOT_WATER_FLOW_TEMP_DELTA_T_C } from '../../../code/models/hot_water_cylinder';
export const HLRPESystemEfficiency1 = () => {
    var _a, _b;
    const hlrContext = React.useContext(HLRContext);
    const heatPump = hlrContext.currentHeatPump;
    const hddPerformanceEstimate = hlrContext.performanceEstimateSummary.hddEstimate;
    return (React.createElement("div", { className: 'flex gap-6 flex-col' },
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "System efficiency"),
            React.createElement("div", { className: "" }, "The efficiency of a heating system describes how much useful output (heat) you get per unit of what you pay for (electricity/gas/oil). A system\u2019s efficiency will often be different when it is doing space heating vs. when it\u2019s making hot water because the temperature that the system is heating the water to is often hotter in water heating cycles. We have used the following inputs when modelling your system.")),
        React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Existing heating system"),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Existing heating system fuel:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, hddPerformanceEstimate.existing.fuelName)),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Hot water heated by:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, hddPerformanceEstimate.existing.fuelName)),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Efficiency - space heating:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hddPerformanceEstimate.existing.efficiencySpaceHeating * 100,
                                "%")),
                        React.createElement("div", { className: "py-2 items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Efficiency - hot water:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hddPerformanceEstimate.existing.efficiencySpaceHeating * 100,
                                "%"))))),
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Heat pump system"),
                    React.createElement("div", null,
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Type of system:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, "Air source heat pump")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Model:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, heatPump.name)),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "MCS certificate numbers:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" }, (_b = (_a = heatPump.range_heat_pump) === null || _a === void 0 ? void 0 : _a.mcs_certificate_numbers) === null || _b === void 0 ? void 0 : _b.join(', '))),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Efficiency - space heating :"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hddPerformanceEstimate.proposed.efficiencySpaceHeating * 100,
                                "%")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Flow temperature - space heating:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hlrContext.design.flow_temp,
                                "\u00B0C")),
                        React.createElement("div", { className: "py-2 border-b border-gray-200 border-dashed items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Efficiency - hot water:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hddPerformanceEstimate.proposed.efficiencyHotWater * 100,
                                "%")),
                        React.createElement("div", { className: "py-2 items-baseline gap-2 flex justify-between" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-semibold" }, "Flow temperature - hot water:"),
                            React.createElement("div", { className: "text-xs font-semibold text-nowrap" },
                                hlrContext.design.hot_water_storage_temperature_c + HOT_WATER_FLOW_TEMP_DELTA_T_C,
                                "\u00B0C")))))),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Energy prices"),
            React.createElement("div", { className: "" }, "As the last few years have shown, energy prices are very hard to predict. In modelling your bills we have used the current energy price cap and also shown you a few different heat pump tariff options."),
            React.createElement("div", { className: "" },
                "There are an increasing number of heat pump tariffs that give you access to cheaper electricity. These fall into two categories: ",
                React.createElement("span", { className: 'font-bold' }, "type of use"),
                " and ",
                React.createElement("span", { className: 'font-bold' }, "time of use"),
                "."),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: 'font-bold' }, "Type of use"),
                " heat pump tariffs detect how much electricity your heat pump is using, and charge you a lower rate for that electricity."),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: 'font-bold' }, "Time of use"),
                " tariffs charge you different amounts depending on when you use the electricity. So you can set your hot water heating schedule (and to a lesser extent your space heating schedule) to make use of lower prices."))));
};
