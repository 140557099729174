export const PickByCompanyPreset = (preset) => {
    BASE_COLOURS.find(x => x.colour === preset);
};
export const BASE_COLOURS = [
    {
        colour: 'SECONDARY',
        button: 'bg-gray-100 border-gray-100 text-gray-700 border-gray-300',
        input: 'border-gray-500 focus:border-gray-700 hover:border-gray-700',
        buttonGroup: 'border-gray-500',
        background: 'bg-gray-500',
        colourIcon: 'bg-gray-300 text-slate-700'
    },
    {
        colour: 'PRIMARY',
        button: 'bg-gray-900 border-gray-300 text-gray-50 disabled:text-gray-700 disabled:bg-gray-100',
        input: 'border-gray-300 focus:border-gray-500 text-gray-600',
        buttonGroup: 'border-gray-500',
        background: 'bg-gray-500',
        header: 'bg-white text-gray-700',
        colourIcon: 'bg-gray-300 text-gray-700'
    },
    {
        colour: 'RED',
        button: 'bg-red-500 border-red-500 focus:bg-red-700 focus:border-red-700 hover:bg-red-700 hover:border-red-700 disabled:bg-red-500 disabled:border-red-500',
        input: 'border-red-500 focus:border-red-700 hover:border-red-700',
        buttonGroup: 'border-red-500',
        background: 'bg-red-500',
        colourIcon: 'bg-red-300 text-red-700'
    },
    {
        colour: 'YELLOW',
        button: 'bg-yellow-500 border-yellow-500 focus:bg-yellow-700 focus:border-yellow-700 hover:bg-yellow-700 hover:border-yellow-700 disabled:bg-yellow-500 disabled:border-yellow-500',
        input: 'border-yellow-500 focus:border-yellow-700 hover:border-yellow-700',
        buttonGroup: 'border-yellow-500',
        background: 'bg-yellow-500',
        colourIcon: 'bg-yellow-300 text-yellow-700'
    },
    {
        colour: 'GREEN',
        button: 'bg-green-500 border-green-500 focus:bg-green-700 focus:border-green-700 hover:bg-green-700 hover:border-green-700 disabled:bg-green-500 disabled:border-green-500',
        input: 'border-green-500 focus:border-green-700 hover:border-green-700',
        buttonGroup: 'border-green-500',
        background: 'bg-green-500',
        colourIcon: 'bg-green-300 text-green-700'
    },
    {
        colour: 'BLUE',
        button: 'bg-blue-500 border-blue-500 focus:bg-blue-700 focus:border-blue-700 hover:bg-blue-700 hover:border-blue-700 disabled:bg-blue-500 disabled:border-blue-500',
        input: 'border-blue-500 focus:border-blue-700 hover:border-blue-700',
        buttonGroup: 'border-blue-500',
        background: 'bg-blue-500'
    }
];
export const PRIMARY = BASE_COLOURS.find(x => x.colour === 'PRIMARY');
export const SECONDARY = BASE_COLOURS.find(x => x.colour === 'SECONDARY');
export const WARNING = BASE_COLOURS.find(x => x.colour === 'YELLOW');
export const DANGER = BASE_COLOURS.find(x => x.colour === 'RED');
export const SUCCESS = BASE_COLOURS.find(x => x.colour === 'GREEN');
export const INFO = BASE_COLOURS.find(x => x.colour === 'BLUE');
