export const HEATING_SYSTEM_AGES = [
    { name: 'Pre-1994', efficiency_percentage: 82 },
    { name: '1994-2007', efficiency_percentage: 87 },
    { name: 'Post 2007', efficiency_percentage: 92 }
];
export const HEATING_SYSTEM_TYPES = [
    { uuid: 'mains_gas_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'mains_gas', efficiency_percentage: undefined },
    { uuid: 'mains_gas_system_boiler', name: 'System Boiler', fuel_uuid: 'mains_gas', efficiency_percentage: undefined },
    { uuid: 'lpg_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'lpg', efficiency_percentage: undefined },
    { uuid: 'lpg_system_boiler', name: 'System Boiler', fuel_uuid: 'lpg', efficiency_percentage: undefined },
    { uuid: 'oil_combi_boiler', name: 'Combi Boiler', fuel_uuid: 'oil', efficiency_percentage: undefined },
    { uuid: 'oil_system_boiler', name: 'System Boiler', fuel_uuid: 'oil', efficiency_percentage: undefined },
    {
        uuid: 'electricity_air_source_heat_pump',
        name: 'Air source heat pump',
        fuel_uuid: 'electricity',
        efficiency_percentage: 250
    },
    {
        uuid: 'electricity_ground_source_heat_pump',
        name: 'Ground source heat pump',
        fuel_uuid: 'electricity',
        efficiency_percentage: 300
    },
    { uuid: 'electricity_storage_heater', name: 'Storage heaters', fuel_uuid: 'electricity', efficiency_percentage: 100 },
    { uuid: 'electricity_in_room_heater', name: 'In-room heaters', fuel_uuid: 'electricity', efficiency_percentage: 100 }
];
export const getExistingSystemEfficiencyFromSurvey = (survey) => {
    const existingSystemType = HEATING_SYSTEM_TYPES.find(x => x.uuid === survey.existing_system_type_uuid);
    const existingSystemAge = HEATING_SYSTEM_AGES.find(x => x.name === survey.existing_system_age_uuid);
    return getHeatingSystemEfficiency(existingSystemType, existingSystemAge);
};
export const getHeatingSystemEfficiency = (heatingSystemType, heatingSystemAge) => {
    var _a;
    if (heatingSystemType === null || heatingSystemType === void 0 ? void 0 : heatingSystemType.efficiency_percentage)
        return heatingSystemType.efficiency_percentage / 100;
    return ((_a = heatingSystemAge === null || heatingSystemAge === void 0 ? void 0 : heatingSystemAge.efficiency_percentage) !== null && _a !== void 0 ? _a : 0) / 100;
};
