import React from 'react';
import { orderBy, sum } from 'lodash';
import { SurveyElectricsContext } from '../electrics';
import { ClickableCard } from '../../../../components/content_display/card';
import { Table } from '../../../../components/content_display/table';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsConsumerUnitDetailsBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    const setConsumerUnitItems = (items) => setSurvey({ ...survey, electrics_consumer_unit_items: items });
    const tableColumns = [
        { key: 'name', name: 'Name' },
        { key: 'fuse_size_a', name: 'Fuse size (A)' }
    ];
    // Calculate the maximum demand by getting 100% of the highest fuse item and 40% of all other items.
    const getConsumerUnitMaximumDemand = (consumerUnitItems) => {
        if (consumerUnitItems.length === 0)
            return 0;
        const orderedItems = orderBy(consumerUnitItems, x => x.fuse_size_a, 'desc');
        const maxItem = orderedItems[0].fuse_size_a;
        const rest = sum(orderedItems.slice(1).map(x => x.fuse_size_a));
        return `100% x ${maxItem} A + 40% x ${rest} A = ${maxItem + rest * 0.4} A`;
    };
    // This function assumes a UK 230V system.
    const kwToAmps = (kw) => {
        const voltage = 230;
        const watts = kw * 1000;
        return watts / voltage;
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Consumer unit"),
        React.createElement("div", { className: 'flex flex-col gap-2' },
            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Contents"),
            React.createElement(Table, { columns: tableColumns, rows: survey.electrics_consumer_unit_items, addNew: true, editable: true, onDeleteRow: (id) => setConsumerUnitItems(survey.electrics_consumer_unit_items.filter(x => x.uuid !== id)), onSaveRow: (row) => row.uuid ? setConsumerUnitItems(survey.electrics_consumer_unit_items.map(x => x.uuid === row.uuid ? {
                    ...row,
                    fuse_size_a: Number(row.fuse_size_a)
                } : x)) : setConsumerUnitItems([...survey.electrics_consumer_unit_items, {
                        ...row,
                        fuse_size_a: Number(row.fuse_size_a),
                        uuid: crypto.randomUUID()
                    }]) }),
            React.createElement("div", { className: 'font-bold text-gray-900' }, "Calculated maximum demand"),
            React.createElement("div", null, getConsumerUnitMaximumDemand(survey.electrics_consumer_unit_items))),
        React.createElement("div", { className: "gap-3 flex justify-between" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Spare way on the consumer unit"),
            React.createElement(Toggle, { value: survey.electrics_spare_way, setValue: () => setSurvey({ ...survey, electrics_spare_way: !survey.electrics_spare_way }) })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Breakers make"),
            React.createElement(Input, { value: survey.electrics_breaker_make, placeholder: 'e.g. Hager', setValue: (e) => setSurvey({ ...survey, electrics_breaker_make: e }) })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Notes"),
            React.createElement(TextArea, { value: survey.electrics_notes, setValue: (e) => setSurvey({ ...survey, electrics_notes: e }) })),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: 'flex flex-col gap-5' },
                React.createElement("div", { className: "justify-between items-center gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Smart meter"),
                    React.createElement(Toggle, { value: survey.electrics_has_smart_meter, setValue: () => setSurvey({
                            ...survey,
                            electrics_has_smart_meter: !survey.electrics_has_smart_meter
                        }) })),
                survey.electrics_has_smart_meter && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'text-gray-900' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Peak electricity demand"),
                            React.createElement("div", { className: 'text-sm text-gray-500' }, "If the homeowner has an in-home display or an app connected to their smart meter, find the max half-hourly energy consumption from last year. We'll convert this to the average current in that half hour. This can be used as an alternative measure of max demand.")),
                        React.createElement(Input, { type: 'number', value: survey.electrics_peak_electricity_demand_kwh.toString(), setValue: (e) => setSurvey({ ...survey, electrics_peak_electricity_demand_kwh: Number(e) }), postfix: 'kWh' })),
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Calculated maximum demand"),
                        React.createElement("div", null,
                            Math.round(kwToAmps(survey.electrics_peak_electricity_demand_kwh / 0.5)),
                            " A"))))),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "MPAN number"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "A 13 digit number typically printed on an electricity bill.")),
            React.createElement(Input, { value: survey.electrics_mpan_number, setValue: (e) => setSurvey({ ...survey, electrics_mpan_number: e }) })));
};
