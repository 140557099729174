// bit mask for granular user assess
import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
export const USER_ROLE_SIMPLE = 0x00;
export const USER_ROLE_HAS_SURVEY_ACCESS = 0x01;
// superadmin has access to everything
// 12 bits only because the `role` column is a signed smallint (2 bytes)
export const USER_ROLE_SUPERADMIN = 0xFFF;
// Surveyors only have access to surveys, not estimates or the settings page
export const USER_ROLE_SURVEYOR = 0x02;
/* Returns the numeric role for the user in the given company,
 * or -1 if the user doesn't have access to the company */
export const getRoleForCompany = (user, companySubdomain) => {
    var _a;
    if (!user || !companySubdomain) {
        return -1;
    }
    if (user.role === USER_ROLE_SUPERADMIN) {
        return USER_ROLE_SUPERADMIN;
    }
    return ((_a = user.companies.find(company => company.subdomain === companySubdomain)) === null || _a === void 0 ? void 0 : _a.role) || -1;
};
export const isSuperAdmin = (user) => {
    if (!user) {
        return false;
    }
    return user.role === USER_ROLE_SUPERADMIN;
};
const hasAccess = (company, user, roles) => {
    if (!user || !company) {
        return false;
    }
    if (isSuperAdmin(user)) {
        return true;
    }
    const companyRole = getRoleForCompany(user, company.public_info.subdomain);
    if (companyRole === -1) {
        return false;
    }
    return roles.some(requiredRole => requiredRole === companyRole);
};
export const hasSurveyAccess = (company, user) => hasAccess(company, user, [USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR]);
export const hasEnquriesAccess = (company, user) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS]);
export const hasInventoryAccess = (company, user) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS]);
export const hasSettingsAccess = (company, user) => hasAccess(company, user, [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS]);
export const getUser = async () => {
    try {
        const result = await client.get(`${apiUrl}users/me`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error getting user', e);
        return undefined;
    }
};
export const postUserEvent = async (event) => {
    try {
        const result = await client.post(`${apiUrl}users/event`, event, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error posting user event user', e);
        return undefined;
    }
};
