import React from 'react';
import { SurveyEHSContext } from '../existing_heating_system';
import { getElectricityPencePerKwh, getHeatingFuel, getHeatingPencePerUnit, HEATING_FUELS } from '../../../../code/models/heating_fuel';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../../../components/buttons/icon';
import { Input } from '../../../../components/inputs_and_selections/input';
export const defaultTariffIsOverridden = (fuel, existingSystemPencePerUnitOverride) => {
    return existingSystemPencePerUnitOverride !== undefined && existingSystemPencePerUnitOverride !== fuel.default_tarrif;
};
export const EHSEnergyUseBlock = () => {
    const ehsContext = React.useContext(SurveyEHSContext);
    const { survey, setSurvey } = ehsContext;
    const heatingFuelId = survey.existing_system_fuel_uuid;
    const heatingFuel = getHeatingFuel(heatingFuelId);
    const consumptionFuelUnits = survey.existing_system_annual_consumption_kWh > 0 ? Math.round(survey.existing_system_annual_consumption_kWh / heatingFuel.kWh_per_unit) : '';
    const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity');
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Energy use"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" },
                    heatingFuel.name,
                    " annual consumption"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "E.g. from a recent bill or from the smart meter in-home display"),
                React.createElement(Input, { step: 0.01, type: 'number', value: consumptionFuelUnits.toString(), setValue: (e) => setSurvey({
                        ...survey,
                        existing_system_annual_consumption_kWh: Number(e) * heatingFuel.kWh_per_unit
                    }), postfix: heatingFuel.unit })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" },
                    heatingFuel.name,
                    " tariff"),
                React.createElement(Input, { step: 0.01, type: 'number', value: getHeatingPencePerUnit(heatingFuel, survey.existing_system_p_per_unit_override).toString(), setValue: (e) => setSurvey({ ...survey, existing_system_p_per_unit_override: Number(e), electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? parseFloat(e) : survey.electricity_p_per_kwh_override }), postfix: React.createElement("div", { className: 'flex items-center gap-2.5' },
                        React.createElement("div", null, heatingFuel.penceUnit),
                        React.createElement("div", null, defaultTariffIsOverridden(heatingFuel, survey.existing_system_p_per_unit_override) && React.createElement(Icon, { icon: faTimesCircle, onClick: () => setSurvey(({ ...survey, existing_system_p_per_unit_override: undefined, electricity_p_per_kwh_override: heatingFuelId === 'electricity' ? undefined : survey.electricity_p_per_kwh_override })), colour: 'text-gray-400' }))) })),
            heatingFuelId !== 'electricity' && React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Electricity tariff"),
                React.createElement(Input, { step: 0.01, type: 'number', value: getElectricityPencePerKwh(survey.electricity_p_per_kwh_override).toString(), setValue: (e) => setSurvey({ ...survey, electricity_p_per_kwh_override: Number(e) }), postfix: React.createElement("div", { className: 'flex items-center gap-2.5' },
                        React.createElement("div", null, electricity === null || electricity === void 0 ? void 0 : electricity.penceUnit),
                        React.createElement("div", null, defaultTariffIsOverridden(electricity, survey.electricity_p_per_kwh_override) &&
                            React.createElement(Icon, { icon: faTimesCircle, onClick: () => setSurvey(({ ...survey, electricity_p_per_kwh_override: undefined })), colour: 'text-gray-400' }))) }))));
};
