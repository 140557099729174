import React, { useState } from 'react';
import { orderBy, uniq } from 'lodash';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../code/models/range_heat_pump';
import { DesignPageHeader } from './design/components/design_page_header';
import { RadioGroup } from '../../components/inputs_and_selections/radio';
import { ListItem } from '../../components/content_display/list_item';
import { Badge } from '../../components/indicators_and_messaging/badge';
export const SelectAHeatPumpPage = ({ heatPumps, designTempC, flowTempC, totalHeatLossKW, setCurrentPage, design, setDesign }) => {
    const [rangeFilter, setRangeFilter] = useState('All');
    const ranges = ['All', ...uniq(heatPumps.map(x => { var _a, _b; return (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name; })).filter(x => !!x)];
    const filteredHeatPumps = orderBy(heatPumps.filter(x => { var _a, _b; return rangeFilter === 'All' || ((_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) === rangeFilter; }).map(x => ({
        uuid: x.uuid,
        name: x.name,
        capacity: getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw
    })), x => x.capacity);
    return React.createElement("div", { className: 'flex flex-col' },
        React.createElement(DesignPageHeader, { title: 'Select a heat pump', onBack: () => setCurrentPage('MAIN') },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'flex flex-col gap-3' },
                    React.createElement("div", { className: "bg-white flex-col flex text-left" },
                        React.createElement("div", { className: "flex-col gap-2 flex" },
                            React.createElement("div", { className: "text-gray-500 text-xs font-semibold uppercase" }, "Range"),
                            React.createElement(RadioGroup, { items: ranges.map(x => ({
                                    name: x,
                                    onClick: () => setRangeFilter(x),
                                    variant: rangeFilter === x ? 'ACTIVE' : 'DEFAULT'
                                })) })))))),
        React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200 " }, filteredHeatPumps.map(x => React.createElement(ListItem, { onClick: () => {
                setDesign({ ...design, current_heat_pump_uuid: x.uuid });
                setCurrentPage('MAIN');
            }, key: x.uuid, primaryText: x.name, rightBadge: totalHeatLossKW
                ? React.createElement(Badge, { color: x.capacity >= totalHeatLossKW ? 'GREEN' : 'RED', text: x.capacity.toFixed(1) + ' kW' })
                : React.createElement(Badge, { color: 'LIGHT', text: x.capacity.toFixed(1) + ' kW' }) }))));
};
