import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { Button } from '../../../components/buttons/button';
import { DesignPageHeader } from '../design/components/design_page_header';
import { HLRContext } from './heatloss_report';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { ToggleInputGroup } from '../../../components/inputs_and_selections/toggle_input_group';
import { postUserEvent } from '../../../code/models/user';
export const HeatLossReportSettings = ({ setPage, setReportPage, excludedSections, setExcludedSections }) => {
    const hlrContext = React.useContext(HLRContext);
    const addOrRemoveSection = (section) => setExcludedSections(prev => prev.includes(section)
        ? prev.filter(x => x !== section)
        : [...prev, section]);
    return React.createElement("div", null,
        React.createElement(DesignPageHeader, { title: 'Report', onBack: () => setPage(undefined) }),
        React.createElement("div", { className: "p-5 bg-white flex-col gap-5 flex" },
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Configure report"),
                React.createElement("div", { className: "text-sm" }, "Configure which sections of the report you want to include.")),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Summary', helperText: 'A page providing the customer with an overview of the heat loss findings, chosen heat pump and cost savings.' }), toggle: React.createElement(Toggle, { value: !excludedSections.includes('SUMMARY'), setValue: () => addOrRemoveSection('SUMMARY') }) })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Overall heat loss', helperText: 'Includes the calculation conditions, heat loss breakdown by room and element, and heat loss by floor including the floor-plan.' }), toggle: React.createElement(Toggle, { value: !excludedSections.includes('HEAT_LOSS'), setValue: () => {
                            // If we're not currently excluding the heat loss section, we're about to exclude it
                            // because this is happening on the click of the toggle - so we need to exclude the room by room section
                            // if it's not already excluded
                            if (!excludedSections.includes('HEAT_LOSS') && !excludedSections.includes('HEAT_LOSS_ROOM_BY_ROOM')) {
                                addOrRemoveSection('HEAT_LOSS_ROOM_BY_ROOM');
                            }
                            addOrRemoveSection('HEAT_LOSS');
                        } }) }),
                React.createElement(ToggleInputGroup, { disabled: excludedSections.includes('HEAT_LOSS'), formLabel: React.createElement(FormLabel, { labelText: 'Room-by-room heat loss detail', helperText: 'The details of all the inputs and results for every room in the house, with a page per room.' }), toggle: React.createElement(Toggle, { disabled: excludedSections.includes('HEAT_LOSS'), value: !excludedSections.includes('HEAT_LOSS_ROOM_BY_ROOM'), setValue: () => addOrRemoveSection('HEAT_LOSS_ROOM_BY_ROOM') }) })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Design', helperText: 'Details of the proposed heat pump, emitter replacement, proposed cylinder and associated hot water calcs.' }), toggle: React.createElement(Toggle, { value: !excludedSections.includes('DESIGN'), setValue: () => addOrRemoveSection('DESIGN') }) })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Sound assessment', helperText: 'Results of the sound assessment along with the inputs and calculations behind the assessment.' }), toggle: React.createElement(Toggle, { value: !excludedSections.includes('SOUND_ASSESSMENT'), setValue: () => addOrRemoveSection('SOUND_ASSESSMENT') }) })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(ToggleInputGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Performance estimate', helperText: 'An estimate of system performance covering energy, bills and carbon, plus the assumptions behind the numbers.' }), toggle: React.createElement(Toggle, { value: !excludedSections.includes('PERFORMANCE_ESTIMATE'), setValue: () => addOrRemoveSection('PERFORMANCE_ESTIMATE') }) })),
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Printing instructions"),
            React.createElement("div", { className: "p-5 bg-yellow-100 rounded-lg flex" },
                React.createElement("div", { className: "flex-col gap-1 flex" },
                    React.createElement("div", { className: "text-yellow-900 text-base font-bold" }, "For best results, print from Chrome"),
                    React.createElement("div", { className: "text-yellow-900 text-sm" }, "Chrome currently provides the best output for customers."))),
            React.createElement("ul", { className: "list-decimal list-inside text-sm" },
                React.createElement("li", null, "From the preview screen click Save as PDF"),
                React.createElement("li", null, "Turn off headers/footers and turn on background graphics (under More settings - Options)"),
                React.createElement("li", null,
                    "Under Printer or Destination choose ",
                    React.createElement("span", { className: "text-gray-900 font-bold" }, "Save as PDF"),
                    " or ",
                    React.createElement("span", { className: "text-gray-900 font-bold" }, "Print to PDF"))),
            React.createElement(Button, { onClick: async () => {
                    const rtn = await postUserEvent({ event_type: 1, company_uuid: hlrContext === null || hlrContext === void 0 ? void 0 : hlrContext.company.public_info.uuid, extra_data: { survey_uuid: hlrContext === null || hlrContext === void 0 ? void 0 : hlrContext.survey.uuid } });
                    if (!rtn) {
                        alert('Failed to generate report. Check your internet connection and try again.');
                    }
                    else {
                        setReportPage('REPORT');
                    }
                } }, "View report")));
};
