import React from 'react';
import { SubHeader } from './sub_header';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/24/outline';
export const NextStepsComponent = ({ isScottish, isChecked }) => {
    return React.createElement("div", { className: "w-full flex flex-col justify-start items-start gap-2" },
        React.createElement(SubHeader, { content: "Next steps" }),
        React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
            isChecked ? React.createElement(CheckCircleIcon, { className: 'w-6 h-6 text-green-500' }) : React.createElement(CheckCircleIconOutline, { className: 'w-6 h-6 text-gray-400' }),
            React.createElement("div", { className: `grow shrink basis-0 text-gray-600 text-base font-medium ${isChecked ? 'line-through' : ''}` }, "Initial cost estimate")),
        isScottish && React.createElement(React.Fragment, null,
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium" }, "Home Energy Improvement Report from Home Energy Scotland")),
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium" }, "Detailed room-by-room heat loss survey and full proposal")),
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium" }, "Apply for funding from Home Energy Scotland")),
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium" }, "Installation")),
            React.createElement("div", { className: "text-sm mt-5" }, "Please note some of these steps can happen concurrently - your installer can explain in more detail.")),
        !isScottish && React.createElement(React.Fragment, null,
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium  " }, "Full heat loss survey")),
            React.createElement("div", { className: "self-stretch justify-start items-start gap-2 inline-flex" },
                React.createElement(CheckCircleIconOutline, { className: "w-6 h-6 text-gray-400" }),
                React.createElement("div", { className: "grow shrink basis-0 text-gray-600 text-base font-medium  " }, "Your heat pump is installed"))));
};
