import { scaleBy, maxScale, minScale } from './constants';
export const onWheel = (e, setScale, setStagePosition) => {
    e.evt.preventDefault();
    const stage = e.currentTarget.getStage();
    if (!stage)
        return;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    if (!pointer)
        return;
    const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale
    };
    // how to scale? Zoom in? Or zoom out?
    let direction = e.evt.deltaY > 0 ? 1 : -1;
    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
        direction = -direction;
    }
    const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale >= maxScale || newScale <= minScale)
        return;
    const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
    };
    setScale(newScale);
    setStagePosition({ x: newPos.x, y: newPos.y });
};
