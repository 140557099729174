import React from 'react';
import { faCamera, faCube, faFire } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { CanvasNav } from './canvas_nav';
export const RoomButtons = ({ currentRoom, setPage }) => {
    return React.createElement(CanvasNav, { title: currentRoom.name, items: [
            { name: 'Details', onClick: () => setPage('ROOM_DETAILS', true), icon: faFileLines, variant: 'DEFAULT' },
            { name: 'Floor', onClick: () => setPage('ROOM_FLOOR', true), icon: faCube, variant: 'DEFAULT' },
            { name: 'Ceiling', onClick: () => setPage('ROOM_CEILING', true), icon: faCube, variant: 'DEFAULT' },
            { name: `${currentRoom.images.length > 0 ? currentRoom.images.length : ''} Photo${currentRoom.images.length > 1 || currentRoom.images.length === 0 ? 's' : ''}`, onClick: () => setPage('ROOM_PHOTOS', true), icon: faCamera, variant: currentRoom.images.length > 0 ? 'BLUE' : 'DEFAULT' },
            { name: `${currentRoom.radiators.length > 0 ? currentRoom.radiators.length : ''} Emitter${currentRoom.radiators.length > 1 || currentRoom.radiators.length === 0 ? 's' : ''}`, onClick: () => setPage('ROOM_EMITTERS', true), icon: faFire, variant: currentRoom.radiators.length > 0 ? 'BLUE' : 'DEFAULT' }
        ] });
};
