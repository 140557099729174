import React, { useEffect, useState } from 'react';
import { apiUrl, client } from '../code/axios';
import { logEvent } from '../code/log_event';
import { isValidEmail, isValidPassword, isValidPhone } from '../code/validators';
import { Button } from '../components/buttons/button';
import { Input } from '../components/inputs_and_selections/input';
import { Section } from '../components/containers/section';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { signUp } from '../code/models/auth';
import { AuthSDK } from '../code/utils/auth_provider';
export const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [logo, setLogo] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordRepeatedValid, setPasswordRepeatedValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [companyPhoneValid, setCompanyPhoneValid] = useState(true);
    // we use companyAddressValid == null to store uncertain state.
    // In this state we don't know if the address is valid or not
    // and we don't show any error message
    const [companyAddressValid, setCompanyAddressValid] = useState();
    const [logoValid, setLogoValid] = useState(true);
    // to delay user input for address
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [changed, setChanged] = useState({
        email: false,
        password: false,
        passwordRepeated: false,
        firstName: false,
        lastName: false,
        companyName: false,
        companyPhone: false,
        companyAddress: false
    });
    const isValid = () => {
        return emailValid &&
            passwordValid &&
            passwordRepeatedValid &&
            firstNameValid &&
            lastNameValid &&
            companyNameValid &&
            companyPhoneValid &&
            companyAddressValid &&
            logoValid;
    };
    const handleLogoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const image = new Image();
            image.onload = () => {
                const maxWidth = 640;
                const maxHeight = 480;
                let width = image.width;
                let height = image.height;
                // Calculate the scale factor
                const widthScale = maxWidth / width;
                const heightScale = maxHeight / height;
                const scaleFactor = Math.min(widthScale, heightScale);
                // If the image is larger than the max dimensions, resize it
                if (scaleFactor < 1) {
                    width *= scaleFactor;
                    height *= scaleFactor;
                }
                // Draw the resized image on the canvas
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);
                // Convert canvas to base64
                canvas.toBlob(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        var _a, _b;
                        setLogo((_b = (_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
                    };
                    reader.readAsDataURL(blob);
                });
            };
            image.src = URL.createObjectURL(file);
        }
    };
    const handleAddressChange = (e) => {
        const newAddress = e;
        setCompanyAddress(newAddress);
        setChanged({ ...changed, companyAddress: true });
        // we use null to store uncertain state.
        // In this state we don't know if the address is valid or not
        // and we don't show any error message
        setCompanyAddressValid(undefined);
    };
    const updateDelayedAddress = (address) => {
        const result = async () => {
            var _a;
            if (address === '')
                return;
            // check if address is valid and exists
            const response = await client.get(`${apiUrl}geocode?address=${encodeURIComponent(address)}`);
            if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.lat) && response.data.lng) {
                setCompanyAddressValid(true);
                setMapCenter(response.data);
            }
            else {
                setCompanyAddressValid(false);
                setMapCenter({ lat: 0, lng: 0 });
            }
        };
        result();
    };
    const onSubmit = async () => {
        if (!isValid())
            return;
        logEvent({ name: 'Sign Up Posted', properties: {} }, null);
        const response = await signUp(email, password, firstName, lastName, companyName, companyPhone, companyAddress, mapCenter, logo);
        await AuthSDK.signIn(email, password);
        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
            window.location.href = '/' + response.data.company.subdomain + '/admin';
        }
        else {
            // FIXME: show error message
            alert('Something went wrong. Please try again.');
        }
    };
    useEffect(() => {
        setEmailValid(isValidEmail(email));
        setPasswordValid(isValidPassword(password));
        setPasswordRepeatedValid(password === passwordRepeated);
        setFirstNameValid(firstName.length > 0);
        setLastNameValid(lastName.length > 0);
        setCompanyNameValid(companyName.length > 0);
        setCompanyPhoneValid(isValidPhone(companyPhone));
        setLogoValid(logo.length > 0);
    }, [email, password, passwordRepeated, firstName, lastName, companyName, companyPhone, logo]);
    useEffect(() => {
        const timer = setTimeout(() => {
            updateDelayedAddress(companyAddress);
        }, 800);
        return () => { clearTimeout(timer); };
    }, [companyAddress]);
    return React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto" },
        React.createElement(Section, { border: true, title: "Sign up" },
            React.createElement("div", { className: "space-y-4" },
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Email"),
                    React.createElement(Input, { onEnter: onSubmit, type: "email", value: email, setValue: (e) => {
                            setEmail(e);
                            setChanged({ ...changed, email: true });
                        }, placeholder: "Enter your email address" })),
                changed.email && !emailValid && React.createElement("span", { className: "text-red-500" }, "Invalid email address"),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Password"),
                    React.createElement(Input, { onEnter: onSubmit, type: "password", value: password, setValue: (e) => {
                            setPassword(e);
                            setChanged({ ...changed, password: true });
                        }, placeholder: "Enter your password" })),
                changed.password && !passwordValid && React.createElement("span", { className: "text-red-500" },
                    "Invalid password. ",
                    React.createElement("br", null),
                    " Must be at least 8 characters long and contain at least one letter and one number."),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Repeat your password"),
                    React.createElement(Input, { onEnter: onSubmit, type: "password", value: passwordRepeated, setValue: (e) => {
                            setPasswordRepeated(e);
                            setChanged({ ...changed, passwordRepeated: true });
                        }, placeholder: "Enter your password again" })),
                changed.passwordRepeated && !passwordRepeatedValid && React.createElement("span", { className: "text-red-500" }, "Password is not the same"),
                React.createElement("div", { className: "flex space-x-4" },
                    React.createElement("div", { className: "space-y-2 w-1/2" },
                        React.createElement("div", { className: "font-semibold" }, "First Name"),
                        React.createElement(Input, { onEnter: onSubmit, type: "text", value: firstName, setValue: (e) => {
                                setFirstName(e);
                                setChanged({ ...changed, firstName: true });
                            }, placeholder: "Enter your first name" }),
                        changed.firstName && !firstNameValid && React.createElement("span", { className: "text-red-500" }, "Invalid first name")),
                    React.createElement("div", { className: "space-y-2 w-1/2" },
                        React.createElement("div", { className: "font-semibold" }, "Last Name"),
                        React.createElement(Input, { onEnter: onSubmit, type: "text", value: lastName, setValue: (e) => {
                                setLastName(e);
                                setChanged({ ...changed, lastName: true });
                            }, placeholder: "Enter your last name" }),
                        changed.lastName && !lastNameValid && React.createElement("span", { className: "text-red-500" }, "Invalid last name"))),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Company name"),
                    React.createElement(Input, { onEnter: onSubmit, type: "text", value: companyName, setValue: (e) => {
                            setCompanyName(e);
                            setChanged({ ...changed, companyName: true });
                        }, placeholder: "Enter your company name" })),
                changed.companyName && !companyNameValid && React.createElement("span", { className: "text-red-500" }, "Invalid name"),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Company phone"),
                    React.createElement(Input, { onEnter: onSubmit, type: "text", value: companyPhone, setValue: (e) => {
                            setCompanyPhone(e);
                            setChanged({ ...changed, companyPhone: true });
                        }, placeholder: "Enter your company phone number" })),
                changed.companyPhone && !companyPhoneValid && React.createElement("span", { className: "text-red-500" }, "Invalid phone"),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Company address"),
                    React.createElement(Input, { onEnter: onSubmit, type: "text", value: companyAddress, setValue: (e) => {
                            handleAddressChange(e);
                            setChanged({ ...changed, companyAddress: true });
                        }, placeholder: "Enter your company phone number" })),
                changed.companyAddress && companyAddressValid === false && React.createElement("span", { className: "text-red-500" }, "Invalid address"),
                changed.companyAddress && companyAddressValid && mapCenter.lat !== 0 && mapCenter.lng !== 0 &&
                    React.createElement(Map, { center: mapCenter, className: "min-h-[200px] w-full h-full", zoom: 13 },
                        React.createElement(Marker, { position: mapCenter })),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "font-semibold" }, "Company Logo"),
                    React.createElement("input", { type: "file", onChange: handleLogoChange, accept: "image/*" })),
                changed.email &&
                    changed.password &&
                    changed.passwordRepeated &&
                    changed.firstName &&
                    changed.lastName &&
                    changed.companyName &&
                    changed.companyPhone &&
                    changed.companyAddress &&
                    !logo && React.createElement("span", { className: "text-red-500" }, "Add logo"),
                React.createElement(Button, { disabled: !isValid(), block: true, onClick: onSubmit }, "Sign Up"))));
};
