import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { StepHeader } from '../components/steps_header';
import { SurveyCheckbox } from '../components/survey_checkbox';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { formatDateMonthYear } from '../../../code/format_date';
export const EPCChangesStep = () => {
    var _a, _b, _c;
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [selectedOptions, setSelectedOptions] = useState(survey.lead.epcChanges);
    const handleToggleOption = (option, selected) => {
        const newState = { ...selectedOptions };
        // no changes option is a special case
        // if it's selected, we have to unselect all other options
        if (option === 'noChanges' && selected) {
            newState.insulation = false;
            newState.loftConversion = false;
            newState.extension = false;
            newState.replacedWindows = false;
            newState.other = false;
        }
        else {
            // if any other option is selected, we have to unselect "no changes" option
            newState.noChanges = false;
        }
        newState[option] = selected;
        setSelectedOptions(newState);
    };
    const handleNavigatePreviousPage = () => {
        dispatch({
            type: SurveyActionType.NavigatePreviousPage
        });
    };
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Changes since EPC step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                epcChanges: selectedOptions
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { colour: 'LIGHT', onClick: handleNavigatePreviousPage, block: false, className: "self-start" },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement(ArrowLeftIcon, { className: "h-4 w-4" }),
                React.createElement("div", null, "Back"))),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: `Have you made changes to your home since your last EPC
      (${formatDateMonthYear((_b = (_a = survey.lead.epcData) === null || _a === void 0 ? void 0 : _a.inspectionDate) !== null && _b !== void 0 ? _b : (_c = survey.lead.epc_scotland) === null || _c === void 0 ? void 0 : _c.inspection_date)})
      that impact your heating and hot water needs?` }),
        React.createElement("div", { className: "w-full flex-col justify-start items-start gap-3 inline-flex" },
            React.createElement(SurveyCheckbox, { label: "Improved insulation", onSelect: (v) => { handleToggleOption('insulation', v); }, selected: selectedOptions.insulation }),
            React.createElement(SurveyCheckbox, { label: "Loft conversion", onSelect: (v) => { handleToggleOption('loftConversion', v); }, selected: selectedOptions.loftConversion }),
            React.createElement(SurveyCheckbox, { label: "Extension", onSelect: (v) => { handleToggleOption('extension', v); }, selected: selectedOptions.extension }),
            React.createElement(SurveyCheckbox, { label: "Replaced windows", onSelect: (v) => { handleToggleOption('replacedWindows', v); }, selected: selectedOptions.replacedWindows }),
            React.createElement(SurveyCheckbox, { label: "No changes", onSelect: (v) => { handleToggleOption('noChanges', v); }, selected: selectedOptions.noChanges })),
        React.createElement(Button, { size: 'LG', disabled: 
            // if no options are selected, disable the button
            !selectedOptions.noChanges &&
                !selectedOptions.insulation &&
                !selectedOptions.loftConversion &&
                !selectedOptions.extension &&
                !selectedOptions.replacedWindows &&
                !selectedOptions.other, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
