import React, { useContext, useEffect, useRef, useState } from 'react';
import { ClickableCard } from '../../components/content_display/card';
import { calculateQuote } from '../../code/calculate_quote';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { AdminContext } from '../admin/admin_layout';
import { getAddressIncludingPostcode, getLead } from '../../code/models/lead';
import { formatPrice } from '../../code/format_price';
import { checkIfScottish } from '../../code/models/address';
import { BUSGrantBadge } from '../admin/lead_page';
import { ensureLeadHasEPCRecommendations, ensureLeadHasLocation3D, ensurePropertySurveyBackwardsCompatibility, ESTIMATE_MAPPED_ACH, ESTIMATE_MAPPED_AGE_BAND, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS, ESTIMATE_MAPPED_BUILT_FORM, ESTIMATE_MAPPED_DESIGN_TEMP, ESTIMATE_MAPPED_FUEL_TYPE, ESTIMATE_MAPPED_HEAT_PUMP, ESTIMATE_MAPPED_HOT_WATER_CYLINDER, ESTIMATE_MAPPED_INDOOR_TEMP, ESTIMATE_MAPPED_MATERIALS, ESTIMATE_MAPPED_PROPERTY_TYPE, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS } from '../../code/models/property';
import { getHeatTransferCoefficientWattsPerKelvin, getRoomWatts, getTotalHeatLossWatts } from '../../code/models/heat_loss';
import { FloorPage } from './floor/floor';
import { chain, sum } from 'lodash';
import { DesignHome } from './design_home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { instantiateMaterialSet, mapEstimateMaterials } from '../../code/models/material';
import { leadAgeBandToSurveyAgeBand } from '../../code/models/u_value';
import { EmitterDesignPage, getRadiatorBadge } from './design/pages/emitter_design_page';
import { getEmitterWatts, RADIATOR_MODELS } from '../../code/models/radiator_model';
import { PipeworkDesignPage } from './design/pages/pipework_design_page';
import { PerformanceDesignPage } from './design/pages/performance_design_page';
import { QuoteDesignPage } from './design/pages/quote_design_page';
import { NewFloor, Survey } from './survey/survey';
import { FloorList } from './floor/floor_list';
import { SurveySettings } from './survey/survey_settings';
import { SiteDetails } from './survey/site_details';
import { DefaultMaterialsTab } from './materials/default_materials_tab';
import { Electrics } from './survey/electrics';
import { HeatPumpLocation } from './survey/heat_pump_location';
import { CylinderLocation } from './survey/cylinder_location';
import { CylinderDesignPage } from './design/pages/cylinder_design_page';
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../code/survey_defaults';
import { getRoleForCompany, hasEnquriesAccess, hasSurveyAccess, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SUPERADMIN, USER_ROLE_SURVEYOR } from '../../code/models/user';
import { ExistingHeatingSystem } from './survey/existing_heating_system';
import { useSyncingDB } from '../../code/use_syncing_db';
import { FilesDexieWrapper, MaterialsDexieWrapper, SurveyDexieWrapper } from '../../code/sync_wrapper';
import { OfflineBlock } from './particles/offline_block';
import { getSoundAssessmentData } from '../../code/sound_assessment';
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../code/models/radiator';
import { isFlagSet } from '../../code/helpers';
import { getPerformanceEstimateSummary } from '../../code/models/performance_estimate';
import { findMaxValidFlowTempForScop, findMinValidFlowTempForScop, getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../code/models/range_heat_pump';
import { HEATING_FUELS } from '../../code/models/heating_fuel';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getHotWaterCalculationRowLegionella, getHotWaterCalculationRowNormalOperation, getNumberOfOccupants } from '../../code/models/hot_water_cylinder';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { OfflinePage } from '../admin/offline_page';
import { HeatLossReport } from './proposal/heatloss_report';
import { stepSize } from './constants';
import { getRoomRadiatorWatts } from '../../code/models/room';
import { getDesignConditions } from '../../code/models/design_temp';
import { convertLatLngListToLatLongLiteral } from '../../code/geocoding';
import { Pipework } from './survey/pipework';
import { HeatPumpDesign } from './design/pages/heat_pump_design';
import { canViewSurvey, createSurveyInvitation, revokeInvitation } from '../../code/models/invitations';
import { UnauthorizedPage } from '../admin/unauthorized_page';
import { Invitations } from '../admin/components/invitations';
import { RequireRole } from '../../require_role';
import { FloorplanFlow } from './survey/floorplan_flow';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { getPipeData } from '../../code/models/pipes';
export const IsOfflineContext = React.createContext(false);
export const PropertyHome = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26;
    const navigate = useNavigate();
    const leadUUID = (_a = window.location.pathname.match(/\/surveys\/([a-f0-9-]+)$/)) === null || _a === void 0 ? void 0 : _a[1];
    const companyPublicInfo = useRouteLoaderData('company_root');
    const adminContext = useContext(AdminContext);
    const surveyDBWrapper = useRef(new SurveyDexieWrapper(companyPublicInfo.uuid));
    const filesDBWrapper = useRef(new FilesDexieWrapper(companyPublicInfo.uuid));
    const materialsDBWrapper = useRef(new MaterialsDexieWrapper(companyPublicInfo.uuid));
    const [page, setPage] = useState();
    const [currentFloorId, setCurrentFloorId] = useState();
    const [designTab, setDesignTab] = useState('');
    const [surveyTab, setSurveyTab] = useState('');
    const [currentRoomId, setCurrentRoomId] = useState();
    const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
    const [stageSize, setStageSize] = useState({
        width: 0,
        height: 0
    });
    const [invitations, setInvitations] = useState([]);
    const stageRef = useRef(null);
    const [stageScale, setStageScale] = useState(1);
    const [initState, setInitState] = useState(0);
    const [survey, setSurvey] = useSyncingDB(surveyDBWrapper.current, leadUUID, {
        ...DEFAULT_PROPERTY_SURVEY,
        created_at: (new Date()).getTime(),
        uuid: leadUUID,
        designs: [{ ...DEFAULT_SURVEY_DESIGN, uuid: crypto.randomUUID() }]
    }, 10 * 1000, ensurePropertySurveyBackwardsCompatibility, () => {
        setInitState(prev => prev | INIT_STATE_GOT_SURVEY);
    });
    const [files, setFiles] = useSyncingDB(filesDBWrapper.current, leadUUID, [], 60 * 1000, undefined, () => {
        setInitState(prev => prev | INIT_STATE_GOT_FILES);
    });
    const [customMaterials, setCustomMaterials] = useSyncingDB(materialsDBWrapper.current, leadUUID, [], 10 * 1000, undefined, () => {
        setInitState(prev => prev | INIT_STATE_GOT_MATERIALS);
    });
    const [lead] = useIndexedDBFallback('admin_data', 'lead-' + leadUUID, undefined, async () => {
        const apiLead = await getLead(leadUUID, companyPublicInfo.uuid);
        if (!apiLead)
            return;
        // backward compatibility with old leads before we started to store location and recommendations to the lead
        const leadWithPostcodeLocation = await ensureLeadHasLocation3D(apiLead, companyPublicInfo.uuid);
        return await ensureLeadHasEPCRecommendations(leadWithPostcodeLocation, companyPublicInfo.uuid, true);
    }, () => {
        setInitState(prev => prev | INIT_STATE_GOT_LEAD);
    });
    // control the synchronization loaders initialization state
    const INIT_STATE_GOT_SURVEY = 0x001;
    const INIT_STATE_GOT_MATERIALS = 0x002;
    const INIT_STATE_GOT_FILES = 0x004;
    const INIT_STATE_GOT_LEAD = 0x008;
    const INIT_STATE_ALL = INIT_STATE_GOT_SURVEY | INIT_STATE_GOT_MATERIALS | INIT_STATE_GOT_FILES | INIT_STATE_GOT_LEAD;
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        // check access rights
        if (!adminContext.isLoading && !hasSurveyAccess((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company, (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.user))
            return;
        // is all data loaded?
        if (initState !== INIT_STATE_ALL)
            return;
        // the lead still can be undefined if working offline
        if (lead) {
            handleEstimateMapping(lead, adminContext.data.genericMaterials, (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.heatPumps) !== null && _d !== void 0 ? _d : [], (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.hotWaterCylinders) !== null && _f !== void 0 ? _f : [], (_g = adminContext.data) === null || _g === void 0 ? void 0 : _g.company, survey, setSurvey, setCustomMaterials);
        }
    }, [initState]);
    useEffect(() => {
        var _a, _b, _c, _d;
        setInvitations((_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.invitations) === null || _c === void 0 ? void 0 : _c.sent.filter(x => x.entity_type === 'survey' && x.entity_uuid === leadUUID)) !== null && _d !== void 0 ? _d : []);
    }, [(_d = (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.invitations) === null || _d === void 0 ? void 0 : _d.sent]);
    // blockers of rendering in case the data is not loaded yet
    if (initState !== INIT_STATE_ALL || adminContext.isLoading || !survey || !lead) {
        return React.createElement("div", { className: 'p-5 flex justify-center' },
            adminContext.isOffline && React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys`, customHeader: 'This survey has not been saved locally.', customMessage: 'If you find internet and open this survey we will save it locally for use offline.' }),
            !adminContext.isOffline && 'Loading...');
    }
    if (((_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.user) && !hasSurveyAccess((_f = adminContext.data) === null || _f === void 0 ? void 0 : _f.company, (_g = adminContext.data) === null || _g === void 0 ? void 0 : _g.user))
        return React.createElement("div", { className: 'p-5 flex justify-center' }, "403. Access denied");
    const setLocationImages = async (value) => setSurvey({ ...survey, location_images: value });
    const setCustomRadiatorModels = (updateFn) => {
        setSurvey((prevSurvey) => ({
            ...prevSurvey,
            custom_radiator_models: updateFn(prevSurvey.custom_radiator_models)
        }));
    };
    const customRadiatorModels = survey.custom_radiator_models; // Doing this at top level so can easily swap out for a database read when we move custom radiator models to the company level
    const allRadiatorModels = [...RADIATOR_MODELS, ...customRadiatorModels];
    const setFloors = async (floors) => setSurvey(prev => ({ ...prev, floors }));
    const setFloor = (floor) => {
        setFloors([...survey.floors.map(x => x.uuid === floor.uuid ? floor : x)]);
    };
    const removeFloor = (id) => {
        const filteredFloors = survey.floors.filter(x => x.uuid !== id);
        setFloors([...filteredFloors]);
        if (filteredFloors.length > 0)
            setCurrentFloorId(filteredFloors[0].uuid);
    };
    const setDesign = async (design) => setSurvey(prev => ({ ...prev, designs: [design] }));
    const setFlowTemp = async (temp) => await setDesign({ ...design, flow_temp: temp });
    const setQuoteLineItems = async (value) => await setDesign({ ...design, quote_line_items: value });
    // Inputs
    const design = survey.designs[0];
    const heatPumps = ((_h = adminContext.data.heatPumps) !== null && _h !== void 0 ? _h : []).filter(x => !x.deleted_at);
    const hydratedHeatPumps = heatPumps.map(x => ({
        ...x,
        range_heat_pump: {
            ...x.range_heat_pump,
            brand_range: adminContext.data.brandRanges.find(y => { var _a; return y.uuid === ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); })
        }
    }));
    const hotWaterCylinders = ((_j = adminContext.data.hotWaterCylinders) !== null && _j !== void 0 ? _j : []).filter(x => !x.deleted_at);
    const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === design.current_hot_water_cylinder_uuid);
    // later: converting the data structure back and forth for this property is a pain. When we have a migration approach lets just save it in this structure on the property then you can just use it straight off the lead
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const altitudetoUseM = (_k = survey.altitude_override_m) !== null && _k !== void 0 ? _k : lead.property.altitudeM;
    const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, survey.exposed_location); // done separately as needed in survey_settings
    const designTempC = (_l = survey.design_temp_override_c) !== null && _l !== void 0 ? _l : designTempDefaultC;
    // Summary calculations
    const totalHeatLossKw = getTotalHeatLossWatts(survey, designTempC, groundTempC, stepSize) / 1000;
    // calculate demandMet by room groups, not independent rooms.
    // because some rooms are gaining heat from the other rooms in the group
    const roomGroupsHeatDemandMet = chain(survey.floors.flatMap(x => x.rooms.map(y => ({ ...y, floor: x }))))
        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
        .map((values, key) => {
        const roomGroupRadiatorWatts = sum(values.map(r => getRoomRadiatorWatts(r, design, survey, designTempC, groundTempC)));
        const roomGroupRoomWatts = sum(values.map(r => getRoomWatts(r, r.floor.rooms, designTempC, groundTempC, stepSize, survey)));
        const status = roomGroupRadiatorWatts > roomGroupRoomWatts ? 'Sufficient' : design.undersized_emitter_rooms.some(x => x.room_uuid === key) ? 'Accepted' : 'Insufficient';
        return { roomGroupKey: key, status };
    })
        .value();
    const emitterDemandStatus = roomGroupsHeatDemandMet.every(x => x.status === 'Sufficient')
        ? 'Sufficient' // sufficient if all marked sufficient
        //   Insufficient if any marked insufficient
        : roomGroupsHeatDemandMet.some(x => x.status === 'Insufficient')
            ? 'Insufficient'
            : 'Accepted'; // accepted if not all sufficient and none insufficient
    // Heat pump calculations
    const currentHeatPump = hydratedHeatPumps.find(x => x.uuid === design.current_heat_pump_uuid);
    const scopSpaceHeating = getHeatPumpScopAtFlowTemp(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, design.flow_temp);
    const soundAssessmentData = getSoundAssessmentData(survey.sound_barrier_uuid, survey.reflective_surfaces, survey.sound_distance, (_m = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _m === void 0 ? void 0 : _m.sound_power_max_dba);
    const heatPumpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, designTempC, design.flow_temp);
    const soundAssessment = currentHeatPump ? (_o = soundAssessmentData === null || soundAssessmentData === void 0 ? void 0 : soundAssessmentData.finalResultDba) !== null && _o !== void 0 ? _o : 0 : 0;
    const minFlowTemp = findMinValidFlowTempForScop(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump);
    const maxFlowTemp = findMaxValidFlowTempForScop(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump);
    // Radiator calculations
    const roomOrRoomGroups = survey.floors.flatMap(x => x.rooms.map(y => ({ uuid: y.uuid, group_uuid: y.room_group_uuid })));
    const addedOrReplacedRadiators = design.radiators
        .filter(x => roomOrRoomGroups.some(y => y.uuid === x.room_uuid || y.group_uuid === x.room_uuid))
        .map(r => {
        var _a;
        return ({
            radiator_type_uuid: r.emitter_type === 'RADIATOR' ? (_a = r.radiator_type) === null || _a === void 0 ? void 0 : _a.uuid : undefined,
            name: getEmitterTypeName(r),
            dimensions: getEmitterSizeName(r),
            badge: getRadiatorBadge(r, false, design.removed_radiator_uuids, false)
        });
    });
    const emitterRows = [...addedOrReplacedRadiators];
    const totalNewRadiators = addedOrReplacedRadiators.length;
    const radiatorPrice = 600; // TODO: How do we set radiator prices?
    const emitterColumns = [
        { name: 'Item', render: (row) => React.createElement("div", null, row.name) },
        { name: 'Dimensions', render: (row) => React.createElement("div", null, row.dimensions) },
        { name: 'Badge', render: (row) => React.createElement("div", { className: 'flex justify-end' }, row.badge) }
    ];
    // Hot water calculations
    const cylinderReheatRow = getCylinderReheatCalculationRow(currentHotWaterCylinder, design.hot_water_storage_temperature_c, currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, designTempC);
    const numberOfOccupants = getNumberOfOccupants(survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l);
    const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c);
    const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, (_p = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.litres) !== null && _p !== void 0 ? _p : 0, survey.legionnaires_cycle_weeks);
    const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear;
    const scopHotWater = hotWaterRowNormal.heatingEfficiency;
    // Performance calculations
    // Ignore legionella for now in HDD based performance estimate - add back in when use a less conservative efficiency
    const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear;
    const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(survey, designTempC, groundTempC, stepSize);
    const eligibleForHeatPumpPlus = ((_r = (_q = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _q === void 0 ? void 0 : _q.brand_range) === null || _r === void 0 ? void 0 : _r.brand) ? ['Vaillant', 'Viessman'].includes((_t = (_s = currentHeatPump.range_heat_pump) === null || _s === void 0 ? void 0 : _s.brand_range) === null || _t === void 0 ? void 0 : _t.brand) : false;
    const performanceEstimateSummary = getPerformanceEstimateSummary(survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, scopHotWater, scopSpaceHeating, eligibleForHeatPumpPlus);
    // Use HDD based values for bill estimates for now - allow user to choose later
    const annualBillEstimateGBP = performanceEstimateSummary.hddEstimate.proposed.costPriceCap;
    const performanceEstimateColumns = [
        {
            key: 'name',
            name: 'Name',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.name) : React.createElement("div", null, row.name))
        },
        {
            key: 'kwh',
            name: 'Energy',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.kwh) : React.createElement("div", null, row.kwh))
        },
        {
            key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
            name: 'Bills',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.costUserEnteredTariff) : React.createElement("div", null, row.costUserEnteredTariff))
        },
        {
            key: 'emissionsKG',
            name: 'Emissions',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.emissionsKG) : React.createElement("div", null, row.emissionsKG))
        }
    ];
    // Quote calculations
    const lineItemColumns = [
        { key: 'name', name: 'Item' },
        { key: 'quantity', name: 'Qty' },
        { key: 'value', name: 'Value', render: (row) => React.createElement("div", { className: 'text-gray-600' },
                "\u00A3",
                row.value) }
    ];
    const defaultLineItems = [
        {
            uuid: undefined,
            name: (_u = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name) !== null && _u !== void 0 ? _u : '',
            quantity: 1,
            value: (_v = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.price) !== null && _v !== void 0 ? _v : 0,
            type: 'HEAT_PUMP'
        },
        {
            uuid: undefined,
            name: (_w = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.name) !== null && _w !== void 0 ? _w : '',
            quantity: 1,
            value: (_x = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.price) !== null && _x !== void 0 ? _x : 0,
            type: 'CYLINDER'
        },
        { uuid: undefined, name: 'Parts', quantity: 1, value: (_y = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.parts) !== null && _y !== void 0 ? _y : 0, type: 'PARTS' },
        { uuid: undefined, name: 'Labour', quantity: 1, value: (_z = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.price) !== null && _z !== void 0 ? _z : 0, type: 'LABOUR' },
        { uuid: undefined, name: 'Radiators', quantity: totalNewRadiators, value: radiatorPrice, type: 'RADIATORS' }
    ];
    const hydratedLineItems = [...defaultLineItems.map(dli => {
            const override = design.quote_line_items.find(li => li.type === dli.type);
            return override || dli;
        }), ...design.quote_line_items.filter(x => x.type === 'ADDITIONAL')];
    const totalCost = sum(hydratedLineItems.map(x => x.value * x.quantity));
    // Pipework calculations
    const designedEmitters = getFullEmittersListByStatus(survey, design)
        .filter(x => x.status !== 'REMOVED' && x.radiator.type !== 'SECONDARY')
        .map(x => ({ ...(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator), room: x.room, watts: getEmitterWatts(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator, x.room, design, survey, designTempC, groundTempC) }));
    // Get the index emitter. If the survey's index emitter has been replaced then set that replacement emitter as the index emitter apart from if an override has been set in the design (this means as soon as the user changes it in design the link to the survey is lost)
    const indexEmitterReplacementUUID = (_0 = designedEmitters.find(x => x.type === 'DESIGN' && x.replaces_uuid === survey.index_emitter_uuid)) === null || _0 === void 0 ? void 0 : _0.uuid;
    const indexEmitterUUID = (_2 = (_1 = design.index_emitter_uuid_override) !== null && _1 !== void 0 ? _1 : indexEmitterReplacementUUID) !== null && _2 !== void 0 ? _2 : survey.index_emitter_uuid;
    // Get the secondary emitters. If the survey's secondary index emitters have been replaced then set those replacement emitters as the secondary emitters
    const secondaryEmitterUUIDsThroughReplacement = designedEmitters.filter(x => x.type === 'DESIGN' && x.replaces_uuid && survey.secondary_index_emitter_uuids.includes(x.replaces_uuid)).map(x => x.uuid);
    const secondaryEmitterUUIDSAll = [
        ...survey.secondary_index_emitter_uuids.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // remaining survey emitters that were on the survey index circuit
        ...secondaryEmitterUUIDsThroughReplacement.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // survey emitters that were on the survey index circuit but have been replaced
        ...design.secondary_index_emitter_uuids // emitters that have been added on the design side
    ].filter(x => designedEmitters.some(y => y.uuid === x)); // only include if it's in the designed emitters list (normally selecting from design rads so not strictly needed, but clearer this way)
    const secondaryEmitterUUIDS = Array.from(new Set(secondaryEmitterUUIDSAll)); // remove duplicates - unlikely to occur but could if you added a survey to the design list of emitters and then added it on the survey side
    const pipeDataList = getPipeData(survey, design, design.delta_t_flow_return_c, design.flow_temp, heatPumpCapacityResult.capacityKw, designedEmitters, indexEmitterUUID, secondaryEmitterUUIDS);
    // Survey & Design pages
    const currentFloor = survey.floors.find(x => x.uuid === currentFloorId);
    if (currentFloor) {
        return React.createElement(FloorPage, { removeFloor: removeFloor, setCurrentFloorId: setCurrentFloorId, survey: survey, floors: survey.floors, floor: currentFloor, setFloor: setFloor, files: files, setFiles: setFiles, designTempC: designTempC, groundTempC: groundTempC, setSurvey: setSurvey, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, lead: lead, setSurveyTab: setSurveyTab, design: design, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, stageScale: stageScale, setStageScale: setStageScale, stagePosition: stagePosition, setStagePosition: setStagePosition, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, currentFloorId: currentFloorId, stageRef: stageRef, stageSize: stageSize, setStageSize: setStageSize, companyUuid: companyPublicInfo.uuid });
    }
    if (surveyTab === 'FLOOR_LIST') {
        return React.createElement(FloorList, { setFlowTemp: setFlowTemp, floors: survey.floors, setSurveyTab: setSurveyTab, setFloorId: setCurrentFloorId, stepSize: stepSize, designTempC: designTempC, groundTempC: groundTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, design: design, setCurrentRoomId: setCurrentRoomId, setSurvey: setSurvey });
    }
    if (surveyTab === 'NEW_FLOOR') {
        return React.createElement(NewFloor, { setSurveyTab: setSurveyTab, setSurvey: setSurvey, setCurrentFloorId: setCurrentFloorId, floors: survey.floors });
    }
    if (surveyTab === 'SETTINGS') {
        return React.createElement(SurveySettings, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, designTempDefault: designTempDefaultC, altitudeDefaultM: (_3 = lead.property.altitudeM) !== null && _3 !== void 0 ? _3 : 0 });
    }
    if (surveyTab === 'FLOORPLAN_FLOW') {
        return React.createElement(FloorplanFlow, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, designTempDefault: designTempDefaultC, altitudeDefaultM: (_4 = lead.property.altitudeM) !== null && _4 !== void 0 ? _4 : 0, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, setCurrentFloorId: setCurrentFloorId });
    }
    if (surveyTab === 'PROPERTY') {
        return React.createElement(SiteDetails, { lead: lead, postcodeLocation: latLng, survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, files: files, setFiles: setFiles });
    }
    if (surveyTab === 'EXISTING_HEATING') {
        return React.createElement(ExistingHeatingSystem, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, files: files, setFiles: setFiles });
    }
    if (surveyTab === 'MATERIALS') {
        return React.createElement(DefaultMaterialsTab, { setSurveyTab: setSurveyTab, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, setSurvey: setSurvey, survey: survey });
    }
    if (surveyTab === 'ELECTRICS') {
        return React.createElement(Electrics, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles });
    }
    if (surveyTab === 'PIPEWORK') {
        return React.createElement(Pipework, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab });
    }
    if (surveyTab === 'HEAT_PUMP') {
        return React.createElement(HeatPumpLocation, { setSurveyTab: setSurveyTab, survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: hydratedHeatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, flowTempC: design.flow_temp });
    }
    if (designTab === 'HEAT_PUMP') {
        return React.createElement(HeatPumpDesign, { setDesignTab: setDesignTab, survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: hydratedHeatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, scop: scopSpaceHeating, totalHeatLossKW: totalHeatLossKw });
    }
    if (designTab === 'EMITTER') {
        return React.createElement(EmitterDesignPage, { setDesignTab: setDesignTab, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, floors: survey.floors, setFloor: setFloor, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, designTempC: designTempC, groundTempC: groundTempC, survey: survey, design: design, setDesign: setDesign, annualBillEstimateGBP: annualBillEstimateGBP, files: files, emitterDemandStatus: emitterDemandStatus, companyUuid: companyPublicInfo.uuid, setFiles: setFiles, setSurvey: setSurvey });
    }
    if (surveyTab === 'CYLINDER') {
        return React.createElement(CylinderLocation, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles });
    }
    if (designTab === 'CYLINDER') {
        return React.createElement(CylinderDesignPage, { files: files, setFiles: setFiles, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, setSurvey: setSurvey, cylinderReheatRow: cylinderReheatRow, hotWaterRowNormal: hotWaterRowNormal, hotWaterRowLegionella: hotWaterRowLegionella, design: design, setDesign: setDesign, setDesignTab: setDesignTab, hotWaterCylinders: hotWaterCylinders, currentHotWaterCylinderId: design.current_hot_water_cylinder_uuid });
    }
    if (designTab === 'PIPEWORK') {
        return React.createElement(PipeworkDesignPage, { setDesignTab: setDesignTab, flowTempC: design.flow_temp, deltaTFlowReturnC: design.delta_t_flow_return_c, design: design, setDesign: setDesign, survey: survey, pipeDataList: pipeDataList, designedEmitters: designedEmitters, indexEmitterUUID: indexEmitterUUID, secondaryEmitterUUIDS: secondaryEmitterUUIDS, secondaryEmitterUUIDsThroughReplacement: secondaryEmitterUUIDsThroughReplacement });
    }
    if (designTab === 'PERFORMANCE' && lead) {
        return React.createElement(PerformanceDesignPage, { survey: survey, setSurvey: setSurvey, postcode: lead.property.postcode, setDesignTab: setDesignTab, performanceEstimateColumns: performanceEstimateColumns, performanceEstimateSummary: performanceEstimateSummary, degreeDays: degreeDays, heatLossWattsPerKelvin: heatLossWattsPerKelvin, scopSpaceHeating: scopSpaceHeating, scopHotWater: scopHotWater });
    }
    if (designTab === 'QUOTE') {
        return React.createElement(QuoteDesignPage, { setDesignTab: setDesignTab, defaultLineItems: defaultLineItems, lineItems: design.quote_line_items, setLineItems: setQuoteLineItems });
    }
    if (page === 'SURVEY') {
        return React.createElement(Survey, { lead: lead, survey: survey, setSurvey: setSurvey, surveyTab: surveyTab, setSurveyTab: setSurveyTab, stepSize: stepSize, setCurrentFloorId: setCurrentFloorId, setPage: setPage });
    }
    if (page === 'DESIGN') {
        return React.createElement(DesignHome, { design: design, setDesign: setDesign, setDesignTab: setDesignTab, soundAssessment: soundAssessment, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, heatPumpCapacityResult: heatPumpCapacityResult, totalHeatLossKwSurvey: totalHeatLossKw, totalCost: totalCost, annualBillEstimateGBP: annualBillEstimateGBP, reheatTimeHoursAndMinutes: (_5 = cylinderReheatRow === null || cylinderReheatRow === void 0 ? void 0 : cylinderReheatRow.reheatTimeHoursAndMinutes) !== null && _5 !== void 0 ? _5 : '', hotWaterConsumptionAnnual: hotWaterConsumptionAnnual, performanceEstimateColumns: performanceEstimateColumns, hddPerformanceEstimate: performanceEstimateSummary.hddEstimate, emitterColumns: emitterColumns, emitterRows: emitterRows, scop: scopSpaceHeating, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, lineItemColumns: lineItemColumns, hydratedLineItems: hydratedLineItems, setPage: setPage, emitterDemandStatus: emitterDemandStatus, pipeData: pipeDataList });
    }
    if (page === 'HEATLOSS_REPORT') {
        return React.createElement(HeatLossReport, { survey: survey, design: design, lead: lead, company: adminContext.data.company, soundAssessment: soundAssessment, designTempC: designTempC, groundTempC: groundTempC, degreeDays: degreeDays, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, cylinderReheatRow: cylinderReheatRow, hotWaterRowNormal: hotWaterRowNormal, hotWaterRowLegionella: hotWaterRowLegionella, performanceEstimateSummary: performanceEstimateSummary, performanceEstimateColumns: performanceEstimateColumns, eligibleForHeatPumpPlus: eligibleForHeatPumpPlus, files: files, setPage: setPage });
    }
    // Base Page
    const { totalWatts, heatPump: leadHeatPump, hotWaterCylinder: leadHotWaterCylinder, totalPrice, busGrantEligibleReasons, hesGrantEligibleReasons } = calculateQuote(lead, (_7 = (_6 = adminContext.data) === null || _6 === void 0 ? void 0 : _6.heatPumps) !== null && _7 !== void 0 ? _7 : [], (_9 = (_8 = adminContext.data) === null || _8 === void 0 ? void 0 : _8.hotWaterCylinders) !== null && _9 !== void 0 ? _9 : [], (_10 = adminContext.data) === null || _10 === void 0 ? void 0 : _10.company, undefined, undefined);
    const notGrantEligible = (!lead.epc_scotland && (!((_11 = lead.epcData) === null || _11 === void 0 ? void 0 : _11.lmkKey) || busGrantEligibleReasons.some(x => !x.hasPassed)));
    const isScottish = checkIfScottish(lead.property.postcode);
    const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed);
    const estimateValues = [
        { key: 'Heat loss', value: `${(totalWatts / 1000).toFixed(2)} kW` },
        { key: 'Heat pump', value: leadHeatPump === null || leadHeatPump === void 0 ? void 0 : leadHeatPump.name },
        { key: 'Cylinder', value: leadHotWaterCylinder === null || leadHotWaterCylinder === void 0 ? void 0 : leadHotWaterCylinder.name },
        {
            key: 'BUS Grant',
            value: React.createElement("div", { className: 'flex justify-end' },
                React.createElement(BUSGrantBadge, { isScottish: isScottish, notGrantEligible: notGrantEligible, notHesGrantEligible: notHesGrantEligible, lead: lead }))
        },
        { key: 'Price (post grant)', value: formatPrice(totalPrice) }
    ];
    const surveyValues = [
        { key: 'Heat loss', value: `${totalHeatLossKw.toFixed(2)} kW` },
        { key: 'Heat pump location', value: !survey.location_description || survey.location_description.length === 0 ? 'N/A' : survey.location_description, parentClass: 'truncate' },
        { key: 'Sound assessment', value: React.createElement("div", { className: 'flex justify-end' },
                React.createElement(Badge, { color: soundAssessment === 0 ? 'YELLOW' : soundAssessment <= 42 ? 'GREEN' : 'RED', text: soundAssessment === 0 ? 'Incomplete' : soundAssessment <= 42 ? 'Pass' : 'Fail' })), parentClass: '' },
        { key: 'Cylinder location', value: !survey.cylinder_location_description || survey.location_description.length === 0 ? 'N/A' : survey.cylinder_location_description, parentClass: 'truncate' }
    ];
    const designValues = [
        { key: 'Heat pump', value: (_12 = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name) !== null && _12 !== void 0 ? _12 : 'N/A' },
        { key: 'Cylinder', value: (_13 = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.name) !== null && _13 !== void 0 ? _13 : 'N/A' },
        { key: 'Flow temperature', value: (_14 = design.flow_temp + ' °C') !== null && _14 !== void 0 ? _14 : 'N/A' },
        { key: 'Radiator changes', value: totalNewRadiators }
    ];
    // If the user is a surveyor and doesn't have access to this specific survey, show error page
    const userRole = getRoleForCompany((_15 = adminContext.data) === null || _15 === void 0 ? void 0 : _15.user, (_17 = (_16 = adminContext.data) === null || _16 === void 0 ? void 0 : _16.company) === null || _17 === void 0 ? void 0 : _17.public_info.subdomain);
    if (userRole === USER_ROLE_SURVEYOR && !canViewSurvey({ user: (_18 = adminContext.data) === null || _18 === void 0 ? void 0 : _18.user, leadUUID: survey.uuid, companySubdomain: (_20 = (_19 = adminContext.data) === null || _19 === void 0 ? void 0 : _19.company) === null || _20 === void 0 ? void 0 : _20.public_info.subdomain })) {
        return React.createElement(UnauthorizedPage, null);
    }
    const sendInvitation = async (email) => {
        if (!email)
            return;
        const invitation = await createSurveyInvitation({
            email,
            companyUuid: companyPublicInfo.uuid,
            surveyUuid: leadUUID
        });
        if (invitation) {
            setInvitations([...invitations, invitation]);
        }
    };
    const revokeSurveyInvitation = async (uuid) => {
        const success = await revokeInvitation(uuid);
        if (success) {
            setInvitations(invitations.filter(x => x.uuid !== uuid));
        }
    };
    return (React.createElement(RequireRole, { roles: [USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR] },
        React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement("div", { className: 'flex flex-col gap-3 px-5 py-4 border-b border-gray-200' },
                React.createElement("div", { className: 'flex flex-wrap items-center gap-3' },
                    React.createElement("div", { className: 'cursor-pointer flex flex-wrap gap-3 items-center', onClick: () => history.back() },
                        React.createElement(FontAwesomeIcon, { className: 'text-gray-600', icon: faChevronLeft }),
                        React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, (_21 = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _21 === void 0 ? void 0 : _21.name),
                        React.createElement("div", { className: 'text-sm text-gray-600' }, lead && getAddressIncludingPostcode(lead))))),
            React.createElement(OfflineBlock, null),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-4 flex" },
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Estimate', onClick: () => navigate(`/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}`), disabled: !hasEnquriesAccess((_22 = adminContext.data) === null || _22 === void 0 ? void 0 : _22.company, (_23 = adminContext.data) === null || _23 === void 0 ? void 0 : _23.user) },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, estimateValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: "text-xs flex-1 font-semibold text-end" }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Survey', onClick: () => setPage('SURVEY') },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, surveyValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: `text-xs flex-1 font-semibold text-end ${x.parentClass}` }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Design', onClick: () => setPage('DESIGN') },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, designValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: "text-xs flex-1 font-semibold text-end" }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Report', onClick: () => setPage('HEATLOSS_REPORT') }),
                [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SUPERADMIN].includes(getRoleForCompany((_24 = adminContext === null || adminContext === void 0 ? void 0 : adminContext.data) === null || _24 === void 0 ? void 0 : _24.user, (_26 = (_25 = adminContext === null || adminContext === void 0 ? void 0 : adminContext.data) === null || _25 === void 0 ? void 0 : _25.company) === null || _26 === void 0 ? void 0 : _26.public_info.subdomain)) &&
                    React.createElement(Invitations, { invitations: invitations, handleSendInvitation: sendInvitation, handleRevokeInvitation: revokeSurveyInvitation, title: 'Invite someone to this survey', helpText: 'People you invite to a survey will only have access to that survey, and will not be able to see your enquiries, costs, or inventory.' })))));
};
const handleEstimateMapping = (lead, genericMaterials, heatPumps, hotWaterCylinders, company, survey, setSurvey, setCustomMaterials) => {
    var _a, _b, _c, _d, _e, _f, _g;
    // MATERIALS
    // if survey does not have default materials, we need to provide them
    // usually applicable to the first time survey creation
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_MATERIALS)) {
        const mappedMaterials = mapEstimateMaterials(lead, genericMaterials);
        instantiateMaterialSet(mappedMaterials, setCustomMaterials);
        setSurvey(prev => ({
            ...prev,
            default_materials: mappedMaterials,
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_MATERIALS
        }));
    }
    // AGE BAND
    // check either the age_band is set OR has the EstimateAgeBand type (the `id` attribute is only available in the SurveyAgeBand type)
    // in both cases we need to set the age_band attribute from the EPC or the lead (the method chooses it)
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_AGE_BAND)) {
        setSurvey(prev => ({
            ...prev,
            age_band: leadAgeBandToSurveyAgeBand(lead),
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_AGE_BAND
        }));
    }
    // HEATING SYSTEM
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_FUEL_TYPE)) {
        const estimateFuelType = ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.fuelType) ? lead.property.houseOverrides.fuelType : lead.property.fuelType;
        let mappedUUID;
        switch (estimateFuelType.toLowerCase()) {
            case 'mains gas':
                mappedUUID = (_b = HEATING_FUELS.find(x => x.uuid === 'mains_gas')) === null || _b === void 0 ? void 0 : _b.uuid;
                break;
            case 'oil':
                mappedUUID = (_c = HEATING_FUELS.find(x => x.uuid === 'oil')) === null || _c === void 0 ? void 0 : _c.uuid;
                break;
            case 'lpg':
                mappedUUID = (_d = HEATING_FUELS.find(x => x.uuid === 'lpg')) === null || _d === void 0 ? void 0 : _d.uuid;
                break;
            case 'electric':
                mappedUUID = (_e = HEATING_FUELS.find(x => x.uuid === 'electricity')) === null || _e === void 0 ? void 0 : _e.uuid;
                break;
            case 'other':
                // Map other to mains gas for now
                mappedUUID = (_f = HEATING_FUELS.find(x => x.uuid === 'mains_gas')) === null || _f === void 0 ? void 0 : _f.uuid;
                break;
        }
        setSurvey(prev => ({
            ...prev,
            existing_system_fuel_uuid: mappedUUID !== null && mappedUUID !== void 0 ? mappedUUID : '',
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_FUEL_TYPE
        }));
    }
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP) ||
        !isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
        // Calculate heat pump and cylinder size based on lead data as is.
        // One time mapping so that the heat loss survey being incomplete doesn't result in us suggesting tiny heat pumps
        const { heatPump: leadHeatPump, hotWaterCylinder: leadHotWaterCylinder } = calculateQuote(lead, heatPumps, hotWaterCylinders, company, undefined, undefined);
        // heat pump uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP)) {
            setSurvey(prev => {
                const design = prev.designs[0];
                design.current_heat_pump_uuid = leadHeatPump === null || leadHeatPump === void 0 ? void 0 : leadHeatPump.uuid;
                return {
                    ...prev,
                    designs: [design],
                    flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_HEAT_PUMP
                };
            });
        }
        // hot water cylinder uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
            setSurvey(prev => {
                const design = prev.designs[0];
                design.current_hot_water_cylinder_uuid = leadHotWaterCylinder === null || leadHotWaterCylinder === void 0 ? void 0 : leadHotWaterCylinder.uuid;
                return {
                    ...prev,
                    designs: [design],
                    flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_HOT_WATER_CYLINDER
                };
            });
        }
    }
    // quote line items
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS)) {
        // The design's quote_line_items attribute should be empty here, so we just override it with our mapped values
        // It means, if there was something in the quote_line_items, it will be removed
        const items = [];
        (_g = lead.lead_line_items) === null || _g === void 0 ? void 0 : _g.forEach(x => {
            items.push({
                uuid: undefined,
                name: x.name,
                quantity: 1,
                value: x.value,
                type: 'ADDITIONAL'
            });
        });
        setSurvey(prev => {
            const design = prev.designs[0];
            if (!design.quote_line_items)
                design.quote_line_items = [];
            design.quote_line_items = items;
            return {
                ...prev,
                designs: [design],
                flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_QUOTE_LINE_ITEMS
            };
        });
    }
    // design outdoor temp — override if the design temp is set explicitly,
    // otherwise use a default value which is provided separately in the UI
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_DESIGN_TEMP)) {
        setSurvey(prev => {
            var _a, _b, _c, _d;
            return ({
                ...prev,
                design_temp_override_c: ((_b = (_a = lead.property) === null || _a === void 0 ? void 0 : _a.houseOverrides) === null || _b === void 0 ? void 0 : _b.designTempOverride) ? (_d = (_c = lead.property) === null || _c === void 0 ? void 0 : _c.houseOverrides) === null || _d === void 0 ? void 0 : _d.designTempOverride : prev.design_temp_override_c,
                flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_DESIGN_TEMP
            });
        });
    }
    // indoor temp — override if the indoor temp is set explicitly
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_INDOOR_TEMP)) {
        setSurvey(prev => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                ...prev,
                indoor_temp_overall_c: ((_b = (_a = lead.property) === null || _a === void 0 ? void 0 : _a.houseOverrides) === null || _b === void 0 ? void 0 : _b.internalTempOverride) ? (_d = (_c = lead.property) === null || _c === void 0 ? void 0 : _c.houseOverrides) === null || _d === void 0 ? void 0 : _d.internalTempOverride : prev.indoor_temp_overall_c,
                use_cibse_indoor_temps: !((_f = (_e = lead.property) === null || _e === void 0 ? void 0 : _e.houseOverrides) === null || _f === void 0 ? void 0 : _f.internalTempOverride), // if the override is set, we don't use the CIBSE values
                flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_INDOOR_TEMP
            });
        });
    }
    // ACH
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_ACH)) {
        setSurvey(prev => {
            var _a, _b, _c, _d, _e, _f;
            return ({
                ...prev,
                air_change_per_hour_overall: ((_b = (_a = lead.property) === null || _a === void 0 ? void 0 : _a.houseOverrides) === null || _b === void 0 ? void 0 : _b.airChangeOverride) ? (_d = (_c = lead.property) === null || _c === void 0 ? void 0 : _c.houseOverrides) === null || _d === void 0 ? void 0 : _d.airChangeOverride : prev.air_change_per_hour_overall,
                use_cibse_air_change_values: !((_f = (_e = lead.property) === null || _e === void 0 ? void 0 : _e.houseOverrides) === null || _f === void 0 ? void 0 : _f.airChangeOverride), // if the override is set, we don't use the CIBSE values
                air_change_year_uuid: prev.age_band ? prev.age_band.ach_age_key : prev.air_change_year_uuid,
                flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_ACH
            });
        });
    }
    // Property type
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_PROPERTY_TYPE)) {
        setSurvey(prev => ({
            ...prev,
            property_type: lead.property.propertyType,
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_PROPERTY_TYPE
        }));
    }
    // Built form
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BUILT_FORM)) {
        setSurvey(prev => ({
            ...prev,
            built_form: lead.property.builtForm,
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_BUILT_FORM
        }));
    }
    // Bedrooms and bathroooms
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS)) {
        setSurvey(prev => ({
            ...prev,
            bedrooms: lead.property.noBedrooms,
            bathrooms: lead.property.noBathrooms,
            flags_estimate_mappings: prev.flags_estimate_mappings | ESTIMATE_MAPPED_BEDROOMS_BATHROOMS
        }));
    }
    // OTHER ATTRIBUTES: not mapping them because there is no UI to change them in the Survey. Once we have the UI, we can add the mapping for them.
    // ~Address~ — taken from the Lead in the UI
    // ~total floor area~ — taken from the Lead (lead?.property.floorArea) in the UI
};
