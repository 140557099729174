import { HeatPump, Leaves, Luggage } from '../assets/images/survey_images/survey_images';
import React, { useEffect, useState } from 'react';
import { Accordion } from '../components/content_display/accordion';
import { Button } from '../components/buttons/button';
import { useParams, useRouteLoaderData } from 'react-router-dom';
import { getCompanyBookingConfirmationActionEmailTemplate, getCompanyBookingConfirmationNoActionEmailTemplate } from '../code/models/company';
import { getCalculatedQuote, sendCallback, sendRejected, sendSurvey, updateCalculatedQuote } from '../code/models/calculated_quote';
import { checkIfEligibleForBusGrant, getFlightsSaved } from '../code/calculate_quote';
import { numberFormat } from '../code/number_format';
import { ColoredBlockWrapper } from './survey/components/colored_block_wrapper';
import { LogoHeader } from './survey/components/logo_header';
import { StepHeader } from './survey/components/steps_header';
import { SubHeader } from './survey/components/sub_header';
import { faqRows } from './survey/steps/get_contacts_step';
import { checkIfScottish } from '../code/models/address';
import { Link } from '../components/buttons/link';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { logEvent } from '../code/log_event';
import { formatPrice } from '../code/format_price';
import { NextStepsComponent } from './survey/components/next_steps';
import { renderHTMLReplacingPlaceholders, TTPlaceholderCompanyName, TTPlaceholderCompanyPhone, TTPlaceholderCustomerName } from '../code/tiptap_placeholders';
import { openInNewTab } from '../code/helpers';
export const EstimatePage = () => {
    const { estimateId } = useParams();
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [estimate, setEstimate] = useState();
    const [isScottish, setIsScottish] = useState(false);
    const [grantEligible, setGrantEligible] = useState(false);
    useEffect(() => {
        const asyncCall = async () => {
            var _a, _b;
            const estimate = await getCalculatedQuote(estimateId, companyPublicInfo.uuid);
            if (((estimate === null || estimate === void 0 ? void 0 : estimate.status) === 'Sent' || !(estimate === null || estimate === void 0 ? void 0 : estimate.status)) && !isInstallerClick)
                await updateCalculatedQuote({ ...estimate, status: 'Opened' }, companyPublicInfo.subdomain);
            setEstimate(estimate);
            setIsScottish(checkIfScottish(estimate.postcode));
            if (isScottish || !estimate.lmk_key)
                return;
            const epc = estimate === null || estimate === void 0 ? void 0 : estimate.epc;
            const inspectionDate = (epc === null || epc === void 0 ? void 0 : epc.inspectionDate) ? new Date(epc === null || epc === void 0 ? void 0 : epc.inspectionDate) : undefined;
            // the epc_recommendations might be not stored in the Lead
            // if installer have sent the estimate before our release to store the epc_recommendations in the Lead
            // in that case we just don't show the green box on the page
            if ((_a = estimate.lead) === null || _a === void 0 ? void 0 : _a.epc_recommendations) {
                const grantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, (_b = estimate.lead) === null || _b === void 0 ? void 0 : _b.epc_recommendations);
                setGrantEligible(isScottish || grantEligibleReasons.every(x => x.hasPassed));
            }
        };
        if (estimateId)
            asyncCall();
    }, [estimateId]);
    if (!estimate)
        return;
    return React.createElement(EstimatePageInner, { estimate: estimate, isScottish: isScottish, grantEligible: grantEligible, companyPublicInfo: companyPublicInfo });
};
export const EstimatePageInner = ({ estimate, isScottish, grantEligible, companyPublicInfo }) => {
    var _a;
    const [body, setBody] = useState();
    if (!estimate)
        return;
    // Only show heat loss button and text if english property and the installer allows survey booking
    const showHeatLossSurveyButton = !companyPublicInfo.no_survey_booking && !isScottish;
    // on Request Callback button click
    const handlerRequestCallback = async () => {
        logEvent({ name: 'Request a callback clicked', properties: {} }, companyPublicInfo.subdomain);
        await sendCallback(estimate.uuid, companyPublicInfo.uuid);
        setBody(React.createElement("div", null,
            "You have asked for a ",
            React.createElement("span", { className: "font-semibold" }, "callback"),
            ". We'll be in touch within the next 24 hours to discuss your heat pump."));
    };
    // on Not Interested button click
    const handlerNotInterested = async () => {
        logEvent({ name: 'Not interested clicked', properties: {} }, companyPublicInfo.subdomain);
        await sendRejected(estimate, companyPublicInfo.uuid);
        setBody(React.createElement("div", null, "Don't hesitate to get in touch if you change your mind."));
    };
    // on Request Survey button click
    const handlerRequestSurvey = async () => {
        var _a, _b;
        logEvent({ name: 'Request heat loss survey clicked', properties: {} }, companyPublicInfo.subdomain);
        let actionTemplateRendered;
        if (companyPublicInfo.booking_redirect_enabled) {
            actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationActionEmailTemplate(companyPublicInfo.booking_confirmation_action_template), {
                [TTPlaceholderCustomerName.id]: (_a = estimate.name) !== null && _a !== void 0 ? _a : '',
                [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
                [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone
            });
            openInNewTab(companyPublicInfo.booking_redirect_url);
        }
        else {
            actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationNoActionEmailTemplate(companyPublicInfo.booking_confirmation_noaction_template), {
                [TTPlaceholderCustomerName.id]: (_b = estimate.name) !== null && _b !== void 0 ? _b : '',
                [TTPlaceholderCompanyName.id]: companyPublicInfo.name,
                [TTPlaceholderCompanyPhone.id]: companyPublicInfo.phone
            });
        }
        await sendSurvey(estimate, companyPublicInfo.uuid, actionTemplateRendered);
        if (companyPublicInfo.booking_redirect_enabled) {
            setBody(React.createElement("div", null,
                "Please book directly on the ",
                React.createElement("a", { className: 'underline text-blue-700', href: companyPublicInfo.booking_redirect_url, target: '_blank', rel: "noreferrer" }, "linked page"),
                "."));
        }
        else {
            setBody(React.createElement("div", null,
                "You have asked for a ",
                React.createElement("span", { className: "font-semibold" }, "survey"),
                ". We'll be in touch within the next 24 hours to arrange a time for your survey."));
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("div", { className: 'border-solid border-b border-gray-300' },
                React.createElement(LogoHeader, { companyPublicInfo: companyPublicInfo, center: true })),
            React.createElement("div", { className: "px-7 py-8 flex-col gap-8 flex max-w-xl mx-auto w-full" },
                React.createElement(StepHeader, { guideText: estimate.address + ', ' + estimate.postcode, headerText: "Your heat pump estimate", captionText: `Thanks ${estimate.name}! Based on the details you provided we've run some numbers on your property.` }),
                React.createElement("div", { className: "flex flex-col gap-4" },
                    React.createElement(ColoredBlockWrapper, { color: "yellow" },
                        React.createElement("div", { className: "w-full flex flex-row justify-start items-start gap-5" },
                            React.createElement("img", { src: Luggage, alt: "Luggage", className: "w-10 py-2" }),
                            React.createElement("div", { className: "gap-1 flex-col flex" },
                                React.createElement(SubHeader, { size: 'sm', content: `We estimate your property has ${(estimate.total_watts / 1000).toFixed(1)}kW of heat loss` }),
                                React.createElement("div", { className: "mix-blend-luminosity text-gray-600 text-xs font-medium  " },
                                    "You could reduce your annual carbon footprint by ",
                                    numberFormat(0).format(estimate.co2_saved_kg),
                                    " kg of CO2 by switching to a heat pump (based on UK government forecasts for decarbonisation of the grid). That's equivalent to ",
                                    ' ' + getFlightsSaved(estimate.co2_saved_kg),
                                    " flights to Spain every year.")))),
                    React.createElement(ColoredBlockWrapper, { color: "gray" },
                        React.createElement("div", { className: "w-full flex flex-row justify-start items-start gap-5" },
                            React.createElement("img", { src: HeatPump, alt: "Heat pump", className: "w-10 py-2" }),
                            React.createElement("div", { className: "gap-1 flex-col flex" },
                                React.createElement(SubHeader, { size: 'sm', content: `We suggest a ${estimate.heat_pump_name} heat pump` }),
                                React.createElement("div", { className: "mix-blend-luminosity text-gray-600 text-xs font-medium" }, "This will provide heating and hot water for your home with optimal efficiency. Let us know if there is another brand you are interested in as we may be able to cater for this.")))),
                    (isScottish || grantEligible) &&
                        React.createElement(ColoredBlockWrapper, { color: "green" },
                            React.createElement("div", { className: "flex gap-5" },
                                React.createElement("img", { src: Leaves, alt: "Leaves", className: "w-10 py-2" }),
                                React.createElement("div", { className: "gap-1 flex-col flex" },
                                    React.createElement(SubHeader, { size: 'sm', content: isScottish ? 'You may be eligible for a grant of up to £7,500-£9,000 from Home Energy Scotland, plus an interest free loan of up to £7,500' : 'You are likely to be eligible for a boiler upgrade scheme grant of £7,500' }))))),
                React.createElement("div", { className: "w-full flex flex-col gap-4" },
                    React.createElement(SubHeader, { content: "Cost estimate" }),
                    React.createElement("div", { className: "space-y-2 text-sm flex flex-col w-full" },
                        React.createElement("div", { className: "flex flex-col" },
                            React.createElement("div", { className: "flex gap-x-4 justify-between font-bold text-gray-900" },
                                React.createElement("div", { className: "font-semibold" },
                                    React.createElement("span", { className: "me-2" }, "\u26A1\uFE0F"),
                                    "Total Cost (Pre-Grant)"),
                                React.createElement("div", null, formatPrice(estimate.total_price - estimate.grant))),
                            React.createElement("div", { className: "space-y-2 text-sm flex flex-col indent-5" },
                                React.createElement("div", { className: "w-full border-t border-gray-300 my-2" }),
                                (estimate === null || estimate === void 0 ? void 0 : estimate.heat_pump_price) && estimate.heat_pump_price > 0 ? React.createElement("div", { className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83D\uDCA8"),
                                        " ",
                                        estimate.heat_pump_name),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(estimate.heat_pump_price) : '')) : undefined,
                                (estimate === null || estimate === void 0 ? void 0 : estimate.hot_water_cylinder_price) && (estimate === null || estimate === void 0 ? void 0 : estimate.hot_water_cylinder_price) > 0 ? React.createElement("div", { className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83D\uDEB0"),
                                        " ",
                                        estimate.hot_water_cylinder_name),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(estimate.hot_water_cylinder_price) : '')) : undefined,
                                (estimate === null || estimate === void 0 ? void 0 : estimate.heat_pump_parts) && (estimate === null || estimate === void 0 ? void 0 : estimate.heat_pump_parts) > 0 ? React.createElement("div", { className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83D\uDD29"),
                                        " Parts & fittings"),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(estimate.heat_pump_parts) : '')) : undefined,
                                (estimate === null || estimate === void 0 ? void 0 : estimate.labour) && (estimate === null || estimate === void 0 ? void 0 : estimate.labour) > 0 ? React.createElement("div", { className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83D\uDC77"),
                                        " Installation"),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(estimate.labour) : '')) : undefined, (_a = estimate === null || estimate === void 0 ? void 0 : estimate.calculated_estimate_line_items) === null || _a === void 0 ? void 0 :
                                _a.map(x => React.createElement("div", { key: x.uuid, className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\u2795"),
                                        " ",
                                        x.name),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.value) : ''))),
                                (estimate === null || estimate === void 0 ? void 0 : estimate.survey) && estimate.survey > 0 ? React.createElement("div", { className: "flex gap-x-4 justify-between" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83D\uDD75"),
                                        " Survey"),
                                    React.createElement("div", null, companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(estimate.survey) : '')) : undefined),
                            !isScottish && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "w-full border-t border-gray-300 my-2" }),
                                React.createElement("div", { className: "flex gap-x-4 justify-between font-bold text-gray-900" },
                                    React.createElement("div", { className: "font-semibold" },
                                        React.createElement("span", { className: "me-2" }, "\uD83C\uDF31"),
                                        " Boiler Upgrade Scheme (BUS) Grant"),
                                    React.createElement("div", null, formatPrice(estimate.grant)))),
                            React.createElement("div", { className: "w-full border-t border-gray-300 my-2" })),
                        React.createElement("div", { className: "justify-end items-start inline-flex" },
                            React.createElement("div", { className: "text-right text-gray-900 text-2xl font-bold" }, formatPrice(estimate.total_price))),
                        React.createElement("div", { className: "flex mt-4 flex-row gap-4 items-center bg-amber-100 text-sm p-4 rounded-lg text-red-900" },
                            React.createElement(ExclamationCircleIcon, { className: 'w-16' }),
                            "Please note this is an initial estimate based on the information we have so far. We'll be able to provide a final quote once we've done a room-by-room heat loss survey of your property."))),
                React.createElement(NextStepsComponent, { isScottish: isScottish, isChecked: true }),
                React.createElement(ColoredBlockWrapper, { color: "blue" },
                    React.createElement("div", { className: "flex flex-col gap-6" },
                        body && React.createElement("div", { className: "flex flex-col gap-3" },
                            React.createElement(SubHeader, { content: "Thank you" }),
                            body),
                        !body && React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "flex flex-col gap-4" },
                                showHeatLossSurveyButton &&
                                    React.createElement("div", { className: "flex flex-col gap-3" },
                                        React.createElement(SubHeader, { content: "Book your heat loss survey" }),
                                        React.createElement("div", { className: "mix-blend-luminosity text-gray-600 text-sm" }, "If you'd like to proceed, the next step is to do an in-person heat loss survey of your property. You'll receive a detailed room-by-room heat loss report as well as a final quote for the suggested set of works."),
                                        React.createElement("div", { className: "mix-blend-luminosity text-gray-600 text-sm" },
                                            "This will cost \u00A3", estimate === null || estimate === void 0 ? void 0 :
                                            estimate.survey.toFixed(0),
                                            " and is already included in the total estimated cost above.")),
                                showHeatLossSurveyButton &&
                                    React.createElement("div", { className: "flex flex-col gap-1" },
                                        React.createElement(Button, { onClick: handlerRequestSurvey }, "Book a heat loss survey")),
                                !showHeatLossSurveyButton && React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: "flex flex-col gap-3" },
                                        React.createElement(SubHeader, { content: "Let us know if you\u2019d like to proceed" }),
                                        React.createElement("div", { className: "mix-blend-luminosity text-gray-600 text-sm" }, "Please request a callback so we can answer any outstanding questions and book in the next steps. And if you\u2019re not interested in progressing things right now, please just let us know below."))),
                                React.createElement(Button, { colour: !showHeatLossSurveyButton ? 'DARK' : 'LIGHT', onClick: handlerRequestCallback }, "Request a callback"),
                                isScottish && React.createElement(React.Fragment, null,
                                    React.createElement(Button, { colour: "LIGHT" },
                                        React.createElement("a", { target: "_blank", href: "https://www.homeenergyscotland.org/funding/grants-loans/", className: "font-semibold", rel: "noreferrer" }, "Visit Home Energy Scotland"))),
                                React.createElement(Link, { onClick: handlerNotInterested, className: "text-center text-sm" }, "I'm not interested right now"))))),
                React.createElement("div", { className: "w-full flex flex-col justify-start items-start gap-1" },
                    React.createElement(SubHeader, { content: "Common questions" }),
                    React.createElement(Accordion, { rows: faqRows() })))));
};
