import React, { useEffect } from 'react';
import { Select } from '../../../components/inputs_and_selections/select';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
export const EditMaterialLayerBlock = ({ layer, setLayer, materialLayers, onSave, onCancel }) => {
    const [isValid, setIsValid] = React.useState(true);
    useEffect(() => {
        // check validation
        setIsValid(layer.material_layer && layer.layer_thickness_mm > 0);
    }, [layer]);
    const thicknessValidator = (e) => isNaN(Number(e)) || !e || Number(e) < 0 ? { value: undefined, message: 'Not a valid number.' } : { value: Number(e), message: '' };
    const getLayerDescription = (layer) => {
        if (layer.extra_data.const_r_value) {
            return layer.name;
        }
        else {
            return layer.name + ' - ' + layer.extra_data.conductivity_w_per_m_k + ' W/mK';
        }
    };
    // NB! don't use the Section component here until it supports showing dropdowns properly (they got hidden inside the Section)
    // TODO: should be able to refactor this to use navCard component
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'w-full p-4 bg-gray-100 rounded-lg flex flex-col justify-start items-start gap-4' },
            React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Material"),
                React.createElement(Select, { filter: true, options: materialLayers
                        .sort((a, b) => {
                        const order = { air: 0, wall: 1, insulation: 2, surface: 3 };
                        const aKey = Object.keys(order).find(key => a.name.toLowerCase().startsWith(key)) || a.name;
                        const bKey = Object.keys(order).find(key => b.name.toLowerCase().startsWith(key)) || b.name;
                        if (aKey !== bKey)
                            return order[aKey] - order[bKey];
                        return a.name.localeCompare(b.name);
                    })
                        .map(ml => ({ key: ml.uuid, value: getLayerDescription(ml) })), setSelectedKey: x => {
                        const ml = materialLayers.find(y => y.uuid === x);
                        setLayer({ ...layer, material_layer: ml });
                    }, selectedKey: layer.material_layer.uuid })),
            React.createElement("div", { className: 'flex flex-col gap-2 self-stretch' },
                React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Thickness"),
                React.createElement(Input, { postfix: React.createElement("span", { className: 'text-gray-500' }, "mm"), value: layer.layer_thickness_mm.toString(), validator: thicknessValidator, size: 'MD', type: "number", step: 1, setValue: (value) => {
                        setLayer({ ...layer, layer_thickness_mm: Number(value) });
                    } })),
            React.createElement("div", { className: 'flex flex-row justify-between items-center gap-4' },
                React.createElement(Button, { disabled: !isValid, colour: 'DARK', onClick: onSave }, layer.uuid ? 'Update' : 'Add'),
                React.createElement(Button, { colour: 'LIGHT', onClick: onCancel }, "Cancel"))));
};
