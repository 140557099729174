import Dexie from 'dexie';
import { apiUrl, client } from './axios';
import { AuthSDK } from './utils/auth_provider';
import { base64DataURLtoFile, fileToBase64 } from './helpers';
class DexieWrapper extends Dexie {
    constructor(companyUUID, urlNamespace, tableName) {
        super(tableName);
        this.companyUUID = undefined;
        this.tableName = undefined;
        this.urlNamespace = undefined;
        this.companyUUID = companyUUID;
        this.tableName = tableName;
        this.urlNamespace = urlNamespace;
    }
    getTableName() {
        return this.tableName;
    }
    // just a stub, to be replaced in a child class
    async serialize(data) {
        return data;
    }
    // just a stub, to be replaced in a child class
    async deserialize(data) {
        return data;
    }
    async getServerItemCheckpointTime(uuid, onError) {
        try {
            const result = await client.get(`${apiUrl}teams/${this.companyUUID}/sync/${this.urlNamespace}/${uuid}/checkpoint_time`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
            if (result.status !== 200) {
                onError();
                return undefined;
            }
            return result.data;
        }
        catch (e) {
            console.error(e);
            onError();
        }
    }
    async postLocalItemToServer(uuid, data, onError) {
        try {
            // serialize data
            const syncData = {
                ...data,
                sync_item: {
                    ...data.sync_item,
                    data: (await this.serialize(data.sync_item.data))
                },
                store_conflicts: data.store_conflicts
            };
            // make a request
            const result = await client.post(`${apiUrl}teams/${this.companyUUID}/sync/${this.urlNamespace}/${uuid}`, syncData, { headers: { 'x-auth-token': AuthSDK.getToken() } });
            if (result.status === 200) {
                return {
                    uuid: result.data.uuid,
                    data: await this.deserialize(result.data.data),
                    checkpoint_time: result.data.checkpoint_time
                };
            }
            onError();
        }
        catch (e) {
            console.error(e);
            onError();
        }
        return undefined;
    }
}
export class SurveyDexieWrapper extends DexieWrapper {
    constructor(companyUUID) {
        super(companyUUID, 'surveys', 'surveys');
        this.version(1).stores({
            surveys: '++uuid, data, updated_at' // Primary key and indexed props
        });
    }
}
export class FilesDexieWrapper extends DexieWrapper {
    constructor(companyUUID) {
        super(companyUUID, 'files', 'files');
        this.version(1).stores({
            files: '++uuid, data, updated_at' // Primary key and indexed props
        });
    }
    async serialize(data) {
        const syncData = data;
        return await Promise.all(syncData.map(async (file) => {
            return {
                uuid: file.uuid,
                data64: await fileToBase64(file.file)
            };
        }));
    }
    async deserialize(data) {
        if (!data)
            return data;
        return data.map(file => {
            const fileData = base64DataURLtoFile(file.data64, file.uuid);
            return {
                uuid: file.uuid,
                file: fileData
            };
        });
    }
}
export class MaterialsDexieWrapper extends DexieWrapper {
    constructor(companyUUID) {
        super(companyUUID, 'custom_materials', 'materials');
        this.version(1).stores({
            materials: '++uuid, data, updated_at' // Primary key and indexed props
        });
    }
}
