import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon';
import React from 'react';
export const SurveyOptionGroup = ({ options, selectedOptionKey, onSelect, noCheckmark = false, inline = false }) => {
    // a function to calc class for selected option
    const selectedClass = (key) => {
        if (selectedOptionKey === key) {
            return 'border-solid border-2 border-gray-700';
        }
        else {
            // unselected option also has an invisible border (color is the same as background),
            // it prevents the layout from jumping when the selected state visible border appears
            // playing with padding didn't help
            return 'border-solid border-2 border-gray-100 bg-gray-100';
        }
    };
    const positioningClasses = inline ? 'flex-row' : 'flex-col';
    return React.createElement("div", { className: `${positioningClasses} w-full gap-3 flex` }, options.map((option, index) => {
        return React.createElement("div", { key: index, onClick: () => { onSelect === null || onSelect === void 0 ? void 0 : onSelect(option.key); }, className: `${selectedClass === null || selectedClass === void 0 ? void 0 : selectedClass(option.key)} cursor-pointer rounded-xl items-center flex justify-between p-2 sm:p-3` },
            React.createElement("div", { className: "flex gap-x-4 items-center" },
                option.icon && React.createElement("img", { className: "h-8 w-8 sm:h-16 sm:w-16", alt: option.label, src: option.icon }),
                React.createElement("div", { className: "flex-col flex text-sm sm:text-base" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, option.label),
                    option.sublabel && React.createElement("div", { className: "select-none text-gray-500" }, option.sublabel))),
            !noCheckmark && React.createElement(React.Fragment, null,
                selectedOptionKey === option.key && React.createElement(CheckCircleIcon, { className: "w-5 h-5" }),
                selectedOptionKey !== option.key && React.createElement("div", { className: "w-5 h-5" },
                    React.createElement("div", { className: "w-5 h-5 bg-white rounded-full border-solid border border-neutral-300" }))));
    }));
};
