import { getEpcRecommendations } from './epc';
import { getBuiltForm, getBuiltFormScotland, getConstructionAge, getConstructionAgeScotland, getFloorInsulation, getLoftInsulation, getPropertyType, getPropertyTypeScotland, getUValue, getWallGroup, getWallType, getWindowType, getWindowTypeScotland, parseConstructionAgeBand } from './u_value';
import { tryParseFloat } from '../helpers';
import { getAddressIncludingPostcode, patchLead } from './lead';
import { getFuelTypeFromEPC } from './fuel';
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_QUOTE_LINE_ITEM, DEFAULT_SURVEY_DESIGN, DEFAULT_SURVEY_DESIGN_BOILER, DEFAULT_DESIGN_RADIATOR, DEFAULT_SURVEY_DOOR, DEFAULT_SURVEY_FLOOR, DEFAULT_SURVEY_IMAGE, DEFAULT_SURVEY_RADIATOR, DEFAULT_SURVEY_ROOM, DEFAULT_SURVEY_WALL, DEFAULT_SURVEY_WINDOW } from '../survey_defaults';
import { geocodeAddress } from '../geocoding';
export const DEFAULT_PROPERTY = {
    roomHeight: 2.4,
    address: '',
    yearBuilt: '',
    postcode: '',
    floorArea: 0,
    floorAreaUnits: 'sqm',
    noBathrooms: 0,
    noBedrooms: 0,
    builtForm: '',
    propertyType: '',
    wallGroup: '',
    wallType: '',
    floorType: 'None',
    loftInsulation: '',
    windowType: '',
    fuelType: ''
};
// to avoid mapping something twice, we flag things as mapped when it's done
// we're using the flagging approach because estimate might has more values to be mapped in the future and we need to map them skipping already mapped
export const ESTIMATE_MAPPED_MATERIALS = 0x0001;
export const ESTIMATE_MAPPED_AGE_BAND = 0x0002;
export const ESTIMATE_MAPPED_FUEL_TYPE = 0x0004;
export const ESTIMATE_MAPPED_HEAT_PUMP = 0x0008;
export const ESTIMATE_MAPPED_HOT_WATER_CYLINDER = 0x0010;
export const ESTIMATE_MAPPED_DESIGN_TEMP = 0x0020;
export const ESTIMATE_MAPPED_INDOOR_TEMP = 0x0040;
export const ESTIMATE_MAPPED_ACH = 0x0080;
export const ESTIMATE_MAPPED_QUOTE_LINE_ITEMS = 0x0100;
export const ESTIMATE_MAPPED_PROPERTY_TYPE = 0x0200;
export const ESTIMATE_MAPPED_BUILT_FORM = 0x0400;
export const ESTIMATE_MAPPED_BEDROOMS_BATHROOMS = 0x0800;
export const pipeMaterials = ['Copper', 'PEX', 'MLCP'];
export const isPreFlowFeatureSurvey = (survey) => {
    // disable flow for surveys created on or before 2024-08-05 00:00:00
    return !survey.created_at || survey.created_at <= Date.parse('2024-08-05');
};
export const ensurePropertySurveyBackwardsCompatibility = (survey) => {
    var _a;
    const result = {
        ...DEFAULT_PROPERTY_SURVEY,
        ...survey,
        // if the survey.created_at is not set, set it to zero
        created_at: (_a = survey.created_at) !== null && _a !== void 0 ? _a : 0,
        // Migrations m to mm
        // Heat pump available space
        available_space_width_mm: survey.available_space_width_mm ? survey.available_space_width_mm : survey.available_space_width_m ? survey.available_space_width_m * 1000 : 0,
        available_space_width_m: undefined,
        available_space_height_mm: survey.available_space_height_mm ? survey.available_space_height_mm : survey.available_space_height_m ? survey.available_space_height_m * 1000 : 0,
        available_space_height_m: undefined,
        available_space_depth_mm: survey.available_space_depth_mm ? survey.available_space_depth_mm : survey.available_space_depth_m ? survey.available_space_depth_m * 1000 : 0,
        available_space_depth_m: undefined,
        // Cylinder available space
        cylinder_available_width_mm: survey.cylinder_available_width_mm ? survey.cylinder_available_width_mm : survey.cylinder_available_width_m ? survey.cylinder_available_width_m * 1000 : 0,
        cylinder_available_width_m: undefined,
        cylinder_available_height_mm: survey.cylinder_available_height_mm ? survey.cylinder_available_height_mm : survey.cylinder_available_height_m ? survey.cylinder_available_height_m * 1000 : 0,
        cylinder_available_height_m: undefined,
        cylinder_available_depth_mm: survey.cylinder_available_depth_mm ? survey.cylinder_available_depth_mm : survey.cylinder_available_depth_m ? survey.cylinder_available_depth_m * 1000 : 0,
        cylinder_available_depth_m: undefined,
        // Migrations to manifolds
        manifolds: !survey.manifolds ? [] : survey.manifolds.map(manifold => {
            var _a, _b, _c;
            return {
                ...manifold,
                ufh_temp_differs_from_system_temp: (_a = manifold.ufh_temp_differs_from_system_temp) !== null && _a !== void 0 ? _a : true,
                max_mean_water_temp_c: (_c = (_b = manifold.max_mean_water_temp_c) !== null && _b !== void 0 ? _b : (manifold.flow_temp_c && manifold.return_temp_c && (manifold.flow_temp_c + manifold.return_temp_c) / 2)) !== null && _c !== void 0 ? _c : 40,
                flow_temp_c: undefined, // set these to undefined to avoid confusion
                return_temp_c: undefined
            };
        })
    };
    // explicitly apply nested defaults
    // for floors
    result.floors = result.floors.map(floor => {
        return {
            ...DEFAULT_SURVEY_FLOOR,
            ...{
                ...floor,
                rooms: !floor.rooms ? [] : floor.rooms.map(room => {
                    return {
                        ...DEFAULT_SURVEY_ROOM,
                        ...{
                            ...room,
                            walls: !room.walls ? [] : room.walls.map(wall => {
                                return {
                                    ...DEFAULT_SURVEY_WALL,
                                    ...{
                                        ...wall,
                                        windows: !wall.windows ? [] : wall.windows.map(window => {
                                            return {
                                                ...DEFAULT_SURVEY_WINDOW,
                                                ...window,
                                                //   m to mm migration - delete once happy enough people have opened
                                                width_mm: window.width_mm ? window.width_mm : window.width_m ? window.width_m * 1000 : 0,
                                                width_m: undefined,
                                                height_mm: window.height_mm ? window.height_mm : window.height_m ? window.height_m * 1000 : 0,
                                                height_m: undefined
                                            };
                                        }),
                                        doors: !wall.doors ? [] : wall.doors.map(door => {
                                            return {
                                                ...DEFAULT_SURVEY_DOOR,
                                                ...door
                                            };
                                        })
                                    }
                                };
                            }),
                            radiators: !room.radiators ? [] : room.radiators.map(radiator => {
                                var _a;
                                radiator.survey_or_design = !radiator.survey_or_design ? radiator.type === 'DESIGN' ? 'DESIGN' : 'SURVEY' : radiator.survey_or_design;
                                radiator.emitter_type = !radiator.emitter_type ? (radiator.type === 'DESIGN' || radiator.type === 'SURVEY') ? 'RADIATOR' : radiator.type : radiator.emitter_type;
                                if (radiator.emitter_type === 'SECONDARY')
                                    return radiator;
                                if (radiator.emitter_type === 'UNDERFLOOR') {
                                    return { ...radiator, pipe_model_uuid: (_a = radiator.pipe_model_uuid) !== null && _a !== void 0 ? _a : (radiator.pipe_diameter_uuid === '16mm' ? 'pex_16' : 'pex_20') };
                                }
                                return {
                                    ...DEFAULT_SURVEY_RADIATOR,
                                    ...{
                                        ...radiator,
                                        // Rename radiator_diameter_uuid to radiator_model_uuid
                                        pipe_model_uuid: radiator.pipe_model_uuid ? radiator.pipe_model_uuid : radiator.pipe_diameter_uuid ? radiator.pipe_diameter_uuid : undefined,
                                        pipe_diameter_uuid: undefined,
                                        // Remove this "migration" after confident that all radiators have pipe_model_uuid
                                        photos: !radiator.photos ? [] : radiator.photos.map(photo => {
                                            return {
                                                ...DEFAULT_SURVEY_IMAGE,
                                                ...photo
                                            };
                                        })
                                    }
                                };
                            }),
                            images: !room.images ? [] : room.images.map(image => {
                                return {
                                    ...DEFAULT_SURVEY_IMAGE,
                                    ...image
                                };
                            })
                        }
                    };
                })
            }
        };
    });
    // for designs
    result.designs = !result.designs ? [] : result.designs.map(design => {
        return {
            ...DEFAULT_SURVEY_DESIGN,
            ...{
                ...design,
                // boilers: SurveyDesignBoiler[]
                quote_line_items: !design.quote_line_items ? [] : design.quote_line_items.map(item => {
                    return {
                        DEFAULT_QUOTE_LINE_ITEM,
                        ...item
                    };
                }),
                boilers: !design.boilers ? [] : design.boilers.map(boiler => {
                    return {
                        ...DEFAULT_SURVEY_DESIGN_BOILER,
                        ...boiler
                    };
                }),
                radiators: !design.radiators ? [] : design.radiators.map(radiator => {
                    return {
                        ...DEFAULT_DESIGN_RADIATOR,
                        ...{
                            ...radiator,
                            // Set design rad to default rad pipework if not defined. This is a temporary migration
                            // Ideally would do off paired survey rad, but not enough people using this that that's worth it
                            pipe_model_uuid: (radiator.emitter_type === 'RADIATOR' || radiator.emitter_type === 'UNDERFLOOR') && radiator.pipe_model_uuid ? radiator.pipe_model_uuid : survey.existing_system_pipework_uuid
                            // Remove this "migration" after confident that all radiators have pipe_model_uuid
                        }
                    };
                })
            }
        };
    });
    // for location_images
    result.location_images = !result.location_images ? [] : result.location_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    // for drain_photos
    result.drain_photos = !result.drain_photos ? [] : result.drain_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for cylinder_photos
    result.cylinder_photos = !result.cylinder_photos ? [] : result.cylinder_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for existing_cylinder_photos
    result.existing_cylinder_photos = !result.existing_cylinder_photos ? [] : result.existing_cylinder_photos.map(photo => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...photo
        };
    });
    // for property_images
    result.property_images = !result.property_images ? [] : result.property_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    // for electrics_images
    result.electrics_images = !result.electrics_images ? [] : result.electrics_images.map(image => {
        return {
            ...DEFAULT_SURVEY_IMAGE,
            ...image
        };
    });
    return result;
};
export const parseScottishEpc = async (epc, lead) => {
    if (!epc)
        return lead;
    const newLead = { ...lead };
    newLead.epc_scotland_id = epc.uprn;
    newLead.epc_scotland = epc;
    newLead.property = parseScottishEpcInner(epc, newLead.property) || newLead.property;
    // For scottish properties the EPC recommendations are stored in the EPC data on the "Improvements"
    // We should standardise these at some point so stored in the same structure on our side, but leaving for now
    newLead.epc_recommendations = [];
    return newLead;
};
export const parseScottishEpcInner = (epc, property) => {
    var _a, _b;
    if (!epc)
        return;
    const wallGroup = getWallGroup(epc.wall_description);
    const parsedEPCNoBedrooms = isNaN(epc.number_heated_rooms) ? 0 : epc.number_heated_rooms;
    const constructionAge = getConstructionAgeScotland(epc);
    const parsedConstructionAgeBand = parseConstructionAgeBand(constructionAge);
    // NPH: Technically there could be a studio flat with no bedrooms, but it's extremely unlikely that someone with a studio flat will be getting a heat pump
    // NPH: the -2 intuitively makes sense: the number of bedrooms is probably the number of habitable rooms minus the kitchen and living room. Of course there could also be a study or other rooms but it's a good approximation
    // IK: and if there is no EPC, we keep it 1 as well
    const noBedrooms = (parsedEPCNoBedrooms - 2) <= 1 ? 1 : parsedEPCNoBedrooms - 2;
    // SPR-44: An example which has no basis in real data, but feels right is: up to 3 bedrooms = 1 bathroom, 4-5 bedrooms = 2 bathrooms, 6-8 bedrooms = 3 bathrooms
    // NPH: we're estimating too many bathrooms for homes at the moment, so I checked the code and the bathroom estimation is being based the parsedEPCNoBedrooms (the number of habitable rooms) when it should be based on noBedrooms
    const noBathrooms = (noBedrooms <= 3) ? 1 : ([4, 5].includes(noBedrooms) ? 2 : 3);
    return {
        ...property,
        yearBuilt: constructionAge,
        construction_age_band_uuid: parsedConstructionAgeBand === null || parsedConstructionAgeBand === void 0 ? void 0 : parsedConstructionAgeBand.uuid,
        construction_age_band: parsedConstructionAgeBand,
        epcRating: epc.current_energy_rating,
        floorArea: (_a = tryParseFloat(epc.total_floor_area.toString(), property.floorArea)) !== null && _a !== void 0 ? _a : 0,
        roomHeight: (_b = tryParseFloat(epc.floor_height.toString(), property.roomHeight)) !== null && _b !== void 0 ? _b : 0,
        propertyType: getPropertyTypeScotland(epc),
        builtForm: getBuiltFormScotland(epc),
        windowType: getWindowTypeScotland(epc),
        fuelType: getFuelTypeFromEPC(epc.main_fuel),
        wallGroup,
        wallType: (wallGroup && getWallType(epc.wall_description, wallGroup)),
        floorType: getFloorInsulation(epc.floor_description),
        loftInsulation: getLoftInsulation(epc.roof_description),
        windowUValue: getUValue(epc.windows_description),
        floorUValue: getUValue(epc.floor_description),
        roofUValue: getUValue(epc.roof_description),
        wallUValue: getUValue(epc.wall_description),
        noBedrooms,
        noBathrooms
    };
};
export const parseEnglishEpc = async (epc, lead) => {
    const newLead = { ...lead };
    newLead.epcId = epc.lmkKey;
    newLead.epcData = epc;
    newLead.property = parseEnglishEpcInner(epc, newLead.property) || newLead.property;
    newLead.epc_recommendations = epc ? await getEpcRecommendations(epc.lmkKey) : [];
    return newLead;
};
export const parseEnglishEpcInner = (epc, property) => {
    var _a, _b;
    if (!epc)
        return;
    const wallGroup = getWallGroup(epc.wallsDescription);
    const parsedEPCNoBedrooms = isNaN(parseInt(epc.numberHeatedRooms)) ? 0 : parseInt(epc.numberHeatedRooms);
    const constructionAge = getConstructionAge(epc);
    const parsedConstructionAgeBand = parseConstructionAgeBand(constructionAge);
    // NPH: Technically there could be a studio flat with no bedrooms, but it's extremely unlikely that someone with a studio flat will be getting a heat pump
    // NPH: the -2 intuitively makes sense: the number of bedrooms is probably the number of habitable rooms minus the kitchen and living room. Of course there could also be a study or other rooms but it's a good approximation
    // IK: and if there is no EPC, we keep it 1 as well
    const noBedrooms = (parsedEPCNoBedrooms - 2) <= 1 ? 1 : parsedEPCNoBedrooms - 2;
    // SPR-44: An example which has no basis in real data, but feels right is: up to 3 bedrooms = 1 bathroom, 4-5 bedrooms = 2 bathrooms, 6-8 bedrooms = 3 bathrooms
    // NPH: we're estimating too many bathrooms for homes at the moment, so I checked the code and the bathroom estimation is being based the parsedEPCNoBedrooms (the number of habitable rooms) when it should be based on noBedrooms
    const noBathrooms = (noBedrooms <= 3) ? 1 : ([4, 5].includes(noBedrooms) ? 2 : 3);
    return {
        ...property,
        yearBuilt: constructionAge,
        construction_age_band_uuid: parsedConstructionAgeBand === null || parsedConstructionAgeBand === void 0 ? void 0 : parsedConstructionAgeBand.uuid,
        construction_age_band: parsedConstructionAgeBand,
        epcRating: epc.currentEnergyRating,
        floorArea: (_a = tryParseFloat(epc.totalFloorArea, property.floorArea)) !== null && _a !== void 0 ? _a : 0,
        roomHeight: (_b = tryParseFloat(epc.floorHeight, property.roomHeight)) !== null && _b !== void 0 ? _b : 0,
        propertyType: getPropertyType(epc),
        builtForm: getBuiltForm(epc),
        windowType: getWindowType(epc),
        fuelType: getFuelTypeFromEPC(epc.mainFuel),
        wallGroup,
        wallType: (wallGroup && getWallType(epc.wallsDescription, wallGroup)),
        floorType: (getFloorInsulation(epc.floorDescription)),
        loftInsulation: getLoftInsulation(epc.roofDescription),
        windowUValue: getUValue(epc.windowsDescription),
        floorUValue: getUValue(epc.floorDescription),
        roofUValue: getUValue(epc.roofDescription),
        wallUValue: getUValue(epc.wallsDescription),
        noBedrooms,
        noBathrooms
    };
};
export const ensureLeadHasLocation3D = async (lead, companyUUID) => {
    var _a, _b;
    // do not store if there is an information in property.location
    if (lead.property.postcodeLocation && lead.property.altitudeM)
        return lead;
    const location3D = await geocodeAddress(getAddressIncludingPostcode(lead), lead.property.postcode);
    if (!location3D)
        return lead;
    const newLead = {
        ...lead,
        property: {
            ...lead.property,
            postcodeLocation: (_a = lead.property.postcodeLocation) !== null && _a !== void 0 ? _a : [location3D.lat, location3D.lng],
            altitudeM: (_b = lead.property.altitudeM) !== null && _b !== void 0 ? _b : location3D.altitudeM
        }
    };
    await patchLead(lead.uuid, newLead, companyUUID);
    return newLead;
};
export const ensureLeadHasEPCRecommendations = async (lead, companyUUID, save) => {
    var _a;
    // do not store if there is an information in property.location
    if (!lead)
        return lead;
    if (lead.epc_recommendations !== undefined && lead.epc_recommendations !== null)
        return lead;
    const recommendations = ((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.lmkKey) ? await getEpcRecommendations(lead.epcData.lmkKey) : [];
    if (save) {
        await patchLead(lead.uuid, { epc_recommendations: recommendations }, companyUUID);
    }
    return { ...lead, epc_recommendations: recommendations };
};
