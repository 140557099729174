import React, { useContext } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Caption } from '../components/caption_1';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { Button } from '../../../components/buttons/button';
import { useRouteLoaderData } from 'react-router-dom';
import { logEvent } from '../../../code/log_event';
import { ColoredBlockWrapper } from '../components/colored_block_wrapper';
import { StepHeader } from '../components/steps_header';
import { SurveyDispatchContext } from '../survey_page';
export const NoEPCFoundStep = () => {
    // survey context and dispatch
    const dispatch = useContext(SurveyDispatchContext);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const handleNavigateNextPage = () => {
        logEvent({ name: 'No EPC found step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(StepHeader, { headerText: "We cannot find an energy certificate for your home" }),
        React.createElement(ColoredBlockWrapper, { color: "blue" },
            React.createElement("div", { className: "flex flex-col gap-6" },
                React.createElement(Caption, { content: "Please tell us about your home in the following steps." }),
                React.createElement(Button, { size: 'LG', onClick: handleNavigateNextPage },
                    React.createElement("div", { className: "flex items-center gap-x-2" },
                        React.createElement("div", null, "Tell us about your home"),
                        React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))))));
};
